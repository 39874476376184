<template>
  <v-card flat class="pa-1">
    <v-card class="mx-auto" flat outlined>
      <v-toolbar dense flat>
        <v-toolbar-title class="text-subtitle-1">
          <v-btn
            x-small
            elevation="0"
            fab
            text
            class="white--text"
            :color="getColor(customer.gender)"
          >
            <font-awesome-icon
              :icon="['fal', 'voicemail']"
              fixed-width
              style="font-size: 18px"
            />
          </v-btn>
          <span class="ml-1 mt-4">
            Recados do paciente:
            <span class="font-weight-medium">{{
              customer.fullName
            }}</span></span
          ></v-toolbar-title
        >

        <v-spacer></v-spacer>
      </v-toolbar>

      <v-divider></v-divider>
      <v-card flat class="pa-2" color="transparent">
        <v-data-iterator
          v-if="message"
          :items="message.messages"
          :items-per-page.sync="itemsPerPage"
          :page.sync="page"
          :search="search"
          :sort-by="sortBy.toLowerCase()"
          :sort-desc="sortDesc"
          hide-default-footer
        >
          <template v-slot:header>
            <v-toolbar
              class="rounded mb-2"
              height="50"
              width="100%"
              elevation="0"
            >
              <v-text-field
                outlined
                color="grey darken-1"
                v-model="search"
                dense
                clearable
                flat
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Pesquisar"
              ></v-text-field>
              <template v-if="$vuetify.breakpoint.mdAndUp">
                <v-select
                  outlined
                  color="grey darken-1"
                  class="fit ml-2"
                  full-width
                  v-model="sortBy"
                  flat
                  hide-details
                  dense
                  :items="keys"
                  prepend-inner-icon="mdi-magnify"
                  label="Ordernar"
                ></v-select>
                <v-spacer></v-spacer>
                <v-btn-toggle v-model="sortDesc" mandatory>
                  <v-btn fab dark outlined x-small color="grey" :value="true">
                    <v-icon>mdi-arrow-up</v-icon>
                  </v-btn>
                  <v-btn fab dark outlined x-small color="grey" :value="false">
                    <v-icon>mdi-arrow-down</v-icon>
                  </v-btn>
                </v-btn-toggle>
              </template>
              <v-spacer></v-spacer>
              <template>
                <span class="ml-4">
                  Página {{ page }} de {{ numberOfPages }}
                </span>
                <v-btn
                  fab
                  dark
                  x-small
                  outlined
                  color="grey"
                  elevation="0"
                  class="ml-4 mr-1"
                  @click="formerPage"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn
                  fab
                  dark
                  x-small
                  outlined
                  color="grey"
                  class="ml-1 mr-4"
                  elevation="0"
                  @click="nextPage"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </template>
              <v-spacer></v-spacer>
              <template>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      color="grey darken-1"
                      elevation="0"
                      dark
                      class="ml-2 mr-4"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ itemsPerPage }}
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(number, index) in itemsPerPageArray"
                      :key="index"
                      @click="updateItemsPerPage(number)"
                    >
                      <v-list-item-title>{{ number }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-toolbar>
          </template>

          <template v-slot:default="props" class="ml-15 mr-15">
            <v-row>
              <v-col class="col-fluid">
                <v-alert
                  dense
                  v-for="message in props.items"
                  :key="message.id"
                  outlined
                  :color="getColor(message)"
                  :type="getType(message)"
                  prominent
                >
                  <span class="caption seconday--text">{{
                    message.created.toDate() | moment("from", "now")
                  }}</span>
                  <span class="ml-1 caption grey--text text--darken-3"
                    ><div v-html="message.message"></div> </span>
                  <container v-if="message.url">
                    <audio class="mt-2 mb-n1" controls>
                      <source :src="message.url" type="audio/ogg" />
                      Your browser does not support the audio element.
                    </audio>
                  </container>
                </v-alert>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </v-card>
    </v-card>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: true,
      itemsPerPageArray: [3, 9, 15],
      search: "",
      sortDesc: true,
      page: 1,
      itemsPerPage: 4,
      sortBy: "created",
      keys: [{ text: "Data", value: "created" }],
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.message.messages.length / this.itemsPerPage);
    },
    ...mapGetters({
      customer: "customer",
      message: "message",
    }),
  },
  methods: {
    getColor(message) {
      if (message) {
        if (message.read) return "grey lighten-1";
        else return "secondary";
      }
    },
    getType(message) {
      if (message) {
        if (message.read) return "success";
        else return "info";
      }
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
  },
};
</script>
<style scoped>
.v-select.fit {
  width: min-content;
}
.v-select.fit .v-select__selection--comma {
  text-overflow: unset;
}
</style>
