import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

export default {
  state: {
    journeys: [],
    journeysHistory: [],
  },
  mutations: {},
  actions: {
    unbindJourneys: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef("journeys", true);
    }),
    bindJourneys: firestoreAction(async (context, data) => {
      let journey = data.journey;
      let ref = db.collection("journeys");
      ref = ref.where("active", "==", true);
      ref = ref.where("journey", "==", journey);
      await context.bindFirestoreRef("journeys", ref, { reset: true });
    }),
    unbindJourneysHistory: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef("journeysHistory", true);
    }),
    bindJourneysHistory: firestoreAction(async (context, data) => {
      let journey = data.journey;
      let ref = db.collection("journeys");
      ref = ref.where("active", "==", false);
      ref = ref.where("journey", "==", journey);
      await context.bindFirestoreRef("journeysHistory", ref, { reset: true });
    }),
  },
  getters: {
    journeys(state) {
      return state.journeys;
    },

    journeysHistory(state) {
      return state.journeysHistory;
    },
  },
};
