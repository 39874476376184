import Vue from "vue";
import App from "./App.vue";

import LoadScript from "vue-plugin-load-script";
Vue.use(LoadScript);

import VueMeta from "vue-meta";
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});

import { library } from "@fortawesome/fontawesome-svg-core";
import { faToggleOff, faToggleOn } from "@fortawesome/pro-duotone-svg-icons";
import { faBrainCircuit } from "@fortawesome/pro-thin-svg-icons";
import {
  faReplyClock,
  faUserShield,
  faDisplayMedical,
  faRobot,
  faReply,
  faHandHoldingMedical,
  faEye,
  faVial,
  faLink,
  faBrain,
  faCommentAlt,
  faUserPlus,
  faCheck,
  faCheckDouble,
  faUserEdit,
  faPen,
  faClinicMedical,
  faVirus,
  faWalking,
  faRulerVertical,
  faWeight,
  faUniversalAccess,
  faInfoCircle,
  faStarExclamation,
  faScalpelPath,
  faWheelchair,
  faKidneys,
  faTint,
  faDisease,
  faHeartbeat,
  faBoneBreak,
  faStomach,
  faMeh,
  faLips,
  faHeat,
  faWind,
  faBaby,
  faHeadSide,
  faLungs,
  faExclamationTriangle,
  faUserInjured,
  faHeadSideCough,
  faThermometerFull,
  faBurgerSoda,
  faHourglassEnd,
  faCalendarAlt,
  faClipboardUser,
  faLaptopMedical,
  faTemperatureHigh,
  faSignOut,
  faSyringe,
  faFileMedicalAlt,
  faBookMedical,
  faUserMdChat,
  faMobileAndroid,
  faMailbox,
  faIdCard,
  faBabyCarriage,
  faHistory,
  faPeopleArrows,
  faHouseLeave,
  faBriefcase,
  faNotesMedical,
  faCalendarPlus,
  faUserMd,
  faHandHeart,
  faBacterium,
  faDewpoint,
  faPrescriptionBottleAlt,
  faPlusSquare,
  faTrashAlt,
  faWindowClose,
  faInboxOut,
  faSave,
  faRecycle,
  faTrash,
  faMonitorHeartRate,
  faDiagnoses,
  faPills,
  faAmbulance,
  faFilePrescription,
  faBuilding,
  faXRay,
  faFileMedical,
  faCommentAltMedical,
  faTachometerAltFast,
  faAllergies,
  faTired,
  faBed,
  faHospitalUser,
  faPaperPlane,
  faChartArea,
  faInfo,
  faDoorClosed,
  faCameraWeb,
  faChartUser,
  faSquareInfo,
  faFileCircleXmark,
  faPersonCircleCheck,
  faBallotCheck,
  faVoicemail,
  faCircleExclamationCheck,
  faNoteMedical,
  faRetweet,
  faSirenOn,
  faBars,
  faTimelineArrow,
  faFaceThermometer,
  faPersonWalkingArrowRight,
  faMagnifyingGlassChart,
  faFileImport,
  faStamp,
  faSquarePollHorizontal,
  faThumbsUp,
  faCommentSlash,
  faMapLocationDot,
  faSquareCaretRight,
  faSquareQuestion,
  faSitemap,
  faTimer,
  faUser,
  faCopy,
  faTicket,
  faCalendarPen,
  faUserSlash,
  faMessage,
  faGauge,
  faGaugeMax,
  faGaugeLow,
  faChartLine,
} from "@fortawesome/pro-light-svg-icons";
import {
  faArrowsV,
  faRunning,
  faSmoking,
  faDesktop,
  faHeadSideMedical,
} from "@fortawesome/pro-solid-svg-icons";
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from "@fortawesome/vue-fontawesome";

import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "@/plugins/vuetify";
import VueSweetalert2 from "vue-sweetalert2";

import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import "tiptap-vuetify/dist/main.css";

import { auth, db } from "./firebase";

import "./scss/main.scss";

library.add(
  faChartLine,
  faGaugeMax,
  faGaugeLow,
  faGauge,
  faMessage,
  faUserSlash,
  faCalendarPen,
  faTicket,
  faReplyClock,
  faUserShield,
  faCopy,
  faDisplayMedical,
  faUser,
  faTimer,
  faSitemap,
  faSquareQuestion,
  faRobot,
  faToggleOff,
  faToggleOn,
  faSquareCaretRight,
  faMapLocationDot,
  faCommentSlash,
  faThumbsUp,
  faSquarePollHorizontal,
  faBrainCircuit,
  faFaceThermometer,
  faSirenOn,
  faNoteMedical,
  faCircleExclamationCheck,
  faBallotCheck,
  faPersonCircleCheck,
  faFileCircleXmark,
  faSquareInfo,
  faChartUser,
  faCameraWeb,
  faReply,
  faHandHoldingMedical,
  faDoorClosed,
  faInfo,
  faEye,
  faVial,
  faLink,
  faBrain,
  faCommentAlt,
  faUserPlus,
  faCheck,
  faCheckDouble,
  faUserEdit,
  faPen,
  faPaperPlane,
  faHospitalUser,
  faClinicMedical,
  faVirus,
  faTired,
  faWalking,
  faRulerVertical,
  faUniversalAccess,
  faInfoCircle,
  faStarExclamation,
  faScalpelPath,
  faWheelchair,
  faKidneys,
  faTint,
  faDisease,
  faHeartbeat,
  faBoneBreak,
  faStomach,
  faMeh,
  faLips,
  faHeat,
  faWind,
  faHeadSide,
  faBaby,
  faLungs,
  faExclamationTriangle,
  faUserInjured,
  faHeadSideCough,
  faThermometerFull,
  faBurgerSoda,
  faHourglassEnd,
  faAllergies,
  faClipboardUser,
  faLaptopMedical,
  faTemperatureHigh,
  faSignOut,
  faSyringe,
  faFileMedicalAlt,
  faBookMedical,
  faUserMdChat,
  faMobileAndroid,
  faMailbox,
  faIdCard,
  faBabyCarriage,
  faHistory,
  faPeopleArrows,
  faHouseLeave,
  faBriefcase,
  faTint,
  faWeight,
  faArrowsV,
  faRunning,
  faTired,
  faBed,
  faSmoking,
  faNotesMedical,
  faCalendarPlus,
  faUserMd,
  faHandHeart,
  faBacterium,
  faDewpoint,
  faPrescriptionBottleAlt,
  faPlusSquare,
  faTrashAlt,
  faWindowClose,
  faInboxOut,
  faSave,
  faRecycle,
  faTrash,
  faMonitorHeartRate,
  faDiagnoses,
  faPills,
  faAmbulance,
  faFilePrescription,
  faBuilding,
  faDesktop,
  faFileMedical,
  faXRay,
  faCommentAltMedical,
  faHeadSideMedical,
  faTachometerAltFast,
  faCalendarAlt,
  faChartArea,
  faVoicemail,
  faRetweet,
  faBars,
  faTimelineArrow,
  faPersonWalkingArrowRight,
  faMagnifyingGlassChart,
  faFileImport,
  faStamp
);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("font-awesome-layers", FontAwesomeLayers);
Vue.component("font-awesome-layers-text", FontAwesomeLayersText);

Vue.use(VueSweetalert2);
Vue.use(TiptapVuetifyPlugin, { vuetify, iconsGroup: "mdi" });

const moment = require("moment");

require("moment/locale/pt");
Vue.use(require("vue-moment"), { moment });

import VuetifyMask from "vuetify-mask";
Vue.use(VuetifyMask);

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

import CountdownPlus from "vue-countdown-plus";
Vue.component(CountdownPlus.name, CountdownPlus);

Vue.prototype.$sisVersion = require("./version/appversion.json");

import browserDetect from "vue-browser-detect-plugin";
Vue.use(browserDetect);

import UUID from "vue-uuid";
Vue.use(UUID);

Vue.config.productionTip = false;
Vue.config.devtools = false;

import SecurityService from "./Services/SecurityService";
Vue.prototype.$SecurityService = SecurityService;

import LoggerService from "./Services/LoggerService";
Vue.prototype.$LoggerService = LoggerService;
// Handle all Vue errors
Vue.config.errorHandler = (error) => LoggerService.onVueError(error);
Vue.config.warnHandler = (warn) => LoggerService.onVueWarn(warn);

import * as ss from "simple-statistics";
Vue.prototype.$ss = ss;

export let app;
auth.onAuthStateChanged(async (user) => {
  if (!app) {
    if (user) {
      await auth.signOut();
    }
    app = new Vue({
      router,
      store,
      vuetify,
      metaInfo: () => ({ title: "SIS-Sistema de Inteligência em Saúde" }),
      render: (h) => h(App),
    }).$mount("#app");
  }

  if (user) {
    user.getIdTokenResult().then(async (idTokenResult) => {
      if (!idTokenResult.claims.professional) {
        auth.signOut().then(() => {
          router.go();
        });
      }
    });
    if (!user.getIdToken(false)) {
      auth.signOut().then(() => {
        router.go();
      });
    }
  }
});
