import { firestoreAction } from "vuexfire";
import { db } from "../../../firebase";

export default {
  state: {
    pastHistoryOfCurrentDisease: null,
    diagnosticHypothesis: null,
    healthNote: null,
    diagnosticHypothesisCid: [],
    medicalHandling: [],
  },
  mutations: {
    SET_PAST_HISTORY_OF_CURRENT_DISEASE(state, data) {
      state.pastHistoryOfCurrentDisease = data;
    },

    SET_DIAGNOSTIC_HYPOTHESIS(state, data) {
      state.diagnosticHypothesis = data;
    },

    SET_DIAGNOSTIC_HYPOTHESIS_CID(state, data) {
      state.diagnosticHypothesisCid = data;
    },

    SET_MEDICAL_HANDLING(state, data) {
      state.medicalHandling = data;
    },
    SET_HEALTH_NOTE(state, data) {
      state.healthNote = data;
    },
  },
  actions: {
    setPastHistoryOfCurrentDisease({ commit }, value) {
      commit("SET_PAST_HISTORY_OF_CURRENT_DISEASE", value);
    },
    setDiagnosticHypothesis({ commit }, value) {
      commit("SET_DIAGNOSTIC_HYPOTHESIS", value);
    },
    setDiagnosticHypothesisCid({ commit }, value) {
      commit("SET_DIAGNOSTIC_HYPOTHESIS_CID", value);
    },
    setMedicalHandling({ commit }, value) {
      commit("SET_MEDICAL_HANDLING", value);
    },
    setHealthNote({ commit }, value) {
      commit("SET_HEALTH_NOTE", value);
    },
  },
  getters: {
    pastHistoryOfCurrentDisease(state) {
      return state.pastHistoryOfCurrentDisease;
    },
    diagnosticHypothesis(state) {
      return state.diagnosticHypothesis;
    },
    diagnosticHypothesisCid(state) {
      return state.diagnosticHypothesisCid;
    },
    medicalHandling(state) {
      return state.medicalHandling;
    },
    healthNote(state) {
      return state.healthNote;
    },
  },
};
