<template>
  <v-chip x-small label :class="status.elevation" :color="status.color" :text-color="status.textColor">{{ status.text }}
  </v-chip>
</template>

<script>
import { db } from "../../../../../firebase";

export default {
  props: {
    monitor: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      healthAlerts: 0,
      anamnesisNotAnsweredAlerts: 0,
    };
  },
  watch: {
    async monitor(newValue) {
      this.healthAlerts = 0;
      this.anamnesisNotAnsweredAlerts = 0;
      await this.init();
    },
  },
  computed: {
    status() {
      let status = {};
      status.elevation = "elevation-0";
      if (this.monitor.executed) {
        if (this.monitor.cancelled) {
          if (this.anamnesisNotAnsweredAlerts > 0) {
            status.color = "amber darken-4";
            status.text = "QUESTIONÁRIO OBRIGATÓRIO NÃO RESPONDIDO";
            status.textColor = "white";
          } else {
            status.color = "yellow darken-3";
            status.text = "QUESTIONÁRIO NÃO RESPONDIDO";
            status.textColor = "white";
          }
        } else {
          if (this.monitor.action == "message") {
            status.color = "success darken-1";
            status.text = "MENSAGEM ENVIADA";
            status.textColor = "white";
          } else if (this.monitor.interview || this.monitor.survey) {
            if (this.healthAlerts > 0) {
              if (this.monitor.answered) {
                status.color = "error darken-1";
                status.text = "ALERTA DE SAÚDE";
                status.textColor = "white";
              } else {
                status.color = "error darken-1";
                status.text = "ALERTA DE SAÚDE [PACIENTE AINDA RESPONDENDO]";
                status.textColor = "white";
              }
            } else {
              if (this.monitor.answered) {
                status.color = "primary";
                status.text = "QUESTIONÁRIO RESPONDIDO";
                status.textColor = "white";
              } else {
                status.color = "secondary darken-1";
                status.text = "PACIENTE RESPONDENDO QUESTIONÁRIO";
                status.textColor = "white";
              }
            }
          } else {
            if (this.monitor.quit) {
              status.color = "grey darken-1";
              status.text = "CANCELADO";
              status.textColor = "white";
            } else {
              status.color = "grey darken-1";
              status.text = "AGUARDANDO O PACIENTE RESPONDER";
              status.textColor = "white";
            }

          }
        }
      } else {
        status.color = "grey lighten-4";
        status.text = "NÃO ENVIADO";
        status.textColor = "grey lighten-1";
        status.elevation = "elevation-0";
      }
      return status;
    },
  },
  methods: {
    async init() {
      let monitorRef = db.collection("monitoring-actions").doc(this.monitor.id);
      let healthAlertsCollection = await monitorRef
        .collection("alerts")
        .where("type", "==", "healthAlert")
        .where("treated", "==", false)
        .get();
      this.healthAlerts =
        healthAlertsCollection.docs != null
          ? healthAlertsCollection.docs.length
          : 0;

      let anamnesisNotAnsweredAlertsCollection = await monitorRef
        .collection("alerts")
        .where("type", "==", "anamnesisNotAnswered")
        .where("treated", "==", false)
        .get();
      this.anamnesisNotAnsweredAlerts =
        anamnesisNotAnsweredAlertsCollection.docs != null
          ? anamnesisNotAnsweredAlertsCollection.docs.length
          : 0;
    },
  },
  async created() {
    await this.init();
  },
};
</script>
