<template>
	<v-list-item link router :to="item.router">
		<v-list-item-action>
			<font-awesome-icon :icon="['fal', item.icon]" style="font-size: 20px" fixed-width />
		</v-list-item-action>
		<v-list-item-content>
			<v-list-item-title class="caption font-weight-light">{{ item.title }}</v-list-item-title>
		</v-list-item-content>
	</v-list-item>
</template>

<script>
	export default {
		props: {
			item: Object,
		},
		computed: {},
		methods: {},
	};
</script>

<style></style>
