<template>
  <v-dialog v-model="dialog" max-width="1200" eager>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            small
            color="primary"
            text
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
            elevation="0"
            min-width="35"
            max-width="35"
            min-height="35"
            max-height="35"
          >
            <font-awesome-icon
              :icon="['fal', 'calendar-plus']"
              fixed-width
              style="font-size: 25px"
            />
          </v-btn>
        </template>
        <span>Agendar Consulta</span>
      </v-tooltip>
    </template>
    <v-card>
      <NewSchedule :patient="patient" v-if="dialog" @closeSchedule="closeSchedule"></NewSchedule>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import NewSchedule from "./NewSchedule.vue";

export default {
  data: () => ({
    dialog: false,
  }),
  props: {
    patient: {
      type: String,
      required: false,
      default: null,
    },
  },
  components: { NewSchedule },
  computed: {
    ...mapGetters({}),
  },
  watch: {},
  methods: {
    closeSchedule() {
      this.$emit("closeSchedule");
      this.dialog = false;
    },
  },
  destroyed() {},
};
</script>

<style>
</style>