<template>
  <v-card class="mx-auto" flat>
    <v-card class="mb-2 rounded" flat color="grey lighten-3">
      <v-toolbar flat height="35" elevation="0" class="secondary">
        <v-toolbar-title class="subtitle-2 white--text"
          >{{sequence}}. Conduta</v-toolbar-title
        >
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card>
    <v-card class="mt-2 pa-0" flat>
      <v-autocomplete
        width="100%"
        outlined
        flat
        chips
        clearable
        deletable-chips
        multiple
        small-chips
        v-model="handlings"
        :items="items"
        color="blue-grey lighten-2"
        label="Selecione as condutas"
        :return-object="true"
        item-text="value"
        item-value="value"
      >
        <template v-slot:item="data">
          <template>
            <v-list-item-content>
              <v-list-item-title v-html="data.item.value"></v-list-item-title>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
    </v-card>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    sequence: {
      type: Number,
      required: true,
      default: null,
    },
  },
  data: () => ({
    autoUpdate: true,
    isUpdating: false,
    handlings: [],
    items: [
      { code: "1", value: "Instrução e informação do paciente" },
      { code: "2", value: "Prescrição do tratamento" },
      { code: "3", value: "Solicitação de exames" },
      {
        code: "4",
        value: "Acompanhamento em atendimento primário",
      },
      { code: "5", value: "Sugestão para agendar consulta com especialista" },
      { code: "6", value: "Instrução de buscar atenção médica com agilidade" },
    ],
  }),
  components: {},
  computed: {
    ...mapGetters({
      medicalHandling: "medicalHandling",
    }),
  },

  watch: {
    handlings(newValue, oldValue) {
      this.$store.dispatch("setMedicalHandling", newValue);
    },
  },
  methods: {
    remove(item) {
      const index = this.items.indexOf(item.code);
      if (index >= 0) this.items.splice(index, 1);
    },
  },
  created() {
    this.handlings = this.medicalHandling;
  },
};
</script>

<style>
</style>
