<template>
  <v-card color="transparent mt-1 ml-5 mr-5" flat tile>
    <iframe
      class="data-studio"
      src="https://datastudio.google.com/embed/reporting/82be3aa7-d609-4844-b737-d0efa1a4e594/page/huQ5B"
      frameborder="0"
      style="border: 0"
      allowfullscreen
    ></iframe>
  </v-card>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() {},
};
</script>
<style lang="scss">
@import "../../../scss/_variables.scss";
.data-studio {
  @media #{$xxlmax-device} {
    width: 100%;
    height: 920px;
  }

  @media #{$xlmax-device} {
    width: 100%;
    height: 700px;
  }

  @media #{$desktop-device} {
    width: 100%;
    height: 600px;
  }

  @media #{$tablet-device} {
    width: 100%;
    height: 800px;
  }

  @media #{$large-mobile} {
    height: 700px;
  }

  @media #{$extra-small-mobile} {
    height: 600px;
  }
}
</style>