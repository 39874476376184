<template>
  <v-card class="mx-auto" flat>
    <v-card class="mb-2 rounded" flat color="grey lighten-3">
      <v-toolbar flat height="50" elevation="0" class="secondary">
        <v-toolbar-title class="subtitle-2 white--text"
          >2. Nota de Saúde</v-toolbar-title
        >
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card>
    <HealthNote></HealthNote>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import HealthNote from "../../../components/note/HealthNote.vue";

export default {
  data: () => ({}),
  components: { HealthNote },
  computed: {},
  methods: {},
};
</script>

<style>
</style>