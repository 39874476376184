<template>
  <v-card class="mx-auto" flat>
    <v-card class="mb-2 rounded" flat color="grey lighten-3">
      <v-toolbar flat height="35" elevation="0" class="warning">
        <v-toolbar-title class="subtitle-2 grey--text text--darken-3">{{ sequence }}. Liberar</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small class="mr-n3" height="25" dark color="primary" elevation="0" center
          @click="close()">
          <font-awesome-icon class="mr-2" color="white" :icon="['fal', 'user']" fixed-width
            style="font-size: 15px" />Liberar Paciente
        </v-btn>
      </v-toolbar>
    </v-card>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { functions } from "../../../../../../firebase";

export default {
  props: {
    sequence: {
      type: String,
      required: true,
      default: null,
    },
  },
  data: () => ({}),
  components: {},
  computed: {
    ...mapGetters({
      customer: "customer",
      queueInfo: "queueInfo",
    }),
  },
  watch: {},
  methods: {
    async close() {
      await this.$store.dispatch("fetchQueueInfo", this.queueInfo);
      this.$swal
        .fire({
          title: "Liberar Paciente",
          text:
            "Você realmente deseja liberar o paciente " +
            this.customer.fullName +
            " do seu atendimento?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            this.$swal.showLoading();
            this.$swal.getCancelButton().setAttribute("disabled", "");

            let sisInterviewReleasePatient = functions.httpsCallable(
              "sis-interview-release-patient-api"
            );
            await sisInterviewReleasePatient({
              queueInfo: this.queueInfo,
            })
              .then(async (result) => {
                return;
              })
              .catch((error) => {
                this.$swal.getCancelButton().setAttribute("enabled", "");
                this.$swal.showValidationMessage(
                  `Erro ao liberar o paciente: ${error}`
                );
                console.error(error.message);
              });
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.$store.dispatch("releaseRoom");
            this.$swal
              .fire({
                icon: "success",
                title: "Paciente liberado com sucesso! ",
                toast: false,
                showConfirmButton: true,
                timer: 1000,
                timerProgressBar: true,
              })
          }
        });
    },
  },
  created() {

  },
};
</script>

<style>

</style>
