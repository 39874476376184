<template>
  <v-dialog v-model="dialog" max-width="800" eager>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate :rotate="360" :size="100" :width="8" color="white">
      </v-progress-circular>
    </v-overlay>
    <template v-slot:activator="{ on: dialog, attrs: attrsDialog }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip, attrs: attrsTooltip }">
          <v-chip v-if="tags != null && tags.length > 0" v-bind="{ ...attrsTooltip, ...attrsDialog }"
            v-on="{ ...tooltip, ...dialog }" x-small label :class="tag().elevation" :color="tag().color"
            :text-color="tag().textColor">{{
            tag().text }}</v-chip>
          <v-chip v-else x-small label :class="tag().elevation" :color="tag().color" :text-color="tag().textColor">{{
          tag().text }}</v-chip>
        </template>
        <span>Classificar Monitoria</span>
      </v-tooltip>
    </template>
    <v-card flat class="pa-1">

      <v-card class="mx-auto" flat outlined>
        <v-toolbar dense flat>
          <v-toolbar-title class="text-subtitle-1">
            <v-btn class="mr-1" text small elevation="0" color="grey--text text--darken-2" min-width="12" max-width="12"
              min-height="20" max-height="20">
              <font-awesome-icon :icon="['fal', 'stamp']" fixed-width style="font-size: 20px" />
            </v-btn>
            <span class="ml-1 mt-4">Classificação</span>
          </v-toolbar-title>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card flat color="transparent">
          <v-card-text>
            <v-select :items="tags" label="Classificação" item-text="text" clearable outlined v-model="selectedTag"
              :return-object="true"></v-select>
          </v-card-text>
          <v-card-actions class="mt-n8">
            <v-spacer></v-spacer>
            <v-btn dark color="primary" elevation="0" text @click="classify()">
              <v-icon dark>mdi-stamper</v-icon> Classificar
            </v-btn>
            <v-btn dark color="error" elevation="0" text @click="remove()">
              <v-icon dark>mdi-delete</v-icon> Remover
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-card>
  </v-dialog>

</template>

<script>
import { mapGetters } from "vuex";
import { db } from "../../../../../firebase";
export default {
  components: {},
  props: {
    monitoring: {
      type: Object,
      required: false,
      default: null,
    }
  },
  data() {
    return {
      tags: [],
      selectedTag: null,
      lastTag: null,
      dialog: false,
      overlay: false,
    };
  },
  watch: {
  },
  computed: {
    ...mapGetters({
      monitors: "monitors",
    }),
  },
  methods: {
    tag() {
      let tag = {};
      tag.elevation = "elevation-0";
      if (this.lastTag) {
        tag.color = "secondary darken-1";
        tag.text = (this.lastTag ? this.lastTag.text : '');
        tag.textColor = "white";
      } else {
        tag.color = "grey lighten-4";
        tag.text = "SEM CLASSIFICAÇÃO";
        tag.textColor = "grey lighten-1";
        tag.elevation = "elevation-0";
      }
      return tag;
    },
    async classify() {
      if (this.selectedTag) {
        this.overlay = true;
        let monitoringRef = await db.collection("monitoring").doc(this.monitoring.id);
        monitoringRef.update({ tag: this.selectedTag }, { merge: true }).then(() => {
          this.$swal.fire({
            icon: "success",
            title: "Monitoria classificada com sucesso! ",
            toast: true,
            position: "bottom",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
          this.lastTag = this.selectedTag;
          this.dialog = false;
          this.overlay = false;
        })
      }
    },
    async remove() {
      if (this.selectedTag) {
        this.overlay = true;
        let monitoringRef = await db.collection("monitoring").doc(this.monitoring.id);
        monitoringRef.update({ tag: null }, { merge: true }).then(() => {
          this.$swal.fire({
            icon: "success",
            title: "Classificação removida com sucesso! ",
            toast: true,
            position: "bottom",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
          this.lastTag = null;
          this.dialog = false;
          this.overlay = false;
        })
      }
    },
    async init() {
      this.tags = await this.monitors.find((m) => m.queryId === this.monitoring.monitor.queryId).tags;
      this.selectedTag = this.monitoring.tag;
      this.lastTag = this.monitoring.tag;
    },
  },
  async created() {
    await this.init();
  },
};
</script>
