<template>
  <v-card color="transparent ml-5 mr-5" flat tile>
    <v-card-title>
      Conversas
      <v-spacer></v-spacer>
    </v-card-title>
    <vue-advanced-chat height="calc(80vh)" :current-user-id="professional.id" :message-actions="messageActions"
      :templates-text="templatesText" :text-messages="textMessages" :showSearch="true" :show-files="true"
      :show-audio="true" :show-emojis="true" :show-reaction-emojis="true" :show-new-messages-divider="true"
      :show-footer="true" :text-formatting="true" :textarea-action-enabled="true" :single-room="true" :rooms="rooms"
      :rooms-loaded="true" accepted-files="image/png, image/jpeg, application/pdf" :messages="chat.messages"
      :messages-loaded="messagesLoaded" @send-message="sendMessage" @fetch-messages="fetchMessages" :styles="{
        general: {
          color: '#0a0a0a',
          colorSpinner: '#333',
          borderStyle: '1px solid #e1e4e8',
        },
      
        container: {
          boxShadow:
            '0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)',
        },
      
        content: {
          background: '#f8f8f8',
        },
      
        footer: {
          background: '#ffffff',
          backgroundReply: 'rgba(0, 0, 0, 0.08)',
        },
      
        icons: {
          search: '#9ca6af',
        },
      }">
    </vue-advanced-chat>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { functions } from "../../../firebase";
import { register } from 'vue-advanced-chat'
register()


export default {
  components: {
  },
  data() {
    return {
      telemedicine: "",
      textMessages: {
        ROOMS_EMPTY: "Sem conversa",
        ROOM_EMPTY: "Nenhuma conversa selecionada",
        NEW_MESSAGES: "Novas mensagens",
        MESSAGE_DELETED: "Esta mensagem foi removida",
        MESSAGES_EMPTY: "Nenhuma mensagem",
        CONVERSATION_STARTED: "A conversa começou em:",
        TYPE_MESSAGE: "Digite sua mensagem",
        SEARCH: "Pesquisar",
        IS_ONLINE: "Está online",
        LAST_SEEN: "Última conexão ",
        IS_TYPING: "está digitando...",
      },
      messageActions: [
        {
          name: "addNote",
          title: "Salvar como nota",
        },
      ],
      templatesText: [],
      rooms: [
        {
          roomId: null,
          roomName: "",
          avatar:
            "https://firebasestorage.googleapis.com/v0/b/icareyou-dev.appspot.com/o/img%2Fbubble.png?alt=media&token=089ff61d-c6f8-4286-9539-65ccceeb2991",
          users: [],
          typingUsers: [],
        },
      ],
      messagesLoaded: false,
      messages: [],
    };
  },
  computed: {
    ...mapGetters({
      chat: "chat",
      customer: "customer",
      professional: "professional",
      interview: "interview",
    }),
  },
  methods: {
    async fetchMessages() {
      this.messagesLoaded = true;
    },
    async sendMessage(message) {
      let sendMessage = functions.httpsCallable(
        "sis-conversation-send-message-api"
      );
      await sendMessage({
        id: this.chat.id,
        senderId: this.professional.id,
        message: message,
        username: this.professional.name,
        customer: this.customer,
        professional: this.professional,
      })
        .then((result) => {
          return;
        })
        .catch((error) => {
          console.error(error.message);
        });
    },
  },
  mounted() {
    this.templatesText.push({
      tag: "sala",
      text:
        this.professional.telemedicine +
        "?username=" +
        this.customer.name +
        "&autocheckin=true" +
        "&l=pt",
    });
    this.rooms[0].roomName = this.customer.fullName;
    this.rooms[0].roomId = this.chat.id;
    this.rooms[0].users.push({
      _id: this.customer.id,
      username: this.customer.name,
      status: {
        state: "online",
      },
    });
    this.rooms[0].users.push({
      _id: this.professional.id,
      username: this.professional.name,
      status: {
        state: "online",
      },
    });
  },
};
</script>

<style>

</style>