<template>
  <v-card flat width="100%" color="transparent" class="mt-4">
    <v-overlay :value="overlay">
      <v-progress-circular
        :rotate="360"
        :size="100"
        :width="15"
        :value="load"
        color="white"
      >
        {{ Math.trunc(load) }}
      </v-progress-circular>
    </v-overlay>
    <v-data-table
      class="elevation-0 transparent"
      :headers="headers"
      :items="queueClosed"
      :search="search"
      :items-per-page="10"
      :sort-by="['cloased']"
      :sort-desc="[false]"
      :calculate-widths="true"
      :loading="loading"
    >
      <template v-slot:[`item.action`]="{ item }">
        <ActionsMenu :patient="item.uid" :name="item.fullName"></ActionsMenu>
      </template>

      <template v-slot:[`item.age`]="{ item }">
        <AgeChip :customer="item.uid"></AgeChip>
      </template>

      <template v-slot:[`item.encounterTime`]="{ item }">
        <v-chip label color="success" x-small>{{
          item.closed.toDate()
            | moment("from", item.practitioner[0].at.toDate())
        }}</v-chip>
      </template>

      <template v-slot:[`item.created`]="{ item }">
        <v-chip label color="secondary" x-small>{{
          item.created.toDate() | moment("HH:mm")
        }}</v-chip>
      </template>

      <template v-slot:[`item.started`]="{ item }">
        <v-chip label color="secondary" x-small>{{
          item.practitioner[0].at.toDate() | moment("HH:mm")
        }}</v-chip>
      </template>

      <template v-slot:[`item.closed`]="{ item }">
        <v-chip label color="secondary" x-small>{{
          item.closed.toDate() | moment("HH:mm")
        }}</v-chip>
      </template>

      <template v-slot:[`item.delay`]="{ item }">
        <v-rating v-model="item.delayAlerts">
          <template v-slot:item="props">
            <v-icon
              class="mr-n2 ml-n2"
              :color="props.isFilled ? 'warning' : 'grey lighten-1'"
              small
            >
              {{ props.isFilled ? "mdi-circle" : "mdi-circle-outline" }}
            </v-icon>
          </template>
        </v-rating>
      </template>

      <template v-slot:[`item.alias`]="{ item }">
        <v-chip label outlined color="primary" x-small>{{
          item.location.alias
        }}</v-chip>
      </template>
      <template v-slot:[`item.fullName`]="{ item }">
        <span class="caption">{{ item.fullName }}</span>
      </template>
      <template v-slot:[`item.phone`]="{ item }">
        <span class="caption">{{ item.phone }}</span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { stg, functions } from "../../../../firebase";
import ActionsMenu from "../../ehr/components/actions/Menu.vue";
import AgeChip from "../age/AgeChip.vue";

export default {
  props: {
    search: {
      type: String,
      required: true,
      default: null,
    },
    loading: {
      type: Boolean,
      required: true,
      default: null,
    },
  },
  components: {
    ActionsMenu,
    AgeChip,
  },
  data() {
    return {
      overlay: false,
      load: 0,
      headers: [
        {
          text: "Tempo de Atendimento",
          value: "encounterTime",
          sortable: false,
        },
        { text: "Alertas de Atraso", value: "delay", sortable: false },
        { text: "Sala de Espera", value: "created", sortable: false },
        { text: "Início", value: "started", sortable: false },
        { text: "Fim", value: "closed", sortable: false },
        { text: "Idade", value: "age" },
        { text: "Nome", value: "fullName" },
        { text: "Contato", value: "phone" },
        { text: "Sala", value: "alias" },
        { text: "Ações", value: "action", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters({
      interview: "interview",
      professional: "professional",
      queueClosed: "queueClosed",
      views: "views",
      rooms: "rooms",
    }),
  },
  methods: {
    getBirthDate(birthdate) {
      return this.$moment(birthdate, "DD/MM/YYYY");
    },
    async getProfilePicture(file) {
      stg
        .ref()
        .child(file)
        .getDownloadURL()
        .then((url) => {
          return url.toString();
        });
    },
    getColor(gender) {
      if (gender === "Feminino") return "pink";
      else return "primary";
    },
  },
};
</script>
<style>
.select {
  max-width: 500px;
  min-width: 500px;
}

.search {
  max-width: 400px;
  min-width: 400px;
}
</style>