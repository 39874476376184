<template>
  <v-card color="transparent" flat>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate :rotate="360" :size="100" :width="8" color="white">
      </v-progress-circular>
    </v-overlay>

    <v-toolbar dense rounded outlined elevation="0">
      <v-card-title class="ml-n6 grey--text text--darken-2">
        <font-awesome-icon :icon="['fal', 'thumbs-up']" fixed-width style="font-size: 25px" />Pesquisas de Satisfação de
        Atendimentos
      </v-card-title>
      <v-spacer></v-spacer>
      <router-link v-if="$routerHistory.hasPrevious()" :to="{ path: $routerHistory.previous().path }">
        <v-btn class="secondary--text" small text elevation="0" color="primary" min-width="40" max-width="30"
          min-height="30" max-height="30">
          <font-awesome-icon :icon="['fal', 'reply']" fixed-width style="font-size: 25px" />
        </v-btn>
      </router-link>
    </v-toolbar>

    <v-card-title>
      <v-row class="mt-0">
        <v-spacer></v-spacer>
        <v-select color="purple" dense outlined :items="professionalJourneys" v-model="selectedProfessionalJourneys"
          label="Linhas de Cuidado" item-text="text" clearable multiple
          @change="(selection) => selectionChanged(selection)" :return-object="true" class="select caption">
          <template v-slot:selection="{ item, index }">
            <v-chip small outlined v-if="index === 0">
              <span class="caption">{{ item.text }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text text-caption">(+ outros)</span>
          </template>
        </v-select>
        <v-text-field dense outlined class="ml-5 search caption" color="purple" v-model="search"
          append-icon="mdi-magnify" label="Nome do paciente" single-line hide-details clearable></v-text-field>
      </v-row>
    </v-card-title>

    <v-card class="mt-0" width="100%" outlined color="grey lighten-2" elevation="24">
      <v-tabs left v-model="tab" grow icons-and-text color="secondary" height="35">
        <v-tabs-slider color="warning"></v-tabs-slider>
        <v-tab>
          <v-card flat class="transparent" v-if="totalAnsweredSurveys > 0">
            <v-badge inline color="success" :content="totalAnsweredSurveys"><span
                class="caption">Respondidas</span></v-badge>
            <v-btn @click="downloadAnsweredSurveys()" class="mt-1 ml-1" elevation="0" color="secondary" icon
              min-width="0" max-width="30" min-height="0" max-height="30">
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </v-card>
          <v-card flat class="transparent" v-else>
            <v-badge inline color="secondary" content="0"><span class="caption">
                Respondidas
              </span>
            </v-badge>
          </v-card>
        </v-tab>
      </v-tabs>
    </v-card>
    <v-tabs-items class="transparent" v-model="tab">
      <v-tab-item class="transparent" reverse-transition="fade-transition" transition="fade-transition">
        <Executed :search="search"></Executed>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import Executed from "./tabs/Executed.vue";
import { json2csv } from 'json-2-csv';
import { saveAs } from 'file-saver';

export default {
  name: "app-survey-journey",
  components: {
    Executed,
  },
  data() {
    return {
      professionalJourneys: [],
      selectedProfessionalJourneys: [],
      tab: null,
      overlay: false,
      search: "",
    };
  },
  computed: {
    ...mapGetters({
      surveys: "surveys",
      surveysNotAnswered: "surveysNotAnswered",
      professional: "professional",
      journey: "journey",
    }),
    totalAnsweredSurveys() {
      return this.surveys != null &&
        this.surveys.length > 0
        ? this.surveys.length
        : "0";
    },
  },
  methods: {
    async downloadAnsweredSurveys() {

      let download = [];

      for (let i = 0; i < this.surveys.length; i++) {
        let survey = {};
        survey.enviado = this.$moment(this.surveys[i].created.toDate(), "DD/MM/YYYY hh:mm:ss").format('DD/MM/YYYY hh:mm:ss');
        survey.respondido = this.$moment(this.surveys[i].ended.toDate(), "DD/MM/YYYY hh:mm:ss").format('DD/MM/YYYY hh:mm:ss');
        survey.paciente = this.surveys[i].customerName;
        survey.origem = this.surveys[i].sourceText;
        survey.pesquisa = this.surveys[i].surveyId;

        for (let a = 0; a < this.surveys[i].answers.length; a++) {
          survey['pergunta-' + (a + 1)] = this.surveys[i].answers[a].question;
          survey['resposta-' + (a + 1)] = this.surveys[i].answers[a].text;
          survey['valor-' + (a + 1)] = this.surveys[i].answers[a].value;
        }
        download.push(survey);
      }

      let options = {
        delimiter: {
          wrap: '"', // Double Quote (") character
          field: ';', // Comma field delimiter
          eol: '\n' // Newline delimiter
        },
        prependHeader: true,
        sortHeader: false,
        excelBOM: true,
        trimHeaderValues: true,
        trimFieldValues: true,
        expandArrayObjects: true,
        useDateIso8601Format: true,
        //keys: ['paciente', 'enviado', 'respondido', 'origem']
      };

      let json2csvCallback = function (err, csv) {
        if (err) throw err;
        var blob = new Blob([csv], { type: ".csv;charset=utf-8" });
        let time = new Date().toUTCString();
        saveAs(blob, "pesquisa_de_satisfação_atendimentos" + time + ".csv");
      };

      json2csv(download, json2csvCallback, options);
    },
    async selectionChanged(selection) {
      this.overlay = true;

      if (selection.length == 0) {
        this.$store.dispatch("unbindSurveys");
        this.overlay = false;
      }

      this.$store.dispatch("unbindSurveys");

      let selectedList = await Array.from(selection, (s) => s.queryId);
      await this.$store.dispatch("bindSurveys", {
        source: selectedList,
        type: "encounter"
      });
      this.overlay = false;
    },
    getColor(gender) {
      if (gender == "Feminino") return "pink";
      else return "primary";
    }
  },
  beforeCreate() {
    this.$store.dispatch("resetCustomerState");
    this.$store.dispatch("unbindNotes");
    this.$store.dispatch("unbindMessage");
    this.$store.dispatch("unbindSurveys");
    this.$store.dispatch("unbindMonitoringSurveyNotAnswered");
  },
  beforeDestroy() { },
  async created() {
    this.overlay = true;
    this.professionalJourneys = await this.journey.filter(function (m) {
      return this.findIndex((pm) => pm.queryId == m.queryId) > -1;
    }, this.professional.journeys);

    this.selectedProfessionalJourneys = this.professionalJourneys;
    await this.selectionChanged(this.professional.journeys);
    this.overlay = false;
  }
};
</script>
<style lang="scss">
@import "../../../../scss/_variables.scss";

.data-studio {
  @media #{$xxlmax-device} {
    width: 100%;
    height: 600px;
  }

  @media #{$xlmax-device} {
    width: 100%;
    height: 700px;
  }

  @media #{$desktop-device} {
    width: 100%;
    height: 600px;
  }

  @media #{$tablet-device} {
    width: 100%;
    height: 800px;
  }

  @media #{$large-mobile} {
    height: 700px;
  }

  @media #{$extra-small-mobile} {
    height: 600px;
  }
}

.select {
  max-width: 650px;
  min-width: 650px;
}

.search {
  max-width: 350px;
  min-width: 350px;
}

.v-dialog {
  border-radius: 6px;
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  z-index: inherit;
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%);
}
</style>