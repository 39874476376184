<template>
  <v-card flat class="pa-1">
    <v-card class="mx-auto" flat outlined>
      <v-toolbar dense flat>
        <v-toolbar-title class="text-subtitle-1">
          <v-btn x-small elevation="0" fab text class="white--text"
            :color="getColor(customer.gender)">
            <font-awesome-icon :icon="['fal', 'syringe']" fixed-width style="font-size: 18px" />
          </v-btn>
          <span class="ml-1 mt-4"> Vacinas</span>
        </v-toolbar-title>

        <v-spacer></v-spacer>
      </v-toolbar>

      <v-divider></v-divider>
      <v-card flat color="transparent" class="d-flex justify-center">
        <v-card-text>
          <v-row>
            <v-col class="d-flex justify-center align-self-baseline flex-wrap">
              <v-card width="100%" flat>
                <v-list dense class="overflow-y-auto">
                  <v-list-item-group v-if="items != null && items.length > 0" color="primary">
                    <v-list-item v-for="(ds, i) in items" :key="i">
                      <v-list-item-icon>
                        <v-chip v-if="ds.data.value == 'no' || ds.data.value == 'não'" color="error" small label
                          text-color="white">
                          {{ ds.data.text }}
                        </v-chip>
                        <v-chip v-if="
                          ds.data.value == 'yes' || ds.data.value == 'sim'
                        " color="success" small label text-color="white">
                          {{ ds.data.text }}
                        </v-chip>
                        <v-chip v-if="ds.data.value == 'idk'" color="secondary" small label text-color="white">
                          {{ ds.data.text }}
                        </v-chip>
                      </v-list-item-icon>
                      <v-list-item-content class="ml-n6">
                        <v-list-item-title class="text-caption">
                          {{ ds.view.text }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                  <v-list-item-group v-else color="primary">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="text-caption">
                          Não há informações sobre vacinas!</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
  }),
  components: {},
  computed: {
    ...mapGetters({
      customer: "customer",
      datasourceEHR: "datasourceEHR",
    }),
    items() {
      return this.datasourceEHR["vaccines"];
    },
  },
  methods: {
    getColor(gender) {
      if (gender === "Feminino") return "pink";
      else return "primary";
    },
  },
  destroy() {

  },
};
</script>

<style>

</style>
