import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

export default {
  state: {
    vitalSigns: [],
  },
  actions: {
    bindVitalSigns: firestoreAction((context) => {
      let ref = db.collection("vital-signs");
      context.bindFirestoreRef("vitalSigns", ref, {
        wait: true,
        reset: true,
      });
    }),
  },
  getters: {
    vitalSigns(state) {
      return state.vitalSigns;
    },
  },
};
