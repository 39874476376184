<template>
  <v-card v-if="waitingRoomKpis" flat color="transparent" class="" outlined>
    <v-card
      class="mt-2 d-flex justify-center align-content-center flex-wrap transparent"
      width="100%"
      flat
    >
      <RoomOverviewBarChart
        :info="waitingRoomKpis"
        :month="month"
      ></RoomOverviewBarChart>
      <MeanTimeBarChart
        :info="waitingRoomKpis"
        :month="month"
      ></MeanTimeBarChart>
      <WaitTimeBarChart
        :info="waitingRoomKpis"
        :month="month"
      ></WaitTimeBarChart>
      <EnterTheRoomTimeBarChart
        :info="waitingRoomKpis"
        :month="month"
      ></EnterTheRoomTimeBarChart>
      <DelayTimeBarChart
        :info="waitingRoomKpis"
        :month="month"
      ></DelayTimeBarChart>
    </v-card>
  </v-card>
</template>      

<script>
import { mapGetters } from "vuex";
import ActionsMenu from "../../ehr/components/actions/Menu.vue";
import RoomOverviewBarChart from "../chart/RoomOverviewBarChart.vue";
import MeanTimeBarChart from "../chart/MeanTimeBarChart.vue";
import WaitTimeBarChart from "../chart/WaitTimeBarChart.vue";
import EnterTheRoomTimeBarChart from "../chart/EnterTheRoomTimeBarChart.vue";
import DelayTimeBarChart from "../chart/DelayTimeBarChart.vue";
import LineChart from "../chart/LineChart.vue";

export default {
  name: "kpis",
  components: {
    ActionsMenu,
    RoomOverviewBarChart,
    MeanTimeBarChart,
    WaitTimeBarChart,
    EnterTheRoomTimeBarChart,
    DelayTimeBarChart,
    LineChart,
  },
  props: {
    month: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      tab: null,
      items: null,
      search: "",
    };
  },
  computed: {
    ...mapGetters({
      professional: "professional",
      waitingRoomKpis: "waitingRoomKpis",
    }),
  },
  methods: {},
  mounted() {},
};
</script>
<style lang="scss">
@import "../../../../scss/variables";

.data-studio {
  @media #{$xxlmax-device} {
    width: 100%;
    height: 600px;
  }

  @media #{$xlmax-device} {
    width: 100%;
    height: 700px;
  }

  @media #{$desktop-device} {
    width: 100%;
    height: 600px;
  }

  @media #{$tablet-device} {
    width: 100%;
    height: 800px;
  }

  @media #{$large-mobile} {
    height: 700px;
  }

  @media #{$extra-small-mobile} {
    height: 600px;
  }
}
</style>