<template>
  <v-card class="pa-0 mt-2 mx-auto" flat>
    <v-card class="mb-2 rounded" flat color="grey lighten-3">
      <v-toolbar dense rounded outlined elevation="0" height="35">
        <v-spacer></v-spacer>
        <ResourcesDialog :filter="'finding'"></ResourcesDialog>
      </v-toolbar>
    </v-card>
    <v-card outlined flat min-height="65vh" max-height="65vh" class="pa-0 d-flex-column justify-start">
      <TiptapVuetify min-height="59vh" max-height="50vh" scrolled :toolbar-attributes="{}" rounded
        v-model="pastHistoryOfCurrentDisease" :extensions="extensions"
        :card-props="{ flat: true, color: '', outlined: false, class: 'pa-0' }" :editor-props="{
          attributes: {
            class: 'prose mx-auto focus:outline-none',
          }
        }" />
    </v-card>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import {
  History,
  TiptapVuetify,
  Bold,
  Italic,
  Strike,
  Underline,
  BulletList,
  OrderedList,
  ListItem,
  Blockquote,

} from "tiptap-vuetify";

export default {
  props: {
    sequence: {
      type: Number,
      required: true,
      default: null,
    },
  },
  data: () => ({
    timer: null,
    extensions: [
      History,
      Blockquote,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      Bold,
    ],
  }),
  components: {
    TiptapVuetify,
  },
  computed: {
    pastHistoryOfCurrentDisease: {
      get() {
        return this.$store.getters.pastHistoryOfCurrentDisease;
      },
      set(value) {
        this.$store.dispatch("setPastHistoryOfCurrentDisease", value);
      },
    },
    ...mapGetters({}),
  },
  methods: {},
};
</script>

<style>

</style>