<template>
  <v-card color="transparent" flat>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate :rotate="360" :size="100" :width="8" color="white">
      </v-progress-circular>
    </v-overlay>

    <v-toolbar dense rounded outlined elevation="0">
      <v-card-title class="ml-n6 grey--text text--darken-2">
        <font-awesome-icon :icon="['fal', 'monitor-heart-rate']" fixed-width style="font-size: 25px" />Monitoramentos
      </v-card-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-1" @click="$router.replace({ name: 'MonitorSurveys' })" small text v-bind="attrs" v-on="on"
            elevation="0" color="primary" min-width="35" max-width="35" min-height="35" max-height="35">
            <font-awesome-icon :icon="['fal', 'thumbs-up']" fixed-width style="font-size: 25px" />
          </v-btn>
        </template>
        <span>Pesquisas de Satisfação</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-1" @click="$router.replace({ name: 'MonitorHistorical' })" small text v-bind="attrs"
            v-on="on" elevation="0" color="primary" min-width="35" max-width="35" min-height="35" max-height="35">
            <font-awesome-icon :icon="['fal', 'magnifying-glass-chart']" fixed-width style="font-size: 25px" />
          </v-btn>
        </template>
        <span>Histórico</span>
      </v-tooltip>
    </v-toolbar>

    <v-card-title>
      <v-row class="mt-0">
        <v-spacer></v-spacer>
        <v-select color="purple" dense outlined :items="professionalMonitors" v-model="selectedProfessionalMonitors"
          label="Monitoria" item-text="text" clearable multiple @change="(selection) => selectionChanged(selection)"
          :return-object="true" class="select caption">
          <template v-slot:selection="{ item, index }">
            <v-chip small outlined v-if="index === 0">
              <span class="caption">{{ item.text }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text text-caption">(+ outros)</span>
          </template>
        </v-select>
        <v-text-field dense outlined class="ml-5 search caption" color="purple" v-model="search"
          append-icon="mdi-magnify" label="Nome do paciente" single-line hide-details clearable></v-text-field>
      </v-row>
    </v-card-title>

    <v-card width="100%" outlined color="grey lighten-2" elevation="24">
      <v-tabs left v-model="tab" grow icons-and-text color="secondary" height="35">
        <v-tabs-slider color="warning"></v-tabs-slider>
        <v-tab>
          <v-badge v-if="totalNewHealthAlerts > 0" inline color="error" :content="totalNewHealthAlerts"><span
              class="caption">Alertas de Saúde</span></v-badge>
          <v-badge v-else inline color="success" content="0"><span class="caption">Alertas de Saúde</span></v-badge>
        </v-tab>
        <v-tab class="">
          <v-badge v-if="totalImportantAnamnesisNotAnswered > 0" inline color="error"
            :content="totalImportantAnamnesisNotAnswered"><span class="caption">
              Questionários Obrigatórios Não Respondidos
            </span></v-badge>
          <v-badge v-else inline color="success" content="0"><span class="caption">
              Questionários Obrigatórios Não Respondidos
            </span></v-badge>
        </v-tab>
        <v-tab class="">
          <v-badge inline color="secondary" :content="totalInExecutions"><span class="caption">Em Execução</span>
          </v-badge>
        </v-tab>
      </v-tabs>
    </v-card>
    <v-tabs-items class="transparent" v-model="tab">
      <v-tab-item class="transparent" reverse-transition="fade-transition" transition="fade-transition">
        <NewHealthAlerts :search="search"></NewHealthAlerts>
      </v-tab-item>
      <v-tab-item class="transparent" reverse-transition="fade-transition" transition="fade-transition">
        <NotAnswered :search="search"></NotAnswered>
      </v-tab-item>
      <v-tab-item class="transparent" reverse-transition="fade-transition" transition="fade-transition">
        <InExecution :search="search"></InExecution>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import AgeChip from "./age/AgeChip.vue";
import InExecution from "./tabs/InExecution.vue";
import NotAnswered from "./tabs/NotAnswered.vue";
import NewHealthAlerts from "./tabs/NewHealthAlerts.vue";

export default {
  name: "app-monitoring",
  components: {
    AgeChip,
    //Tabs
    InExecution,
    NotAnswered,
    NewHealthAlerts,
  },
  data() {
    return {
      tab: null,
      professionalMonitors: [],
      selectedProfessionalMonitors: [],
      overlay: false,
      search: "",
      dialogBi: false,
    };
  },
  computed: {
    ...mapGetters({
      monitoring: "monitoring",
      monitoringNewHealthAlerts: "monitoringNewHealthAlerts",
      monitoringImportantAnamnesisNotAnswered:
        "monitoringImportantAnamnesisNotAnswered",
      monitors: "monitors",
      professional: "professional",
    }),
    totalInExecutions() {
      return this.monitoring != null && this.monitoring.length > 0
        ? this.monitoring.length
        : "0";
    },
    totalNewHealthAlerts() {
      return this.monitoringNewHealthAlerts != null &&
        this.monitoringNewHealthAlerts.length > 0
        ? this.monitoringNewHealthAlerts.length
        : "0";
    },
    totalImportantAnamnesisNotAnswered() {
      return this.monitoringImportantAnamnesisNotAnswered != null &&
        this.monitoringImportantAnamnesisNotAnswered.length > 0
        ? this.monitoringImportantAnamnesisNotAnswered.length
        : "0";
    },
  },
  methods: {
    monitoringListener(list) {
      var audioFile = new Audio(
        "https://firebasestorage.googleapis.com/v0/b/icareyou-dev.appspot.com/o/sound%2Fmessage_tones.mp3?alt=media&token=d613cb23-725b-4752-a8c4-58348c9ab905"
      );
      audioFile.play();
    },
    async selectionChanged(selection) {
      this.overlay = true;

      if (selection.length == 0) {
        await this.$store.dispatch("unbindMonitoring");
        await this.$store.dispatch("unbindMonitoringNewHealthAlerts");
        await this.$store.dispatch(
          "unbindMonitoringImportantAnamnesisNotAnswered"
        );
        this.overlay = false;
      }

      await this.$store.dispatch("unbindMonitoring");
      await this.$store.dispatch("unbindMonitoringNewHealthAlerts");
      await this.$store.dispatch(
        "unbindMonitoringImportantAnamnesisNotAnswered"
      );

      let selectedList = await Array.from(selection, (s) => s.queryId);
      await this.$store.dispatch("bindMonitoring", {
        monitoring: selectedList,
        alert: false,
      });
      await this.$store.dispatch("bindMonitoringNewHealthAlerts", {
        monitoring: selectedList,
        alert: false,
      });
      await this.$store.dispatch(
        "bindMonitoringImportantAnamnesisNotAnswered",
        {
          monitoring: selectedList,
          alert: false,
        }
      );
      this.overlay = false;
    },
    getColor(gender) {
      if (gender == "Feminino") return "pink";
      else return "primary";
    },
  },
  beforeCreate() {
    this.$store.dispatch("resetCustomerState");
    this.$store.dispatch("unbindNotes");
    this.$store.dispatch("unbindMessage");
    this.$store.dispatch("unbindMonitoring");
    this.$store.dispatch("unbindMonitoringNewHealthAlerts");
    this.$store.dispatch("unbindMonitoringImportantAnamnesisNotAnswered");
  },
  beforeDestroy() { },
  async created() {
    this.professionalMonitors = await this.monitors.filter(function (m) {
      return this.findIndex((pm) => pm.queryId == m.queryId) > -1;
    }, this.professional.monitors);

    this.selectedProfessionalMonitors = this.professionalMonitors;
    await this.selectionChanged(this.professional.monitors);
  },
};
</script>
<style lang="scss">
@import "../../../../scss/_variables.scss";

.data-studio {
  @media #{$xxlmax-device} {
    width: 100%;
    height: 600px;
  }

  @media #{$xlmax-device} {
    width: 100%;
    height: 700px;
  }

  @media #{$desktop-device} {
    width: 100%;
    height: 600px;
  }

  @media #{$tablet-device} {
    width: 100%;
    height: 800px;
  }

  @media #{$large-mobile} {
    height: 700px;
  }

  @media #{$extra-small-mobile} {
    height: 600px;
  }
}

.select {
  max-width: 650px;
  min-width: 650px;
}

.search {
  max-width: 350px;
  min-width: 350px;
}

.v-dialog {
  border-radius: 6px;
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  z-index: inherit;
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%);
}
</style>