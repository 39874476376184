<template>
	<v-autocomplete width="100%" outlined flat chips small-chips v-model="primaryCare" :items="primaryCares"
		color="purple lighten-2" :return-object="true">
		<template v-slot:item="data">
			<template>
				<v-list-item-content>
					<v-list-item-title v-html="data.item.text"></v-list-item-title>
				</v-list-item-content>
			</template>
		</template>
	</v-autocomplete>
</template>

<script>
import { mapGetters } from "vuex";
import { db } from "../../../../../firebase";

export default {
	components: {},
	data: () => ({
		primaryCare: null,
	}),
	computed: {
		...mapGetters({
			customer: "customer",
			professional: "professional",
			primaryCares: "primaryCares",
			queueInfo: "queueInfo"
		}),
	},
	methods: {},
	watch: {
		primaryCare: async function (newPrimaryCare, oldPrimaryCare) {
			if (oldPrimaryCare) {
				let ehrRef = db.collection("electronic-health-record");

				let primaryCareUpdate = {};
				primaryCareUpdate = this.primaryCare;

				let practitionerRef = {};
				practitionerRef.at = new Date();
				practitionerRef.location = {};
				practitionerRef.location = this.queueInfo.location;
				practitionerRef.id = this.professional.id;
				practitionerRef.email = this.professional.email;
				practitionerRef.fullName = this.professional.fullName;
				practitionerRef.professionalId = this.professional.professionalId;
				practitionerRef.cpf = this.professional.cpf;
				practitionerRef.specialty = this.professional.specialty;
				practitionerRef.phone = this.professional.phone;
				practitionerRef.queue = this.queueInfo.queueId;

				if (!primaryCareUpdate.practitioner) {
					primaryCareUpdate.practitioner = [];
				}

				primaryCareUpdate.practitioner.push(practitionerRef);

				ehrRef
					.doc(this.customer.id)
					.update({
						primaryCare: primaryCareUpdate,
					})
					.then(() => {
						this.$swal.fire({
							icon: "success",
							title: "Linha de Cuidado Preferencial alterada com sucesso! ",
							toast: true,
							position: "top-end",
							showConfirmButton: false,
							timer: 3000,
							timerProgressBar: true,
						});
					});
			}
		},
	},
	async created() {
		let ehrRef = db.collection("electronic-health-record").doc(this.customer.id);
		let ehrDoc = await ehrRef.get();
		let ehrData = ehrDoc.data();
		this.primaryCare = ehrData.primaryCare.value;
	},
};
</script>

<style>

</style>
