<template>
  <v-card flat>
    <v-card class="mb-2 rounded" flat color="grey lighten-3">
      <v-toolbar flat height="50" elevation="0" class="secondary">
        <v-toolbar-title class="subtitle-2 white--text"
          >1. Respostas Prévias do Paciente</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <ResourcesDialog :filter="'finding'"></ResourcesDialog>
      </v-toolbar>
    </v-card>
    <v-card outlined flat>
      <v-list dense max-height="80vh" min-height="80vh" class="overflow-y-auto">
        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item v-for="(ds, i) in items" :key="i">
              <v-list-item-icon>
             <v-chip
                  v-if="ds.data.value == 'no'"
                  color="error"
                  small
                  label
                  text-color="white"
                >
                  {{ ds.data.text }}
                </v-chip>
                <v-chip
                  v-if="ds.data.value == 'yes'"
                  color="success"
                  small
                  label
                  text-color="white"
                >
                  {{ ds.data.text }}
                </v-chip>
                <v-chip
                  v-if="ds.data.value == 'idk'"
                  color="secondary"
                  small
                  label
                  text-color="white"
                >
                  {{ ds.data.text }}
                </v-chip>
          </v-list-item-icon>
            <v-list-item-content class="ml-n6">
              <v-list-item-title class="text-caption"> {{ ds.view.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ResourcesDialog from "../../../components/resource/Dialog.vue";

export default {
  data: () => ({
    selectedItem: 1,
    items: [],
  }),
  components: { ResourcesDialog },
  computed: {
    ...mapGetters({
      datasource: "datasource",
    }),
  },
  methods: {},
  created() {
    this.items = this.datasource["findings"].sort((a, b) =>
      a.data.value < b.data.value ? 1 : -1
    );
  },
};
</script>

<style>
</style>