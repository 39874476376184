export default {
  strict: true,
  state: {
    services: [],
    physicianDiagnose: null,
    prescriptions: [],
  },
  mutations: {
    ADD_SERVICE(state, data) {
      state.services.push(data);
    },
    ADD_PRESCRIPTION(state, data) {
      state.prescriptions.push(data);
    },
    SET_PRESCRIPTIONS(state, data) {
      state.prescriptions = data;
    },
    SET_SERVICES(state, data) {
      state.services = data;
    },
    SET_MONITORS(state, data) {
      state.monitors = data;
    },
    SET_DIAGNOSE(state, data) {
      state.physicianDiagnose = data;
    },
    REMOVE_SERVICE(state, data) {
      Object.entries(state.services).forEach((current, index, arr) => {
        if (current[1].medicine === data.medicine) {
          state.services.splice(index, 1);
        }
      });
    },
    RESET_RECOMMENDATIONS(state) {
      state.services = [];
      state.prescriptions = [];
      state.physicianDiagnose = null;
    },
  },
  actions: {
    changePrescription(context, value) {
      context.commit("ADD_PRESCRIPTION", value);
    },
    changeService(context, value) {
      context.commit("ADD_SERVICE", value);
    },
    changeDiagnose(context, value) {
      context.commit("SET_DIAGNOSE", value);
    },
    setServices(context, value) {
      context.commit("SET_SERVICES", value);
    },
    setPrescriptions(context, value) {
      context.commit("SET_PRESCRIPTIONS", value);
    },
    removeService(context, value) {
      context.commit("REMOVE_SERVICE", value);
    },
    resetRecommendations(context) {
      context.commit("RESET_RECOMMENDATIONS");
    },
  },
  getters: {
    services(state) {
      return state.services;
    },
    prescriptions(state) {
      return state.prescriptions;
    },
    physicianDiagnose(state) {
      return state.physicianDiagnose;
    },
  },
};
