<template>
  <v-card color="transparent" flat>
    <v-toolbar dense rounded outlined elevation="0">
      <v-card-title class="ml-n6 grey--text text--darken-2">
        <font-awesome-icon
          :icon="['fal', 'map-location-dot']"
          fixed-width
          style="font-size: 25px"
        />Linhas de Cuidado
      </v-card-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-1"
            @click="$router.replace({ name: 'JourneySurveys' })"
            small
            text
            v-bind="attrs"
            v-on="on"
            elevation="0"
            color="primary"
            min-width="35"
            max-width="35"
            min-height="35"
            max-height="35"
          >
            <font-awesome-icon
              :icon="['fal', 'thumbs-up']"
              fixed-width
              style="font-size: 25px"
            />
          </v-btn>
        </template>
        <span>Pesquisas de Satisfação</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-1"
            small
            text
            v-bind="attrs"
            @click="$router.replace({ name: 'JourneysHistorical' })"
            v-on="on"
            elevation="0"
            color="primary"
            min-width="35"
            max-width="35"
            min-height="35"
            max-height="35"
          >
            <font-awesome-icon
              :icon="['fal', 'magnifying-glass-chart']"
              fixed-width
              style="font-size: 25px"
            />
          </v-btn>
        </template>
        <span>Painel Inteligente</span>
      </v-tooltip>
    </v-toolbar>

    <v-card-title>
      <v-row class="mt-0">
        <v-spacer></v-spacer>
        <v-select
          color="purple"
          dense
          outlined
          :items="professionalJourneys"
          v-model="selectedProfessionalJourneys"
          label="Linhas de Cuidado"
          item-text="text"
          clearable
          @change="(selection) => selectionChanged(selection)"
          :return-object="true"
          class="select caption"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip small outlined v-if="index === 0">
              <span class="caption">{{ item.text }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text text-caption"
              >(+ outros)</span
            >
          </template>
        </v-select>
        <v-text-field
          dense
          outlined
          class="ml-5 search caption"
          color="purple"
          v-model="search"
          append-icon="mdi-magnify"
          label="Nome do paciente"
          single-line
          hide-details
          clearable
        ></v-text-field>
      </v-row>
    </v-card-title>

    <v-card width="100%" outlined color="grey lighten-2" elevation="24">
      <v-tabs
        left
        v-model="tab"
        grow
        icons-and-text
        color="secondary"
        height="35"
      >
        <v-tabs-slider color="warning"></v-tabs-slider>
        <v-tab>
          <v-card flat class="transparent" v-if="totalInExecutions > 0">
            <v-badge inline color="secondary" :content="totalInExecutions"
              ><span class="caption">Em Execução</span></v-badge
            >
            <v-btn
              @click="downloadInExecutionJourneys()"
              class="mt-1 ml-1"
              elevation="0"
              color="secondary"
              icon
              min-width="0"
              max-width="30"
              min-height="0"
              max-height="30"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </v-card>
          <v-card flat class="transparent" v-else>
            <v-badge inline color="secondary" content="0"
              ><span class="caption">Em Execução</span></v-badge
            >
          </v-card>
        </v-tab>
        <v-tab>
          <v-card flat class="transparent" v-if="totalExecuted > 0">
            <v-badge inline color="secondary" :content="totalExecuted"
              ><span class="caption">Já Executadas</span></v-badge
            >
            <v-btn
              @click="downloadExecutedJourneys()"
              class="mt-1 ml-1"
              elevation="0"
              color="secondary"
              icon
              min-width="0"
              max-width="30"
              min-height="0"
              max-height="30"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </v-card>
          <v-card flat class="transparent" v-else>
            <v-badge inline color="secondary" content="0"
              ><span class="caption">Executadas</span></v-badge
            >
          </v-card>
        </v-tab>
      </v-tabs>
    </v-card>
    <v-tabs-items class="transparent" v-model="tab">
      <v-tab-item
        class="transparent"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
        <InExecution
          :loading="loadingInExecution"
          :search="search"
        ></InExecution>
      </v-tab-item>
      <v-tab-item
        class="transparent"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
        <Executed :loading="loadingExecuted" :search="search"></Executed>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import AgeChip from "./age/AgeChip.vue";
import InExecution from "./tabs/InExecution.vue";
import Executed from "./tabs/Executed.vue";
import { json2csv } from "json-2-csv";
import { saveAs } from "file-saver";

export default {
  name: "app-monitoring",
  components: {
    AgeChip,
    //Tabs
    InExecution,
    Executed,
  },
  data() {
    return {
      loadingInExecution: false,
      loadingExecuted: false,
      tab: null,
      professionalJourneys: [],
      selectedProfessionalJourneys: [],
      search: "",
    };
  },
  computed: {
    ...mapGetters({
      journey: "journey",
      journeys: "journeys",
      journeysHistory: "journeysHistory",
      professional: "professional",
    }),
    totalInExecutions() {
      return this.journeys != null && this.journeys.length > 0
        ? this.journeys.length
        : "0";
    },
    totalExecuted() {
      return this.journeysHistory != null && this.journeysHistory.length > 0
        ? this.journeysHistory.length
        : "0";
    },
  },
  methods: {
    async downloadExecutedJourneys() {
      let options = {
        delimiter: {
          wrap: '"', // Double Quote (") character
          field: ";", // Comma field delimiter
          eol: "\n", // Newline delimiter
        },
        prependHeader: true,
        sortHeader: false,
        excelBOM: true,
        trimHeaderValues: true,
        trimFieldValues: true,
        expandArrayObjects: true,
        useDateIso8601Format: true,
        excludeKeys: [
          "updated.nanoseconds",
          "active",
          "journey",
          "status",
          "customer",
          "instance",
          "start.nanoseconds",
          "created.nanoseconds",
          "created.seconds",
        ],
      };

      let json2csvCallback = function (err, csv) {
        if (err) throw err;
        var blob = new Blob([csv], { type: ".csv;charset=utf-8" });
        let time = new Date().toUTCString();
        saveAs(blob, "pacientes_finalizados_linha_de_cuidados" + time + ".csv");
      };

      json2csv(this.journeysHistory, json2csvCallback, options);
    },
    async downloadInExecutionJourneys() {
      let options = {
        delimiter: {
          wrap: '"', // Double Quote (") character
          field: ";", // Comma field delimiter
          eol: "\n", // Newline delimiter
        },
        prependHeader: true,
        sortHeader: false,
        excelBOM: true,
        trimHeaderValues: true,
        trimFieldValues: true,
        expandArrayObjects: true,
        useDateIso8601Format: true,
        excludeKeys: [
          "journey",
          "status",
          "customer",
          "instance",
          "start.nanoseconds",
          "created.nanoseconds",
          "created.seconds",
        ],
      };

      let json2csvCallback = function (err, csv) {
        if (err) throw err;
        var blob = new Blob([csv], { type: ".csv;charset=utf-8" });
        let time = new Date().toUTCString();
        saveAs(blob, "pacientes_em_execucao_linha_de_cuidados" + time + ".csv");
      };

      json2csv(this.journeys, json2csvCallback, options);
    },
    async selectionChanged(selection) {
      this.loadingExecuted = true;
      this.loadingInExecution = true;
      if (selection) {
        if (selection.length == 0) {
          await this.$store.dispatch("unbindJourneys");
          await this.$store.dispatch("unbindJourneysHistory");
          this.loadingExecuted = false;
          this.loadingInExecution = false;
        }

        await this.$store.dispatch("unbindJourneys");
        await this.$store.dispatch("unbindJourneysHistory");

        //let selectedList = await Array.from(selection, (s) => s.queryId);
        this.$store
          .dispatch("bindJourneys", {
            journey: selection.queryId,
            alert: false,
          })
          .then(() => {
            this.loadingInExecution = false;
          });
        this.$store
          .dispatch("bindJourneysHistory", {
            journey: selection.queryId,
            alert: false,
          })
          .then(() => {
            this.loadingExecuted = false;
          });
      } else {
        await this.$store.dispatch("unbindJourneys");
        await this.$store.dispatch("unbindJourneysHistory");
        this.loadingExecuted = false;
        this.loadingInExecution = false;
      }
    },
    getColor(gender) {
      if (gender == "Feminino") return "pink";
      else return "primary";
    },
  },
  beforeCreate() {
    this.$store.dispatch("resetCustomerState");
    this.$store.dispatch("unbindNotes");
    this.$store.dispatch("unbindMessage");
    this.$store.dispatch("unbindJourneys");
    this.$store.dispatch("unbindJourneysHistory");
  },
  beforeDestroy() {},
  async created() {
    this.professionalJourneys = await this.journey.filter(function (m) {
      return this.findIndex((pm) => pm.queryId == m.queryId) > -1;
    }, this.professional.journeys);

    let firstElement = {};
    firstElement.value = "";
    firstElement.text = "";

    //this.selectedProfessionalJourneys = this.professionalJourneys[0];
    this.selectedProfessionalJourneys = firstElement;
    //await this.selectionChanged(this.selectedProfessionalJourneys[1]);
  },
};
</script>
<style lang="scss">
@import "../../../../scss/_variables.scss";

.data-studio {
  @media #{$xxlmax-device} {
    width: 100%;
    height: 600px;
  }

  @media #{$xlmax-device} {
    width: 100%;
    height: 700px;
  }

  @media #{$desktop-device} {
    width: 100%;
    height: 600px;
  }

  @media #{$tablet-device} {
    width: 100%;
    height: 800px;
  }

  @media #{$large-mobile} {
    height: 700px;
  }

  @media #{$extra-small-mobile} {
    height: 600px;
  }
}

.select {
  max-width: 650px;
  min-width: 650px;
}

.search {
  max-width: 350px;
  min-width: 350px;
}

.v-dialog {
  border-radius: 6px;
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  z-index: inherit;
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%);
}
</style>