import {firestoreAction} from "vuexfire";
import {db} from "../../firebase";

export default {
    state: {
        chat: null
    },
    actions: {
        unbindChat: firestoreAction(
            ({unbindFirestoreRef}) => {
                unbindFirestoreRef("chat", true);
            }
        ),
        bindChat: firestoreAction(
            (context, data) => {
                let ref = db.collection("conversations").doc(data.id);
                context.bindFirestoreRef("chat", ref, {reset: true});
            }
        )
    },
    getters: {
        chat(state) {
            return state.chat;
        }
    }
};
