<template>
  <v-card class="pa-0 mt-2 mx-auto" flat>
    <v-card class="mb-2 rounded" flat color="grey lighten-3">
      <v-toolbar dense rounded outlined elevation="0" height="35">
        <v-spacer></v-spacer>
        <ResourcesDialog :filter="'finding'"></ResourcesDialog>
      </v-toolbar>
    </v-card>
    <v-card outlined flat min-height="65vh" max-height="65vh" class="overflow-y-auto">
      <v-data-table class="elevation-0" :headers="headers" :items="this.datasource['findings']" :items-per-page="30"
        :sort-by="['view.text']" :sort-desc="[false]" :calculate-widths="true" dense hide-default-header
        hide-default-footer group-by="view.group">

        <template v-slot:[`item.view.text`]="{ item }">
          <span class="caption">{{
            item.view.text
          }}:</span><br>
          <v-chip class="mb-2" label color="secondary white--text" x-small>{{
            item.data.value
          }}</v-chip>
          <span v-if="item.view.showHistory" class="caption">
            <History></History>
          </span>

        </template>

        <template v-slot:[`group.header`]="{ items, group, headers, isOpen, toggle }">
          <td :colspan="headers.length">
            <v-btn @click="toggle" small icon :ref="group" :data-open="isOpen">
              <v-icon small v-if="isOpen">mdi-chevron-up</v-icon>
              <v-icon small v-else>mdi-chevron-down</v-icon>
            </v-btn>
            <span class="caption">
              {{ items[0].view.group }}
            </span>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ResourcesDialog from "../../../../components/resource/Dialog.vue";
import History from "../../../../components/resource/History.vue"

export default {
  props: {
    sequence: {
      type: Number,
      required: true,
      default: null,
    },
  },
  data: () => ({
    selectedItem: 1,
    headers: [
      { text: "Informação", value: "view.text", sortable: false },
    ],
  }),
  components: { ResourcesDialog, History },
  computed: {
    ...mapGetters({
      datasource: "datasource",
    }),
  },
  methods: {

  },
  mounted() {
    Object.keys(this.$refs).forEach(k => {
      this.$refs[k].$el.click()
    })
  },
};
</script>

<style>

</style>