import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

const getDefaultState = () => {
  return {
    customer: {},
    customerId: null,
  };
};

const state = getDefaultState();

export default {
  state,
  mutations: {
    SET_CUSTOMER(state, data) {
      state.customer = data;
    },
    SET_CUSTOMER_ID(state, value) {
      state.customerId = value;
    },
    RESET_CUSTOMER_STATE(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    resetCustomerState({ commit }) {
      commit("RESET_CUSTOMER_STATE");
    },
    setCustomerId({ commit }, id) {
      commit("SET_CUSTOMER_ID", id);
    },
    fetchCustomer(context, data) {
      context.state.customer = {};
      db.collection("customer")
        .doc(data.customer)
        .get()
        .then((customerDoc) => {
          if (customerDoc) {
            let customer = customerDoc.data();
            customer.id = customerDoc.id;
            context.commit("SET_CUSTOMER", customer);
          }
        });
    },
  },
  getters: {
    customer(state) {
      return state.customer;
    },
    customerId(state) {
      return state.customerId;
    },
  },
};
