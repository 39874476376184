<template>
  <v-chip label x-small outlined>{{ primaryCare }}</v-chip>
</template>

<script>
import { db } from "../../../../../firebase";

export default {
  components: {},
  props: {
    customer: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      primaryCare: null,
    };
  },
  watch: {},
  computed: {},
  methods: {},
  async created() {
    let ehrDoc = await db
      .collection("electronic-health-record")
      .doc(this.customer)
      .get();
    this.primaryCare = ehrDoc.data().primaryCare.text;
  },
};
</script>
