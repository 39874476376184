<template>
  <v-dialog v-model="dialog" max-width="400" eager>
    <template v-slot:activator="{ on: dialog, attrs: attrsDialog }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip, attrs: attrsTooltip }">
          <v-btn
            text
            color="primary"
            v-bind="{ ...attrsTooltip, ...attrsDialog }"
            v-on="{ ...tooltip, ...dialog }"
            elevation="0"
            min-width="35"
            max-width="35"
            min-height="35"
            max-height="35"
          >
            <font-awesome-icon
              :icon="['fal', 'syringe']"
              fixed-width
              style="font-size: 25px"
            />
          </v-btn>
        </template>
        <span>Vacinas</span>
      </v-tooltip>
    </template>
    <v-card class="pa-1">
      <Immunization></Immunization>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import Immunization from "./Immunization.vue";

export default {
  components: {
    Immunization,
  },
  props: {
    patient: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  watch: {
    async dialog(visible) {
      if (visible) {
        if (this.patient) {
          await this.$store.dispatch("fetchCustomer", {
            customer: this.patient,
          });
          await this.$store.dispatch("fetchDatasourceEHR", {
            customer: this.patient,
          });
        } else {
          await this.$store.dispatch("fetchDatasourceEHR", {
            customer: this.customer.id,
          });
        }
      } else {
        if (this.patient) {
          await this.$store.dispatch("resetCustomerState");
          await this.$store.dispatch("resetDatasourceEHR");
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      customer: "customer",
    }),
  },
  methods: {},
  created() {},
};
</script>
