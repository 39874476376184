<template>
  <v-dialog :id="card.id" v-model="dialog" width="700" eager>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        elevation="0"
        min-width="0"
        color="secondary"
        max-width="25"
        min-height="0"
        max-height="25"
      >
        <font-awesome-icon
          :icon="['fal', 'info-circle']"
          fixed-width
          class="white--text"
          style="font-size: 17px"
        />
      </v-btn>
    </template>

    <v-card color="grey lighten-5">
      <v-card-title class="text-subtitle-2">
        <font-awesome-icon
          :icon="['fal', 'info-circle']"
          fixed-width
          style="font-size: 15px"
          class="mt-4"
        />
        <span class="ml-1 mt-4">Detalhes: {{ card.text }}</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row dense>
            <v-col cols="12">
              <v-card outlined elevation="0" class="mb-4">
                <v-card-title>
                  <font-awesome-icon
                    class="amber--text text--darken-3 mr-2"
                    :icon="['fal', 'exclamation-triangle']"
                    fixed-width
                    style="font-size: 20px"
                  />
                  Fatores de Risco
                </v-card-title>
                <v-card-text>
                  <v-list dense>
                    <v-list-item-group color="primary">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="wrap-text"
                            v-text="card.info.riskFactor"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card-text>
              </v-card>
              <v-card outlined elevation="0" class="mb-4">
                <v-card-title>
                  <font-awesome-icon
                    class="amber--text text--darken-3 mr-2"
                    :icon="['fal', 'head-side-cough']"
                    fixed-width
                    style="font-size: 20px"
                  />
                  Sintomas Associados
                </v-card-title>
                <v-card-text>
                  <v-list dense subheader>
                    <v-list-item-group color="primary">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="wrap-text"
                            v-html="card.info.associatedSymptoms"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card-text>
              </v-card>

              <v-card outlined elevation="0" class="mb-4">
                <v-card-title>
                  <font-awesome-icon
                    class="amber--text text--darken-3 mr-2"
                    :icon="['fal', 'diagnoses']"
                    fixed-width
                    style="font-size: 20px"
                  />
                  Diagnósticos Diferenciais
                </v-card-title>
                <v-card-text>
                  <v-list dense>
                    <v-list-item-group color="primary">
                      <v-list-item
                        :href="differentialDiagnoses.link"
                        v-for="(differentialDiagnoses, i) in card.details
                          .differentialDiagnoses"
                        :key="i"
                      >
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="differentialDiagnoses.text"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card-text>
              </v-card>
              <v-card outlined elevation="0" class="mb-4">
                <v-card-title>
                  <font-awesome-icon
                    class="amber--text text--darken-3 mr-2"
                    :icon="['fal', 'vial']"
                    fixed-width
                    style="font-size: 20px"
                  />
                  Confirmação Diagnóstica
                </v-card-title>
                <v-card-text>
                  <v-list dense>
                    <v-list-item-group color="primary">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="card.details.diagnosticConfirmation"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card-text>
              </v-card>
              <v-card outlined elevation="0" class="mb-4">
                <v-card-title>
                  <font-awesome-icon
                    class="amber--text text--darken-3 mr-2"
                    :icon="['fal', 'user-md']"
                    fixed-width
                    style="font-size: 20px"
                  />
                  Tratamento
                </v-card-title>
                <v-card-text>
                  <v-list dense>
                    <v-list-item-group color="primary">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="wrap-text"
                            v-text="card.details.treatment"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card-text>
              </v-card>
              <v-card outlined elevation="0">
                <v-card-title>
                  <font-awesome-icon
                    class="amber--text text--darken-3 mr-2"
                    :icon="['fal', 'link']"
                    fixed-width
                    style="font-size: 20px"
                  />
                  Recursos Externos
                </v-card-title>
                <v-card-text>
                  <v-list dense>
                    <v-list-item-group color="primary">
                      <v-list-item
                        v-for="(externalResources, i) in card.details
                          .externalResources"
                        :key="i"
                      >
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="externalResources.text"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
  props: {
    card: Object,
  },
};
</script>

<style>
.wrap-text {
  white-space: normal;
}
</style>
