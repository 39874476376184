import { firestoreAction } from "vuexfire";
import { db, FieldPath } from "../../firebase";

export default {
    state: {
        customers: [],
        customersIndicators: 0,
    },
    mutations: {
    },
    actions: {
        unbindCustomers: firestoreAction(
            ({ unbindFirestoreRef }) => {
                unbindFirestoreRef("customers", true);
            }
        ),
        bindCustomers: firestoreAction(
            async (context, data) => {
                let initial = data.initial;
                let developmentMode = data.developmentMode;
                let professional = data.professional;

                let ref = db.collection("customer");
                if (initial) {
                    ref = ref.where("initial", "==", initial);
                }

                ref = ref.where("dev", "==", developmentMode);
                ref = ref.where("active", "==", true);

                await context.bindFirestoreRef("customers", ref, {
                    wait: true,
                    reset: true
                });
            }
        ),
        bindCustomersIndicators: firestoreAction(
            (context, data) => {
                let ref = db.collection("customer").doc("indicators");
                context.bindFirestoreRef("customersIndicators", ref, {
                    wait: true,
                    reset: true
                });
            }
        )
    },
    getters: {
        customers(state) {
            return state.customers;
        },
        customersIndicators(state) {
            return state.customersIndicators;
        }
    }
};
