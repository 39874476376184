<template>
  <div id="anamnesis" v-if="
    monitor.action == 'anamnesis' &&
    monitor.cancelled == true &&
    anamnesisNotAnsweredAlerts > 0
  ">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-on="on" v-bind="attrs" elevation="0" small text color="primary" min-width="35" max-width="35"
          min-height="35" max-height="35" @click="open()">
          <font-awesome-icon :icon="['fal', 'clipboard-user']" fixed-width style="font-size: 25px" />
        </v-btn>
      </template>
      <span>RESPONDER QUESTIONÁRIO</span>
    </v-tooltip>

    <v-dialog v-model="dialog" max-width="550" eager>
      <v-card>
        <Anamnesis v-if="dialog" :monitor="monitor" :interview="interview" :alert="alert"
          @close-interview="closeInterview"></Anamnesis>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { db, functions } from "../../../../../firebase";
import Anamnesis from "./Anamnesis.vue";

export default {
  props: {
    monitor: {
      type: Object,
      required: false,
      default: null,
    },
    alert: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: () => ({
    dialog: false,
    anamnesisNotAnsweredAlerts: 0,
    interview: {},
  }),
  components: { Anamnesis },
  computed: {
    ...mapGetters({
      customer: "customer",
      professional: "professional",
    }),
  },
  watch: {
    async monitor(newValue) {
      this.anamnesisNotAnsweredAlerts = 0;
      await this.init();
    },
  },
  methods: {
    closeInterview() {
      this.dialog = false;
      this.$emit("closeInterview");
    },
    open() {
      this.$swal
        .fire({
          title: "Responder Questionário",
          text:
            this.professional.name +
            ", quer responder para " +
            (this.customer.gender == "Masculino" ? "o" : "a") +
            " paciente " +
            this.customer.name +
            " o questionário " +
            this.monitor.anamnesisId +
            "?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            this.$swal.showLoading();
            this.$swal.getCancelButton().setAttribute("disabled", "");
            let sisInterviewPractitionerCreate = functions.httpsCallable(
              "sis-interview-practitioner-create-api"
            );
            await sisInterviewPractitionerCreate({
              customer: this.customer.id,
              anamnesis: this.monitor.anamnesisId,
            })
              .then(async (result) => {
                this.interview.id = result.data.interview;
                this.interview.startStep = result.data.step;
                return;
              })
              .catch((error) => {
                console.error(error.message);
                this.$swal.getCancelButton().setAttribute("enabled", "");
                this.$swal.showValidationMessage(
                  `Erro ao iniciar as respostas do questionário: ${error}`
                );
              });
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.dialog = true;
          }
        });
    },
    async init() {
      let monitorRef = db.collection("monitoring-actions").doc(this.monitor.id);

      await monitorRef
        .collection("alerts")
        .where("type", "==", "anamnesisNotAnswered")
        .where("treated", "==", false)
        .get()
        .then((snap) => {
          if (!snap.empty) {
            this.anamnesisNotAnsweredAlertsCollection = new Map(
              snap.docs.map((alert) => {
                return [alert.id, alert.data()];
              })
            );
            this.anamnesisNotAnsweredAlerts =
              this.anamnesisNotAnsweredAlertsCollection.size;
          }
        });
    },
  },
  async created() {
    await this.init();
  },
};
</script>

<style>

</style>