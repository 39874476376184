<template>
	<v-dialog v-if="card != null" v-model="dialog" width="700" eager>
		<template v-slot:activator="{ on, attrs }">
			<v-btn v-bind="attrs" v-on="on" elevation="0" min-width="0" color="secondary" max-width="25" min-height="0"
				max-height="25">
				<font-awesome-icon :icon="['fal', 'history']" fixed-width class="white--text" style="font-size: 17px" />
			</v-btn>
		</template>

		<v-card>
			<v-card-title class="text-subtitle-2">
				<font-awesome-icon :icon="['fal', 'history']" fixed-width style="font-size: 15px" class="mt-4" />
				<span class="ml-1 mt-4">Histórico: {{ card.text }}</span>
				<v-spacer></v-spacer>
				<v-text-field v-model="search" append-icon="mdi-magnify" label="Filtrar" single-line hide-details
					clearable></v-text-field>
			</v-card-title>
			<v-card-text>
				<v-data-table v-if="history" class="elevation-0" :headers="headers" :items="history" :search="search"
					:items-per-page="5" :sort-by="['created']" :sort-desc="[false]" calculate-widths="true">
					<template v-slot:[`item.text`]="{ item }">
						<v-chip label small color="warning" text-color="black" class="mx-0 text-left text-caption">{{
								item.text ? item.text : item.value
						}}</v-chip>
					</template>
					<template v-slot:[`item.time`]="{ item }">
						<v-chip outlined small pill color="primary">{{ new Date(item.lastUpdate._seconds * 1000) |
								moment("from", "now", true)
						}}</v-chip>
					</template>
					<template v-slot:[`item.date`]="{ item }">
						<v-chip outlined small pill color="primary">{{ new Date(item.lastUpdate._seconds * 1000) |
								moment("DD/MM/YYYY")
						}}</v-chip>
					</template>
					<template v-slot:[`item.hour`]="{ item }">
						<v-chip outlined small pill color="primary">{{ new Date(item.lastUpdate._seconds * 1000) |
								moment("HH:MM")
						}}</v-chip>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	data() {
		return {
			dialog: false,
			search: "",
			headers: [
				{ text: "Tempo", value: "time", sort: false },
				{ text: "Data", value: "date", sort: false },
				{ text: "Hora", value: "hour", sort: false },
				{ text: "Resposta", value: "text", sort: false },
			],
		};
	},
	props: {
		card: Object,
		history: Object,
	},
};
</script>

<style>

</style>
