<template>
  <v-card
    v-if="show"
    class="pa-2 ml-2 mr-2 mt-2 mb-2 d-flex flex-column justify-center align-content-center"
    elevation="3"
    min-height="300"
    min-width="600"
  >
    <apexchart
      width="100%"
      heigth="100%"
      type="bar"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </v-card>
</template>

<script>
export default {
  name: "mean-time-bar-chart",
  computed: {},
  props: {
    info: {
      type: Object,
      required: false,
      default: null,
    },
    month: {
      type: String,
      required: false,
      default: null,
    },
  },
  data: function () {
    return {
      show: false,
      chartOptions: {
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {
            return val + " min";
          },
        },
        xaxis: {
          title: {
            text: "Dia",
            offsetY: 90,
            style: {
              fontSize: "12px",
              color: "#9d9d9d",
            },
          },
          categories:
            Object.keys(this.info.days)[0] != "undefined"
              ? Object.keys(this.info.days)
              : ["Não Definido"],
          labels: {
            style: {
              fontSize: "12px",
              color: "#9d9d9d",
            },
          },
        },
        yaxis: {
          title: {
            text: "Tempo Médio de Espera (minutos)",
            style: {
              fontSize: "12px",
              color: "#9d9d9d",
            },
          },
        },
        title: {
          text:
            "Tempo Médio de Espera para ser Atendido no mês de " + this.month,
          floating: true,
          offsetY: 0,
          align: "center",
          style: {
            fontSize: "12px",
            color: "#6d6d6d",
          },
        },
      },
      series: [
        {
          name: "Tempo Médio de Espera:",
          data: Object.entries(this.info.days).map(([key, val]) => {
            if (val.encounter.waittimes && val.encounter.waittimes.length > 0) {
              return (this.$ss.mean(val.encounter.waittimes) / 60).toFixed(0);
            } else {
              return "0";
            }
          }),
        },
      ],
    };
  },
  created() {
    this.show =
      Object.entries(this.info.days).map(([key, val]) => {
        if (val.encounter.waittimes && val.encounter.waittimes.length > 0) {
          return (this.$ss.mean(val.encounter.waittimes) / 60).toFixed(0);
        } else {
          return "0";
        }
      }).length > 0;
  },
};
</script>