<template>
  <v-card color="transparent" flat>
    <v-dialog v-model="dialog" width="900" height="600">
      <v-overlay :value="overlay">
        <v-progress-circular
          :size="50"
          :width="3"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-overlay>

      <template v-slot:activator="{ on: dialog, attrs: attrsDialog }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip, attrs: attrsTooltip }">
            <v-btn
              class="mx-1"
              text
              small
              color="primary"
              v-on="{ ...tooltip, ...dialog }"
              v-bind="{ ...attrsTooltip, ...attrsDialog }"
              elevation="0"
              min-width="0"
              max-width="30"
              min-height="0"
              max-height="30"
            >
              <font-awesome-icon
                :icon="['fal', 'note-medical']"
                fixed-width
                style="font-size: 25px"
              />
            </v-btn>
          </template>
          <span>Adicionar Nota de Saúde</span>
        </v-tooltip>
      </template>

      <v-card class="pa-1">
        <v-card flat class="pa-1">
          <v-card class="mx-auto" flat outlined>
            <v-toolbar dense flat>
              <v-toolbar-title class="text-subtitle-1">
                <v-btn
                  x-small
                  elevation="0"
                  fab
                  text
                  class="white--text"
                  :color="getColor(customer.gender)"
                >
                  <font-awesome-icon
                    :icon="['fal', 'note-medical']"
                    fixed-width
                    style="font-size: 18px"
                  />
                </v-btn>
                <span class="ml-1 mt-4">
                  Nota de saúde para o paciente:
                  <span class="font-weight-medium">{{
                    customer.fullName
                  }}</span></span
                ></v-toolbar-title
              >

              <v-spacer></v-spacer>
              <v-btn
                class="mr-2"
                dark
                text
                color="secondary"
                elevation="0"
                @click="saveNote()"
              >
                <v-icon dark>mdi-plus</v-icon> Salvar nota
              </v-btn>
            </v-toolbar>

            <v-divider></v-divider>
            <v-card flat class="pa-2" color="transparent">
              <TiptapVuetify
                id="note"
                v-model="note"
                :extensions="extensions"
                :card-props="{ flat: true, color: 'grey lighten-5' }"
              />
            </v-card>
          </v-card>
        </v-card>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { functions } from "../../../../../firebase";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";

export default {
  components: {
    TiptapVuetify,
  },
  computed: {
    ...mapGetters({
      customer: "customer",
      professional: "professional",
    }),
  },
  data: () => ({
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3, 4, 5],
          },
        },
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
    ],
    note: null,
    dialog: false,
    overlay: false,
  }),
  methods: {
    getColor(gender) {
      if (gender === "Feminino") return "pink";
      else return "primary";
    },
    saveNote() {
      if (this.note) {
        this.overlay = true;

        let sisEhrNoteSave = functions.httpsCallable(
          "sis-ehr-clinical-save-note-api"
        );
        sisEhrNoteSave({
          customer: this.customer.id,
          text: this.note,
          professional: this.professional,
          config: {
            type: "note",
            icon: "notes-medical",
            color: "amber",
            colorCard: "amber-color",
          },
        })
          .then((result) => {
            this.overlay = false;
            this.$swal.fire({
              icon: "success",
              title: "Nota de saúde salva com sucesso! ",
              toast: true,
              position: "bottom",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
            });
            this.note = null;
            this.dialog = false;
          })
          .catch((error) => {
            console.error(error.message);
          });
      }
    },
  },
  created() {},
  beforeDestroy() {},
};
</script>