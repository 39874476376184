<template>
  <v-card flat width="100%" color="transparent" class="mt-4">
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        :rotate="360"
        :size="100"
        :width="8"
        color="white"
      >
      </v-progress-circular>
    </v-overlay>

    <v-data-table
      class="elevation-0 transparent"
      :headers="headers"
      :items="monitoringImportantAnamnesisNotAnswered"
      :search="search"
      :items-per-page="10"
      :calculate-widths="true"
      sort-by="customerName"
    >
      <template v-slot:[`item.age`]="{ item }">
        <AgeChip :customer="item.customer"></AgeChip>
      </template>

      <template v-slot:[`item.customerName`]="{ item }">
        <span class="caption"> {{ item.customerName }}</span>
      </template>

      <template v-slot:[`item.start`]="{ item }">
        <span class="caption">{{
          item.created.toDate() | moment("from", "now")
        }}</span>
      </template>

      <template v-slot:[`item.monitoring`]="{ item }">
        <span class="caption">{{ item.monitor.text }}</span>
      </template>

      <template v-slot:[`item.execution`]="{ item }">
        <v-progress-circular
          :rotate="360"
          :size="40"
          :width="2"
          :value="Math.trunc((item.executed / item.total) * 100)"
          color="secondary"
        >
          {{ Math.trunc((item.executed / item.total) * 100) }}%
        </v-progress-circular>
      </template>

      <template v-slot:[`item.cancelled`]="{ item }">
        <v-progress-circular
          :rotate="360"
          :size="40"
          :width="2"
          :value="Math.trunc((item.cancelled / item.total) * 100)"
          color="error"
        >
          {{ Math.trunc((item.cancelled / item.total) * 100) }}%
        </v-progress-circular>
      </template>

      <template v-slot:[`item.details`]="{ item }">
        <v-btn
          @click="openMonitor(item)"
          class="mr-1"
          small
          text
          elevation="0"
          color="primary"
          min-width="40"
          max-width="40"
          min-height="40"
          max-height="40"
        >
          <font-awesome-icon
            :icon="['fal', 'timeline-arrow']"
            fixed-width
            style="font-size: 25px"
          />
        </v-btn>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <ActionsMenu
          :patient="item.customer"
          :name="item.customerName"
        ></ActionsMenu>
        <v-tooltip top color="error">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="remove(item)"
              class="mr-1"
              small
              text
              v-bind="attrs"
              v-on="on"
              elevation="0"
              color="error"
              min-width="0"
              max-width="30"
              min-height="0"
              max-height="20"
            >
              <font-awesome-icon
                :icon="['fal', 'person-walking-arrow-right']"
                fixed-width
                style="font-size: 20px"
              />
            </v-btn>
          </template>
          <span>Interromper o Monitoramento</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { functions, db } from "../../../../../firebase";
import AgeChip from "../age/AgeChip.vue";
import ActionsMenu from "../../../ehr/components/actions/Menu.vue";

export default {
  name: "app-monitoring",
  components: {
    AgeChip,
    ActionsMenu,
  },
  props: {
    search: {
      type: String,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      tab: null,
      overlay: false,
      headers: [
        {
          text: "ID",
          value: "id",
          filterable: false,
          sortable: false,
          align: " d-none",
        },
        {
          text: "Detalhes",
          value: "details",
          filterable: false,
          sortable: false,
        },
        { text: "Início", value: "start", filterable: false, sortable: false },
        { text: "Idade", value: "age", sortable: false },
        { text: "Nome", value: "customerName", sortable: false },
        {
          text: "Evolução",
          value: "execution",
          filterable: false,
          sortable: false,
        },
        {
          text: "Não Respondido",
          value: "cancelled",
          filterable: false,
          sortable: false,
        },
        {
          text: "Monitoria",
          value: "monitoring",
          filterable: false,
          sortable: false,
        },
        { text: "Ações", value: "actions", filterable: false, sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters({
      monitoringImportantAnamnesisNotAnswered:
        "monitoringImportantAnamnesisNotAnswered",
      monitors: "monitors",
    }),
    totalAlerts() {
      return this.monitoring.filter(function (m) {
        return m.alerts.length > 0;
      }).length;
    },
  },
  methods: {
    remove(monitor) {
      let reason = this.monitors.find(
        (m) => m.queryId === monitor.monitor.queryId
      ).reason;
      let inputOptions = {};
      for (let index = 0; index < reason.length; index++) {
        inputOptions[reason[index].value] = reason[index].text;
      }
      this.$swal
        .fire({
          title: "Encerrar Monitoramento",
          text: "Você realmente deseja encerrar o monitoramento ?",
          icon: "warning",
          input: "select",
          inputOptions: inputOptions,
          inputPlaceholder: "Selecione o motivo...",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          showLoaderOnConfirm: true,
          preConfirm: async (value) => {
            this.$swal.showLoading();
            this.$swal.getCancelButton().setAttribute("disabled", "");
            let sisMonitoringCancel = functions.httpsCallable(
              "sis-monitoring-cancel-api"
            );
            await sisMonitoringCancel({
              monitor: monitor.id,
              reason: value,
            })
              .then(async (result) => {
                return;
              })
              .catch((error) => {
                this.$swal.getCancelButton().setAttribute("enabled", "");
                this.$swal.showValidationMessage(
                  `Erro ao cancelar o monitoramento atendimento: ${error}`
                );
                console.error(error.message);
              });
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$swal
              .fire({
                icon: "success",
                title: "Monitoramento cancelado com sucesso! ",
                toast: false,
                showConfirmButton: true,
                timer: 1000,
                timerProgressBar: true,
              })
              .then((result) => {
                this.$router.replace({ name: "Monitors" });
              });
          }
        });
    },

    getColor(gender) {
      if (gender == "Feminino") return "pink";
      else return "primary";
    },
    async openMonitor(monitor) {
      this.overlay = true;
      await this.$store.dispatch("fetchCustomer", {
        customer: monitor.customer,
      });

      await this.$store.dispatch("bindCustomerMonitoringActions", {
        customer: monitor.customer,
        monitoring: monitor.id,
      });
      this.overlay = false;
      this.$router.replace({ name: "Monitor" });
    },
  },
};
</script>