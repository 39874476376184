<template>
  <v-card color="transparent" flat tile>
    <patientInfo></patientInfo>
    <v-card-title>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-data-table
      class="elevation-0"
      :headers="headers"
      :items="customerMonitoringActions"
      :items-per-page="10"
      :sort-by="['created']"
      :sort-desc="[false]"
      :calculate-widths="true"
    >
      <template v-slot:[`item.alerts`]="{ item }">
        <CloseAlert :monitor="item"></CloseAlert>
      </template>

      <template v-slot:[`item.plannedDate`]="{ item }">
        <span v-if="item.executed" class="caption">{{
          (parseInt(item.day) >= 10 ? item.day : "0" + item.day) +
          "/" +
          (parseInt(item.month) >= 10 ? item.month : "0" + item.month) +
          "/" +
          item.year
        }}</span>
        <span v-else class="caption grey--text">{{
          (parseInt(item.day) >= 10 ? item.day : "0" + item.day) +
          "/" +
          (parseInt(item.month) >= 10 ? item.month : "0" + item.month) +
          "/" +
          item.year
        }}</span>
      </template>

      <template v-slot:[`item.weekDay`]="{ item }">
        <span v-if="item.executed" class="caption">{{ getWeekDay(item) }}</span>
        <span v-else class="caption grey--text">{{ getWeekDay(item) }}</span>
      </template>

      <template v-slot:[`item.period`]="{ item }">
        <span v-if="item.executed" class="caption">{{
          item.period === "MNG"
            ? "Manhã"
            : item.period === "EVN"
            ? "Tarde"
            : "Noite"
        }}</span>
        <span v-else class="caption grey--text">{{
          item.period === "MNG"
            ? "Manhã"
            : item.period === "EVN"
            ? "Tarde"
            : "Noite"
        }}</span>
      </template>

      <template v-slot:[`item.details`]="{ item }">
        <div v-if="item.action == 'anamnesis'">
          <v-chip x-small label :color="actionColor(item)">
            {{ item.anamnesisId.toUpperCase() }}
          </v-chip>
        </div>
        <div v-else>
          <div v-if="item.action == 'survey'">
            <v-chip x-small label :color="actionColor(item)">
              {{ item.surveyId.toUpperCase() }}
            </v-chip>
          </div>
          <div v-else>
            <span class="text-center caption success--text">{{ "-" }}</span>
          </div>
        </div>
      </template>

      <template v-slot:[`item.type`]="{ item }">
        <div v-if="item.action == 'message'">
          <v-chip x-small label :color="actionColor(item)">{{
            actionText(item)
          }}</v-chip>
        </div>
        <div v-else>
          <div v-if="item.action == 'anamnesis'">
            <v-chip x-small label :color="actionColor(item)"
              >QUESTIONÁRIO</v-chip
            >
          </div>
          <div v-else>
            <v-chip x-small label :color="actionColor(item)"
              >PESQUISA DE SATISFAÇÃO</v-chip
            >
          </div>
        </div>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <Status :monitor="item"></Status>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import patientInfo from "../identification/Identification";
import CloseAlert from "./alert/Close.vue";
import Status from "./status/Status.vue";
import Reply from "./reply/Reply.vue";

export default {
  components: {
    patientInfo,
    CloseAlert,
    Status,
    Reply,
  },
  data() {
    return {
      search: "",
      singleExpand: true,
      expanded: [],
      selectedItem: null,
      headers: [
        { text: "ID", value: "id", sortable: false, align: " d-none" },
        {
          text: "Há alertas?",
          sortable: false,
          value: "alerts",
          align: "center",
        },
        {
          text: "Monitoria do tipo",
          sortable: false,
          value: "type",
          align: "center",
        },
        {
          text: "Enviado(a) ao paciente dia",
          sortable: false,
          value: "plannedDate",
          align: "center",
        },
        {
          text: "No período da",
          sortable: false,
          value: "period",
          align: "center",
        },
        {
          text: "Em um(a)",
          sortable: false,
          value: "weekDay",
          align: "center",
        },
        {
          text: "Com o seguinte estado",
          sortable: false,
          value: "status",
          align: "center",
        },
        {
          text: "Questionário enviado?",
          sortable: false,
          value: "details",
          align: "center",
        },
        {
          text: "Executado",
          sortable: false,
          value: "executedDate",
          align: " d-none",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      customerMonitoringActions: "customerMonitoringActions",
      professional: "professional",
    }),
  },
  methods: {
    actionText(monitor) {
      let text = "";
      if (monitor.action == "message") {
        text = "MENSAGEM";
      } else if (monitor.action == "anamnesis") {
        text = "QUESTIONÁRIO";
      } else {
        text = "PESQUISA DE SATISFAÇÃO";
      }
      return text;
    },
    actionColor(monitor) {
      let color = "";
      if (monitor.action == "message") {
        color =
          monitor.executed == true
            ? "success white--text"
            : "grey lighten-4 grey--text";
      } else if (monitor.action == "anamnesis") {
        color =
          monitor.executed == true ? "primary" : "grey lighten-4 grey--text";
      } else {
        color =
          monitor.executed == true
            ? "orange white--text"
            : "grey lighten-4 grey--text";
      }
      return color;
    },
    getWeekDay(item) {
      switch (
        this.$moment(
          item.year +
            "-" +
            (parseInt(item.month) >= 10 ? item.month : "0" + item.month) +
            "-" +
            (parseInt(item.day) >= 10 ? item.day : "0" + item.day) +
            "T00:00:00"
        ).isoWeekday()
      ) {
        case 1:
          return "Segunda-Feira";
        case 2:
          return "Terça-Feira";
        case 3:
          return "Quarta-Feira";
        case 4:
          return "Quinta-Feira";
        case 5:
          return "Sexta-Feira";
        case 6:
          return "Sábado";
        case 7:
          return "Domingo";
        default:
          return "-";
      }
    },
    getColor(gender) {
      if (gender === "Feminino") return "pink";
      else return "primary";
    },
  },
  created() {},
};
</script>