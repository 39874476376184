<template>
  <v-card flat width="100%" color="transparent" class="mt-4">
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        :rotate="360"
        :size="100"
        :width="8"
        color="white"
      >
      </v-progress-circular>
    </v-overlay>

    <v-data-table
      class="elevation-0 transparent"
      :headers="headers"
      :items="journeysHistory"
      :search="search"
      :items-per-page="10"
      :calculate-widths="true"
      sort-by="customerName"
      :loading="loading"
    >
      <template v-slot:[`item.age`]="{ item }">
        <AgeChip :customer="item.customer"></AgeChip>
      </template>

      <template v-slot:[`item.primaryCare`]="{ item }">
        <PrimaryCare :customer="item.customer"></PrimaryCare>
      </template>

      <template v-slot:[`item.customerName`]="{ item }">
        <CustomerStatus :journey="item" :customer="item.customer"></CustomerStatus>
      </template>

      <template v-slot:[`item.start`]="{ item }">
        <span class="caption">{{
          item.created.toDate() | moment("DD/MM/YYYY HH:MM")
        }}</span>
      </template>

      <template v-slot:[`item.details`]="{ item }">
        <v-btn
          @click="openJourney(item)"
          class="mr-1"
          small
          text
          elevation="0"
          color="primary"
          min-width="40"
          max-width="40"
          min-height="40"
          max-height="40"
        >
          <font-awesome-icon
            :icon="['fal', 'timeline-arrow']"
            fixed-width
            style="font-size: 25px"
          />
        </v-btn>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <!--v-tooltip top color="prinary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="" small text v-bind="attrs" v-on="on" elevation="0" color="primary" min-width="0"
              max-width="30" min-height="0" max-height="20">
              <font-awesome-icon :icon="['fal', 'square-caret-right']" fixed-width style="font-size: 20px" />
            </v-btn>
          </template>
          <span>Próximo Passo</span>
        </v-tooltip-->
        <ActionsMenu
          :patient="item.customer"
          :name="item.customerName"
        ></ActionsMenu>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { functions, db } from "../../../../../firebase";
import AgeChip from "../age/AgeChip.vue";
import CustomerStatus from "../customerStatus/CustomerStatus.vue";
import PrimaryCare from "../primaryCare/PrimaryCare.vue";
import Tags from "../tags/Tags.vue";
import ActionsMenu from "../../../ehr/components/actions/Menu.vue";
import copy from "copy-to-clipboard";

export default {
  name: "app-monitoring",
  components: {
    AgeChip,
    ActionsMenu,
    PrimaryCare,
    Tags,
    CustomerStatus
  },
  props: {
    search: {
      type: String,
      required: true,
      default: null,
    },
    loading: {
      type: Boolean,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      tab: null,
      overlay: false,
      headers: [
        {
          text: "ID",
          value: "id",
          filterable: false,
          sortable: false,
          align: " d-none",
        },
        {
          text: "Detalhes",
          value: "details",
          filterable: false,
          sortable: false,
        },
        { text: "Início", value: "start", filterable: false, sortable: false },
        { text: "Idade", value: "age", sortable: false },
        { text: "Nome", value: "customerName", sortable: false },
        {
          text: "Classificação",
          value: "primaryCare",
          filterable: false,
          sortable: false,
        },
        { text: "Ações", value: "actions", filterable: false, sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters({
      journeysHistory: "journeysHistory",
      journey: "journey",
    }),
  },
  methods: {
    getInstanceInfo(queue) {
      copy(queue.instance, { debug: false });
    },
    getCustomerId(queue) {
      copy(queue.customer, { debug: false });
    },
    async openJourney(journey) {
      this.overlay = true;
      await this.$store.dispatch("fetchCustomer", {
        customer: journey.customer,
      });

      await this.$store.dispatch("bindFlowInstance", {
        instance: journey.instance,
      });
      await this.$store.dispatch("bindFlowInstanceTrail", {
        instance: journey.instance,
      });
      this.overlay = false;
      this.$router.replace({ name: "JourneyFlow" });
    },

    remove(journey) {
      let reason = this.journey.find(
        (j) => j.queryId === journey.journey
      ).reason;
      let inputOptions = {};
      for (let index = 0; index < reason.length; index++) {
        inputOptions[reason[index].value] = reason[index].text;
      }
      this.$swal
        .fire({
          title: "Encerrar Linha de Cuidado",
          text: "Você realmente deseja encerrar essa linha de cuidado?",
          icon: "warning",
          input: "select",
          inputOptions: inputOptions,
          inputPlaceholder: "Selecione o motivo...",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          showLoaderOnConfirm: true,
          preConfirm: async (value) => {
            this.$swal.showLoading();
            this.$swal.getCancelButton().setAttribute("disabled", "");
            let sisJourneyCancel = functions.httpsCallable(
              "sis-journey-cancel-api"
            );
            await sisJourneyCancel({
              customer: journey.customer,
              reason: value,
            })
              .then(async (result) => {
                return;
              })
              .catch((error) => {
                this.$swal.getCancelButton().setAttribute("enabled", "");
                this.$swal.showValidationMessage(
                  `Erro ao cancelar a linha de cuidado: ${error}`
                );
                console.error(error.message);
              });
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$swal
              .fire({
                icon: "success",
                title: "Linha de cuidado cancelado com sucesso! ",
                toast: false,
                showConfirmButton: true,
                timer: 1000,
                timerProgressBar: true,
              })
              .then((result) => {
                this.$router.replace({ name: "Journeys" });
              });
          }
        });
    },

    getColor(gender) {
      if (gender == "Feminino") return "pink";
      else return "primary";
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
@import "../../../../../scss/_variables.scss";

.data-studio {
  @media #{$xxlmax-device} {
    width: 100%;
    height: 600px;
  }

  @media #{$xlmax-device} {
    width: 100%;
    height: 700px;
  }

  @media #{$desktop-device} {
    width: 100%;
    height: 600px;
  }

  @media #{$tablet-device} {
    width: 100%;
    height: 800px;
  }

  @media #{$large-mobile} {
    height: 700px;
  }

  @media #{$extra-small-mobile} {
    height: 600px;
  }
}

.select {
  max-width: 500px;
  min-width: 500px;
}

.search {
  max-width: 400px;
  min-width: 400px;
}

.v-dialog {
  border-radius: 6px;
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  z-index: inherit;
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%);
}
</style>