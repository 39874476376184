<template>
  <v-card class="pa-0" flat>
    <v-autocomplete :cache-items="true" v-if="cid10 != null" :loading="cid10 != null && cid10.length < 12000" outlined
      flat chips clearable deletable-chips multiple small-chips v-model="cids" :items="cid10"
      color="blue-grey lighten-2" label="Selecione os CID's" item-text="code" item-value="code" :return-object="true"
      :filter="customFilter" @input="searchInput = null" :search-input.sync="searchInput">
      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-title class="subtitle-1" v-html="data.item.code"></v-list-item-title>
          <v-list-item-subtitle class="subtitle-2 secondary--text" v-html="data.item.value"></v-list-item-subtitle>
        </v-list-item-content>
      </template>
      <template v-slot:append-outer>
        <v-dialog max-width="400" v-model="dialog" eager>
          <template v-slot:activator="{ on: dialog, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn icon class="mt-n1 ml-n1" color="primary" dark v-bind="attrs" v-on="{ ...tooltip, ...dialog }"
                  elevation="0" min-width="0" max-width="30" min-height="0" max-height="30">
                  <font-awesome-icon :icon="['fal', 'info-circle']" fixed-width style="font-size: 20px" />
                </v-btn>
              </template>
              <span>Visualizar</span>
            </v-tooltip>
          </template>
          <v-card v-if="cids != null && cids.length > 0" flat>
            <v-list dense nav>
              <v-list-item-group color="primary">
                <v-list-item v-for="(cid, i) in cids" :key="i">
                  <v-list-item-icon>
                    <v-icon>mdi-alert-circle-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-html="cid.code"></v-list-item-title>
                    <v-list-item-subtitle v-html="cid.value" class="text-justify text-wrap">
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
          <v-card v-else flat>
            <v-card-text class="caption pa-2">Não há nenhum CID selecionado para esse paciente.</v-card-text>
          </v-card>
        </v-dialog>
      </template>
    </v-autocomplete>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    searchInput: null,
    dialog: false,
    cids: [],
    autoUpdate: true,
    isUpdating: false,
  }),
  components: {},
  computed: {
    ...mapGetters({
      diagnosticHypothesisCid: "diagnosticHypothesisCid",
      cid10: "cid10"
    }),
  },
  watch: {
    cids(newValue, oldValue) {
      this.$store.dispatch("setDiagnosticHypothesisCid", newValue);
    },
  },
  methods: {
    customFilter(item, queryText, itemText) {
      const textOne = item.code.toLowerCase()
      const textTwo = item.value.toLowerCase()
      const searchText = queryText.toLowerCase()

      return textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1
    },
    remove(item) {
      const index = this.cids.indexOf(item.code);
      if (index >= 0) this.cids.splice(index, 1);
    },
  },
  async created() {
    this.cids = this.diagnosticHypothesisCid;
  },
};
</script>

<style>

</style>