<template>
  <v-card flat>
    <v-card class="mb-2 rounded" outlined flat color="grey lighten-3">
      <v-toolbar flat height="35" elevation="0" class="purple">
        <v-toolbar-title class="subtitle-2 white--text">{{sequence}}. Informações Gerais do Paciente</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card>
    <v-sheet outlined color="purple" rounded>
      <v-card flat class="pa-2">
        <v-row>
          <v-col><span class="caption">{{ weight.view.text }}:</span></v-col>
          <v-col><span class="caption grey--text text--darken-2 font-weight-black">{{ weight.data.value
          }} Kg</span></v-col>
        </v-row>
        <v-row class="mt-n6">
          <v-col><span class="caption">{{ height.view.text }}:</span></v-col>
          <v-col><span class="caption grey--text text--darken-2 font-weight-black">{{ height.data.value
          }} cm</span></v-col>
        </v-row>
        <v-row class="mt-n6">
          <v-col><span class="caption">IMC:</span></v-col>
          <v-col><span class="caption grey--text text--darken-2 font-weight-black">{{ imc }} kg/m2</span></v-col>
        </v-row>
        <v-row class="mt-n6">
          <v-col><span class="caption">Comorbidades:</span></v-col>
          <v-col><span class="caption grey--text text--darken-2 font-weight-black">{{ cmbd }}</span></v-col>
        </v-row>
        <v-row class="mt-n6">
          <v-col><span class="caption">Diabetes:</span></v-col>
          <v-col><span class="caption grey--text text--darken-2 font-weight-black">{{ dmt2 }}</span></v-col>
        </v-row>
      </v-card>
    </v-sheet>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { functions } from "../../../../../../../firebase";

export default {
  props: {
    sequence: {
      type: Number,
      required: true,
      default: null,
    },
  },
  data: () => ({
    selectedItem: 1,
    weight: null,
    height: null,
    imc: 0,
    cmbd: null,
    dmt2: null,
    diabetes: null,
    diabetesType: null,
  }),
  components: {},
  computed: {
    ...mapGetters({
      datasource: "datasource",
      customer: "customer"
    }),
  },
  methods: {},
  async created() {
    this.weight = this.datasource["findings"].find((view) => view.resource.queryId === 'vital-signs-weight');
    this.height = this.datasource["findings"].find((view) => view.resource.queryId === 'vital-signs-height');
    this.diabetes = this.datasource["findings"].find((view) => view.resource.queryId === 'conditions-diabetes');
    this.diabetesType = this.datasource["findings"].find((view) => view.resource.queryId === 'conditions-diabetes-type');


    let sisCalculatedFieldsCalculate = functions.httpsCallable(
      "sis-calculated-fields-calculate-api"
    );
    await sisCalculatedFieldsCalculate({
      customer: this.customer.id,
      field: "cf-imc"
    })
      .then((result) => {
        this.imc = result.data.value.toFixed(2);
      })
      .catch((error) => {
        console.error(error.message);
      });

    await sisCalculatedFieldsCalculate({
      customer: this.customer.id,
      field: "cf-cmbd"
    })
      .then((result) => {
        this.cmbd = (result.data.value ? "Sim" : "Não");
      })
      .catch((error) => {
        console.error(error.message);
      });

    await sisCalculatedFieldsCalculate({
      customer: this.customer.id,
      field: "cf-dmt2"
    })
      .then((result) => {
        this.dmt2 = (result.data.value ? "Tipo 2" : this.diabetes.data.value == 'Sim' ? this.diabetesType.data.value : this.diabetes.data.value);
      })
      .catch((error) => {
        console.error(error.message);
      });



  },
};
</script>

<style>

</style>
