<template>
  <v-container class="mr-1" name="ro-internal-medicine-menu">
    <v-row>
      <v-col>
        <PatientDetailsButton></PatientDetailsButton>
      </v-col>
      <v-col class="ml-n4"> <ScheduleButton></ScheduleButton> </v-col>
      <v-col class="ml-n4">
        <ImmunizationHistoryButton></ImmunizationHistoryButton>
      </v-col>
      <v-col class="ml-n4">
        <PatientHistoryButton></PatientHistoryButton>
      </v-col>
       <v-col class="ml-n4">
        <MessageButton></MessageButton>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import PatientDetailsButton from "../../../../components/profile/Dialog.vue";
import ImmunizationHistoryButton from "../../../../components/immunization/Dialog.vue";
import PatientHistoryButton from "../../../../components/historic/Dialog.vue";
import ScheduleButton from "../../../../components/schedule/Dialog.vue";
import MessageButton from "../../../../components/message/Dialog.vue";

export default {
  components: {
    PatientDetailsButton,
    ImmunizationHistoryButton,
    PatientHistoryButton,
    ScheduleButton,
    MessageButton,
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {},
  destroyed() {},
};
</script>
<style>
</style>