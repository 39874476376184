<template>
  <v-card
    flat
    width="100%"
    color="transparent"
    class="mt-4"
  >
    <v-overlay :value="overlay">
      <v-progress-circular
        :rotate="360"
        :size="100"
        :width="15"
        :value="load"
        color="white"
      >
        {{ Math.trunc(load) }}
      </v-progress-circular>
    </v-overlay>
    <v-data-table
      class="elevation-0 transparent"
      :headers="headers"
      :items="queueInAttendance"
      :search="search"
      :items-per-page="10"
      :sort-by="['created']"
      :sort-desc="[false]"
      :calculate-widths="true"
      :loading="loading"
    >
      <template v-slot:[`item.action`]="{ item }">
        <v-tooltip color="secondary" left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              @click="openInterview(item)"
              class="mr-1"
              small
              text
              elevation="0"
              :color="getColor(item.gender)"
              min-width="30"
              max-width="30"
              min-height="30"
              max-height="30"
            >
              <font-awesome-icon
                :icon="['fal', 'user-doctor']"
                fixed-width
                style="font-size: 25px"
              />
            </v-btn>
          </template>
          <span class="white--text">Continuar Atendimento</span>
        </v-tooltip>
        <ActionsMenu :patient="item.uid" :name="item.fullName"></ActionsMenu>
      </template>

      <template v-slot:[`item.age`]="{ item }">
        <AgeChip :customer="item.uid"></AgeChip>
      </template>

      <template v-slot:[`item.createdDate`]="{ item }">
        <v-chip label outlined x-small pill :color="getColor(item.gender)">{{
          item.createdDate
        }}</v-chip>
      </template>

      <template v-slot:[`item.waitTime`]="{ item }">
        <v-chip @click="getQueueInfo(item)"  label color="secondary" x-small>{{
          item.practitioner[item.practitioner.length - 1].at.toDate()
            | moment("from", "now")
        }}</v-chip>
      </template>

      <template v-slot:[`item.alias`]="{ item }">
        <v-chip outlined color="primary" label x-small>{{
          item.location.alias
        }}</v-chip>
      </template>
      <template v-slot:[`item.fullName`]="{ item }">
        <span class="caption">{{
          item.fullName
        }}</span>
      </template>
      <template v-slot:[`item.phone`]="{ item }">
        <span class="caption">{{ item.phone }}</span>
      </template>

      <template v-slot:[`item.practitioner[0].fullName`]="{ item }">
        <span class="caption">{{ item.practitioner[0].fullName }}</span>
      </template>

      <template v-slot:[`item.practitioner[0].specialty`]="{ item }">
        <span class="caption">{{ item.practitioner[0].specialty }}</span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { stg, functions } from "../../../../firebase";
import ActionsMenu from "../../ehr/components/actions/Menu.vue";
import AgeChip from "../age/AgeChip.vue";
import copy from "copy-to-clipboard";

export default {
  props: {
    search: {
      type: String,
      required: true,
      default: null,
    },
    loading: {
      type: Boolean,
      required: true,
      default: null,
    },
  },
  components: {
    ActionsMenu,
    AgeChip,
  },
  data() {
    return {
      overlay: false,
      load: 0,
      headers: [
        { text: "Tempo de Atendimento", value: "waitTime", sortable: false },
        { text: "Idade", value: "age" },
        { text: "Nome", value: "fullName" },
        { text: "Contato", value: "phone" },
        { text: "Sala", value: "alias" },
        { text: "Profissional", value: "practitioner[0].fullName" },
        { text: "Especialidade", value: "practitioner[0].specialty" },
        { text: "filter", value: "location.alias", align: " d-none" },
        { text: "Continuar Atendimento?", value: "action", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters({
      interview: "interview",
      professional: "professional",
      queueInAttendance: "queueInAttendance",
      views: "views",
      rooms: "rooms",
    }),
  },
  methods: {
    getQueueInfo(queue) {
      copy(queue.id, { debug: false });
    },
    getBirthDate(birthdate) {
      return this.$moment(birthdate, "DD/MM/YYYY");
    },
    async getProfilePicture(file) {
      stg
        .ref()
        .child(file)
        .getDownloadURL()
        .then((url) => {
          return url.toString();
        });
    },
    getColor(gender) {
      if (gender === "Feminino") return "pink";
      else return "primary";
    },
    async openInterview(queueInfo) {
      this.$LoggerService.debug(`Starting encounter`, queueInfo);
      this.overlay = true;
      let _room = this.rooms.find(
        (room) => room.queryId === queueInfo.location.identifier
      );

      let _roomView = _room.views.find(
        (view) => view.profile === this.professional.profile
      );

      let _encounterView = _roomView.view;

      if (_roomView.list && _roomView.list.length > 0) {
        if (this.professional.dev) {
          let inputOptions = {};
          for (let index = 0; index < _roomView.list.length; index++) {
            inputOptions[_roomView.list[index]] = _roomView.list[index];
          }
          await this.$swal
            .fire({
              title: "Versão da Sala",
              text: "Você deseja realizar o atendimento com qual versão da sala de atendimento?",
              icon: "info",
              input: "select",
              inputOptions: inputOptions,
              inputPlaceholder: "Selecione a versão...",
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Selecionar",
              showLoaderOnConfirm: true,
              preConfirm: async (value) => {
                this.$swal.showLoading();
                this.$swal.getCancelButton().setAttribute("disabled", "");
                _encounterView = value;
              },
            })
            .then((result) => {
              if (result.isConfirmed) {
                this.$swal.fire({
                  icon: "success",
                  title: "Versão da sala selecionada! ",
                  position: "bottom",
                  toast: true,
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              }
            });
        }
      }

      this.$LoggerService.debug(`Encounter seletecd view ${_encounterView}`);
      let _view = this.views.find((view) => view.queryId === _encounterView);

      //Fetch Customer
      this.load += 100 / 8;
      await this.$store.dispatch("fetchCustomer", { customer: queueInfo.uid });
      this.$LoggerService.debug(`Customer fetched`);

      //Fetch History
      this.load += 100 / 8;
      await this.$store.dispatch("fetchDatasourceEHR", {
        customer: queueInfo.uid,
      });
      this.$LoggerService.debug(`Datasource EHR fetched`);

      //Fetch Resources
      this.load += 100 / 8;
      await this.$store.dispatch("fetchInterview", {
        queue: queueInfo,
        view: _view,
      });
      this.$LoggerService.debug(`Inteview information fetched`);

      //Fetch Queue
      this.load += 100 / 8;
      await this.$store.dispatch("fetchQueueInfo", queueInfo);
      this.$LoggerService.debug(`QueueInfo stored`);

      //Fetch Resources
      this.load += 100 / 8;
      await this.$store.dispatch("bindChat", { id: queueInfo.dialog });
      this.$LoggerService.debug(`Conversation fetched`);

      //Fetch PrimaryCare
      this.load += 100 / 8;
      await this.$store.dispatch("bindPrimaryCares");
      this.$LoggerService.debug(`Primary Care fetched`);

      //Add Practitioner
      this.$LoggerService.debug(`Calling sis-interview-add-practitioner-api`);
      let sisInterviewAddPractitioner = functions.httpsCallable(
        "sis-interview-add-practitioner-api"
      );
      await sisInterviewAddPractitioner({
        customer: queueInfo.uid,
        interview: this.interview.id,
        queue: queueInfo.id,
        professional: this.professional,
        action: "start",
      })
        .then((result) => {
          this.$LoggerService.debug(
            `sis-interview-add-practitioner-api processed`
          );
          this.load = 100;
          this.overlay = false;
          this.$LoggerService.debug(`Router to view ${_view.name}`);
          this.$router.replace({ name: _view.name });
        })
        .catch((error) => {
          this.$LoggerService.error(error.message, error);
        });
    },
  },
};
</script>
<style>
.select {
  max-width: 500px;
  min-width: 500px;
}

.search {
  max-width: 400px;
  min-width: 400px;
}
</style>