<template>
  <v-dialog v-model="dialog" max-width="1000" eager>
    <template v-slot:activator="{ on: dialog, attrs: attrsDialog }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip, attrs: attrsTooltip }">
          <div v-if="newVoiceMail">
            <v-badge color="error" overlap bordered dot>
              <v-btn text small color="primary" v-bind="{...attrsDialog, ...attrsTooltip}"
                v-on="{ ...tooltip, ...dialog }" elevation="0" min-width="35" max-width="35" min-height="35"
                max-height="35">
                <font-awesome-icon :icon="['fal', 'voicemail']" fixed-width style="font-size: 25px" />
              </v-btn>
            </v-badge>
          </div>
          <div v-else>
            <v-btn text small color="primary" v-bind="{...attrsDialog, ...attrsTooltip}"
              v-on="{ ...tooltip, ...dialog }" elevation="0" min-width="35" max-width="35" min-height="35"
              max-height="35">
              <font-awesome-icon :icon="['fal', 'voicemail']" fixed-width style="font-size: 25px" />
            </v-btn>
          </div>
        </template>
        <span>Recados do Paciente</span>
      </v-tooltip>
    </template>
    <v-card class="pa-1">
      <Messages></Messages>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { functions, db } from "../../../../../firebase";
import Messages from "./Messages.vue";

export default {
  components: {
    Messages,
  },
  props: {
    patient: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      newVoiceMail: false,
    };
  },
  watch: {
    messages() {
      if (this.patient) {
        this.verifyNewMessage(this.patient);
      } else {
        this.verifyNewMessage(this.customer.id);
      }
    },
    async dialog(visible) {
      if (visible) {
        if (this.patient) {
          await this.$store.dispatch("fetchCustomer", {
            customer: this.patient,
          });
          await this.$store.dispatch("bindMessage", { customer: this.patient });
        } else {
          await this.$store.dispatch("bindMessage", {
            customer: this.customer.id,
          });
        }
      } else {
        if (this.patient) {
          await this.$store.dispatch("resetCustomerState");
          await this.$store.dispatch("unbindMessage");
          this.messageRead(this.patient);
        } else {
          await this.$store.dispatch("unbindMessage");
          this.messageRead(this.customer.id);
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      customer: "customer",
      messages: "messages",
    }),
  },
  methods: {
    verifyNewMessage(customer) {
      if (this.messages.filter((m) => m.id == customer).length > 0) {
        this.newVoiceMail = true;
      } else {
        this.newVoiceMail = false;
      }
    },
    messageRead(customer) {
      let sisMessageRead = functions.httpsCallable("sis-message-read-api");
      sisMessageRead({
        customer: customer,
      });
    },
  },

  created() {
    if (this.patient) {
      this.verifyNewMessage(this.patient);
    } else {
      this.verifyNewMessage(this.customer.id);
    }
  },
};
</script>
