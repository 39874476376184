<template>
  <v-card color="transparent" flat>
    <v-dialog v-model="dialog" width="900" height="600">
      <template v-slot:activator="{ on: dialog, attrs: attrsDialog }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip, attrs: attrsTooltip }">
            <v-btn class="mx-1" text small color="primary" v-on="{ ...tooltip, ...dialog }"
              v-bind="{ ...attrsTooltip, ...attrsDialog }" elevation="0" min-width="0" max-width="30" min-height="0"
              max-height="30">
              <div v-if="newMessage">
                <v-badge bordered bottom color="warning accent-5" dot offset-x="10" offset-y="10">
                  <font-awesome-icon :icon="['fal', 'message']" fixed-width style="font-size: 25px" />
                </v-badge>
              </div>
              <div v-else>
                <font-awesome-icon :icon="['fal', 'message']" fixed-width style="font-size: 25px" />
              </div>
            </v-btn>
          </template>
          <span>Conversar com o Paciente</span>
        </v-tooltip>
      </template>
      <v-overlay :value="overlay" absolute="absolute">
        <v-progress-circular :size="50" :width="3" color="primary" indeterminate></v-progress-circular>
      </v-overlay>
      <v-card class="pa-1">
        <v-card transparent outlined class="pa-2">
          <Chat v-if="chat != null && chat.messages != null && chat.messages.length >= 0"></Chat>
        </v-card>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import Chat from "../../ehr/components/telemedicine/chat/Chat.vue";

export default {
  components: {
    Chat
  },
  props: {
    customer: {
      type: String,
      required: true,
      default: null,
    },
    conversation: {
      type: String,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      overlay: false,
      newMessage: false,
    };
  },
  watch: {
    async dialog(visible) {
      this.overlay = true;
      if (visible) {
        await this.$store.dispatch("fetchCustomer", { customer: this.customer });
        await this.$store.dispatch("bindChat", { id: this.conversation });
      } else {
        await this.$store.dispatch("resetCustomerState");
        await this.$store.dispatch("unbindChat");
      }
      this.overlay = false;
    },
  },
  computed: {
    ...mapGetters({
      chat: "chat"
    }),
  },
  methods: {},
  async created() { }
}
</script>
