import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

export default {
  state: {
    config: null,
  },
  mutations: {
    SET_CONFIG(state, data) {
      state.config = data;
    },
  },
  actions: {
    async fetchConfig(context) {
      let configRef = db.collection("admin").doc("sis");
      await configRef
        .get()
        .then((configDoc) => {
          context.commit("SET_CONFIG", configDoc.data());
          
          configRef.onSnapshot((doc) => {
            context.commit("SET_CONFIG", doc.data());
          });
        
        });
    },
  },
  getters: {
    config(state) {
      return state.config;
    },
  },
};
