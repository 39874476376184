<template>
  <v-card
    class="pa-2 ml-2 mr-2 mt-2 mb-2 d-flex flex-column justify-center align-content-center"
    elevation="3"
    min-height="300"
    min-width="600"
  >
    <apexchart
      width="100%"
      heigth="100%"
      type="bar"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </v-card>
</template>

<script>
export default {
  name: "room-overview-bar-chart",
  computed: {},
  props: {
    info: {
      type: Object,
      required: false,
      default: null,
    },
    month: {
      type: String,
      required: false,
      default: null,
    },
  },
  data: function () {
    return {
      chartOptions: {
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: true,
        },
        xaxis: {
          title: {
            text: "Dia",
            offsetY: 90,
            style: {
              fontSize: "12px",
              color: "#9d9d9d",
            },
          },
          categories:
            Object.keys(this.info.days)[0] != "undefined"
              ? Object.keys(this.info.days)
              : ["Não Definido"],
          labels: {
            style: {
              fontSize: "12px",
              color: "#9d9d9d",
            },
          },
        },
        yaxis: {
          title: {
            text: "Quantidade de Atendimentos",
            style: {
              fontSize: "12px",
              color: "#9d9d9d",
            },
          },
        },
        title: {
          text: "Atendimentos de " + this.month,
          floating: true,
          offsetY: 0,
          align: "center",
          style: {
            fontSize: "12px",
            color: "#6d6d6d",
          },
        },
      },
      series: [
        {
          name: "Atendimentos:",
          data: Object.entries(this.info.days).map(([key, val]) => {
            return val.closed;
          }),
        },
      ],
    };
  },
  created() {},
};
</script>