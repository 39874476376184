<template>
  <v-chip label x-small outlined color="primary">{{ description }}</v-chip>
</template>

<script>
import { db } from "../../../../../firebase";

export default {
  components: {},
  props: {
    instance: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      description: 0,
    };
  },
  watch: {},
  computed: {},
  methods: {
    formatBirthdate(birthdate) {
      return this.$moment(birthdate, "DD/MM/YYYY");
    },
  },
  async created() {
    let instanceRef = db.collection("flows-instances").doc(this.instance);
    let trailRef = instanceRef.collection("trail").orderBy("created", "desc");
    let trailDoc = await trailRef.get();

    this.description = trailDoc.docs[0].data().description;

  },
};
</script>
