import {db, firebase, functions} from "../../firebase";

export default {
    state: {
        apiKey: null,
        secretKey: null,
        api: null,
        frontEnd: null,
        memedOverlay: false
    },
    mutations: {
        SET_APIKEY(state, value) {
            state.apiKey = value;
        },
        SET_SECRETKEY(state, value) {
            state.secretKey = value;
        },
        SET_API(state, value) {
            state.api = value;
        },
        SET_FRONTEND(state, value) {
            state.frontEnd = value;
        },
        SET_MEMED_OVERLAY(state) {
            state.memedOverlay = !state.memedOverlay;
        }
    },
    actions: {
        async fetchMemedOverlay({commit}) {
            commit("SET_MEMED_OVERLAY")
        },
        async fetchMemed(context, professional) {
            let memedConfigRef = db.collection("memed").doc("config");
            let memedConfigDoc = await memedConfigRef.get();
            let memedConfigData = memedConfigDoc.data();

            if (professional.dev) {
                context.commit("SET_APIKEY", memedConfigData.dev.apiKey);
                context.commit("SET_SECRETKEY", memedConfigData.dev.secretKey);
                context.commit("SET_API", memedConfigData.dev.api);
                context.commit("SET_FRONTEND", memedConfigData.dev.frontEnd);
            } else {
                if (memedConfigData.production) {
                    context.commit("SET_APIKEY", memedConfigData.prod.apiKey);
                    context.commit("SET_SECRETKEY", memedConfigData.prod.secretKey);
                    context.commit("SET_API", memedConfigData.prod.api);
                    context.commit("SET_FRONTEND", memedConfigData.prod.frontEnd);
                } else {
                    context.commit("SET_APIKEY", memedConfigData.dev.apiKey);
                    context.commit("SET_SECRETKEY", memedConfigData.dev.secretKey);
                    context.commit("SET_API", memedConfigData.dev.api);
                    context.commit("SET_FRONTEND", memedConfigData.dev.frontEnd);
                }
            }
        }
    },
    getters: {
        apiKey(state) {
            return state.apiKey;
        },
        secretKey(state) {
            return state.secretKey;
        },
        api(state) {
            return state.api;
        },
        frontEnd(state) {
            return state.frontEnd;
        },
        memedOverlay(state) {
            return state.memedOverlay;
        }
    }
};
