<template>
  <v-card color="transparent" flat tile>
    <patientInfo></patientInfo>
    <v-card-title>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-data-table
      class="elevation-0"
      :headers="headers"
      :items="flowInstanceTrail"
      :items-per-page="50"
      :sort-by="['created']"
      :sort-desc="[false]"
      :calculate-widths="true"
    >
      <template v-slot:[`item.alerts`]="{ item }">
        <CloseAlert :monitor="item"></CloseAlert>
      </template>

      <template v-slot:[`item.step`]="{ item, index }">
        <v-chip @click="getTrailId(item)" x-small outlined color="grey">
          {{ index + 1 }}
        </v-chip>
      </template>

      <template v-slot:[`item.plannedDate`]="{ item }">
        <span v-if="item.executed" class="caption">{{
          (parseInt(item.day) >= 10 ? item.day : "0" + item.day) +
          "/" +
          (parseInt(item.month) >= 10 ? item.month : "0" + item.month) +
          "/" +
          item.year
        }}</span>
        <span v-else class="caption grey--text">{{
          (parseInt(item.day) >= 10 ? item.day : "0" + item.day) +
          "/" +
          (parseInt(item.month) >= 10 ? item.month : "0" + item.month) +
          "/" +
          item.year
        }}</span>
      </template>

      <template v-slot:[`item.weekDay`]="{ item }">
        <span v-if="item.executed" class="caption">{{ getWeekDay(item) }}</span>
        <span v-else class="caption grey--text">{{ getWeekDay(item) }}</span>
      </template>

      <template v-slot:[`item.icon`]="{ item }">
        <font-awesome-icon
          :class="actionColorText(item)"
          :icon="['fal', actionIcon(item)]"
          fixed-width
          style="font-size: 22px"
        />
      </template>

      <template v-slot:[`item.type`]="{ item }">
        <v-card v-if="getDetails(item) == null" flat class="transparent">
          <v-chip x-small outlined label :color="actionColor(item)">
            {{ actionText(item) }}
          </v-chip>
        </v-card>
        <v-card v-else flat class="transparent">
          <v-tooltip right :color="actionColor(item)">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-bind="attrs"
                v-on="on"
                x-small
                label
                :color="actionColor(item)"
              >
                {{ actionText(item) }}
              </v-chip>
            </template>
            <span>{{ getDetails(item) }}</span>
          </v-tooltip>
        </v-card>
      </template>

      <template v-slot:[`item.description`]="{ item }">
        <span class="caption" :color="actionColorText(item)">{{
          item.description
        }}</span>
      </template>

      <template v-slot:[`item.actions`]="{ item, index }">
        <v-tooltip top color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="backTime(item, index)"
              class="mr-1"
              small
              text
              v-bind="attrs"
              v-on="on"
              elevation="0"
              color="primary"
              min-width="0"
              max-width="30"
              min-height="0"
              max-height="20"
            >
              <font-awesome-icon
                :icon="['fal', 'reply-time']"
                fixed-width
                style="font-size: 20px"
              />
            </v-btn>
          </template>
          <span>Voltar ao passo</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { functions } from "../../../../firebase";
import patientInfo from "../identification/Identification";
import CloseAlert from "./alert/Close.vue";
import Status from "./status/Status.vue";
import Reply from "./reply/Reply.vue";
import copy from "copy-to-clipboard";

export default {
  components: {
    patientInfo,
    CloseAlert,
    Status,
    Reply,
  },
  data() {
    return {
      search: "",
      singleExpand: true,
      expanded: [],
      selectedItem: null,
      headers: [
        { text: "ID", value: "id", sortable: false, align: " d-none" },
        {
          text: "Passo",
          sortable: false,
          value: "step",
          align: "center",
        },
        {
          text: "",
          sortable: false,
          value: "icon",
          align: "center",
        },
        {
          text: "Momento do tipo",
          sortable: false,
          value: "type",
          align: "center",
        },
        {
          text: "Ocorreu na data",
          sortable: false,
          value: "plannedDate",
          align: "center",
        },
        {
          text: "Em um(a)",
          sortable: false,
          value: "weekDay",
          align: "center",
        },
        {
          text: "Momento",
          sortable: false,
          value: "description",
          align: "justify",
        },
        { text: "Ações", value: "actions", filterable: false, sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters({
      flowInstanceTrail: "flowInstanceTrail",
      flowInstance: "flowInstance",
      professional: "professional",
      customer: "",
    }),
  },
  methods: {
    getTrailId(trail) {
      copy(trail.id, { debug: false });
    },
    backTime(step, index) {
      this.$swal
        .fire({
          title: "Voltar ao Passo",
          text: "Você realmente deseja voltar ao passo selecionado?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          showLoaderOnConfirm: true,
          preConfirm: async (value) => {
            this.$swal.showLoading();
            this.$swal.getCancelButton().setAttribute("disabled", "");
            this.flowInstanceTrail.sort(function (a, b) {
              if (a.created > b.created) return 1;
              if (a.created < b.created) return -1;
              return 0;
            });
            let stepsToDelete = this.flowInstanceTrail
              .slice(index, this.flowInstanceTrail.length)
              .map(function (trail, index) {
                var str = trail.id;
                return str;
              });
            let sisJourneyBackStep = functions.httpsCallable(
              "sis-journey-back-step-api"
            );
            await sisJourneyBackStep({
              instance: this.flowInstance.id,
              step: step,
              delete: stepsToDelete,
              professional: this.professional,
            })
              .then(async (result) => {
                return;
              })
              .catch((error) => {
                this.$swal.getCancelButton().setAttribute("enabled", "");
                this.$swal.showValidationMessage(
                  `Erro ao voltar o paciente na jornada: ${error}`
                );
                console.error(error.message);
              });
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$swal
              .fire({
                icon: "success",
                title: "Paciente voltou ao passo selecionado com sucesso! ",
                toast: false,
                showConfirmButton: true,
                timer: 1000,
                timerProgressBar: true,
              })
              .then((result) => {
                this.$router.replace({ name: "Journeys" });
              });
          }
        });
    },
    getDetails(trail) {
      let text = null;
      switch (trail.type) {
        case "message":
          text = null;
          break;
        case "template":
          text = null;
          break;
        case "question":
          text = null;
          break;
        case "schedule":
          text = null;
          break;
        case "manual-schedule":
          text = trail.calendar.eventSummary;
          break;
        case "event":
          text = null;
          break;
        case "primaryCare":
          text = trail.primaryCare.text;
          break;
        case "monitor":
          text = null;
          break;
        case "router":
          text = null;
          break;
        case "anamnesis":
          text = trail.anamnesis;
          break;
        case "survey":
          text = trail.survey;
          break;
        default:
          text = null;
          break;
      }
      return text;
    },
    actionText(trail) {
      let text = "";
      switch (trail.type) {
        case "message":
          text = "MENSAGEM";
          break;
        case "template":
          text = "TEMPLATE";
          break;
        case "question":
          text = "PERGUNTA";
          break;
        case "schedule":
          text = "AGENDAMENTO";
          break;
        case "manual-schedule":
          text = "AGENDAMENTO MANUAL";
          break;
        case "event":
          text = "AGUARDANDO EVENTO";
          break;
        case "primaryCare":
          text = "ATENÇÃO PRIMÁRIA";
          break;
        case "monitor":
          text = "MONITORIA";
          break;
        case "router":
          text = "REGRA";
          break;
        case "anamnesis":
          text = "QUESTIONÁRIO";
          break;
        case "close":
          text = "FIM";
          break;
        case "manual-close":
          text = "ENCERRAMENTO MANUAL";
          break;
        case "journey":
          text = "LINHA DE CUIDADO";
          break;
        case "survey":
          text = "PESQUISA DE SATISFAÇÃO";
          break;
        case "encounter":
          text = "CONSULTA";
          break;
        default:
          text = "NÃO DEFINIDO";
          break;
      }
      return text;
    },
    actionColor(trail) {
      let text = "primary";
      switch (trail.type) {
        case "message":
          text = "success";
          break;
        case "template":
          break;
        case "question":
          text = "blue white--text";
          break;
        case "schedule":
          text = "purple white--text";
          break;
        case "manual-schedule":
          text = "purple white--text";
          break;
        case "event":
          text = "pink white--text";
          break;
        case "primaryCare":
          text = "orange white--text";
          break;
        case "monitor":
          text = "yellow white--text";
          break;
        case "router":
          text = "grey darken-2 white--text";
          break;
        case "anamnesis":
          text = "blue white--text";
          break;
        case "close":
          text = "black";
          break;
        case "manual-close":
          text = "black";
          break;
        case "journey":
          text = "blue-grey darken-1 white--text";
          break;
        case "survey":
          text = "blue white--text";
          break;
        case "encounter":
          text = "purple white--text";
          break;
        default:
          break;
      }
      return text;
    },
    actionColorText(trail) {
      let text = "primary--text";
      switch (trail.type) {
        case "message":
          text = "success--text";
          break;
        case "template":
          break;
        case "question":
          text = "blue--text";
          break;
        case "survey":
          text = "blue--text";
          break;
        case "schedule":
          text = "purple--text";
          break;
        case "manual-schedule":
          text = "purple--text";
          break;
        case "event":
          text = "pink--text";
          break;
        case "encounter":
          text = "purple--text";
          break;
        case "primaryCare":
          text = "orange--text";
          break;
        case "monitor":
          text = "yellow--text";
          break;
        case "router":
          text = "grey--text text--darken-2";
          break;
        case "anamnesis":
          text = "blue--text";
          break;
        case "close":
          text = "black--text";
          break;
        case "manual-close":
          text = "black--text";
          break;
        case "journey":
          text = "blue-grey--text text--darken-1";
          break;
        default:
          break;
      }
      return text;
    },
    actionIcon(trail) {
      let icon = "";
      switch (trail.type) {
        case "message":
          icon = "message";
          break;
        case "template":
          icon = "square-caret-right";
          break;
        case "question":
          icon = "square-question";
          break;
        case "schedule":
          icon = "calendar-plus";
          break;
        case "manual-schedule":
          icon = "calendar-plus";
          break;
        case "event":
          icon = "square-caret-right";
          break;
        case "encounter":
          icon = "clinic-medical";
          break;
        case "primaryCare":
          icon = "person-dots-from-line";
          break;
        case "monitor":
          icon = "monitor-heart-rate";
          break;
        case "router":
          icon = "sitemap";
          break;
        case "anamnesis":
          icon = "note-medical";
          break;
        case "survey":
          icon = "thumbs-up";
          break;
        case "close":
          icon = "door-closed";
          break;
        case "manual-close":
          icon = "person-walking-arrow-right";
          break;
        case "journey":
          icon = "map-location-dot";
          break;
        default:
          icon = "square-caret-right";
          break;
      }
      return icon;
    },
    getWeekDay(item) {
      switch (
        this.$moment(
          item.year +
            "-" +
            (parseInt(item.month) >= 10 ? item.month : "0" + item.month) +
            "-" +
            (parseInt(item.day) >= 10 ? item.day : "0" + item.day) +
            "T00:00:00"
        ).isoWeekday()
      ) {
        case 1:
          return "Segunda-Feira";
        case 2:
          return "Terça-Feira";
        case 3:
          return "Quarta-Feira";
        case 4:
          return "Quinta-Feira";
        case 5:
          return "Sexta-Feira";
        case 6:
          return "Sábado";
        case 7:
          return "Domingo";
        default:
          return "-";
      }
    },
    getColor(gender) {
      if (gender === "Feminino") return "pink";
      else return "primary";
    },
  },
  created() {},
};
</script>