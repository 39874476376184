<template>
  <container
    v-if="$SecurityService.verifyButton('actionsMenu')"
    flat
    class="transparent"
  >
    <v-tooltip bottom>
      <template v-slot:activator="{ on: tooltip }">
        <v-menu
          v-model="menu"
          :rounded="true"
          :close-on-content-click="false"
          offset-x
        >
          <template v-slot:activator="{ on: menu, attrs }">
            <v-btn
              text
              small
              color="primary"
              v-bind="attrs"
              v-on="{ ...tooltip, ...menu }"
              elevation="0"
              min-width="30"
              max-width="30"
              min-height="30"
              max-height="30"
            >
              <font-awesome-icon
                :icon="['fal', 'bars']"
                fixed-width
                style="font-size: 20px"
              />
            </v-btn>
          </template>
          <v-card>
            <v-list dense>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="subtitle-2">{{
                    name ? name : fullName
                  }}</v-list-item-title>
                  <v-list-item-subtitle class="caption"
                    >Paciente</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-divider
              class="mt-n2"
              v-if="
                $SecurityService.verifyGroupOfButtons([
                  'notes',
                  'immunization',
                  'profile',
                  'message',
                ])
              "
            ></v-divider>
            <v-list
              dense
              v-if="
                $SecurityService.verifyGroupOfButtons([
                  'notes',
                  'immunization',
                  'profile',
                  'message',
                ])
              "
            >
              <v-list-item class="mt-n3">
                <v-list-item-content>
                  <v-list-item-title class="caption"
                    >Informações</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                class="mt-n4"
                v-if="$SecurityService.verifyButton('notes')"
              >
                <v-list-item-action>
                  <Notes
                    v-if="$SecurityService.verifyButton('notes')"
                    :patient="patient"
                  ></Notes>
                </v-list-item-action>
                <v-list-item-title class="caption ml-n4"
                  >Histórico do paciente</v-list-item-title
                >
              </v-list-item>
              <v-list-item
                class="mt-n4"
                v-if="$SecurityService.verifyButton('schedule')"
              >
                <v-list-item-action>
                  <NewSchedule
                    v-if="$SecurityService.verifyButton('schedule')"
                    :patient="patient"
                  ></NewSchedule>
                </v-list-item-action>
                <v-list-item-title class="caption ml-n4"
                  >Agendar Consulta</v-list-item-title
                >
              </v-list-item>
              <v-list-item
                class="mt-n4"
                v-if="$SecurityService.verifyButton('immunization')"
              >
                <v-list-item-action>
                  <Immunization
                    v-if="$SecurityService.verifyButton('immunization')"
                    :patient="patient"
                  ></Immunization>
                </v-list-item-action>
                <v-list-item-title class="caption ml-n4"
                  >Vacinas</v-list-item-title
                >
              </v-list-item>
              <v-list-item
                class="mt-n4"
                v-if="$SecurityService.verifyButton('profile')"
              >
                <v-list-item-action>
                  <Profile
                    v-if="$SecurityService.verifyButton('profile')"
                    :patient="patient"
                  ></Profile>
                </v-list-item-action>
                <v-list-item-title class="caption ml-n4"
                  >Dados Pessoais do Paciente</v-list-item-title
                >
              </v-list-item>
              <v-list-item
                class="mt-n4"
                v-if="$SecurityService.verifyButton('message')"
              >
                <v-list-item-action>
                  <Message
                    v-if="$SecurityService.verifyButton('message')"
                    :patient="patient"
                  ></Message>
                </v-list-item-action>
                <v-list-item-title class="caption ml-n4"
                  >Recados do Paciente</v-list-item-title
                >
              </v-list-item>
            </v-list>
            <v-divider
              class="mt-n4"
              v-if="$SecurityService.verifyGroupOfButtons(['interview'])"
            ></v-divider>
            <v-list
              dense
              v-if="$SecurityService.verifyGroupOfButtons(['interview'])"
            >
              <v-list-item class="mt-n3">
                <v-list-item-content>
                  <v-list-item-title class="caption"
                    >Atividades</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                class="mt-n4"
                v-if="$SecurityService.verifyButton('interview')"
              >
                <v-list-item-action>
                  <Interview
                    v-if="$SecurityService.verifyButton('interview')"
                    :patient="patient"
                  ></Interview>
                </v-list-item-action>
                <v-list-item-title class="caption ml-n4"
                  >Realizar Consulta</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </template>
      <span>Informações</span>
    </v-tooltip>
  </container>
</template>
<script>
import { mapGetters } from "vuex";
import { db } from "../../../../../firebase";
import NewSchedule from "../calendar/NewScheduleDialog.vue";
import Notes from "../historic/Dialog.vue";
import Profile from "../profile/Dialog.vue";
import Interview from "../interview/Dialog.vue";
import Immunization from "../immunization/Dialog.vue";
import Message from "../message/Dialog.vue";

export default {
  components: {
    Notes,
    Profile,
    Interview,
    Immunization,
    Message,
    NewSchedule,
  },
  props: {
    patient: {
      type: String,
      required: false,
      default: null,
    },
    name: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      fullName: null,
      menu: false,
      message: false,
      hints: true,
      firstName: null,
    };
  },
  computed: {
    ...mapGetters({
      buttons: "buttons",
    }),
  },
  methods: {},
  async created() {
    if (this.name == null && this.patient) {
      let customerDoc = await db.collection("customer").doc(this.patient).get();
      let customerData = customerDoc.data();
      this.fullName = customerData.fullName;
    }
  },
};
</script>
