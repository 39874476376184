<template>
  <v-card color="transparent" flat tile>
    <Menu></Menu>
    <v-card-title class="mt-n4 ml-n4 mb-n8">
      <v-chip class="" small color="secondary" label>
        <span class="caption">
          Total de Pacientes: {{ customersIndicators.total }}</span
        >
      </v-chip>
      <v-spacer></v-spacer>
      <v-switch
        flat
        color="secondary"
        @change="getCustomers()"
        inset
        class="caption"
        v-if="professional.dev"
        x-small
        v-model="developmentMode"
        :label="`Modo desenvoledor: ${developmentMode.toString()}`"
      ></v-switch>
    </v-card-title>
    <v-card-title class="mt-n10">
      <v-btn-toggle color="deep-purple" v-model="initial" class="ml-n4 mr-2">
        <v-btn min-height="30" min-width="30" small text value="A">A</v-btn>
        <v-btn min-height="30" min-width="30" small text value="B">B</v-btn>
        <v-btn min-height="30" min-width="30" small text value="C">C</v-btn>
        <v-btn min-height="30" min-width="30" small text value="D">D</v-btn>
        <v-btn min-height="30" min-width="30" small text value="E">E</v-btn>
        <v-btn min-height="30" min-width="30" small text value="F">F</v-btn>
        <v-btn min-height="30" min-width="30" small text value="G">G</v-btn>
        <v-btn min-height="30" min-width="30" small text value="H">H</v-btn>
        <v-btn min-height="30" min-width="30" small text value="I">I</v-btn>
        <v-btn min-height="30" min-width="30" small text value="J">J</v-btn>
        <v-btn min-height="30" min-width="30" small text value="K">K</v-btn>
        <v-btn min-height="30" min-width="30" small text value="L">L</v-btn>
        <v-btn min-height="30" min-width="30" small text value="M">M</v-btn>
        <v-btn min-height="30" min-width="30" small text value="N">N</v-btn>
        <v-btn min-height="30" min-width="30" small text value="O">O</v-btn>
        <v-btn min-height="30" min-width="30" small text value="P">P</v-btn>
        <v-btn min-height="30" min-width="30" small text value="Q">Q</v-btn>
        <v-btn min-height="30" min-width="30" small text value="R">R</v-btn>
        <v-btn min-height="30" min-width="30" small text value="S">S</v-btn>
        <v-btn min-height="30" min-width="30" small text value="T">T</v-btn>
        <v-btn min-height="30" min-width="30" small text value="U">U</v-btn>
        <v-btn min-height="30" min-width="30" small text value="V">V</v-btn>
        <v-btn min-height="30" min-width="30" small text value="W">W</v-btn>
        <v-btn min-height="30" min-width="30" small text value="X">X</v-btn>
        <v-btn min-height="30" min-width="30" small text value="Y">Y</v-btn>
        <v-btn min-height="30" min-width="30" small text value="Z">Z</v-btn>
      </v-btn-toggle>
      <v-text-field
        color="purple"
        dense
        outlined
        v-model="search"
        append-icon="mdi-magnify"
        label="Nome, contato ou identificador do paciente"
        single-line
        hide-details
        clearable
        class="mr-n4 caption"
      >
      </v-text-field>
    </v-card-title>
    <v-data-table
      class="elevation-0"
      :headers="headers"
      :items="customers"
      :search="search"
      :items-per-page="14"
      :sort-by="['fullName']"
      :sort-desc="[false]"
      :calculate-widths="true"
      :loading="loading"
    >
      <template v-slot:[`item.userId`]="{ item }">
        <span class="caption">{{ item.userId }}</span>
      </template>

      <template v-slot:[`item.age`]="{ item }">
        <span class="caption">{{
          formatBirthdate(item.birthdate) | moment("from", "now", true)
        }}</span>
      </template>
      <template v-slot:[`item.gender`]="{ item }">
        <span class="caption">{{ item.gender }}</span>
      </template>
      <template v-slot:[`item.birthdate`]="{ item }">
        <span class="caption">{{ item.birthdate }}</span>
      </template>
      <template v-slot:[`item.phone`]="{ item }">
        <span class="caption">{{ formatPhone(item.phone) }}</span>
      </template>
      <template v-slot:[`item.features`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="message(item)"
              class="mx-1"
              v-bind="attrs"
              small
              text
              v-on="on"
              elevation="0"
              color="primary"
              min-width="0"
              max-width="30"
              min-height="0"
              max-height="30"
            >
              <font-awesome-icon
                :icon="['fal', 'paper-plane']"
                fixed-width
                style="font-size: 24px"
              />
            </v-btn>
          </template>
          <span>Gestão de Mensagens</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="monitor(item)"
              class="mx-1"
              v-bind="attrs"
              v-on="on"
              small
              text
              elevation="0"
              color="primary"
              min-width="0"
              max-width="30"
              min-height="0"
              max-height="30"
            >
              <font-awesome-icon
                :icon="['fal', 'monitor-heart-rate']"
                fixed-width
                style="font-size: 24px"
              />
            </v-btn>
          </template>
          <span>Monitorar Paciente</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="evaluation(item)"
              class="mx-1"
              v-bind="attrs"
              v-on="on"
              small
              text
              elevation="0"
              color="primary"
              min-width="0"
              max-width="30"
              min-height="0"
              max-height="30"
            >
              <font-awesome-icon
                :icon="['fal', 'ballot-check']"
                fixed-width
                style="font-size: 24px"
              />
            </v-btn>
          </template>
          <span>Avaliar</span>
        </v-tooltip>
        <ActionsMenu :patient="item.id" :name="item.fullName"></ActionsMenu>
      </template>
      <template v-slot:[`item.fullName`]="{ item }">
        <span class="caption">{{ item.fullName }}</span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { formatToCPF, formatToPhone } from "brazilian-values";
import Menu from "./menu/Menu";
import ActionsMenu from "../ehr/components/actions/Menu.vue";

export default {
  data() {
    return {
      search: "",
      developmentMode: false,
      initial: null,
      overlay: false,
      loading: false,
      headers: [
        { text: "Nome", value: "fullName" },
        { text: "Sexo", value: "gender" },
        { text: "Idade", value: "age" },
        { text: "Nascimento", value: "birthdate" },
        { text: "Contato", value: "phone" },
        { text: "Identificador", value: "userId" },
        { text: "Ações", value: "features", sortable: false },
      ],
    };
  },
  components: {
    Menu,
    ActionsMenu,
  },
  computed: {
    ...mapGetters({
      customers: "customers",
      professional: "professional",
      customersIndicators: "customersIndicators",
    }),
  },
  watch: {
    initial(letter) {
      this.loading = true;
      if (letter) {
        this.getCustomers();
      }
    },
  },
  methods: {
    async getCustomers() {
      await this.$store.dispatch("unbindCustomers");
      if (this.initial) {
        this.$store
          .dispatch("bindCustomers", {
            initial: this.initial,
            developmentMode: this.developmentMode,
          })
          .then(() => {
            this.loading = false;
          });
      }
    },
    formatBirthdate(birthdate) {
      return this.$moment(birthdate, "DD/MM/YYYY");
    },
    getColor(gender) {
      if (gender === "Feminino") return "pink";
      else return "primary";
    },
    formatCPF(item) {
      return formatToCPF(item);
    },
    formatPhone(item) {
      return formatToPhone(item);
    },
    async message(customer) {
      await this.$store.dispatch("fetchCustomer", { customer: customer.id });
      await this.$store.dispatch("bindNotes", { customer: customer.id });
      await this.$store.dispatch("bindNotifications", {
        phoneCode: customer.phoneCode,
        phone: customer.phone,
      });
      this.$router.replace({ name: "Message" });
    },
    async monitor(customer) {
      await this.$store.dispatch("fetchCustomer", { customer: customer.id });
      await this.$store.dispatch("bindNotes", { customer: customer.id });
      await this.$store.dispatch("bindCustomerMonitoring", {
        customer: customer.id,
      });
      this.$router.replace({ name: "Monitoring" });
    },
    async evaluation(customer) {
      await this.$store.dispatch("fetchCustomer", { customer: customer.id });
      await this.$store.dispatch("bindNotes", { customer: customer.id });
      await this.$store.dispatch("bindEvaluators");
      await this.$store.dispatch("bindAnamnesis");
      await this.$store.dispatch("bindCustomerEvaluations", {
        customer: customer.id,
      });
      this.$router.replace({ name: "Evaluation" });
    },
  },
  beforeCreate() {
    this.$store.dispatch("resetCustomerState");
    this.$store.dispatch("unbindMessage");
    this.$store.dispatch("unbindNotes");
    this.$store.dispatch("unbindCustomerEvaluations");
    this.$store.dispatch("unbindCustomerMonitoring");
  },
  async created() {
    await this.$store.dispatch("bindCustomersIndicators");
    if (this.professional.dev) {
      this.developmentMode = true;
    }
    //this.getCustomers();
  },
};
</script>
<style>
</style>