<template>
  <v-card color="transparent" flat>
    <patientInfo></patientInfo>
    <v-card-title>
      <AddButton :resourceValue="item"></AddButton>
      <v-spacer></v-spacer>
      <v-text-field
        color="purple"
        v-model="search"
        append-icon="mdi-magnify"
        label="Nome do Avaliador"
        single-line
        hide-details
        clearable
      ></v-text-field>
    </v-card-title>
    <v-data-table
      class="elevation-0"
      :headers="headers"
      :items="customerEvaluations"
      :search="search"
      :items-per-page="13"
      :sort-by="['created']"
      :sort-desc="[true]"
      calculate-widths="false"
    >
      <template v-slot:[`item.created`]="{ item }">
        <v-chip color="secondary" small>{{
          item.created.toDate() | moment("from", "now")
        }}</v-chip>
      </template>
      <template v-slot:[`item.active`]="{ item }">
        <v-chip v-if="item.active" color="success" small> ATIVO </v-chip>
        <v-chip v-else color="warning" small> ENCERRADO </v-chip>
      </template>
      <template v-slot:[`item.evaluationDate`]="{ item }">
         <span class="subtitle-2">{{
          (parseInt(item.day) >= 10 ? item.day : "0" + item.day) +
          "/" +
          (parseInt(item.month) >= 10 ? item.month : "0" + item.month) +
          "/" +
          item.year
        }}</span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import AddButton from "./Add.vue";
import patientInfo from "../identification/Identification";

export default {
  components: {
    AddButton,
    patientInfo,
  },
  data() {
    return {
      overlay: false,
      search: "",
      load: 0,
      headers: [
        { text: "Ativo", value: "active", filterable: false, sortable: false },
        {
          text: "Criado",
          value: "created",
          filterable: false,
          sortable: false,
        },
        {
          text: "Avaliação",
          value: "evaluationDate",
          filterable: false,
          sortable: false,
        },
        {
          text: "Profissional",
          value: "evaluatorName",
          filterable: true,
          sortable: false,
        },
        {
          text: "Alertas",
          value: "alerts",
          filterable: false,
          sortable: false,
        },
        {
          text: "Marcações",
          value: "stamps",
          filterable: false,
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      customerEvaluations: "customerEvaluations",
      customer: "customer",
    }),
  },
  watch: {
    search(newValue, oldValue) {
      if (newValue.text) {
        this.search = newValue.text;
      } else {
        this.search = newValue;
      }
    },
  },
  methods: {},
  async destroyed() {
    await this.$store.dispatch("unbindCustomerEvaluations");
  },
};
</script>
