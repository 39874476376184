<template>
  <v-card outlined class="mr-2 mb-2 d-flex flex-column" width="370" elevation="0">
    <v-card-title class="text-subtitle-2">
      <font-awesome-icon class="error--text" :icon="['fal', 'face-thermometer']" fixed-width style="font-size: 25px" />
      <span class="error--text ml-2">Alerta de Saúde</span>
      <v-spacer></v-spacer>
      <Menu :name="customer.fullName"></Menu>
    </v-card-title>
    <v-card-subtitle class="text-caption"></v-card-subtitle>
    <v-card-text class="caption">
      <v-card class="mx-0 pa-1" outlined>
        <span class="error--text text-justify">{{ alert.question }} <span class="font-weight-bold">{{ alert.answer
        }}</span></span>
      </v-card>
    </v-card-text>
    <v-card-text class="caption mt-n7">
      <v-card class="mx-0 pa-1" outlined>
        <span class="grey--text text--darken-1 text-justify">Questionário que gerou o alerta:
          <span class="font-weight-bold">{{monitor.anamnesisId}}</span></span>
      </v-card>
    </v-card-text>
    <v-card-text v-if="this.alert.treated && alert.practitioner != null" >
      <v-card class="mt-n6 grey lighten-4" elevation="0">
        <v-card-text class="caption"><span class="caption purple--text font-weight-medium">Ação:</span>
          <div v-html="alert.practitioner.action"></div>
        </v-card-text>
        <v-card-text class="caption mt-n7"><span class="caption purple--text font-weight-medium">Tratativa:</span>
          <div class="mb-n4" v-html="treatement"></div>
        </v-card-text>
      </v-card>
    </v-card-text>
    <v-spacer></v-spacer>
    <v-divider></v-divider>
    <v-card-actions>
      <v-chip v-if="alert.treated" label small outlined color="purple" text-color="purple"
        class="mx-0 text-left font-weight-black">Encerrado</v-chip>
      <v-chip v-else label small outlined color="error" text-color="error">Não Encerrado</v-chip>
      <v-spacer></v-spacer>
      <EditAlert :alert="alert" :monitor="monitor" v-if="!alert.treated" @closeAlert="closeAlert"></EditAlert>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import EditAlert from "./EditAlert.vue";
import Menu from "../../../ehr/components/actions/Menu.vue";

export default {
  components: { EditAlert, Menu },
  data() {
    return {
    };
  },
  props: {
    monitor: {
      type: Object,
      required: true,
      default: null,
    },
    alert: {
      type: Object,
      required: true,
      default: null,
    },
  },
  methods: {
    closeAlert() {
      this.$emit("refresh");
    },
  },
  computed: {
    ...mapGetters({
      customer: "customer",
    }),
    treatement() {
      if (this.alert.treated) {
        return this.alert.note;
      } else {
        return "";
      }
    },
    elevation() {
      if (this.alert.treated) {
        return "0";
      } else {
        return '20';
      }
    },
  },
  async mounted() { },
};
</script>

<style>
.v-card__text,
.v-card__title {
  word-break: normal;
  /* maybe !important  */
}
</style>
