<template>
  <v-dialog v-model="dialog" max-width="1200" eager>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom color="primary">
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
            elevation="0"
            icon
            color="primary"
            min-width="0"
            max-width="30"
            min-height="0"
            max-height="30"
          >
            <font-awesome-icon
              :icon="['fal', 'calendar-pen']"
              fixed-width
              style="font-size: 20px"
            />
          </v-btn>
        </template>
        <span>Editar Agendamento</span>
      </v-tooltip>
    </template>
    <v-card>
      <Reschedule
        v-if="dialog"
        :roomId="roomId"
        :eventId="eventId"
        :patient="patient"
        
        @closeSchedule="closeSchedule"
      ></Reschedule>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import Reschedule from "./Reschedule.vue";

export default {
  data: () => ({
    dialog: false,
  }),
  props: {
    roomId: {
      type: String,
      required: false,
      default: null,
    },
    eventId: {
      type: String,
      required: false,
      default: null,
    },
    patient: {
      type: String,
      required: false,
      default: null,
    },
  },
  components: { Reschedule },
  computed: {
    ...mapGetters({}),
  },
  watch: {},
  methods: {
    closeSchedule() {
      this.$emit("closeSchedule");
      this.dialog = false;
    },
  },
  destroyed() {},
};
</script>

<style>
</style>