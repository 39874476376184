<template>
  <v-card
    v-if="moments.length > 0"
    flat
    width="100%"
    color="transparent"
    class="mt-4"
  >
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        :rotate="360"
        :size="100"
        :width="8"
        color="white"
      >
      </v-progress-circular>
    </v-overlay>

    <v-data-table
      class="elevation-0 transparent"
      :headers="headers"
      :items="moments"
      :items-per-page="10"
      :calculate-widths="true"
      sort-by="total"
      :sort-desc="true"
    >
      <template v-slot:[`item.patients`]="{ item }">
        <v-chip
          v-if="item.patients && item.patients.length > 0"
          label
          small
          color="warning"
          @click="getPatientList(item)"
          class="text-left text-caption font-weight-black"
        >
          <span class="ml-1 caption">Pacientes</span>
        </v-chip>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" max-width="1200" eager>
      <v-card flat class="pa-2">
        <v-card class="mx-auto" flat outlined>
          <v-toolbar dense flat>
            <v-toolbar-title class="text-subtitle-1">
              <font-awesome-icon
                :icon="['fal', 'gauge-max']"
                fixed-width
                style="font-size: 16px"
              />
              <span class="font-weight-medium"
                >{{ title }}
              </span></v-toolbar-title
            >

            <v-spacer></v-spacer>
          </v-toolbar>
          <v-divider></v-divider>
          <v-text-field
            color="purple"
            dense
            outlined
            v-model="search"
            append-icon="mdi-magnify"
            label="Nome, contato ou identificador do paciente"
            single-line
            hide-details
            clearable
            class="ma-2 caption"
          >
          </v-text-field>
          <v-data-table
            class="elevation-0"
            :headers="headersPatients"
            :items="patients"
            :search="search"
            :items-per-page="5"
            :sort-by="['fullName']"
            :sort-desc="[false]"
            :calculate-widths="true"
          >
            <template v-slot:[`item.userId`]="{ item }">
              <span class="caption">{{ item.userId }}</span>
            </template>

            <template v-slot:[`item.age`]="{ item }">
              <span class="caption">{{
                formatBirthdate(item.birthdate) | moment("from", "now", true)
              }}</span>
            </template>
            <template v-slot:[`item.gender`]="{ item }">
              <span class="caption">{{ item.gender }}</span>
            </template>
            <template v-slot:[`item.birthdate`]="{ item }">
              <span class="caption">{{ item.birthdate }}</span>
            </template>
            <template v-slot:[`item.phone`]="{ item }">
              <span class="caption">{{ formatPhone(item.phone) }}</span>
            </template>
            <template v-slot:[`item.features`]="{ item }">
              <ActionsMenu
                :patient="item.id"
                :name="item.fullName"
              ></ActionsMenu>
            </template>
            <template v-slot:[`item.fullName`]="{ item }">
              <span class="caption">{{ item.fullName }}</span>
            </template>
          </v-data-table>
        </v-card>
      </v-card>
    </v-dialog>
  </v-card>
  <v-card v-else color="transparent" flat width="100%" class="mt-4">
    <v-card-text class="subtitle-2 grey--text text--darken-1"
      >Não há momentos para essa linha de cuidado!</v-card-text
    >
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { functions } from "../../../../../firebase";
import { formatToCPF, formatToPhone } from "brazilian-values";
import ActionsMenu from "../../../ehr/components/actions/Menu.vue";

export default {
  name: "app-dashboard-moment",
  components: {
    ActionsMenu,
  },
  props: {
    kpis: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      overlay: false,
      title: "",
      moments: [],
      headers: [
        {
          text: "Momento",
          value: "moment",
          filterable: false,
          sortable: true,
        },
        {
          text: "Total",
          value: "total",
          filterable: false,
          sortable: true,
        },
        {
          text: "Pacientes",
          value: "patients",
          filterable: false,
          sortable: false,
        },
      ],
      patients: [],
      dialog: false,
      search: "",
      headersPatients: [
        { text: "Nome", value: "fullName" },
        { text: "Sexo", value: "gender" },
        { text: "Idade", value: "age" },
        { text: "Nascimento", value: "birthdate" },
        { text: "Contato", value: "phone" },
        { text: "Identificador", value: "userId" },
        { text: "Ações", value: "features", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    async getPatientList(item) {
      this.dialog = true;
      this.title = item.moment;
      let sisKpiCustomerList = functions.httpsCallable(
        "sis-kpi-customer-list-api"
      );
      await sisKpiCustomerList({
        patients: item.patients,
      })
        .then(async (result) => {
          this.patients = result.data.patients;
        })
        .catch((error) => {
          console.error(error.message);
        });
    },
    formatBirthdate(birthdate) {
      return this.$moment(birthdate, "DD/MM/YYYY");
    },
    getColor(gender) {
      if (gender === "Feminino") return "pink";
      else return "primary";
    },
    formatCPF(item) {
      return formatToCPF(item);
    },
    formatPhone(item) {
      return formatToPhone(item);
    },
  },
  mounted() {
    for (
      let index = 0;
      index < Object.keys(this.kpis.moments).length;
      index++
    ) {
      this.moments.push(
        this.kpis.moments[Object.keys(this.kpis.moments)[index]]
      );
    }
    console.info(this.moments);
  },
};
</script>
<style lang="scss">
@import "../../../../../scss/_variables.scss";

.data-studio {
  @media #{$xxlmax-device} {
    width: 100%;
    height: 600px;
  }

  @media #{$xlmax-device} {
    width: 100%;
    height: 700px;
  }

  @media #{$desktop-device} {
    width: 100%;
    height: 600px;
  }

  @media #{$tablet-device} {
    width: 100%;
    height: 800px;
  }

  @media #{$large-mobile} {
    height: 700px;
  }

  @media #{$extra-small-mobile} {
    height: 600px;
  }
}
</style>