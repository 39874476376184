import Dashboard from "../../components/structural/Dashboard.vue";
import Drawer from "./drawer";
// Views
import InternalMedicine from "./views/internalMedicine/view";
import Endocrinology from "./views/endocrinology/view";
import nurseHealthScreening from "./views/nurseHealthScreening/view";

export default[
    {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: {
            requiresAuth: true
        },
        children: [
            ...Drawer,
            ...Endocrinology,
            ...InternalMedicine,
            ...nurseHealthScreening
        ]
    },
];
