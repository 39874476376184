<template>
  <v-card class="mx-auto" flat>
    <v-card class="mb-2 rounded" flat color="grey lighten-3">
      <v-toolbar flat height="35" elevation="0" class="secondary">
        <v-toolbar-title class="subtitle-2 white--text">{{ sequence }}. Hipótese Diagnóstica</v-toolbar-title>
        <v-spacer></v-spacer>
        <Suggestion></Suggestion>
      </v-toolbar>
    </v-card>
    <TiptapVuetify min-height="35vh" max-height="35vh" scrolled :toolbar-attributes="{}" rounded
      v-model="diagnosticHypothesis" :extensions="extensions" :card-props="{ flat: true, color: '', outlined: true }" />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import Suggestion from "../../../../components/suggestion/Dialog.vue";
import {
  History,
  TiptapVuetify,
  Bold,
  Italic,
  Strike,
  Underline,
  BulletList,
  OrderedList,
  ListItem,
  Blockquote,
} from "tiptap-vuetify";
export default {
  props: {
    sequence: {
      type: Number,
      required: true,
      default: null,
    },
  },
  data: () => ({
    extensions: [
      History,
      Blockquote,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      Bold,
    ],
  }),
  components: { TiptapVuetify, Suggestion },
  computed: {
    ...mapGetters({}),
    diagnosticHypothesis: {
      get() {
        return this.$store.getters.diagnosticHypothesis;
      },
      set(value) {
        this.$store.dispatch("setDiagnosticHypothesis", value);
      },
    },
  },
  methods: {},
  destroyed() { },
};
</script>

<style>

</style>