import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

export default {
  state: {
    knowledgebase: [],
  },
  actions: {
    bindKnowledgebase: firestoreAction((context) => {
      let ref = db.collection("disease-knowledge-base");
      context.bindFirestoreRef("knowledgebase", ref, {
        reset: true,
      });
    }),
  },
  getters: {
    knowledgebase(state) {
      return state.knowledgebase;
    },
  },
};
