<template>
  <v-card color="transparent" flat>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate :rotate="360" :size="100" :width="8" color="white">
      </v-progress-circular>
    </v-overlay>

    <v-toolbar dense rounded outlined elevation="0">
      <v-card-title class="ml-n6 grey--text text--darken-2">
        <font-awesome-icon :icon="['fal', 'user-shield']" fixed-width style="font-size: 25px" />Termos de Consentimento
      </v-card-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-card-title>
      <v-row class="mt-0">
        <v-spacer></v-spacer>
        <v-select color="purple" dense outlined :items="professionalTcles" v-model="selectedProfessionalTcles"
          label="Tcles" item-text="title" clearable multiple @change="(selection) => selectionChanged(selection)"
          :return-object="true" class="select caption">
          <template v-slot:selection="{ item, index }">
            <v-chip small outlined v-if="index === 0">
              <span class="caption">{{ item.title }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text text-caption">(+ outros)</span>
          </template>
        </v-select>
        <v-text-field dense outlined class="ml-5 search caption" color="purple" v-model="search"
          append-icon="mdi-magnify" label="Nome do paciente" single-line hide-details clearable></v-text-field>
      </v-row>
    </v-card-title>

    <v-card class="mt-0" width="100%" outlined color="grey lighten-2" elevation="24">
      <v-tabs left v-model="tab" grow icons-and-text color="secondary" height="35">
        <v-tabs-slider color="warning"></v-tabs-slider>
        <v-tab>
          <v-badge v-if="totalTclesAccepteds > 0" inline color="success" :content="totalTclesAccepteds"><span
              class="caption">Aceitos</span></v-badge>
          <v-badge v-else inline color="success" content="0"><span class="caption">Aceitos</span></v-badge>
        </v-tab>
        <v-tab class="">
          <v-badge v-if="totalTclesNotAccepteds > 0" inline color="error" :content="totalTclesNotAccepteds"><span
              class="caption">
              Negados
            </span></v-badge>
          <v-badge v-else inline color="secondary" content="0"><span class="caption">
              Negados
            </span></v-badge>
        </v-tab>
      </v-tabs>
    </v-card>
    <v-tabs-items class="transparent" v-model="tab">
      <v-tab-item class="transparent" reverse-transition="fade-transition" transition="fade-transition">
        <Executed :search="search"></Executed>
      </v-tab-item>
      <v-tab-item class="transparent" reverse-transition="fade-transition" transition="fade-transition">
        <NotExecuted :search="search"></NotExecuted>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import Executed from "./tabs/TclesAccepteds.vue";
import NotExecuted from "./tabs/TclesNotAccepteds.vue";

export default {
  name: "app-tcle",
  components: {
    Executed,
    NotExecuted
  },
  data() {
    return {
      professionalTcles: [],
      selectedProfessionalTcles: [],
      tab: null,
      overlay: false,
      search: "",
    };
  },
  computed: {
    ...mapGetters({
      tclesAccepteds: "tclesAccepteds",
      tclesNotAccepteds: "tclesNotAccepteds",
      professional: "professional",
      tcles: "tcles",
    }),
    totalTclesAccepteds() {
      return this.tclesAccepteds != null &&
        this.tclesAccepteds.length > 0
        ? this.tclesAccepteds.length
        : "0";
    },
    totalTclesNotAccepteds() {
      return this.tclesNotAccepteds != null &&
        this.tclesNotAccepteds.length > 0
        ? this.tclesNotAccepteds.length
        : "0";
    },
  },
  methods: {
    async selectionChanged(selection) {
      this.overlay = true;

      if (selection.length == 0) {
        this.$store.dispatch("unbindTclesNotAccepteds");
        this.$store.dispatch("unbindTclesAccepteds");
        this.overlay = false;
      }

      this.$store.dispatch("unbindTclesNotAccepteds");
      this.$store.dispatch("unbindTclesAccepteds");

      let selectedList = Array.from(selection, (s) => s.queryId);

      await this.$store.dispatch("bindTclesNotAccepteds", {
        tcles: selectedList,
      });
      await this.$store.dispatch("bindTclesAccepteds", {
        tcles: selectedList,
      });
      this.overlay = false;
    },
    getColor(gender) {
      if (gender == "Feminino") return "pink";
      else return "primary";
    }
  },
  beforeCreate() {
    this.$store.dispatch("resetCustomerState");
    this.$store.dispatch("unbindNotes");
    this.$store.dispatch("unbindMessage");
    this.$store.dispatch("unbindTclesNotAccepteds");
    this.$store.dispatch("unbindTclesAccepteds");
  },
  beforeDestroy() { },
  async created() {
    this.overlay = true;
    this.professionalTcles = await this.tcles.filter(function (m) {
      return this.findIndex((pm) => pm.queryId == m.queryId) > -1;
    }, this.professional.tcles);

    this.selectedProfessionalTcles = this.professionalTcles;
    await this.selectionChanged(this.professional.tcles);
    this.overlay = false;
  }
};
</script>
<style lang="scss">
@import "../../../../scss/_variables.scss";

.data-studio {
  @media #{$xxlmax-device} {
    width: 100%;
    height: 600px;
  }

  @media #{$xlmax-device} {
    width: 100%;
    height: 700px;
  }

  @media #{$desktop-device} {
    width: 100%;
    height: 600px;
  }

  @media #{$tablet-device} {
    width: 100%;
    height: 800px;
  }

  @media #{$large-mobile} {
    height: 700px;
  }

  @media #{$extra-small-mobile} {
    height: 600px;
  }
}

.select {
  max-width: 650px;
  min-width: 650px;
}

.search {
  max-width: 350px;
  min-width: 350px;
}

.v-dialog {
  border-radius: 6px;
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  z-index: inherit;
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%);
}
</style>