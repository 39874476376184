<template>
  <v-card color="transparent" flat>
    <v-toolbar dense rounded outlined elevation="0">
      <v-card-title class="ml-n6 grey--text text--darken-2">
        <font-awesome-icon
          :icon="['fal', 'clinic-medical']"
          fixed-width
          style="font-size: 25px"
        />Sala de Espera
      </v-card-title>
      <font-awesome-icon
        class="mr-2"
        @click="setAvailability()"
        :icon="['fa-duotone', available ? 'toggle-on' : 'toggle-off']"
        :style="getAvailableStyle()"
        fixed-width
      />
      <v-chip small label :color="available ? 'success' : 'error'">
        {{ available ? "Disponível" : "Não Disponível" }}
      </v-chip>

      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-1"
            @click="$router.replace({ name: 'EncounterSurveys' })"
            small
            text
            v-bind="attrs"
            v-on="on"
            elevation="0"
            color="primary"
            min-width="35"
            max-width="35"
            min-height="35"
            max-height="35"
          >
            <font-awesome-icon
              :icon="['fal', 'thumbs-up']"
              fixed-width
              style="font-size: 25px"
            />
          </v-btn>
        </template>
        <span>Pesquisas de Satisfação</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-1"
            @click="$router.replace({ name: 'WaitingRoomHistorical' })"
            small
            text
            v-bind="attrs"
            v-on="on"
            elevation="0"
            color="primary"
            min-width="35"
            max-width="35"
            min-height="35"
            max-height="35"
          >
            <font-awesome-icon
              :icon="['fal', 'magnifying-glass-chart']"
              fixed-width
              style="font-size: 25px"
            />
          </v-btn>
        </template>
        <span>Histórico</span>
      </v-tooltip>
    </v-toolbar>

    <v-card-title>
      <v-row class="mt-0">
        <v-spacer></v-spacer>
        <v-select
          color="purple"
          dense
          outlined
          :items="professionalRooms"
          v-model="selectedProfessionalRooms"
          label="Salas"
          clearable
          multiple
          item-text="name"
          item-value="queryId"
          @change="(selection) => selectionChanged(selection)"
          class="select caption"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip small outlined v-if="index === 0">
              <span class="caption">{{ item.name }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text text-caption"
              >(+ outros)</span
            >
          </template>
        </v-select>
        <v-text-field
          dense
          outlined
          class="ml-5 search caption"
          color="purple"
          v-model="search"
          append-icon="mdi-magnify"
          label="Nome do paciente"
          single-line
          hide-details
          clearable
        ></v-text-field>
      </v-row>
    </v-card-title>

    <v-card width="100%" outlined color="grey lighten-2" elevation="24">
      <v-tabs
        left
        v-model="tab"
        grow
        icons-and-text
        color="secondary"
        height="35"
      >
        <v-tabs-slider color="warning"></v-tabs-slider>
        <v-tab>
          <v-badge inline color="warning" :content="totalWaiting"
            ><span class="caption">Aguardando Atendimento</span>
          </v-badge>
        </v-tab>
        <v-tab>
          <v-badge inline color="success" :content="totalInAttendance"
            ><span class="caption">Em Atendimento</span>
          </v-badge>
        </v-tab>
        <v-tab>
          <v-badge inline color="grey" :content="totalClosed"
            ><span class="caption">Realizadas Hoje</span>
          </v-badge>
        </v-tab>
      </v-tabs>
    </v-card>
    <v-tabs-items class="transparent" v-model="tab">
      <v-tab-item
        class="transparent"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
        <Waiting :loading="loadingWaiting" :search="search"></Waiting>
      </v-tab-item>
      <v-tab-item
        class="transparent"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
        <InAttendance
          :loading="loadingInAttendance"
          :search="search"
        ></InAttendance>
      </v-tab-item>
      <v-tab-item
        class="transparent"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
        <Closed :loading="loadingClosed" :search="search"></Closed>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { db } from "../../../firebase";
import InAttendance from "./tabs/InAttendance.vue";
import Waiting from "./tabs/Waiting.vue";
import Closed from "./tabs/Closed.vue";

export default {
  name: "app-monitoring",
  components: {
    //Tabs
    InAttendance,
    Waiting,
    Closed,
  },
  data() {
    return {
      tab: null,
      professionalRooms: [],
      selectedProfessionalRooms: [],
      search: "",
      available: true,
      loadingWaiting: false,
      loadingInAttendance: false,
      loadingClosed: false,
    };
  },
  watch: {
    async available(available) {
      if (available) {
        await this.selectionChanged(this.professional.rooms);
      } else {
        this.$store.dispatch("fetchQueueInfoReset");
      }
    },
  },
  computed: {
    ...mapGetters({
      professional: "professional",
      rooms: "rooms",
      queueWaiting: "queueWaiting",
      queueInAttendance: "queueInAttendance",
      queueClosed: "queueClosed",
    }),
    totalInAttendance() {
      return this.queueInAttendance != null && this.queueInAttendance.length > 0
        ? this.queueInAttendance.length
        : "0";
    },
    totalWaiting() {
      return this.queueWaiting != null && this.queueWaiting.length > 0
        ? this.queueWaiting.length
        : "0";
    },
    totalClosed() {
      return this.queueClosed != null && this.queueClosed.length > 0
        ? this.queueClosed.length
        : "0";
    },
  },
  methods: {
    getAvailableStyle() {
      return {
        "font-size": "30px",
        color: this.available ? "green" : "red",
      };
    },
    async setAvailability() {
      this.available = !this.available;

      this.$LoggerService.debug(`Professional available: ${this.available}`);

      await db
        .collection("professionals")
        .doc(this.professional.id)
        .update({ available: this.available }, { merge: true });
    },
    async selectionChanged(selection) {
      if (selection) {
        this.loadingWaiting = true;
        this.loadingInAttendance = true;
        this.loadingClosed = true;

        if (selection.length == 0) {
          await this.$store.dispatch("unbindQueueWaiting");
          await this.$store.dispatch("unbindQueueInAttendance");
          await this.$store.dispatch("unbindQueueClosed");
          this.loadingWaiting = false;
          this.loadingInAttendance = false;
          this.loadingClosed = false;
        }

        await this.$store.dispatch("unbindQueueWaiting");
        await this.$store.dispatch("unbindQueueInAttendance");
        await this.$store.dispatch("unbindQueueClosed");

        this.$store
          .dispatch("bindQueueWaiting", {
            location: selection,
          })
          .then(() => {
            this.loadingWaiting = false;
          });
        this.$store
          .dispatch("bindQueueInAttendance", {
            location: selection,
          })
          .then(() => {
            this.loadingInAttendance = false;
          });
        this.$store
          .dispatch("bindQueueClosed", {
            location: selection,
          })
          .then(() => {
            this.loadingClosed = false;
          });
      } else {
        await this.$store.dispatch("unbindQueueWaiting");
        await this.$store.dispatch("unbindQueueInAttendance");
        await this.$store.dispatch("unbindQueueClosed");
        this.loadingWaiting = false;
        this.loadingInAttendance = false;
        this.loadingClosed = false;
      }
    },
    getColor(gender) {
      if (gender == "Feminino") return "pink";
      else return "primary";
    },
  },
  mounted() {
    this.$LoggerService.info("WaitingRoom carregado com sucesso");
  },
  beforeCreate() {
    this.$LoggerService.debug("Unbinding WaitingRoom data");

    this.$store.dispatch("resetCustomerState");
    this.$store.dispatch("unbindNotes");
    this.$store.dispatch("unbindMessage");
    this.$store.dispatch("unbindQueueWaiting");
    this.$store.dispatch("unbindQueueInAttendance");
    this.$store.dispatch("unbindQueueClosed");
  },
  async created() {
    this.professionalRooms = await this.rooms.filter(function (m) {
      return this.findIndex((pm) => pm == m.queryId) > -1;
    }, this.professional.rooms);

    this.$LoggerService.debug(
      "Professional Rooms loaded",
      this.professionalRooms
    );

    this.selectedProfessionalRooms = this.professionalRooms;
    if (this.professional.available) {
      await this.selectionChanged(this.professional.rooms);
    } else {
      this.available = false;
    }
  },
};
</script>
<style>
.select {
  max-width: 500px;
  min-width: 500px;
}

.search {
  max-width: 400px;
  min-width: 400px;
}
</style>