<template>
  <v-card flat class="transparent">
    <v-row>
      <v-col>
        <PatientDetailsButton></PatientDetailsButton>
      </v-col>
      <v-col class="ml-n4">
        <ScheduleButton></ScheduleButton>
      </v-col>
      <v-col class="ml-n4">
        <ImmunizationHistoryButton></ImmunizationHistoryButton>
      </v-col>
      <v-col class="ml-n4">
        <PatientHistoryButton></PatientHistoryButton>
      </v-col>
      <v-col class="ml-n4 mr-2">
        <MessageButton></MessageButton>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import PatientDetailsButton from "../../../../../components/profile/Dialog.vue";
import ImmunizationHistoryButton from "../../../../../components/immunization/Dialog.vue";
import PatientHistoryButton from "../../../../../components/historic/Dialog.vue";
import ScheduleButton from "../../../../../components/schedule/Dialog.vue";
import MessageButton from "../../../../../components/message/Dialog.vue";

export default {
  components: {
    PatientDetailsButton,
    ImmunizationHistoryButton,
    PatientHistoryButton,
    ScheduleButton,
    MessageButton,
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {},
  destroyed() { },
};
</script>
<style>

</style>