<template>
  <v-card color="transparent" flat>
    <v-toolbar dense rounded outlined elevation="0">
      <v-card-title class="ml-n6 grey--text text--darken-2">
        <font-awesome-icon
          :icon="['fal', 'magnifying-glass-chart']"
          fixed-width
          style="font-size: 25px"
        />Painel Inteligente de Atendimentos
      </v-card-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-1"
            @click="$router.replace({ name: 'WaitingRoom' })"
            small
            text
            v-bind="attrs"
            v-on="on"
            elevation="0"
            color="primary"
            min-width="35"
            max-width="35"
            min-height="35"
            max-height="35"
          >
            <font-awesome-icon
              :icon="['fal', 'clinic-medical']"
              fixed-width
              style="font-size: 25px"
            />
          </v-btn>
        </template>
        <span>Sala de Espera</span>
      </v-tooltip>
    </v-toolbar>
    <v-toolbar rounded outlined elevation="0" class="mt-1">
      <v-select
        color="purple"
        dense
        outlined
        :items="years"
        v-model="year"
        label="Ano"
        :return-object="true"
        class="mt-6 mr-2 caption"
      >
        <template v-slot:selection="{ item, index }">
          <v-chip small outlined v-if="index === 0">
            <span class="caption">{{ item.text }}</span>
          </v-chip>
        </template>
      </v-select>
      <v-select
        :disabled="year == null"
        color="purple"
        dense
        outlined
        :items="months"
        v-model="month"
        label="Mês"
        :return-object="true"
        class="mt-6 mr-2 caption"
      >
        <template v-slot:selection="{ item, index }">
          <v-chip small outlined v-if="index === 0">
            <span class="caption">{{ item.text }}</span>
          </v-chip>
        </template>
      </v-select>
      <v-select
        :disabled="year == null && month == null"
        color="purple"
        dense
        outlined
        :items="professionalRooms"
        v-model="selectedProfessionalRooms"
        item-text="name"
        item-value="queryId"
        label="Sala"
        @change="(selection) => selectionChanged(selection)"
        :return-object="true"
        class="mt-6 mr-2 caption"
      >
        <template v-slot:selection="{ item, index }">
          <v-chip small outlined v-if="index === 0">
            <span class="caption">{{ item.name }}</span>
          </v-chip>
        </template>
      </v-select>
    </v-toolbar>
    <v-card class="mt-1" width="100%" outlined min-height="5vh">
      <v-card-title class="caption mt-n3 ml-n2 font-weight-bold"
        >Visão Geral dos Atendimentos</v-card-title
      >
      <v-card-text>
        <v-row>
          <v-col>
            <v-badge
              v-if="selectedProfessionalRooms && waitingRoomKpis"
              color="primary"
              inline
              overlap
              content="Total de Atendimentos"
            >
              <v-progress-circular
                :rotate="0"
                :size="70"
                :width="10"
                :value="100"
                color="primary"
              >
                {{ waitingRoomKpis.closed }}
              </v-progress-circular>
            </v-badge>
            <v-badge
              v-else
              color="grey"
              inline
              overlap
              content="Total de Atendimentos"
            >
              <v-progress-circular
                :rotate="0"
                :size="70"
                :width="10"
                :value="0"
                color="grey"
              >
                {{ 0 }}
              </v-progress-circular>
            </v-badge>
            <v-badge
              v-if="selectedProfessionalRooms && waitingRoomKpis"
              color="primary"
              inline
              overlap
              content="de Atendimentos com Atrasos Acima de 10 Minutos"
            >
              <v-progress-circular
                :rotate="0"
                :size="70"
                :width="10"
                :value="100"
                color="primary"
              >
                {{ getWaitPercentage() }}%
              </v-progress-circular>
            </v-badge>
            <v-badge
              v-else
              color="grey"
              inline
              overlap
              content="de Atendimentos com Atrasos Acima de 10 Minutos"
            >
              <v-progress-circular
                :rotate="0"
                :size="70"
                :width="10"
                :value="0"
                color="grey"
              >
                {{ 0 }}%
              </v-progress-circular>
            </v-badge>

            <v-badge
              v-if="selectedProfessionalRooms && waitingRoomKpis"
              color="secondary"
              inline
              overlap
              content="Tempo Médio (minutos)"
            >
              <v-progress-circular
                :rotate="0"
                :size="70"
                :width="10"
                :value="100"
                color="secondary"
              >
                {{
                  [$ss.mean(this.waitingRoomKpis.encounter.times), "seconds"]
                    | duration("minutes")
                }}
              </v-progress-circular>
            </v-badge>
            <v-badge
              v-else
              color="grey"
              inline
              overlap
              content="Tempo Médio (minutos)"
            >
              <v-progress-circular
                :rotate="0"
                :size="70"
                :width="10"
                :value="0"
                color="grey"
              >
                {{ 0 }}
              </v-progress-circular>
            </v-badge>

            <v-badge
              v-if="selectedProfessionalRooms && waitingRoomKpis"
              color="warning"
              inline
              overlap
              content="Tempo Médio de Espera (minutos)"
            >
              <v-progress-circular
                :rotate="0"
                :size="70"
                :width="10"
                :value="100"
                color="warning"
              >
                {{
                  [
                    $ss.mean(this.waitingRoomKpis.encounter.waittimes),
                    "seconds",
                  ] | duration("minutes")
                }}
              </v-progress-circular>
            </v-badge>
            <v-badge
              v-else
              color="grey"
              inline
              overlap
              content="Tempo Médio de Espera (minutos)"
            >
              <v-progress-circular
                :rotate="0"
                :size="70"
                :width="10"
                :value="0"
                color="grey"
              >
                {{ 0 }}
              </v-progress-circular>
            </v-badge>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card
      width="100%"
      outlined
      color="grey lighten-2"
      elevation="24"
      class="mt-2"
    >
      <v-tabs
        left
        v-model="tab"
        grow
        icons-and-text
        color="secondary"
        height="35"
      >
        <v-tabs-slider color="warning"></v-tabs-slider>
        <v-tab>
          <span class="caption">Indicadores Gerais</span>
        </v-tab>
        <v-tab>
          <span class="caption">Profissionais</span>
        </v-tab>
        <v-tab>
          <span class="caption">Populacional</span>
        </v-tab>
      </v-tabs>
    </v-card>
    <v-tabs-items class="transparent" v-model="tab">
      <v-tab-item
        class="transparent"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
        <Kpis v-if="month" :month="month.text"></Kpis>
      </v-tab-item>
      <v-tab-item
        class="transparent"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
        <KpisPractitioners v-if="month" :month="month.text"></KpisPractitioners>
      </v-tab-item>
      <v-tab-item
        class="transparent"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
        <Populational></Populational>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import Kpis from "./tabs/Kpis.vue";
import KpisPractitioners from "./tabs/KpisPractitioners.vue";
import Populational from "./tabs/Populational.vue";

export default {
  name: "app-encounter-dashboard",
  components: { Kpis, KpisPractitioners, Populational },
  data() {
    return {
      months: [
        { text: "Janeiro", value: 1 },
        { text: "Fevereiro", value: 2 },
        { text: "Março", value: 3 },
        { text: "Abril", value: 4 },
        { text: "Maio", value: 5 },
        { text: "Junho", value: 6 },
        { text: "Julho", value: 7 },
        { text: "Agosto", value: 8 },
        { text: "Setembro", value: 9 },
        { text: "Outubro", value: 10 },
        { text: "Novembro", value: 11 },
        { text: "Dezembro", value: 12 },
      ],
      years: [
        { text: "2023", value: 2023 },
        { text: "2024", value: 2024 },
        { text: "2025", value: 2025 },
        { text: "2026", value: 2026 },
        { text: "2027", value: 2027 },
        { text: "2028", value: 2028 },
        { text: "2029", value: 2029 },
        { text: "2030", value: 2030 },
      ],
      year: null,
      month: null,
      tab: null,
      today: null,
      professionalRooms: [],
      selectedProfessionalRooms: null,
    };
  },
  props: {
    journeyRooms: {
      type: Object,
      required: false,
      default: null,
    },
  },
  watch: {
    async year(change) {
      await this.$store.dispatch("unbindWaitingRoomKpis");
      this.month = null;
      this.selectedProfessionalRooms = null;
    },
    async month(change) {
      await this.$store.dispatch("unbindWaitingRoomKpis");
      this.selectedProfessionalRooms = null;
    },
  },
  computed: {
    ...mapGetters({
      professional: "professional",
      rooms: "rooms",
      waitingRoomKpis: "waitingRoomKpis",
    }),
  },
  methods: {
    getWaitPercentage() {
      let delays = this.waitingRoomKpis.encounter.waittimes.filter(
        (val) => val / 60 > 10
      );
      let percentage = (delays.length / this.waitingRoomKpis.closed) * 100;
      return percentage.toFixed(0);
    },
    getMeanTime() {
      let duration = this.$moment.duration(
        this.$ss.mean(this.waitingRoomKpis.encounter.times),
        "seconds"
      );
      let formatted = duration.format("hh:mm:ss");
      return 1;
    },
    async selectionChanged(selection) {
      await this.$store.dispatch("unbindWaitingRoomKpis");

      let key = this.year.value + "-" + this.month.value;

      await this.$store.dispatch("bindWaitingRoomKpis", {
        room: selection.queryId,
        key: key,
      });
    },
  },
  async created() {
    this.today = this.$moment(new Date()).format("YYYY-M");
    if (this.journeyRooms) {
      this.professionalRooms = this.journeyRooms;
    } else {
      this.professionalRooms = await this.rooms.filter(function (m) {
        return this.findIndex((pm) => pm == m.queryId) > -1;
      }, this.professional.rooms);
    }
  },
};
</script>
<style lang="scss">
@import "../../../scss/variables";

.data-studio {
  @media #{$xxlmax-device} {
    width: 100%;
    height: 600px;
  }

  @media #{$xlmax-device} {
    width: 100%;
    height: 700px;
  }

  @media #{$desktop-device} {
    width: 100%;
    height: 600px;
  }

  @media #{$tablet-device} {
    width: 100%;
    height: 800px;
  }

  @media #{$large-mobile} {
    height: 700px;
  }

  @media #{$extra-small-mobile} {
    height: 600px;
  }
}

.select {
  max-width: 100%;
  min-width: 620px;
}
</style>