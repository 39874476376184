<template>
  <v-card flat>

    <v-toolbar dense rounded outlined elevation="0">
      <v-card-title class="ml-n6 grey--text text--darken-2">
        <font-awesome-icon :icon="['fal', 'hospital-user']" fixed-width style="font-size: 25px" />Pacientes
      </v-card-title>
      <v-spacer></v-spacer>
      <!--Buttons Actions -->
      <div class="mr-n5">
        <MenuButton v-for="mn in menu" :key="mn.router" :menu="mn" menuAction="patients"></MenuButton>
      </div>
    </v-toolbar>
  </v-card>

</template>

<script>
import { mapGetters } from "vuex";
import MenuButton from "./MenuButton";

export default {
  components: {
    MenuButton,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      menu: "menu",
    }),
  },
  methods: {},
  mounted() { },
};
</script>
