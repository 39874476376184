<template>
  <v-card class="mx-auto" flat>
    <v-card class="mb-2 rounded" flat color="grey lighten-3">
      <v-toolbar flat height="35" elevation="0" class="grey lighten-3">
        <v-toolbar-title class="subtitle-2 grey--text">7. Encerrar</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small class="mr-n3" height="25" dark color="error" elevation="0" center
          @click="close()">
          <font-awesome-icon class="mr-2" color="white" :icon="['fal', 'door-closed']" fixed-width
            style="font-size: 15px" />Encerrar Atendimento
        </v-btn>
      </v-toolbar>
    </v-card>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { functions } from "../../../../../../firebase";

export default {
  data: () => ({}),
  components: {},
  computed: {
    ...mapGetters({
      interview: "interview",
      customer: "customer",
      professional: "professional",
      queueInfo: "queueInfo",
    }),
  },
  watch: {},
  methods: {
    async close() {
      await this.$store.dispatch("fetchQueueInfo", this.queueInfo);
      this.$swal
        .fire({
          title: "Concluir Atendimento",
          text:
            "Você realmente deseja concluir o atendimento de " +
            this.customer.fullName +
            "?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            this.$swal.showLoading();
            this.$swal.getCancelButton().setAttribute("disabled", "");
            let sisEhrInterviewSave = functions.httpsCallable(
              "sis-ehr-interview-save-api"
            );
            await sisEhrInterviewSave({
              customer: this.customer,
              queueInfo: this.queueInfo,
              professional: this.professional,
            })
              .then(async (result) => {
                let sisInterviewClose = functions.httpsCallable(
                  "sis-interview-close-api"
                );
                await sisInterviewClose({
                  customer: this.customer,
                  queueInfo: this.queueInfo,
                  professional: this.professional,
                  action: "close",
                })
                  .then(async (result) => {
                    return;
                  })
                  .catch((error) => {
                    this.$swal.getCancelButton().setAttribute("enabled", "");
                    this.$swal.showValidationMessage(
                      `Erro ao encerrar atendimento: ${error}`
                    );
                    console.error(error.message);
                  });
              })
              .catch((error) => {
                this.$swal.showValidationMessage(
                  `Erro ao encerrar atendimento: ${error.message}`
                );
                console.error(error.message);
              });
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$swal
              .fire({
                icon: "success",
                title: "Atendimento encerrado com sucesso! ",
                toast: false,
                showConfirmButton: true,
                timer: 1000,
                timerProgressBar: true,
              })
              .then(async (result) => {
                await this.$store.dispatch("releaseRoom");
                this.$router.replace({ name: "WaitingRoom" });
              });
          }
        });
    },
  },
  destroyed() { },
};
</script>

<style>

</style>
