import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

export default {
  state: {
    monitors: [],
  },
  actions: {
    unbindMonitors: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef("monitors", true);
    }),
    bindMonitors: firestoreAction((context, data) => {
      let ref = db.collection("monitors");
      context.bindFirestoreRef("monitors", ref, { reset: true });
    }),
  },
  getters: {
    monitors(state) {
      return state.monitors;
    },
  },
};
