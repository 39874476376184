<template>
  <div v-if="anamnesisNotAnsweredAlerts > 0 || healthAlerts > 0">
    <v-dialog v-model="dialog" max-width="1650" eager>
      <template v-slot:activator="{ on, attrs }">
        <v-hover v-slot="{ hover }" open-delay="10">
          <v-btn
            class="mx-1"
            x-small
            color="red"
            v-bind="attrs"
            v-on="on"
            :elevation="hover ? 3 : 8"
          >
            <span class="white--text">Encerrar Alertas</span>
          </v-btn>
        </v-hover>
      </template>
      <v-card class="pa-4 grey lighten-3" min-height="70vh">
        <v-card color="transparent" elevation="0">
          <v-row class="mb-2 mt-1 ml-1">
            <span class="subtitle-2 grey--text text--darken-1"
              >Novos Alertas</span
            >
          </v-row>
          <v-divider></v-divider>
          <v-row class="mt-0 mb-2">
            <v-col class="d-flex justify-start align-self-baseline flex-wrap">
              <div v-for="alert in healthAlertsCollection" :key="alert.key">
                <HealthAlertCard
                  :monitor="monitor"
                  :alert="alert[1]"
                  @refresh="refresh"
                ></HealthAlertCard>
              </div>
              <div
                v-for="alert in anamnesisNotAnsweredAlertsCollection"
                :key="alert.key"
              >
                <NotAnsweredCard
                  :monitor="monitor"
                  :alert="alert[1]"
                  @refresh="refresh"
                ></NotAnsweredCard>
              </div>
            </v-col>
          </v-row>
          <div v-if="hasAlerts">
            <v-row class="mb-2 mt-1 ml-1">
              <span class="subtitle-2 grey--text text--darken-1">
                Alertas Encerrados
              </span>
            </v-row>
            <v-divider></v-divider>
            <v-row class="mt-0">
              <v-col class="d-flex justify-start align-self-baseline flex-wrap">
                <div v-for="alert in alertsCollection" :key="alert.key">
                  <HealthAlertCard
                    v-if="alert[1].type == 'healthAlert'"
                    :monitor="monitor"
                    :alert="alert[1]"
                    @refresh="refresh"
                  ></HealthAlertCard>
                  <NotAnsweredCard
                    v-else
                    :monitor="monitor"
                    :alert="alert[1]"
                    @refresh="refresh"
                  ></NotAnsweredCard>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
  <div v-else>
    <div v-if="hasAlerts">
      <v-dialog v-model="dialog" max-width="1650" eager>
        <template v-slot:activator="{ on, attrs }">
          <v-hover v-slot="{ hover }" open-delay="10">
            <v-btn
              class="mx-1"
              x-small
              color="purple"
              v-bind="attrs"
              v-on="on"
              :elevation="hover ? 3 : 8"
            >
              <span class="white--text">Histórico de Alertas</span>
            </v-btn>
          </v-hover>
        </template>
        <v-card class="pa-4 grey lighten-3" min-height="70vh">
          <v-card color="transparent" elevation="0">
            <v-row class="mb-2 mt-1 ml-1">
              <span class="subtitle-2 grey--text text--darken-1">
                Histórico de Alertas
              </span>
            </v-row>
            <v-divider></v-divider>
            <v-row class="mt-0">
              <v-col class="d-flex justify-start align-self-baseline flex-wrap">
                <div v-for="alert in alertsCollection" :key="alert.key">
                  <HealthAlertCard
                    v-if="alert[1].type == 'healthAlert'"
                    :alert="alert[1]"
                    :monitor="monitor"
                    @refresh="refresh"
                  ></HealthAlertCard>
                  <NotAnsweredCard
                    v-else
                    :monitor="monitor"
                    :alert="alert[1]"
                    @refresh="refresh"
                  ></NotAnsweredCard>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-card>
      </v-dialog>
    </div>
    <div v-else>
      <v-chip
        v-if="monitor.executed"
        x-small
        label
        class="elevation-0"
        color="grey lighten-4"
        text-color="grey lighten-1"
        >SEM ALERTAS</v-chip
      >
      <v-chip
        v-else
        x-small
        label
        class="elevation-0"
        color="grey lighten-4"
        text-color="grey lighten-1"
        >-</v-chip
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { db, firebase } from "../../../../../firebase";
import HealthAlertCard from "./HealthAlertCard.vue";
import NotAnsweredCard from "./NotAnsweredCard.vue";

import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";

export default {
  components: {
    TiptapVuetify,
    HealthAlertCard,
    NotAnsweredCard,
  },
  props: {
    monitor: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      dialog: false,
      note: null,
      hasAlerts: false,
      healthAlerts: 0,
      alertsCollection: [],
      anamnesisNotAnsweredAlerts: 0,
      healthAlertsCollection: [],
      anamnesisNotAnsweredAlertsCollection: [],

      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3, 4, 5],
            },
          },
        ],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak,
      ],
    };
  },
  watch: {
    async monitor(newValue) {
      this.healthAlerts = 0;
      this.anamnesisNotAnsweredAlerts = 0;
      this.hasAlerts = false;
      this.alertsCollection = [];
      this.healthAlertsCollection = [];
      this.anamnesisNotAnsweredAlertsCollection = [];
      await this.init();
    },
  },
  computed: {
    ...mapGetters({
      customer: "customer",
      professional: "professional",
    }),
  },
  methods: {
    async refresh() {
      this.dialog = false;
      this.healthAlerts = 0;
      this.anamnesisNotAnsweredAlerts = 0;
      this.hasAlerts = false;
      this.alertsCollection = [];
      this.healthAlertsCollection = [];
      this.anamnesisNotAnsweredAlertsCollection = [];
      await this.init();
    },
    async init() {
      let monitorRef = db.collection("monitoring-actions").doc(this.monitor.id);

      await monitorRef
        .collection("alerts")
        .where("treated", "==", true)
        .get()
        .then((snap) => {
          if (!snap.empty) {
            this.hasAlerts = true;
            this.alertsCollection = new Map(
              snap.docs.map((alert) => {
                let newAlert = alert.data();
                newAlert.id = alert.id;
                return [alert.id, newAlert];
              })
            );
          }
        });

      await monitorRef
        .collection("alerts")
        .where("type", "==", "healthAlert")
        .where("treated", "==", false)
        .get()
        .then((snap) => {
          if (!snap.empty) {
            this.healthAlertsCollection = new Map(
              snap.docs.map((alert) => {
                let newAlert = alert.data();
                newAlert.id = alert.id;
                return [alert.id, newAlert];
              })
            );
            this.healthAlerts = this.healthAlertsCollection.size;
          }
        });

      await monitorRef
        .collection("alerts")
        .where("type", "==", "anamnesisNotAnswered")
        .where("treated", "==", false)
        .get()
        .then((snap) => {
          if (!snap.empty) {
            this.anamnesisNotAnsweredAlertsCollection = new Map(
              snap.docs.map((alert) => {
                let newAlert = alert.data();
                newAlert.id = alert.id;
                return [alert.id, newAlert];
              })
            );
            this.anamnesisNotAnsweredAlerts =
              this.anamnesisNotAnsweredAlertsCollection.size;
          }
        });
    },
    getColor(gender) {
      if (gender === "Feminino") return "pink";
      else return "primary";
    },
  },
  async created() {
    await this.init();
  },
};
</script>