<template>
  <v-card v-if="monitoringSurveysNotAnswered.length > 0" flat width="100%" color="transparent" class="mt-4">
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate :rotate="360" :size="100" :width="8" color="white">
      </v-progress-circular>
    </v-overlay>

    <v-data-table class="elevation-0 transparent" :headers="headers" :items="monitoringSurveysNotAnswered" :search="search"
      :items-per-page="13" :calculate-widths="true" sort-by="updated">


      <template v-slot:[`item.age`]="{ item }">
        <AgeChip :customer="item.customer"></AgeChip>
      </template>

      <template v-slot:[`item.created`]="{ item }">
        <span v-if="item.executed" class="caption">{{
    (parseInt(item.day) >= 10 ? item.day : "0" + item.day) +
    "/" +
    (parseInt(item.month) >= 10 ? item.month : "0" + item.month) +
    "/" +
    item.year
}}</span>
      </template>

      <template v-slot:[`item.retry`]="{ item }">
        <v-chip v-if="item.surveyRetry != null && item.surveyRetry" x-small label color="primary" text-color="white">SIM
        </v-chip>
        <v-chip v-else x-small label color="grey lighten-3" text-color="grey">
          NÃO</v-chip>
      </template>

      <template v-slot:[`item.customerName`]="{ item }">
        <span class="caption">{{
    item.customerName
}}</span>
      </template>

      <template v-slot:[`item.monitoringText`]="{ item }">
        <span class="caption">{{
    item.monitoringText
}}</span>
      </template>

      <template v-slot:[`item.surveyId`]="{ item }">
        <span class="caption">{{
    item.surveyId
}}</span>
      </template>


      <template v-slot:[`item.actions`]="{ item }">
        <Reply :monitor="item"></Reply>
        <ActionsMenu :patient="item.customer" :name="item.customerName"></ActionsMenu>
        <v-tooltip top color="error">
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="remove(item)" class="mr-1" small text v-bind="attrs" v-on="on" elevation="0" color="error"
              min-width="0" max-width="30" min-height="0" max-height="20">
              <font-awesome-icon :icon="['fal', 'comment-slash']" fixed-width style="font-size: 20px" />
            </v-btn>
          </template>
          <span>Cancelar Pesquisa</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
  <v-card v-else color="transparent" flat width="100%" class="mt-4">
    <v-card-text class="subtitle-2 grey--text text--darken-1">Não há pesquisas de satifação respondidas!</v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { db, firebase } from "../../../../../../firebase";
import AgeChip from "../patient/AgeChip.vue";
import ActionsMenu from "../../../../ehr/components/actions/Menu.vue";
import Reply from "../reply/Reply.vue"

export default {
  name: "app-monitoring",
  components: {
    AgeChip,
    ActionsMenu,
    Reply
  },
  props: {
    search: {
      type: String,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      tab: null,
      overlay: false,
      headers: [
        {
          text: "ID",
          value: "id",
          filterable: false,
          sortable: false,
          align: " d-none",
        },
        {
          text: "Enviado(a) ao paciente dia",
          value: "created",
          filterable: false,
          sortable: false,
        },
        { text: "Já houve reenvio?", value: "retry", filterable: false, sortable: false },
        { text: "Paciente", value: "customerName", sortable: false },
        { text: "Idade", value: "age", filterable: false, sortable: false },
        { text: "Origem", value: "monitoringText", filterable: false, sortable: false },
        {
          text: "Pesquisa",
          value: "surveyId",
          filterable: false,
          sortable: false,
        },
        { text: "Ações", value: "actions", filterable: false, sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters({
      monitoringSurveysNotAnswered: "monitoringSurveysNotAnswered",
    }),
  },
  methods: {

    getColor(gender) {
      if (gender == "Feminino") return "pink";
      else return "primary";
    },

    remove(monitor) {
      this.$swal
        .fire({
          title: "Cancelar Pesquisa de Satisfação",
          text: "Você realmente deseja cancelar definitivamente a pesquisa de satisfação?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          showLoaderOnConfirm: true,
          preConfirm: async (value) => {
            this.$swal.showLoading();
            this.$swal.getCancelButton().setAttribute("disabled", "");

            db.collection("monitoring-actions").doc(monitor.id)
              .update({
                updated: firebase.firestore.Timestamp.now(),
                quit: true
              }, { merge: true })
              .catch((error) => {
                this.$swal.getCancelButton().setAttribute("enabled", "");
                this.$swal.showValidationMessage(
                  `Erro ao cancelar a pesquisa de satisfação: ${error}`
                );
                console.error(error.message);
              });
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$swal
              .fire({
                icon: "success",
                title: "Pesquisa de satisfação cancelada com sucesso! ",
                toast: false,
                showConfirmButton: true,
                timer: 1000,
                timerProgressBar: true,
              })
              .then((result) => {
                this.$router.replace({ name: "Surveys" });
              });
          }
        });
    },
  },
};
</script>
<style lang="scss">
@import "../../../../../../scss/_variables.scss";

.data-studio {
  @media #{$xxlmax-device} {
    width: 100%;
    height: 600px;
  }

  @media #{$xlmax-device} {
    width: 100%;
    height: 700px;
  }

  @media #{$desktop-device} {
    width: 100%;
    height: 600px;
  }

  @media #{$tablet-device} {
    width: 100%;
    height: 800px;
  }

  @media #{$large-mobile} {
    height: 700px;
  }

  @media #{$extra-small-mobile} {
    height: 600px;
  }
}

.v-dialog {
  border-radius: 6px;
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  z-index: inherit;
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%);
}
</style>