<template>
  <v-card min-height="200" class="pa-2" color="transparent" flat>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        :rotate="360"
        :size="100"
        :width="8"
        color="white"
      >
      </v-progress-circular>
    </v-overlay>
    <v-card-title class="text-justify">{{
      questionNumber + ". " + question.question
    }}</v-card-title>
    <v-card-subtitle class="text-justify"
      >Questionário: {{ monitor.anamnesisId }}</v-card-subtitle
    >
    <v-card-text>
      <container v-if="question != null && question.type == 'multi'">
        <v-select
          color="grey darken-2"
          :items="options"
          v-model="answer"
          item-text="text"
          item-value="value"
          label="Respostas"
          clearable
          :return-object="true"
          class="select caption"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip small outlined v-if="index === 0">
              <span class="caption">{{ item.text }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text text-caption">
              (+ outros)
            </span>
          </template></v-select
        >
      </container>
      <container v-else>
        <v-textarea outlined label="Resposta" v-model="answer"></v-textarea>
      </container>
    </v-card-text>
    <v-card min-height="200" color="transparent" flat></v-card>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        v-bind="attrs"
        v-on="on"
        dark
        color="secondary"
        elevation="0"
        text
        @click="nextQuestion()"
      >
        <v-icon dark>mdi-arrow-right-circle-outline</v-icon> Próxima Pergunta
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { functions } from "../../../../../firebase";
import Menu from "../actions/Menu";
import View from "../../views/nurseHealthScreening/View.vue";
import Video from "../telemedicine/video/Video.vue";

export default {
  components: {
    Menu,
    View,
    Video,
  },
  data() {
    return {
      overlay: false,
      disabled: false,
      question: null,
      options: null,
      answer: null,
      step: null,
      questionNumber: 1,
    };
  },
  props: {
    monitor: {
      type: Object,
      required: true,
      default: null,
    },
    interview: {
      type: Object,
      required: true,
      default: null,
    },
    alert: {
      type: Object,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      customer: "customer",
      professional: "professional",
    }),
  },
  methods: {
    async init() {
      this.overlay = true;
      let sisInterviewPractitionerQuestion = functions.httpsCallable(
        "sis-interview-practitioner-question-api"
      );
      await sisInterviewPractitionerQuestion({
        anamnesis: this.monitor.anamnesisId,
        step: this.interview.startStep,
      }).then((result) => {
        this.question = result.data.question;
        this.options = result.data.question.options;
        this.step = this.interview.startStep;
        this.overlay = false;
        return;
      });
    },
    async nextQuestion() {
      try {
        if (this.answer) {
          this.overlay = true;

          let sisInterviewPractitionerSaveStep = functions.httpsCallable(
            "sis-interview-practitioner-save-step-api"
          );
          await sisInterviewPractitionerSaveStep({
            customer: this.customer.id,
            interview: this.interview.id,
            anamnesis: this.monitor.anamnesisId,
            step: this.step,
            answer: this.answer.value,
            monitor: this.monitor.id,
          }).then(async (result) => {
            if (result.data.alert) {
              this.$swal.fire({
                icon: "warning",
                title:
                  result.data.alert.question.replace("?", "") +
                  ": " +
                  result.data.alert.answer,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 15000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  let audioFile = new Audio(
                    "https://firebasestorage.googleapis.com/v0/b/icareyou-dev.appspot.com/o/sound%2Fchat_alertshort.mp3?alt=media&token=b39628e0-202c-4e6e-bf4a-7fb7b117996b"
                  );
                  audioFile.play();
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
              });
            }
            let sisInterviewPractitionerNextQuestion = functions.httpsCallable(
              "sis-interview-practitioner-next-question-api"
            );
            await sisInterviewPractitionerNextQuestion({
              customer: this.customer.id,
              anamnesis: this.monitor.anamnesisId,
              step: this.step,
              answer: this.answer.value,
            }).then(async (result) => {
              this.step = result.data.question.step;

              if (this.step == "EOI") {
                let sisInterviewPractitionerUpdate = functions.httpsCallable(
                  "sis-interview-practitioner-update-api"
                );
                await sisInterviewPractitionerUpdate({
                  monitor: this.monitor.id,
                  interview: this.interview.id,
                  professional: this.professional,
                }).then(async (result) => {
                  let sisMonitoringAlertClose = functions.httpsCallable(
                    "sis-monitoring-alert-close-api"
                  );

                  await sisMonitoringAlertClose({
                    monitor: this.monitor.id,
                    text:
                      "Questionário respondido pelo profissional: " +
                      this.professional.fullName,
                    professional: this.professional,
                    alert: this.alert.id,
                  }).then(async (result) => {
                    let sisMonitoringClose = functions.httpsCallable(
                      "sis-monitoring-close-api"
                    );
                    await sisMonitoringClose({
                      monitor: this.monitor.id,
                      interview: this.interview.id,
                    }).then(async (result) => {
                      this.$swal.fire({
                        icon: "success",
                        title: "Questionário respondido com sucesso!",
                        toast: true,
                        position: "bottom",
                        showConfirmButton: false,
                        timer: 10000,
                        timerProgressBar: true,
                      });
                      this.$emit("closeInterview");
                    });
                  });
                });
              } else {
                let sisInterviewPractitionerQuestion = functions.httpsCallable(
                  "sis-interview-practitioner-question-api"
                );
                await sisInterviewPractitionerQuestion({
                  anamnesis: this.monitor.anamnesisId,
                  step: this.step,
                }).then((result) => {
                  this.question = result.data.question;
                  this.options = result.data.question.options;
                  this.answer = null;
                  this.questionNumber++;
                });
              }
              this.overlay = false;
            });
          });
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
  async created() {
    await this.init();
  },
};
</script>
<style scoped>
.select {
  max-width: 500px;
  min-width: 500px;
}
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>
