<template>
  <v-dialog v-model="dialog" width="700" eager>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn @click="resend()" class="mr-1" v-bind="attrs" v-on="{ ...tooltip, ...dialog }" elevation="0" x-small
            text color="primary" min-width="35" max-width="35" min-height="35" max-height="35">
            <font-awesome-icon :icon="['fal', 'file-import']" fixed-width style="font-size: 25px" />
          </v-btn>
        </template>
        <span>REENVIAR PESQUISA</span>
      </v-tooltip>
    </template>
  </v-dialog>

</template>

<script>
import { mapGetters } from "vuex";
import { db, functions } from "../../../../../../firebase";

export default {
  components: {},
  props: {
    monitor: {
      type: Object,
      required: false,
      default: null,
    }
  },
  data() {
    return {
      dialog: false,
      anamnesisNotAnsweredAlerts: 0,
    };
  },
  watch: {
    async monitor(newValue) {
      this.anamnesisNotAnsweredAlerts = 0;
      await this.init();
    },
  },
  computed: {
    ...mapGetters({
    }),
  },
  methods: {
    resend() {
      this.$swal
        .fire({
          title: "Reenviar Pesquisa de Satisfação",
          text:
            "Você realmente deseja enviar a pesquisa de satisfação do dia " +
            this.monitor.day +
            "/" +
            (this.monitor.month < 10
              ? "0" + this.monitor.month
              : this.monitor.month) +
            "/" +
            this.monitor.year +
            "?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            this.$swal.showLoading();
            this.$swal.getCancelButton().setAttribute("disabled", "");
            let sisSurveyResend = functions.httpsCallable(
              "sis-survey-resend-api"
            );
            await sisSurveyResend({
              monitor: this.monitor.id
            })
              .then(async (result) => {
                return;
              })
              .catch((error) => {
                console.error(error.message);
                this.$swal.getCancelButton().setAttribute("enabled", "");
                this.$swal.showValidationMessage(
                  `Erro ao reenviar a pesquisa de satisfação: ${error}`
                );
              });
          },
        })
        .then((result) => {
          this.dialog = false;
          if (result.isConfirmed) {
            this.$swal.fire({
              icon: "success",
              title: "Pesquisa de satisfação reenviada com sucesso! ",
              toast: false,
              showConfirmButton: true,
              timer: 1000,
              timerProgressBar: true,
            });
          }
        });
    },
  },
  async created() {
  },
};
</script>