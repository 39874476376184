<template>
  <v-app id="app">
    <v-main class="mb-6 pa-0 mt-n15 transparent">
      <v-row class="d-flex flex-row transparent">
        <v-col cols="7" class="d-flex flex-row justify-center transparent">
          <v-card class="d-flex flex-column justify-center transparent" flat>
            <v-card elevation="0" class="mt-n15">
              <v-stepper v-model="stp" class="elevation-0">
                <v-stepper-header>
                  <v-stepper-step
                    :complete="stp > 1"
                    step="1"
                    :rules="[errorId]"
                  >
                    Identificador do Profissional
                    <small>{{ errorMessageId }}</small>
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step step="2" :rules="[errorPIN]">
                    Código de Acesso
                    <small>{{ errorMessagePIN }}</small>
                  </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <v-stepper-content step="1">
                    <v-text-field-cpf
                      class="mt-1"
                      v-model="id"
                      v-bind:label="label"
                      v-bind:properties="{
                        required: true,
                        rules: [
                          (v) =>
                            !!v ||
                            'O número do cpf do profissional é obrigatório para acessar o sistema',
                        ],
                        readonly: false,
                        disabled: false,
                        outlined: true,
                        clearable: true,
                        placeholder: 'Digite seu cpf',
                      }"
                      v-bind:options="{
                        outputMask: '###########',
                        empty: null,
                        applyAfter: false,
                      }"
                      v-on:focus="focusId()"
                    />
                    <!--v-text-field
                              :success="successId"
                              class="mt-2"
                              label=""
                              placeholder="Identificador do Profissional"
                              hint="Digite seu cpf"
                              v-model="id"
                              outlined
                              @focus="focusId()"
                              @keydown.enter.prevent="verifyUser()"
                            ></v-text-field-->

                    <v-btn class="mt-2" color="primary" @click="verifyUser()">
                      <v-icon> mdi-login-variant </v-icon>
                      Verificar
                    </v-btn>
                  </v-stepper-content>

                  <v-stepper-content step="2">
                    <div
                      class="ma-auto position-relative"
                      style="max-width: 300px"
                    >
                      <v-otp-input
                        length="5"
                        v-model="pin"
                        :disabled="loading"
                        @finish="login"
                      ></v-otp-input>
                    </div>
                    <v-btn text @click="cancel()"> Cancel </v-btn>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-card>
          </v-card>
        </v-col>
        <v-col cols="5">
          <v-card
            class="pa-15"
            color="grey lighten-4 mb-n10 mt-n4 ml-15"
            :min-height="screenHeight"
            max-width="580"
            flat
            tile
            elevation="24"
          >
            <v-card-title class="d-flex justify-center align-self-center">
              <v-img
                src="~@/assets/logo/logo.png"
                max-height="250"
                max-width="250"
              ></v-img>
            </v-card-title>
            <v-card-title
              class="
                titleCard
                d-flex
                justify-center
                font-weight-thin
                grey--text
                text--darken-1
              "
            >
              PLATAFORMA INTEGRADA DE eHEALTH
            </v-card-title>
            <v-card-title
              class="
                titleSystem
                d-flex
                justify-center
                grey--text
                text--darken-0
              "
            >
              <font-awesome-icon
                :icon="['fat', 'brain-circuit']"
                fixed-width
                style="font-size: 45px"
              />
            </v-card-title>
            <v-card-title
              class="
                titleSystem
                d-flex
                justify-center
                font-weight-thin
                grey--text
                text--darken-1
              "
            >
              Sistema de Inteligência em Saúde - v
              {{
                sisVersion.version.major +
                "." +
                sisVersion.version.minor +
                "." +
                sisVersion.version.patch
              }}
              <p
                class="
                  titleBuild
                  text-center
                  grey--text
                  text--lighten-1
                  caption
                "
              >
                Last build: {{ sisVersion.build.date }}
              </p>
              <p
                class="
                  titleBuild
                  text-center
                  grey--text
                  text--lighten-1
                  caption
                "
              >
                {{ agent }}
              </p>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>



<script>
import { mapGetters } from "vuex";
import { auth, functions, db } from "../firebase";

export default {
  data() {
    return {
      alert: true,
      label: "Identificador do Profissional",
      loading: false,
      ui: null,
      id: null,
      stp: 1,
      pin: null,
      successId: false,
      errorMessageId: null,
      noErrorId: true,
      errorMessagePIN: null,
      noErrorPIN: true,
      sisVersion: null,
      agent: null,
      screenHeight: 0,
    };
  },
  computed: {
    ...mapGetters({
      session: "session",
    }),
  },
  methods: {
    focusId() {
      this.noErrorId = true;
      this.errorMessageId = null;
    },
    focusPIN() {
      this.noErrorPIN = true;
      this.errorMessagePIN = null;
    },
    errorId() {
      return this.noErrorId;
    },
    errorPIN() {
      return this.noErrorPIN;
    },
    cancel() {
      this.loading = false;
      this.stp = 1;
      this.pin = null;
      this.id = null;
      this.successId = false;
      this.errorMessageId = null;
      this.noErrorId = true;
      this.errorMessagePIN = null;
      this.noErrorPIN = true;
    },
    async verifyUser() {
      let sisLoginPinCreate = functions.httpsCallable(
        "sis-login-pin-professional-create-api"
      );
      sisLoginPinCreate({
        professionalId: this.id,
      })
        .then((result) => {
          this.successId = true;
          this.stp = 2;
        })
        .catch((error) => {
          this.pin = null;
          this.noErrorId = false;
          console.error(error);

          let title = "";
          let text = "";
          if (error.code === "functions/permission-denied") {
            title = "Sem Acesso!";
            text =
              "O profissional não tem acesso ao sistema! Por favor contatar o suporte!";
          } else if (error.code === "functions/internal") {
            title = "Sem Conexão!";
            text =
              "O sistema não consegue estabelecer conexão com os serviços de negócio! Por favor contatar o suporte!";
          }

          this.$swal.fire({
            icon: "error",
            title: title,
            text: text,
            toast: false,
            showConfirmButton: true,
            timer: 5000,
            timerProgressBar: true,
          });
        });
    },
    async login() {
      this.loading = true;
      let sisLoginPinValidation = functions.httpsCallable(
        "sis-login-pin-professional-validation-api"
      );
      await sisLoginPinValidation({
        professionalId: this.id,
        pin: this.pin,
      })
        .then(async (result) => {
          await auth
            .signInWithCustomToken(result.data.uidToken)
            .then((userCredential) => {
              userCredential.user
                .getIdTokenResult()
                .then(async (idTokenResult) => {
                  if (idTokenResult.claims.professional) {
                    await this.$store.dispatch("fetchConfig");
                    await this.$store.dispatch(
                      "fetchProfessional",
                      result.data.professional
                    );
                    await this.$store.dispatch("bindViews");
                    this.$router.replace({
                      name: "WaitingRoom",
                    });
                  } else {
                    let title = "Sem Acesso!";
                    let text =
                      "O profissional não autorizado na plataforma! Por favor contatar o suporte!";
                    this.$swal.fire({
                      icon: "error",
                      title: title,
                      text: text,
                      toast: false,
                      showConfirmButton: true,
                      timer: 5000,
                      timerProgressBar: true,
                    });
                  }
                });
            })
            .catch((error) => {
              this.loading = false;
              this.pin = null;
              this.errorMessagePIN = error.message;
              this.noErrorPIN = false;
              console.error(error);
            });
        })
        .catch((error) => {
          this.loading = false;
          this.pin = null;
          this.noErrorPIN = false;

          let title = "";
          let text = "";
          if (error.code === "functions/failed-precondition") {
            title = "Erro no PIN!";
            text =
              "Você digitou um pin inválido ou expirado! Por favor, tente novamente digitando seu CPF.";
          }

          this.$swal.fire({
            icon: "error",
            title: title,
            text: text,
            toast: false,
            showConfirmButton: true,
            timer: 7000,
            timerProgressBar: true,
          });

          console.error(error);
        });
    },
  },
  async created() {
    this.sisVersion = this.$sisVersion;
    this.agent = this.$browserDetect.meta.ua;
    this.screenHeight = screen.height;

    db.collection("sis")
      .doc("version")
      .get()
      .then((doc) => {
        if (
          doc.data().major != this.sisVersion.version.major ||
          doc.data().minor != this.sisVersion.version.minor ||
          doc.data().patch != this.sisVersion.version.patch
        ) {
          this.$swal.fire({
            width: 900,
            backdrop: true,
            position: "top-start",
            background: "orange",
            html: "<h3 style='color:white'>Há uma nova versão do SIS disponível. Antes de se autenticar, atualize o SIS da seguinte maneira:</h3><h4 style='color:white'>- mantendo a tecla Shift⇧ pressionada, pressionar, em conjunto, a tecla F5, ou,</h4><h4 style='color:white'>- mantendo a tecla Shift⇧ pressionada, clicar no botão atualizar do seu browser.</h4>",
            icon: "warning",
            iconColor: "yellow",
            title: "<h2 style='color:yellow'>Nova versão disponível!</h2>",
            footer:
              "<h2 style='color:yellow'>" +
              "A sua versão é a " +
              this.sisVersion.version.major +
              "." +
              this.sisVersion.version.minor +
              "." +
              this.sisVersion.version.patch +
              ", sendo que a versão mais recente é a " +
              doc.data().major +
              "." +
              doc.data().minor +
              "." +
              doc.data().patch +
              ".</h2>",
            toast: true,
            showConfirmButton: false,
          });
        }
      });
  },
};
</script>

<style scoped>
.titleCard {
  font-size: 30px !important;
  line-height: 1.6;
}

.titleSystem {
  font-size: 20px !important;
  line-height: 1.6;
}

.titleBuild {
  font-size: 10px !important;
  line-height: 1.6;
}

.v-stepper__header {
  box-shadow: none;
}
</style>
