<template>
  <v-card min-height="610">
    <v-row align="center" justify="space-around">
      <v-btn class="mt-6" depressed color="primary"> Abrir sala de vídeo </v-btn>
    </v-row>
  </v-card>
</template>

<script>
import Peer from "peerjs";
export default {
  components: {},
  name: "ICYVideo",
  computed: {},
  methods: {
    async createRoom() {
      const peer = await new Peer({
        host: "peerjs-ps7vtvwr7q-rj.a.run.app",
        port: 443,
      });
    },
  },
  mounted() {},
};
</script>

<style>
</style>