import {firestoreAction} from "vuexfire";
import {db} from "../../firebase";

export default {
    state: {
        primaryCares: [],
        primaryCare: null
    },
    actions: {
        bindPrimaryCares: firestoreAction((context) => {
            let ref = db.collection("primary-care");
            context.bindFirestoreRef("primaryCares", ref, {
                wait: true,
                reset: true
            });
        })
    },
    getters: {
        primaryCares(state) {
            return state.primaryCares;
        }
    }
};
