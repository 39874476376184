import View from "../../../../components/functional/ehr/views/internalMedicine/View.vue";

export default [
  {
    path: "view/internalMedicine",
    name: "internalMedicine",
    component: View,
    meta: {
      requiresAuth: true,
    },
    children: [],
  },
];
