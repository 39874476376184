<template>
  <v-container name="ro-internal-medicine-identification">
    <v-card
      outlined
      max
      max-height="40"
      min-height="40"
      class="grey lighten-4"
    >
      <v-row>
        <v-col cols="4" class="d-flex justify-start">
          <v-card-text class="mt-n2 ml-n2">
            <span class="font-weight-bold grey--text text--darken-1">Nome: </span
            ><span class="primary--text font-weight-bold">{{ customer.fullName }} </span>
            <span class="font-weight-bold grey--text text--darken-1">Idade: </span
            ><span class="primary--text font-weight-bold"
              >{{
                formatBirthdate(customer.birthdate)
                  | moment("from", "now", true)
              }}
            </span>
            <span class="font-weight-bold grey--text text--darken-1">Sexo: </span
            ><span class="primary--text font-weight-bold">{{ customer.gender }}</span>
          </v-card-text>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="8" class="d-flex justify-end mt-1">
          <Menu></Menu>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Menu from "../menu/Menu.vue";

export default {
  components: {
    Menu,
  },
  computed: {
    ...mapGetters({
      customer: "customer",
    }),
  },
  methods: {
    formatBirthdate(birthdate) {
      return this.$moment(birthdate, "DD/MM/YYYY");
    },
  },
  destroyed() {},
};
</script>

<style>
</style>