<template>
  <v-card class="mx-auto" flat>
    <v-card class="mb-2 rounded" flat color="grey lighten-3">
      <v-toolbar flat height="35" elevation="0" class="secondary">
        <v-toolbar-title class="subtitle-2 white--text">{{ sequence }}. Prescrição</v-toolbar-title>
        <v-spacer></v-spacer>
        <Prescription></Prescription>
      </v-toolbar>
    </v-card>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import Prescription from "../../../../components/prescription/Dialog.vue";

export default {
  props: {
    sequence: {
      type: Number,
      required: true,
      default: null,
    },
  },
  data: () => ({
    dialog: false,
  }),
  components: { Prescription },
  computed: {
    ...mapGetters({}),
  },
  watch: {},
  methods: {},
  destroyed() { },
};
</script>

<style>

</style>
