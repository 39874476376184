import {firestoreAction} from "vuexfire";
import {db} from "../../firebase";

export default {
    state: {
        telemedicine: "video",
        telemedicineRooms: [],
        selectedRoom: null
    },
    mutations: {
        SET_TELEMEDICINE(state, data) {
            state.telemedicine = data;
        },
        SET_SELECTED_ROOM(state, data) {
            state.selectedRoom = data;
        }
    },
    actions: {
        reserveRoom(context, data) {
            let room = data.room;
            context.commit("SET_SELECTED_ROOM", room);
        },
        releaseRoom(context) {
            context.commit("SET_SELECTED_ROOM", null);
        },
        fetchTelemedicine(context) {
            db.collection("admin").doc("config").get().then((configDoc) => { // doxy or video
                context.commit("SET_TELEMEDICINE", configDoc.data().telemedicine);
            });
        },
        bindTelemedicineRooms: firestoreAction((context) => {
            let ref = db.collection("telemedicine");
            ref = ref.where("available", "==", true);
            context.bindFirestoreRef("telemedicineRooms", ref, {
                wait: true,
                reset: true
            });
        })
    },
    getters: {
        telemedicine(state) {
            return state.telemedicine;
        },
        telemedicineRooms(state) {
            return state.telemedicineRooms;
        },
        selectedRoom(state) {
            return state.selectedRoom;
        }
    }
};
