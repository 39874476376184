<template>
  <v-card class="mx-auto" flat>
    <v-card class="mb-2 rounded" flat color="grey lighten-3">
      <v-toolbar flat height="35" elevation="0" class="secondary">
        <v-toolbar-title class="subtitle-2 white--text"
          >4. Código Internacional de Doenças</v-toolbar-title
        >
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card>
    <CID></CID>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import CID from "../../../components/cid10/CID.vue";

export default {
  data: () => ({}),
  components: { CID },
  computed: {
    ...mapGetters({}),
  },
  methods: {},
  destroyed() {},
};
</script>

<style>
</style>