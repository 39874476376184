<template>
  <v-dialog v-model="dialog" max-width="500" eager>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn color="grey" icon dark v-bind="attrs" v-on="{ ...tooltip, ...dialog }" elevation="0" min-width="0"
            max-width="30" min-height="0" max-height="30">
            <font-awesome-icon :icon="['fal', 'book-medical']" fixed-width style="font-size: 20px" />
          </v-btn>
        </template>
        <span>Adicionar Queixa Principal</span>
      </v-tooltip>
    </template>
    <v-card class="pa-4">
      <span class="caption d-flex justify-center">Adição de novos recursos desabilitado!</span>
      <!--Resources :filter="filter"></Resources-->
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import Resources from "./Resources.vue";

export default {
  components: {
    Resources,
  },
  props: {
    filter: String,
    default: null,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {},
  created() { },
};
</script>
