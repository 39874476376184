<template>
  <container v-if="journey">
    <v-tooltip bottom color="secondary">
      <template v-slot:activator="{ on: tooltip }">
        <v-text-field
          v-on="{ ...tooltip }"
          prepend-icon="mdi-map"
          dense
          flat
          readonly
          :value="journey"
        />
      </template>
      <span>Linha de cuidado atual do paciente.</span>
    </v-tooltip>
  </container>
  <container v-else>
    <v-tooltip bottom color="warning">
      <template v-slot:activator="{ on: tooltip }">
        <v-text-field
          v-on="{ ...tooltip }"
          prepend-icon="mdi-map"
          dense
          flat
          readonly
          value="O paciente não está em nenhuma linha de cuidado"
        />
      </template>
      <span>O paciente não está em nenhuma linha de cuidado.</span>
    </v-tooltip>
  </container>
</template>

<script>
import { db } from "../../../../../firebase";

export default {
  components: {},
  props: {
    instance: {
      type: String,
      required: false,
      default: null,
    },
    patient: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      journey: null,
    };
  },
  watch: {},
  computed: {},
  methods: {
    formatBirthdate(birthdate) {
      return this.$moment(birthdate, "DD/MM/YYYY");
    },
  },
  async created() {
    if (this.patient) {
      let journeyDoc = await db.collection("journeys").doc(this.patient).get();
      if (journeyDoc.data().active) {
        this.journey = journeyDoc.data().text;
      }
    } else {
      let instanceDoc = await db
        .collection("flows-instances")
        .doc(this.instance)
        .get();
      let journeyDoc = await db
        .collection("journeys")
        .doc(instanceDoc.data().customer)
        .get();
      if (journeyDoc.data().active) {
        this.journey = journeyDoc.data().text;
      }
    }
  },
};
</script>
