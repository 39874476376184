<template>
  <v-card
    class="
      pa-2
      ml-2
      mr-2
      mt-2
      mb-2
      d-flex
      flex-column
      justify-center
      align-content-center
    "
    elevation="3"
    min-height="300"
    min-width="600"
  >
    <apexchart
      ref="lineChart"
      width="100%"
      heigth="100%"
      type="line"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </v-card>
</template>

<script>
export default {
  name: "LineChart",
  props: {
    info: {
      type: Object,
      required: false,
      default: null,
    },
    chartTitle: {
      type: String,
      required: false,
      default: null,
    },
    xaxisTitle: {
      type: String,
      required: false,
      default: null,
    },
    seriesInfo: {
      type: Array,
      required: false,
      default: null,
    },
  },
  data: function () {
    return {
      chartOptions: {
        chart: {
          dropShadow: {
            enabled: false,
            color: "#000",
            top: 8,
            left: 7,
            blur: 5,
            opacity: 0.2,
          },
          toolbar: {
            show: true,
          },
          type: "line",
          events: {
            click: function (chart, w, e) {
              //console.info(chart, w, e)
            },
          },
        },
        legend: {
          show: true,
        },
        colors: [],
        dataLabels: {
          enabled: true,
        },
        xaxis: {
          title: {
            text: this.xaxisTitle,
            offsetY: 90,
            align: "center",
            style: {
              fontSize: "12px",
              color: "#9d9d9d",
            },
          },
          categories: this.categories,
          labels: {
            style: {
              fontSize: "12px",
            },
          },
        },
        yaxis: {
          title: {
            text: "Quantidade de Pacientes",
            align: "center",
            style: {
              fontSize: "12px",
              color: "#9d9d9d",
            },
          },

          min: 0,
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: this.chartTitle,
          floating: true,
          offsetY: 0,
          align: "center",
          style: {
            fontSize: "12px",
            color: "#6d6d6d",
          },
        },
      },
      series: [],
      categories: [],
      colors: [],
    };
  },
  async created() {
    for (let index = 0; index < this.seriesInfo.length; index++) {
      this.categories = this.categories.concat(
        Object.keys(this.seriesInfo[index].series)
      );

      this.series.push({
        name: this.seriesInfo[index].name,
        data: Object.values(this.seriesInfo[index].series),
      });
      this.colors.push(this.seriesInfo[index].color);
    }

    this.categories = this.categories.filter((item, index) => {
      return this.categories.indexOf(item) == index;
    });
  },
  mounted() {
    this.$refs.lineChart.updateOptions({
      xaxis: {
        categories: this.categories,
      },
      colors: this.colors,
    });
  },
};
</script>