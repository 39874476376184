<template>
  <v-card color="transparent" flat>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate :rotate="360" :size="100" :width="8" color="white">
      </v-progress-circular>
    </v-overlay>

    <v-toolbar dense rounded outlined elevation="0">
      <v-card-title class="ml-n6 grey--text text--darken-2">
        <font-awesome-icon :icon="['fal', 'magnifying-glass-chart']" fixed-width style="font-size: 25px" />Histórico de
        Monitoramentos
      </v-card-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-1" @click="$router.replace({ name: 'Monitors' })" small text v-bind="attrs" v-on="on"
            elevation="0" color="primary" min-width="35" max-width="35" min-height="35" max-height="35">
            <font-awesome-icon :icon="['fal', 'monitor-heart-rate']" fixed-width style="font-size: 25px" />
          </v-btn>
        </template>
        <span>Monitoramentos</span>
      </v-tooltip>
    </v-toolbar>
    <v-card class="mt-1" width="100%" outlined min-height="15vh">
      <v-card-title class="caption mt-n3 ml-n2 font-weight-bold">Indicadores</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="2">
            <v-card class="mt-n3 mb-n2 ml-n2 grey lighten-4" min-height="22vh" elevation="0">
              <v-card-title class="caption mt-n3 grey--text text--darken-2 font-weight-medium">Ações</v-card-title>
              <v-card-text class="mt-n4">
                <p class="mb-0 caption">Total: {{historicKpis.total}}</p>
                <p class="mb-0 caption">Realizadas com Sucesso: {{historicKpis.executed}}</p>
                <p class="mb-0 caption">Não Realizadas: {{historicKpis.cancelled}}</p>
                <p class="mb-0 caption">Não Realizadas(%):
                  {{(historicKpis.total?Math.round((historicKpis.cancelled/historicKpis.total)*100):0)}}%</p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card class="mt-n3 mb-n2 ml-n6 grey lighten-4" min-height="22vh" elevation="0">
              <v-card-title class="caption mt-n3 grey--text text--darken-2 font-weight-medium">
                Questionários
                <v-spacer></v-spacer>
                <v-dialog v-model="dialogAnamnesisDetails" max-width="800" eager>
                  <template v-slot:activator="{ on: dialog, attrs: attrsDialog }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on: tooltip, attrs: attrsTooltip }">
                        <v-btn v-bind="{ ...attrsTooltip, ...attrsDialog }" v-on="{ ...tooltip, ...dialog }"
                          class="mr-1" text small elevation="0" color="grey--text text--darken-2" min-width="12" max-width="12"
                          min-height="20" max-height="20">
                          <font-awesome-icon :icon="['fal', 'square-info']" fixed-width style="font-size: 20px" />
                        </v-btn>
                      </template>
                      <span>Detalhamento</span>
                    </v-tooltip>
                  </template>
                  <v-card flat class="pa-1">

                    <v-card class="mx-auto" flat outlined>
                      <v-toolbar dense flat>
                        <v-toolbar-title class="text-subtitle-1">
                          <v-btn class="mr-1" text small elevation="0" color="grey--text text--darken-2" min-width="12" max-width="12"
                            min-height="20" max-height="20">
                            <font-awesome-icon :icon="['fal', 'square-info']" fixed-width style="font-size: 20px" />
                          </v-btn>
                          <span class="ml-1 mt-4">Detalhamento dos Questionários</span>
                        </v-toolbar-title>

                        <v-spacer></v-spacer>
                      </v-toolbar>

                      <v-divider></v-divider>
                      <v-card flat color="transparent" class="d-flex justify-center">
                        <v-card-text>
                          <v-row>
                            <v-col class="d-flex justify-center align-self-baseline flex-wrap">
                              <v-card width="100%" flat>


                                <v-simple-table fixed-header height="400px"
                                  v-if="historicKpis.anamnesisByType != null && Array(historicKpis.anamnesisByType).length > 0">
                                  <template v-slot:default>
                                    <thead>
                                      <tr>
                                        <th class="text-center">
                                          Questionário
                                        </th>
                                        <th class="text-center">
                                          Total
                                        </th>
                                        <th class="text-center">
                                          Respondido
                                        </th>
                                        <th class="text-center">
                                          Não Respondido
                                        </th>
                                        <th class="text-center error--text">
                                          Não Respondido(%)
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="(kpi, i) in historicKpis.anamnesisByType" :key="i">
                                        <td class="text-center">{{ kpi.anamnesis }}</td>
                                        <td class="text-center">{{ kpi.total }}</td>
                                        <td class="text-center">{{ kpi.total - kpi.cancelled}}</td>
                                        <td class="text-center">{{ kpi.cancelled }}</td>
                                        <td class="text-center error--text">{{ Math.round((kpi.cancelled / kpi.total)*100) + "%"}}</td>
                                      </tr>
                                    </tbody>
                                  </template>
                                </v-simple-table>
                                <v-list-item-group v-else color="primary">
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-list-item-title class="text-caption">
                                        Não há informações sobre questionários.
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list-item-group>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-card>




                  </v-card>
                </v-dialog>

              </v-card-title>
              <v-card-text class="mt-n4">
                <p class="mb-0 caption">Total: {{historicKpis.anamnesis}}</p>
                <p class="mb-0 caption">Respondidos: {{historicKpis.answeredAnamnesis}}</p>
                <p class="mb-0 caption">Não Respondidos: {{historicKpis.cancelledAnamnesis}}</p>
                <p class="mb-0 caption">Não Respondidos(%):
                  {{(historicKpis.cancelledAnamnesisPercentage?Math.round(historicKpis.cancelledAnamnesisPercentage):0)}}%
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card class="mt-n3 mb-n2 ml-n10 grey lighten-4" min-height="22vh" elevation="0">
              <v-card-title class="caption mt-n3 grey--text text--darken-2 font-weight-medium">Alertas</v-card-title>
              <v-card-text class="mt-n4">
                <p class="mb-0 caption">Total: {{historicKpis.alerts}}</p>
                <p class="mb-0 caption">Não tratados: {{historicKpis.notTreatedAlerts}}</p>
                <p class="mb-0 caption">de Saúde: {{historicKpis.healthAlerts}}</p>
                <p class="mb-0 caption">de Questionários Não Respondidos: {{historicKpis.anamnesisNotAnswered}}</p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="mt-0 pa-0">
            <v-badge class="pa-1" color="success" inline content="Eficiência" overlap>
              <v-progress-circular :rotate="0" :size="70" :width="10" :value="historicKpis.effectiveness"
                color="success">
                {{(historicKpis.effectiveness?Math.round(historicKpis.effectiveness):0)}}%
              </v-progress-circular>
            </v-badge>
            <v-badge class="pa-1" color="warning" inline content="Ineficiência" overlap>
              <v-progress-circular :rotate="0" :size="70" :width="10" :value="historicKpis.rejection" color="warning">
                {{(historicKpis.rejection?Math.round(historicKpis.rejection):0)}}%
              </v-progress-circular>
            </v-badge>
            <v-badge class="pa-1" color="error" inline content="Alertas de Saúde" overlap>
              <v-progress-circular :rotate="0" :size="70" :width="10" :value="historicKpis.healthAlertsPercentage"
                color="error">
                {{(historicKpis.healthAlertsPercentage?Math.round(historicKpis.healthAlertsPercentage):0)}}%
              </v-progress-circular>
            </v-badge>
            <v-badge class="pa-1" color="error" inline content="Questionários Obrigatórios Não Respondidos" overlap>
              <v-progress-circular :rotate="0" :size="70" :width="10"
                :value="historicKpis.anamnesisNotAnsweredPercentage" color="error">
                {{(historicKpis.anamnesisNotAnsweredPercentage?Math.round(historicKpis.anamnesisNotAnsweredPercentage):0)}}%
              </v-progress-circular>
            </v-badge>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-toolbar class="ml-n4 mt-2 transparent" dense rounded elevation="0">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-on="on" v-bind="attrs" elevation="0" text color="primary" class="mt-n2" @click="open()">
            <font-awesome-icon :icon="['fal', 'magnifying-glass-chart']" fixed-width style="font-size: 25px" />Pesquisar
          </v-btn>
        </template>
        <span>REALIZAR PESQUISA</span>
      </v-tooltip>
      <v-dialog v-model="dialog" max-width="850" eager>

        <v-card class="pa-4">
          <v-card-title class="caption">Filtros</v-card-title>
          <v-card-text>
            <v-select color="purple" dense outlined :items="alertsType" v-model="alertType" label="Alertas do tipo"
              item-text="text" clearable class="selectSmall caption">
            </v-select>
            <v-select color="purple" dense outlined :items="types" v-model="type" label="Com" item-text="text" clearable
              class="selectXSmall caption">
            </v-select>
            <v-select color="purple" dense outlined :items="months" v-model="startMonth" label="Em" item-text="text"
              clearable class="selectXSmall caption">
            </v-select>
            <v-select color="purple" dense outlined :items="months" v-model="endMonth" label="Até" item-text="text"
              clearable class="selectXSmall caption">
            </v-select>
            <v-select color="purple" dense outlined :items="years" v-model="year" label="do Ano de" item-text="text"
              clearable class="selectXSmall caption">
            </v-select>
            <v-select color="purple" dense outlined :items="professionalMonitors" v-model="selectedProfessionalMonitors"
              label="Monitoria" item-text="text" item-value="queryId" clearable multiple class="select caption">
              <template v-slot:selection="{ item, index }">
                <v-chip small outlined v-if="index === 0">
                  <span class="caption">{{ item.text }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text text-caption">(+ outros)</span>
              </template>
            </v-select>
            <v-switch class="ml-4 caption" v-model="onlyWithAlerts"
              :label='onlyWithAlerts?"Todos":"Somente com Alertas"'>
            </v-switch>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-on="on" v-bind="attrs" elevation="0" text color="primary" class="ml-n2" @click="search()">
              <font-awesome-icon :icon="['fal', 'magnifying-glass-chart']" fixed-width style="font-size: 25px" />
              Realizar Pesquisa
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>

    <v-card width="100%" outlined color="grey lighten-2" elevation="24">
      <v-tabs left v-model="tab" grow icons-and-text color="secondary" height="35">
        <v-tabs-slider color="warning"></v-tabs-slider>
        <v-tab>
          <span class="caption">Histórico</span>
        </v-tab>
      </v-tabs>
    </v-card>
    <v-tabs-items class="transparent" v-model="tab">
      <v-tab-item class="transparent" reverse-transition="fade-transition" transition="fade-transition">
        <Historical :name="name"></Historical>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import AgeChip from "./age/AgeChip.vue";
import Historical from "./tabs/Historical.vue";
import { functions } from "../../../../firebase";

export default {
  name: "app-monitoring",
  components: {
    AgeChip,
    //Tabs
    Historical
  },
  data() {
    return {
      months: [{ text: "Janeiro", value: 1 },
      { text: "Fevereiro", value: 2 },
      { text: "Março", value: 3 },
      { text: "Abril", value: 4 },
      { text: "Maio", value: 5 },
      { text: "Junho", value: 6 },
      { text: "Julho", value: 7 },
      { text: "Agosto", value: 8 },
      { text: "Setembro", value: 9 },
      { text: "Outubro", value: 10 },
      { text: "Novembro", value: 11 },
      { text: "Dezembro", value: 12 }],
      years: [{ text: "2022", value: 2022 },
      { text: "2023", value: 2023 },
      { text: "2024", value: 2024 },
      { text: "2025", value: 2022 }],
      types: [{ text: "Início", value: "start" },
      { text: "Término", value: "end" }],
      alertsType: [{ text: "Todos os tipos", value: "all" },
      { text: "Saúde", value: "healthAlert" },
      { text: "Questionário Obrigatório não Respondido", value: "anamnesisNotAnswered" }],

      startMonth: 0,
      endMonth: 0,
      year: 0,
      type: null,
      alertType: null,
      onlyWithAlerts: false,

      tab: null,


      anamnesisByType: {},

      professionalMonitors: [],
      selectedProfessionalMonitors: [],
      overlay: false,
      dialog: null,
      dialogAnamnesisDetails: null,
      name: "",
    };
  },
  computed: {
    ...mapGetters({
      monitors: "monitors",
      professional: "professional",
      historic: "historic",
      historicKpis: "historicKpis"
    }),
  },
  methods: {
    open() {
      this.dialog = true;
    },
    search() {
      if (this.type && this.startMonth && this.endMonth && this.year && this.alertType && this.selectedProfessionalMonitors) {
        this.overlay = true;
        this.dialog = false;
        let sisMonitoringHistory = functions.httpsCallable(
          "sis-monitoring-history-api"
        );

        sisMonitoringHistory({
          startMonth: this.startMonth,
          endMonth: this.endMonth,
          year: this.year,
          type: this.type,
          all: this.onlyWithAlerts,
          alertType: this.alertType,
          monitoring: this.selectedProfessionalMonitors
        })
          .then(async (result) => {
            this.$swal.fire({
              icon: "success",
              title: "Busca realizada com sucesso! ",
              toast: true,
              position: "bottom",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
            });
            await this.$store.dispatch("fetchMonitorHistoricKpis", result.data);
            await this.$store.dispatch("fetchMonitorHistoric", result.data.history);
            this.overlay = false;
          })
          .catch((error) => {
            this.overlay = false;
            console.error(error.message);
          });
      }

    },
    getColor(gender) {
      if (gender == "Feminino") return "pink";
      else return "primary";
    },
  },
  beforeCreate() {
    this.$store.dispatch("resetCustomerState");
    this.$store.dispatch("unbindNotes");
    this.$store.dispatch("unbindMessage");
    this.$store.dispatch("unbindMonitoring");
    this.$store.dispatch("unbindMonitoringNewHealthAlerts");
    this.$store.dispatch("unbindMonitoringImportantAnamnesisNotAnswered");
  },
  async created() {
    await this.$store.dispatch("bindMonitors");
    this.professionalMonitors = await this.monitors.filter(function (m) {
      return this.findIndex((pm) => pm.queryId == m.queryId) > -1;
    }, this.professional.monitors);
  },
};
</script>
<style lang="scss">
@import "../../../../scss/_variables.scss";

.data-studio {
  @media #{$xxlmax-device} {
    width: 100%;
    height: 600px;
  }

  @media #{$xlmax-device} {
    width: 100%;
    height: 700px;
  }

  @media #{$desktop-device} {
    width: 100%;
    height: 600px;
  }

  @media #{$tablet-device} {
    width: 100%;
    height: 800px;
  }

  @media #{$large-mobile} {
    height: 700px;
  }

  @media #{$extra-small-mobile} {
    height: 600px;
  }
}

.select {
  max-width: 100%;
  min-width: 620px;
}
</style>