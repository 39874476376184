import { db, functions } from "../../firebase";

const getDefaultState = () => {
  return {
    datasourceEHR: [],
  };
};

const state = getDefaultState();

export default {
  state,
  mutations: {
    SET_DATASOURCE_EHR(state, value) {
      state.datasourceEHR = value;
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    resetDatasourceEHR({ commit }) {
      commit("RESET_STATE");
    },
    async fetchDatasourceEHR(context, info) {
      let customer = info.customer;
      let ehrDatasourceGet = functions.httpsCallable(
        "sis-ehr-datasource-get-api"
      );

      await ehrDatasourceGet({
        customer: customer,
      })
        .then(async (result) => {
          context.commit("SET_DATASOURCE_EHR", result.data.datasource);
        })
        .catch((error) => {
          console.error(error.message);
        });
    },
  },
  getters: {
    datasourceEHR(state) {
      return state.datasourceEHR;
    },
  },
};
