import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

export default {
  state: {
    anamnesis: [],
  },
  actions: {
    bindAnamnesis: firestoreAction((context, data) => {
      let ref = db.collection("anamnesis");
      context.bindFirestoreRef("anamnesis", ref, {
        reset: true,
      });
    }),
  },
  getters: {
    anamnesis(state) {
      return state.anamnesis;
    },
  },
};
