<template>
  <v-card flat class="pa-2">
    <v-card class="mx-auto" flat outlined>
      <v-toolbar dense flat>
        <v-toolbar-title class="text-subtitle-1">
          <v-btn
            x-small
            elevation="0"
            fab
            text
            class="white--text"
            :color="getColor(customer.gender)"
          >
            <font-awesome-icon
              :icon="['fal', 'calendar-plus']"
              fixed-width
              style="font-size: 20px"
            />
          </v-btn>
          <span class="ml-1 mt-4">Alterar Agendamento</span></v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn
          text
          dark
          color="secondary"
          elevation="0"
          @click="saveSchedule()"
        >
          <v-icon dark>mdi-plus</v-icon>Alterar
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card color="transparent" flat class="mt-2 mb-n5 pa-2">
        <v-row>
          <v-col>
            <v-select
              disabled="true"
              color="purple"
              dense
              outlined
              :items="professionalRooms"
              v-model="room"
              label="Sala"
              item-text="name"
              @change="(selection) => selectionChanged(selection)"
              :return-object="true"
              class="caption"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip small outlined v-if="index === 0">
                  <span class="caption">{{ item.name }}</span>
                </v-chip>
              </template>
            </v-select>
          </v-col>
          <v-col><JourneyName :patient="patient"></JourneyName></v-col>
        </v-row>
      </v-card>
      <v-divider></v-divider>
      <v-card color="transparent" flat class="mt-2 pa-2">
        <v-row
          ><v-col>
            <v-card class="rounded" flat min-height="450" width="100%">
              <v-card-title>
                <span>Data</span>
              </v-card-title>
              <v-date-picker
                v-model="day"
                full-width
                color="primary"
                :min="today"
                :events="events"
                :picker-date.sync="pickerDate"
                :allowed-dates="allowedDates"
                event-color="purple"
              ></v-date-picker></v-card
          ></v-col>
          <v-col>
            <v-card class="rounded" flat min-height="470" width="100%">
              <v-card-title>
                <span>Horário</span>
              </v-card-title>
              <v-select
                :items="periods"
                :disabled="disabledPeriods"
                label="Períodos"
                outlined
                v-model="period"
                :return-object="true"
              ></v-select>
              <v-chip-group column v-model="time">
                <v-row>
                  <v-col>
                    <v-chip
                      :outlined="true"
                      color="secondary"
                      v-for="time in times"
                      :key="time.index"
                      :disabled="time.disabled"
                      :value="time.text"
                    >
                      {{ time.text }}
                    </v-chip>
                  </v-col>
                </v-row>
              </v-chip-group>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-card>
  </v-card>
</template>

<script>
import { db, functions } from "../../../../../firebase";
import { mapGetters } from "vuex";
import JourneyName from "./JourneyName.vue";

export default {
  data() {
    return {
      room: null,
      professionalRooms: [],
      disabledPeriods: true,
      alert: false,
      timeout: 4000,
      alertMessage: null,
      times: [],
      period: null,
      periods: [],
      day: null,
      today: null,
      landscape: true,
      time: null,
      events: [],
      blocked: [],
      pickerDate: null,
      calendarYear: null,
      calendarMonth: null,
      calendarMomentMonth: null,
      calendarDay: null,
      calendarWeekday: null,
    };
  },
  props: {
    roomId: {
      type: String,
      required: false,
      default: null,
    },
    eventId: {
      type: String,
      required: false,
      default: null,
    },
    patient: {
      type: String,
      required: false,
      default: null,
    },
  },

  async created() {
    this.professionalRooms = await this.rooms.filter(function (m) {
      return this.findIndex((pm) => pm == m.queryId) > -1;
    }, this.professional.rooms);
    this.today = this.$moment(new Date()).format("YYYY-MM-DD");
    this.calendarYear = this.$moment(new Date()).year();
    this.calendarMonth = this.$moment(new Date()).month() + 1;
    this.calendarMomentMonth = this.$moment(new Date()).month();
  },
  mounted() {
    this.update();
  },
  computed: {
    ...mapGetters({
      rooms: "rooms",
      customer: "customer",
      professional: "professional",
    }),
  },
  watch: {
    pickerDate: function (newPicker, oldPicker) {
      this.calendarYear = this.$moment(newPicker).year();
      this.calendarMonth = this.$moment(newPicker).month() + 1;
      this.calendarMomentMonth = this.$moment(newPicker).month();
      this.calendarDay = this.$moment(this.day).date();
      this.getEvents();
    },
    day: function (newDay, oldDay) {
      this.calendarDay = this.$moment(this.day).date();
      this.calendarWeekday = this.$moment(this.day).weekday();
      this.getPeriods();
    },
    period: function (newValue, oldValue) {
      this.times = [];
      this.time = null;
      if (newValue) {
        this.updateTime();
      }
    },
  },
  methods: {
    update() {
      this.room = null;
      this.room = this.rooms.find((room) => room.queryId == this.roomId);
    },
    getColor(gender) {
      if (gender === "Feminino") return "pink";
      else return "primary";
    },
    getPeriods() {
      this.times = [];
      this.time = null;
      this.period = null;
      this.periods = null;
      this.disabledPeriods = false;
      if (this.room.periods[this.calendarMomentMonth]) {
        this.periods =
          this.room.periods[this.calendarMomentMonth][this.calendarWeekday];
      } else {
        this.periods = this.room.periods["default"][this.calendarWeekday];
      }
    },
    allowedDates(val) {
      let month = "default";
      if (this.room.periods[this.calendarMomentMonth]) {
        month = this.calendarMomentMonth;
      }

      if (
        this.room != null &&
        this.room.periods[month][this.$moment(val).weekday()]
      ) {
        return true;
      }
      return false;
    },
    getEvents() {
      db.collection("calendar")
        //.where("customer.uid", "==", this.customer.id)
        .where("year", "==", this.calendarYear)
        .where("month", "==", this.calendarMonth)
        .where("roomQueryId", "==", this.roomId)
        .where("status", "not-in", ["closed", "cancelled"])
        .get()
        .then((calendars) => {
          calendars.forEach((calendar) => {
            this.blocked.push({
              key:
                calendar.data().year.toString() +
                calendar.data().month.toString() +
                calendar.data().day.toString() +
                calendar.data().hour.toString() +
                calendar.data().minute.toString(),
            });
            this.events.push(calendar.data().date);
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    updateTime() {
      let now = new Date().getHours();
      let nowMinutes = new Date().getMinutes();
      let slice = this.period.slice;
      let start = parseInt(this.period.start.substring(0, 2));
      let startMinute = parseInt(this.period.start.substring(3, 5));
      let end = parseInt(this.period.end.substring(0, 2));
      let endMinute = parseInt(this.period.end.substring(3, 5));
      for (var h = start; h <= end; h++) {
        for (var s = 0; s < 60 / slice; s++) {
          let time = {};
          time.hour = h;
          time.minute = s * slice;

          if (h == end) {
            if (time.minute > endMinute) {
              continue;
            }
          }

          time.text =
            (time.hour < 10 ? "0" + time.hour : time.hour) +
            ":" +
            (time.minute < 10 ? "0" + time.minute : time.minute);

          if (this.day == this.today) {
            if (now > end) {
              time.disabled = true;
            } else {
              if (time.hour >= now) {
                if (time.minute <= nowMinutes && time.hour <= now) {
                  time.disabled = true;
                } else {
                  //colocar a verificação de disponibilidade
                  let key =
                    this.calendarYear.toString() +
                    this.calendarMonth.toString() +
                    this.calendarDay.toString() +
                    time.hour.toString() +
                    time.minute.toString();
                  if (this.blocked.find((b) => b.key === key)) {
                    time.disabled = true;
                  } else {
                    time.disabled = false;
                  }
                }
              } else {
                time.disabled = true;
              }
            }
          } else {
            let key =
              this.calendarYear.toString() +
              this.calendarMonth.toString() +
              this.calendarDay.toString() +
              time.hour.toString() +
              time.minute.toString();
            if (this.blocked.find((b) => b.key === key)) {
              time.disabled = true;
            } else {
              time.disabled = false;
            }
          }
          this.times.push(time);
        }
      }
    },
    async saveSchedule() {
      if (this.time) {
        let start = new Date(`${this.day}T${this.time}:00`);
        let end = new Date(`${this.day}T${this.time}:00`);
        end.setMinutes(end.getMinutes() + parseInt(this.period.slice));
        let update = {};
        update.date = this.day;
        update.day = start.getDate();
        update.dayEnd = end.getDate();
        update.hour = start.getHours();
        update.minute = start.getMinutes();
        update.hourEnd = end.getHours();
        update.minuteEnd = end.getMinutes();
        update.month = parseInt(start.getMonth()) + 1;
        update.monthEnd = parseInt(end.getMonth()) + 1;
        update.year = start.getFullYear();
        update.yearEnd = end.getFullYear();
        update.start =
          update.year +
          "-" +
          update.month +
          "-" +
          update.day +
          "T" +
          update.hour +
          ":" +
          update.minute;
        update.end =
          update.yearEnd +
          "-" +
          update.monthEnd +
          "-" +
          update.dayEnd +
          "T" +
          update.hourEnd +
          ":" +
          update.minuteEnd;
        update.second = "00";
        update.secondEnd = "59";

        let calendarUpdate = functions.httpsCallable("sis-calendar-update-api");
        await calendarUpdate({
          eventId: this.eventId,
          update: update,
          professional: this.professional,
        })
          .then((result) => {
            this.$emit("closeSchedule");
            this.$swal.fire({
              icon: "success",
              title: "Agendamento atualizado com sucesso! ",
              toast: true,
              position: "bottom",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((error) => {
            console.error(error.message);
          });
      }
    },
  },
  components: { JourneyName },
};
</script>

<style>
</style>
