<template>
  <v-card flat class="pa-1 overflow-y-auto">
    <v-card class="mx-auto" flat outlined>
      <v-toolbar dense flat>
        <v-toolbar-title class="text-subtitle-1">
          <v-btn x-small elevation="0" fab text class="white--text" :color="getColor(customer.gender)">
            <font-awesome-icon :icon="['fal', 'history']" fixed-width style="font-size: 18px" />
          </v-btn>
          <span class="ml-1 mt-4">
            Histórico do Pacientes:
            <span class="font-weight-medium">{{
              customer.fullName
            }}</span></span>
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <NewHealthNote></NewHealthNote>
      </v-toolbar>

      <v-divider></v-divider>
      <v-card flat class="pa-2" color="transparent">
        <v-data-iterator :items="notes" :items-per-page.sync="itemsPerPage" :page.sync="page" :search="search"
          :sort-by="sortBy.toLowerCase()" :sort-desc="sortDesc" hide-default-footer>
          <template v-slot:header>
            <v-toolbar class="rounded mb-2" height="50" width="100%" elevation="0">
              <v-text-field outlined color="grey darken-1" v-model="search" dense clearable flat hide-details
                prepend-inner-icon="mdi-magnify" label="Pesquisar"></v-text-field>
              <template v-if="$vuetify.breakpoint.mdAndUp">
                <v-select color="grey darken-1" class="fit ml-2" full-width v-model="sortBy" flat hide-details outlined
                  dense :items="keys" prepend-inner-icon="mdi-magnify" label="Ordernar"></v-select>
                <v-spacer></v-spacer>
                <v-btn-toggle v-model="sortDesc" mandatory>
                  <v-btn fab outlined dark x-small color="grey" :value="true">
                    <v-icon>mdi-arrow-up</v-icon>
                  </v-btn>
                  <v-btn fab outlined dark x-small color="grey" :value="false">
                    <v-icon>mdi-arrow-down</v-icon>
                  </v-btn>
                </v-btn-toggle>
              </template>
              <v-spacer></v-spacer>
              <template>
                <span class="ml-4">
                  Página {{ page }} de {{ numberOfPages }}
                </span>
                <v-btn fab dark outlined x-small color="grey" elevation="0" class="ml-4 mr-1" @click="formerPage">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn fab dark outlined x-small color="grey" class="ml-1 mr-4" elevation="0" @click="nextPage">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </template>
              <v-spacer></v-spacer>
              <template>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined color="grey darken-1" elevation="0" class="ml-2 mr-4" v-bind="attrs" v-on="on">
                      {{ itemsPerPage }}
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense outlined>
                    <v-list-item v-for="(number, index) in itemsPerPageArray" :key="index"
                      @click="updateItemsPerPage(number)">
                      <v-list-item-title>{{ number }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-toolbar>
          </template>

          <template v-slot:default="props" class="ml-15 mr-15">
            <v-row>
              <v-col class="col-fluid">
                <v-alert v-for="note in props.items" :key="note.id" :color="note.color" outlined dark border="left">
                  <template v-slot:prepend>
                    <v-btn class="mr-3" small outlined elevation="0" fab :color="note.color"
                      @click="clipboard(note.formattedNote)">
                      <font-awesome-icon :icon="['fal', note.icon]" fixed-width style="font-size: 20px" />
                    </v-btn>
                  </template>
                  <span class="caption seconday--text">{{
                    note.created.toDate() | moment("from", "now")
                  }}</span>
                  <span class="ml-1 caption grey--text text--darken-2">{{ note.practitionerName }}
                  </span>
                  <span class="caption grey--text text--darken-2">({{ note.practitionerType }})</span>
                  <span v-if="note.practitionerSpecialty" class="caption grey--text text--darken-2">({{
                    note.practitionerSpecialty
                  }})</span>
                  <span class="ml-1 caption grey--text text--darken-2">[ {{
                    note.created.toDate() | moment("DD/MM/YYYY")
                  }}
                  </span>
                  <span class="caption grey--text text--darken-2">
                    {{ note.basedOn.care }}]</span>
                  <span class="ml-1 caption grey--text text--darken-3">
                    <div v-html="note.note" />
                  </span>
                </v-alert>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </v-card>
    </v-card>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import NewHealthNote from "./NewHealthNote.vue";
import copy from 'copy-to-clipboard';

export default {
  components: {
    NewHealthNote,
  },
  data() {
    return {
      loading: true,
      itemsPerPageArray: [3, 9, 15],
      search: "",
      sortDesc: true,
      page: 1,
      itemsPerPage: 3,
      sortBy: "created",
      keys: [
        { text: "Data", value: "created" },
        { text: "Tipo da Nota", value: "type" },
      ],
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.notes.length / this.itemsPerPage);
    },
    ...mapGetters({
      customer: "customer",
      notes: "notes",
    }),
  },
  methods: {
    clipboard(formattedNote) {
      if (formattedNote) {
        this.$swal.fire({
          icon: "success",
          title: "Nota de saúde copiada com sucesso! ",
          toast: true,
          position: "bottom",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
        copy(formattedNote, { debug: true });
      } else {
        this.$swal.fire({
          icon: "error",
          title: "Somente notas de saúde podem ser copiadas! ",
          toast: true,
          position: "bottom",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
      }
    },
    getColor(gender) {
      if (gender === "Feminino") return "pink";
      else return "primary";
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
  },
};
</script>
<style>
.amber-color {
  background-color: rgba(255, 255, 255, 0.5);
  border-color: #b88a00;
}

.purple-color {
  background-color: rgba(255, 255, 255, 0.5);
  border-color: #9c27b0;
}

.pink-color {
  background-color: rgba(255, 255, 255, 0.5);
  border-color: #e91e63;
}

.green-color {
  background-color: rgba(255, 255, 255, 0.5);
  border-color: #3b943e;
}

.red-color {
  background-color: rgba(255, 255, 255, 0.4);
  border-color: #fc3628;
}

.blue-color {
  background-color: rgba(255, 255, 255, 0.4);
  border-color: #353da7;
}

.grey-color {
  background-color: rgba(255, 255, 255, 0.4);
  border-color: #424242;
}

.orange-color {
  background-color: rgba(255, 255, 255, 0.4);
  border-color: #eb6a1f;
}

.v-select.fit {
  width: min-content;
}

.v-select.fit .v-select__selection--comma {
  text-overflow: unset;
}
</style>
