<template>
  <v-app id="main">
    <v-main
      class="
        transparent
        d-flex
        flex-row
        justify-center
        align-content
        ml-14
        pa-2
      "
    >
      <Drawer></Drawer>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<style>
#dashboard .v-navigation-drawer__border {
  display: none;
}
</style>

<script>
import Drawer from "./Drawer.vue";

export default {
  name: "app-dashboard",
  components: {
    Drawer,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {
  },
};
</script>