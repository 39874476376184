<template>
  <v-card
    class="mr-4 mt-4 d-flex flex-column"
    width="320"
    min-height="165"
    elevation="3"
  >
    <div class="d-flex flex-no-wrap justify-space-between">
      <div>
        <v-card-title class="text-subtitle-2 secondary--text text--darken-1">
          <span class="ml-1 mb-1">{{ kpi.description }}</span>
        </v-card-title>
      </div>
      <v-avatar size="90" rounded class="ma-3" color="grey lighten-5">
        <span
          class="secondary--text text--darken-1 text-h4 mx-0 font-weight-black"
        >
          <v-dialog v-model="dialog" max-width="800" eager>
            <template v-slot:activator="{ on: dialog, attrs }">
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                    small
                    color="primary"
                    text
                    v-bind="attrs"
                    v-on="{ ...tooltip, ...dialog }"
                    elevation="0"
                    min-width="45"
                    max-width="45"
                    min-height="45"
                    max-height="45"
                  >
                    <font-awesome-icon
                      :icon="['fal', 'chart-line']"
                      fixed-width
                      style="font-size: 25px"
                    />
                  </v-btn>
                </template>
                <span>Exibir Informação</span>
              </v-tooltip>
            </template>
            <v-card class="pa-2">
              <LineChart
                v-if="this.series.length > 0"
                :chartTitle="kpi.description"
                :xaxisTitle="kpi.xaxis"
                :seriesInfo="[
                  {
                    series: series,
                    color: '#6d6d6d',
                  },
                ]"
              ></LineChart>
            </v-card>
          </v-dialog>
        </span>
      </v-avatar>
    </div>
    <v-divider></v-divider>
    <v-card-actions>
      <v-chip
        label
        small
        outlined
        color="purple"
        class="mb-n8 text-left text-caption font-weight-black purple--text"
        ><font-awesome-icon
          :icon="['fal', 'gauge-max']"
          fixed-width
          style="font-size: 16px"
        /><span class="ml-1 caption">População: 100% </span></v-chip
      >
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import LineChart from "../chart/LineChart.vue";

export default {
  components: {
    LineChart,
  },
  props: {
    kpi: {
      type: Object,
      required: true,
      default: null,
    },
    kpis: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      series: [],
    };
  },
  methods: {},
  mounted() {
    for (let index = 0; index < Object.keys(this.kpi.series).length; index++) {
      if (
        Object.keys(this.kpi.series)[index] != "description" &&
        Object.keys(this.kpi.series)[index] != "view"
      ) {
        this.series[Object.keys(this.kpi.series)[index]] =
          this.kpi.series[Object.keys(this.kpi.series)[index]];
      }
    }
  },
};
</script>

<style>
</style>