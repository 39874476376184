var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.chat.messages != null && _vm.rooms.length > 0)?_c('vue-advanced-chat',{attrs:{"height":"calc(80vh)","current-user-id":_vm.professional.id,"message-actions":JSON.stringify(_vm.messageActions),"templates-text":JSON.stringify(_vm.templatesText),"text-messages":JSON.stringify(_vm.textMessages),"showSearch":false,"show-files":true,"show-audio":true,"show-emojis":true,"show-reaction-emojis":false,"show-new-messages-divider":true,"show-footer":true,"text-formatting":true,"textarea-action-enabled":false,"single-room":true,"rooms":JSON.stringify(_vm.rooms),"rooms-loaded":true,"accepted-files":"image/png, image/jpeg, application/pdf","messages":JSON.stringify(_vm.chat.messages),"messages-loaded":JSON.stringify(_vm.messagesLoaded),"styles":JSON.stringify({
    general: {
      color: '#0a0a0a',
      colorSpinner: '#333',
      borderStyle: '1px solid #e1e4e8',
    },
  
    container: {
      boxShadow:
        '0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)',
    },
  
    content: {
      background: '#f8f8f8',
    },
  
    footer: {
      background: '#ffffff',
      backgroundReply: 'rgba(0, 0, 0, 0.08)',
    },
  
    icons: {
      search: '#9ca6af',
    },
  })},on:{"send-message":_vm.sendMessage,"fetch-messages":_vm.fetchMessages}}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }