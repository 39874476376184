import {firestoreAction} from "vuexfire";
import {db} from "../../firebase";

export default {
    state: {
        surveys: [],
        monitoringSurveysNotAnswered: []
    },
    mutations: {},
    actions: {
        unbindSurveys: firestoreAction(
            ({unbindFirestoreRef}) => {
                unbindFirestoreRef("surveys", true);
            }
        ),
        bindSurveys: firestoreAction(
            (context, data) => {
                let source = data.source;
                let type = data.type;
                let ref = db.collection("surveys");
                ref = ref.where("status", "==", "closed");
                ref = ref.where("type", "==", type);
                ref = ref.where("sourceQueryId", "in", source);
                context.bindFirestoreRef("surveys", ref, {reset: true});
            }
        ),

        unbindMonitoringSurveyNotAnswered: firestoreAction(
            ({unbindFirestoreRef}) => {
                unbindFirestoreRef("monitoringSurveysNotAnswered", true);
            }
        ),
        bindMonitoringSurveyNotAnswered: firestoreAction(
            (context, data) => {
                let monitoring = data.monitoring;
                let ref = db.collection("monitoring-actions");
                ref = ref.where("action", "==", "survey");
                ref = ref.where("cancelled", "==", true);
                ref = ref.where("quit", "==", false);
                ref = ref.where("monitoringQueryId", "in", monitoring);
                context.bindFirestoreRef("monitoringSurveysNotAnswered", ref, {reset: true});
            }
        )
    },
    getters: {
        surveys(state) {
            return state.surveys;
        },
        monitoringSurveysNotAnswered(state) {
            return state.monitoringSurveysNotAnswered;
        }
    }
};
