import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

const getDefaultState = () => {
  return { journeysKpis: {} };
};

const state = getDefaultState();

export default {
  state,
  mutations: {
    RESET_JOURNEYS_KPIS(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    resetJourneysKpis({ commit }) {
      commit("RESET_JOURNEYS_KPIS");
    },
    unbindJourneysKpis: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef("journeysKpis", true);
    }),
    bindJourneysKpis: firestoreAction((context, data) => {
      let journey = data.journey;
      let key = data.key;
      let ref = db.collection("kpis-journey").doc(journey);
      let kpisRef = ref.collection("kpis").doc(key);
      context.bindFirestoreRef("journeysKpis", kpisRef, { reset: true });
    }),
  },
  getters: {
    journeysKpis(state) {
      return state.journeysKpis;
    },
  },
};
