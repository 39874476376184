<template>
	<v-card flat transparent>
		<v-overlay :value="memedOverlay">
			<v-progress-circular indeterminate :rotate="360" :size="100" :width="8" color="white"> </v-progress-circular>
		</v-overlay>
		<v-card transparent class="mr-1 memed-container" id="memed-container"></v-card>
	</v-card>
</template>

<script>
import { mapGetters } from "vuex";
import store from "../../../../../store/index";
import { functions } from "../../../../../firebase";

export default {
	data: () => {
		return {
			headers: [
				{ text: "Médico", value: "professional.fullName" },
				{ text: "Data", value: "created" },
			],
			script: null,
			body: document.querySelector("body"),
		};
	},
	computed: {
		...mapGetters({
			professional: "professional",
			customer: "customer",
			frontEnd: "frontEnd",
			datasource: "datasource",
			memedOverlay: "memedOverlay",
		}),
	},
	methods: {
		openPrescription(id) {
			document.defaultView.MdHub.command.send("plataforma.prescricao", "viewPrescription", id);
		},
		initMemed() {
			console.info("Iniciando módulo do memed.");
			this.$store.dispatch("fetchMemedOverlay");
			this.script = document.createElement("script");
			this.script.setAttribute("id", "memed");
			this.script.setAttribute("type", "text/javascript");
			this.script.setAttribute("data-color", "#3f51b5");
			this.script.setAttribute("data-token", this.professional.memed.token);
			this.script.setAttribute("data-container", "memed-container");
      this.script.async = true;
			this.script.src = this.frontEnd + "/modulos/plataforma.sinapse-prescricao/build/sinapse-prescricao.min.js";
			this.script.onload = function () {
				console.info("Script on load: start memed.");
				document.defaultView.MdSinapsePrescricao.event.add("core:moduleInit", async function moduleInitHandler(module) {
					console.info("Nome do módulo: ", module.name);
					if (module.name === "plataforma.prescricao") {
						document.defaultView.MdHub.event.add("prescricaoImpressa", function (prescriptionData) {
							let savePrescription = functions.httpsCallable("sis-interview-prescription-save-memed-api");
							savePrescription({
								prescription: prescriptionData,
								customer: store.getters.customer,
								professional: store.getters.professional,
								interview: store.getters.interview,
							})
								.then((result) => {
									console.info("Gravando a prescrição.");
									let sisInterviewNoteSave = functions.httpsCallable("sis-interview-clinical-draft-save-note-api");

									for (let index = 0; index < prescriptionData.prescricao.medicamentos.length; index++) {
										let prescription = prescriptionData.prescricao.medicamentos[index];
										let note = "<ol>";
										note += "<li> Nome: " + prescription.nome + "</li>";
										if (prescription.posologia != null && prescription.posologia != "<p></p>") {
											note += "<ul><li>" + prescription.posologia + "</li></ul>";
										}

										if (prescription.tipo != "custom") {
											note += "<li> Tipo: " + prescription.tipo + "</li>";
										}

										if (prescription.tipo != "exame" && prescription.tipo != "custom") {
											if (prescription.fabricante) {
												note += "<li> Fabricante: " + prescription.fabricante + "</li>";
											}
											if (prescription.tarja) {
												note += "<li> Tarja: " + prescription.tarja + "</li>";
											}
											if (prescription.titularidade) {
												note += "<li> Titularidade: " + prescription.titularidade + "</li>";
											}
										}
										note += "</ol>";
										sisInterviewNoteSave({
											customer: store.getters.customer.id,
											interview: store.getters.interview.id,
											text: note,
											professional: store.getters.professional,
											config: {
												type: "prescriptions",
												icon: prescription.tipo != "exame" ? "pills" : "monitor-heart-rate",
												color: "purple",
												colorCard: "purple-color",
											},
										})
											.then((result) => {
												console.info("Nota de prescrição criada com sucesso!");
											})
											.catch((error) => {
												console.error(error.message);
											});
									}
									this.$swal.fire({
										icon: "success",
										title: "Prescrição do paciente salvo com sucesso! ",
										toast: true,
										position: "bottom",
										showConfirmButton: false,
										timer: 1000,
										timerProgressBar: true,
									});
								})
								.catch((error) => {
									console.error(error.message);
								});
						});

						console.info("Definindo as configurações do ambiente da memed.");
						document.defaultView.MdHub.command.send("plataforma.prescricao", "setFeatureToggle", {
							// Desativa a opção de excluir um paciente (obrigatório)
							deletePatient: false,
							// Desabilita a opção de remover/trocar o paciente (obrigatório)
							removePatient: false,
							// Esconde o histórico de prescrições
							historyPrescription: true,
							// Esconde o botão "Nova Prescrição"
							newPrescription: true,
							// Esconde o botão "Opções Receituário"
							optionsPrescription: false,
							// Desabilita a opção de remover a prescrição no histórico
							removePrescription: false,
							// Esconde o formulário de edição do paciente
							editPatient: false,
							// Desabilita a opção para definir alergias para o paciente
							setPatientAllergy: true,
							// Desabilita a aba "Exames" do Autocomplete de medicamentos
							autocompleteExams: true,
							// Desabilita a aba "Industrializados" do Autocomplete de medicamentos
							autocompleteIndustrialized: true,
							// Desabilita a aba "Manipulados" do Autocomplete de medicamentos
							autocompleteManipulated: true,
							// Desabilita a aba "Composições" do Autocomplete de medicamentos
							autocompleteCompositions: true,
							// Desabilita a aba "Periféricos" do Autocomplete de medicamentos
							autocompletePeripherals: true,
							// Esconde o botão "Copiar para Prontuário" (que copia o conteúdo da prescrição para o clipboard)
							copyMedicalRecords: true,
							// Esconde o botão de fechar da prescrição
							buttonClose: false,
							// Habilita opção Nova Fórmula Manipulada no menu
							newFormula: true,
						});

						console.info("Configurando o Workplace.");
						await document.defaultView.MdHub.command.send("plataforma.prescricao", "setWorkplace", {
							city: store.getters.professional.city ? store.getters.professional.city : "",
							state: store.getters.professional.state ? store.getters.professional.state : "",
							phone: store.getters.professional.phone ? store.getters.professional.phone : "",
							cnes: store.getters.professional.cnes ? store.getters.professional.cnes : "",
							local_name: store.getters.professional.workplace ? store.getters.professional.workplace : "",
							address: store.getters.professional.address ? store.getters.professional.address + ", " + store.getters.professional.addressNumber : "",
						});

						console.info("Iniciando a configuração dos dados do paciente.");
						let mPatient = {};
						mPatient.idExterno = store.getters.customer.id;
						mPatient.nome = store.getters.customer.fullName;

						if (store.getters.customer.cpf) {
							mPatient.cpf = store.getters.customer.cpf;
						} else {
							mPatient.withoutCpf = true;
						}
						mPatient.data_nascimento = store.getters.customer.birthdate;

						if (store.getters.customer.socialName) {
							mPatient.nome_social = store.getters.customer.socialName;
						}

						if (store.getters.customer.address) {
							mPatient.endereco = store.getters.customer.address + ", " + store.getters.customer.addressNumber;
						}

						if (store.getters.customer.city) {
							mPatient.cidade = store.getters.customer.city;
						}

						mPatient.telefone = store.getters.customer.phone;
						mPatient.peso = store.getters.datasource["findings"].find((view) => view.resource.queryId === "vital-signs-weight").data.value;
						mPatient.altura = store.getters.datasource["findings"].find((view) => view.resource.queryId === "vital-signs-height").data.value;

						if (store.getters.customer.mothersName) {
							mPatient.nome_mae = store.getters.customer.mothersName;
						}

						if (store.getters.customer.handicapped) {
							mPatient.dificuldade_locomocao = store.getters.customer.handicapped;
						}

						// Categorias que podem apresentar restrição de itens prescritos (para gerar Alerta de Condição)
						//categoriesConditions: [1],
						try {
							await document.defaultView.MdHub.command.send("plataforma.prescricao", "setPaciente", mPatient).then(function () {
								document.defaultView.MdHub.module.show("plataforma.prescricao");
								store.dispatch("fetchMemedOverlay");
							});
						} catch (e) {
							console.error(e);
						}
					}
				});
			};
			this.body.appendChild(this.script);
		},
	},
	components: {},
	destroyed() {
		document.defaultView.MdHub.server.unbindEvents();
		document.defaultView.MdHub.command.send("plataforma.sdk", "logout");
		delete document.defaultView.MdHub;
	},
	mounted() {
		this.initMemed();
	},
};
</script>

<style scoped>
.memed-container {
	margin: 0 auto;
	width: 100%;
	height: 100%;
}
</style>
