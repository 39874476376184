import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { functions } from "../firebase";
import { app } from "@/main.js";

export default class LoggerService {

    constructor() {
        this.initHandler();
    }

    static initHandler() {
        const scope = this;
        window.onerror = (message, url, lineNo, columnNo, error) => {
            if (error) {
                scope.onError(error);
                let details = {};
                details.url = url;
                details.lineNo = lineNo;
                details.columnNo = columnNo;
                details.error = JSON.stringify(error);
                logToServer(message, details, 'error');
            }
        };
    }

    static onVueError(error, vm, info) {
        const response = error.response;
        if (response && response.status >= 400 && response.status < 405) {
            LoggerService.logVueErrorToServer(response, info, vm);
            return false;
        }
        LoggerService.logVueErrorToServer(error, info, vm);
    }

    static onVueWarn(error, vm, info) {
        const response = error.response;
        if (response && response.status >= 400 && response.status < 405) {
            LoggerService.logVueWarnToServer(response, info, vm);
            return false;
        }
        LoggerService.logVueWarnToServer(error, info, vm);
    }

    static debug(message, details, context) {
        let level = app.$store.getters.config.level
        if (level == 'trace' || level == 'debug') {
            const response = message.response;
            if (response && response.status >= 400 && response.status < 405) {
                LoggerService.logToServer(response, details, 'debug');
                return false;
            }
            LoggerService.logToServer(message, details, 'debug');
        }
    }

    static error(error, details) {
        let level = app.$store.getters.config.level
        if (level == 'trace' || level == 'debug' || level == 'error') {
            const response = error.response;
            if (response && response.status >= 400 && response.status < 405) {
                LoggerService.logToServer(response, details, 'error');
                return false;
            }
            LoggerService.logToServer(error, details, 'error');
        }
    }

    static warn(message, details) {
        let level = app.$store.getters.config.level
        if (level == 'trace' || level == 'debug' || level == 'error' || level == 'warn') {
            const response = message.response;
            if (response && response.status >= 400 && response.status < 405) {
                LoggerService.logToServer(response, details, 'warn');
                return false;
            }
            LoggerService.logToServer(message, details, 'warn');
        }
    }

    static info(message, details) {
        let level = app.$store.getters.config.level
        if (level == 'trace' || level == 'debug' || level == 'error' || level == 'warn' || level == 'info') {
            const response = message.response;
            if (response && response.status >= 400 && response.status < 405) {
                LoggerService.logToServer(response, details, 'info');
                return false;
            }
            LoggerService.logToServer(message, details, 'info');
        }
    }


    static displayErrorAlert(message) {
        Swal.fire({ title: "Error!", text: message, icon: "error" });
    }

    static async logVueErrorToServer(error, info, vm) {
        this.logToServer(error.message, info, 'error');
    }

    static async logVueWarnToServer(error, info, vm) {
        this.logToServer(error.message, info, 'warn');
    }

    static logToServer(message, details, type) {
        let session = app.$store.getters.session;
        let professional = app.$store.getters.professional;
        let router = app._route.name;
        let path = app._route.path;

        let e = new Error();
        if (!e.stack) {
            try {
                // IE requires the Error to actually be thrown or else the 
                // Error's 'stack' property is undefined.
                throw e;
            } catch (e) {
                if (!e.stack) {
                    //return 0; // IE < 10, likely
                }
            }
        }
        let stack = e.stack.toString().split(/\r\n|\n/);
        stack.shift();

        let log = {};
        log.message = message;
        log.type = type;
        log.router = router;
        log.path = path;
        log.context = stack;
        log.details = JSON.stringify(details);

        let sisLoggerLog = functions.httpsCallable(
            "sis-logger-log-api"
        );

        sisLoggerLog({
            professional: (professional != null ? professional.id : null),
            session: session,
            log: log
        })
    }
}
