<template>
  <span class="caption">{{
  formatBirthdate(age) | moment("from", "now", true)
  }}</span>
</template>

<script>
import { db } from "../../../../../../firebase";

export default {
  components: {},
  props: {
    customer: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      age: 0,
    };
  },
  watch: {},
  computed: {},
  methods: {
    formatBirthdate(birthdate) {
      return this.$moment(birthdate, "DD/MM/YYYY");
    },
  },
  async created() {
    let customerDoc = await db.collection("customer").doc(this.customer).get();
    this.age = customerDoc.data().birthdate;
  },
};
</script>
