<template>
  <v-card class="mx-auto" flat>
    <v-card class="mb-2 rounded" flat color="grey lighten-3">
      <v-toolbar flat height="35" elevation="0" class="secondary">
        <v-toolbar-title class="subtitle-2 white--text"
          >6. Prescrição</v-toolbar-title
        >
        <v-spacer></v-spacer>

        <v-dialog v-model="dialog" max-width="1000" eager>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
            small
              class="mr-n3"
              height="25"
              dark
              color="primary"
              elevation="0"
              center
              v-bind="attrs"
              v-on="on"
            >
              <font-awesome-icon
              class="mr-2"
                color="white"
                :icon="['fal', 'pills']"
                fixed-width
                style="font-size: 15px"
              />
              Prescrição
            </v-btn>
          </template>
          <v-card>
            <Prescription></Prescription>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </v-card>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import Prescription from "../../../components/prescription/Prescription.vue";

export default {
  data: () => ({
    dialog: false,
  }),
  components: { Prescription },
  computed: {
    ...mapGetters({}),
  },
  watch: {},
  methods: {},
  destroyed() {},
};
</script>

<style>
</style>
