import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

export default {
  state: {
    monitoring: [],
    monitoringNewHealthAlerts: [],
    monitoringImportantAnamnesisNotAnswered: [],
    customerMonitoring: [],
    customerMonitoringActions: [],
    historic: [],
    historicKpis: {},
  },
  mutations: {
    SET_HISTORIC(state, value) {
      state.historic = value;
    },
    SET_HISTORIC_KPIS(state, value) {
      state.historicKpis = value;
    },
  },
  actions: {
    async fetchMonitorHistoricKpis(context, data) {
      let kpis = {};
      kpis.size = data.size;
      kpis.effectiveness = data.effectiveness;
      kpis.rejection = data.rejection;
      kpis.answeredAnamnesis = data.answeredAnamnesis;
      kpis.healthAlertsPercentage = data.healthAlertsPercentage;
      kpis.anamnesisNotAnsweredPercentage = data.anamnesisNotAnsweredPercentage;
      kpis.notTreatedAlertsPercentage = data.notTreatedAlertsPercentage;
      kpis.cancelledAnamnesisPercentage = data.cancelledAnamnesisPercentage;
      kpis.total = data.total;
      kpis.executed = data.executed;
      kpis.cancelled = data.cancelled;
      kpis.alerts = data.alerts;
      kpis.notTreatedAlerts = data.notTreatedAlerts;
      kpis.healthAlerts = data.healthAlerts;
      kpis.anamnesisNotAnswered = data.anamnesisNotAnswered;
      kpis.anamnesis = data.anamnesis;
      kpis.cancelledAnamnesis = data.cancelledAnamnesis;
      kpis.anamnesisByType = data.anamnesisByType;
      context.commit("SET_HISTORIC_KPIS", kpis);
    },
    async fetchMonitorHistoric(context, historic) {
      context.commit("SET_HISTORIC", historic);
    },
    unbindMonitoringImportantAnamnesisNotAnswered: firestoreAction(
      ({ unbindFirestoreRef }) => {
        unbindFirestoreRef("monitoringImportantAnamnesisNotAnswered", true);
      }
    ),
    bindMonitoringImportantAnamnesisNotAnswered: firestoreAction(
      (context, data) => {
        let monitoring = data.monitoring;
        let ref = db.collection("monitoring");
        ref = ref.where("active", "==", true);
        ref = ref.where("importantAnamnesisNotAnswered", ">", 0);
        ref = ref.where("monitor.queryId", "in", monitoring);
        context.bindFirestoreRef(
          "monitoringImportantAnamnesisNotAnswered",
          ref,
          {
            reset: true,
          }
        );
      }
    ),
    unbindMonitoringNewHealthAlerts: firestoreAction(
      ({ unbindFirestoreRef }) => {
        unbindFirestoreRef("monitoringNewHealthAlerts", true);
      }
    ),
    bindMonitoringNewHealthAlerts: firestoreAction((context, data) => {
      let monitoring = data.monitoring;
      let ref = db.collection("monitoring");
      ref = ref.where("active", "==", true);
      ref = ref.where("healthAlerts", ">", 0);
      ref = ref.where("monitor.queryId", "in", monitoring);
      context.bindFirestoreRef("monitoringNewHealthAlerts", ref, {
        reset: true,
      });
    }),
    unbindMonitoring: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef("monitoring", true);
    }),
    bindMonitoring: firestoreAction((context, data) => {
      let monitoring = data.monitoring;
      let ref = db.collection("monitoring");
      ref = ref.where("active", "==", true);
      ref = ref.where("monitor.queryId", "in", monitoring);
      context.bindFirestoreRef("monitoring", ref, {
        reset: true,
      });
    }),
    unbindCustomerMonitoring: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef("customerMonitoring", true);
    }),
    bindCustomerMonitoring: firestoreAction((context, data) => {
      let customerId = data.customer;
      let ref = db.collection("monitoring");
      context.bindFirestoreRef(
        "customerMonitoring",
        ref.where("customer", "==", customerId),
        {
          reset: true,
        }
      );
    }),
    unbindCustomerMonitoringActions: firestoreAction(
      ({ unbindFirestoreRef }) => {
        unbindFirestoreRef("customerMonitoringActions", true);
      }
    ),
    bindCustomerMonitoringActions: firestoreAction((context, data) => {
      let customerId = data.customer;
      let monitoring = data.monitoring;
      let ref = db.collection("monitoring-actions");
      context.bindFirestoreRef(
        "customerMonitoringActions",
        ref
          .where("customer", "==", customerId)
          .where("monitoring", "==", monitoring),
        {
          reset: true,
        }
      );
    }),
  },
  getters: {
    historicKpis(state) {
      return state.historicKpis;
    },
    historic(state) {
      return state.historic;
    },
    monitoring(state) {
      return state.monitoring;
    },
    monitoringNewHealthAlerts(state) {
      return state.monitoringNewHealthAlerts;
    },
    monitoringImportantAnamnesisNotAnswered(state) {
      return state.monitoringImportantAnamnesisNotAnswered;
    },
    customerMonitoring(state) {
      return state.customerMonitoring;
    },
    customerMonitoringActions(state) {
      return state.customerMonitoringActions;
    },
  },
};
