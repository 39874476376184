<template>
  <v-card color="transparent" flat>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        :rotate="360"
        :size="100"
        :width="8"
        color="white"
      >
      </v-progress-circular>
    </v-overlay>

    <v-toolbar dense rounded outlined elevation="0">
      <v-card-title class="ml-n6 grey--text text--darken-2">
        <font-awesome-icon
          :icon="['fal', 'magnifying-glass-chart']"
          fixed-width
          style="font-size: 25px"
        />Painel Inteligente de Linhas de Cuidado
      </v-card-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-1"
            @click="$router.replace({ name: 'Journeys' })"
            small
            text
            v-bind="attrs"
            v-on="on"
            elevation="0"
            color="primary"
            min-width="35"
            max-width="35"
            min-height="35"
            max-height="35"
          >
            <font-awesome-icon
              :icon="['fal', 'map-location-dot']"
              fixed-width
              style="font-size: 25px"
            />
          </v-btn>
        </template>
        <span>Linhas de Cuidado</span>
      </v-tooltip>
    </v-toolbar>
    <v-toolbar rounded outlined elevation="0" class="mt-1">
      <v-select
        color="purple"
        dense
        outlined
        :items="professionalJourneys"
        v-model="selectedProfessionalJourney"
        label="Linha de Cuidado"
        item-text="text"
        @change="(selection) => selectionChanged(selection)"
        :return-object="true"
        class="mt-6 mr-2 caption"
      >
        <template v-slot:selection="{ item, index }">
          <v-chip small outlined v-if="index === 0">
            <span class="caption">{{ item.text }}</span>
          </v-chip>
        </template>
      </v-select>
      <RoomsDashboardButton :rooms="journeyRooms"></RoomsDashboardButton>
    </v-toolbar>
    <v-card class="mt-1" width="100%" outlined min-height="17vh">
      <v-card-title class="caption mt-n3 ml-n2 font-weight-bold"
        >Visão Geral</v-card-title
      >
      <v-card-text>
        <v-row>
          <v-col>
            <v-badge
              v-if="selectedProfessionalJourney && journeysKpis"
              color="primary"
              inline
              overlap
              content="Total de Pacientes"
            >
              <v-progress-circular
                :rotate="0"
                :size="70"
                :width="10"
                :value="100"
                color="primary"
              >
                {{
                  journeysKpis[$moment().hour()][
                    selectedProfessionalJourney.queryId
                  ].active +
                  journeysKpis[$moment().hour()][
                    selectedProfessionalJourney.queryId
                  ].closed
                }}
              </v-progress-circular>
            </v-badge>
            <v-badge
              v-else
              color="grey"
              inline
              overlap
              content="Total de Pacientes"
            >
              <v-progress-circular
                :rotate="0"
                :size="70"
                :width="10"
                :value="0"
                color="grey"
              >
                {{ 0 }}
              </v-progress-circular>
            </v-badge>
            <v-badge
              v-if="selectedProfessionalJourney && journeysKpis"
              color="success"
              inline
              overlap
              content="Pacientes Ativos na Linha de Cuidado"
            >
              <v-progress-circular
                :rotate="0"
                :size="70"
                :width="10"
                :value="getActivePercentage()"
                color="success"
              >
                {{
                  journeysKpis[$moment().hour()][
                    selectedProfessionalJourney.queryId
                  ].active
                }}
              </v-progress-circular>
            </v-badge>
            <v-badge
              v-else
              color="grey"
              inline
              overlap
              content="Pacientes Ativos na Linha de Cuidado"
            >
              <v-progress-circular
                :rotate="0"
                :size="70"
                :width="10"
                :value="0"
                color="grey"
              >
                {{ 0 }}
              </v-progress-circular>
            </v-badge>
            <v-badge
              v-if="selectedProfessionalJourney && journeysKpis"
              color="warning"
              inline
              content="Pacientes que já encerraram a Linha de Cuidado"
              overlap
            >
              <v-progress-circular
                :rotate="0"
                :size="70"
                :width="10"
                :value="getClosedPercentage()"
                color="warning"
              >
                {{
                  journeysKpis[$moment().hour()][
                    selectedProfessionalJourney.queryId
                  ].closed
                }}
              </v-progress-circular>
            </v-badge>
            <v-badge
              v-else
              color="grey"
              inline
              content="Pacientes que já encerraram a Linha de Cuidado"
              overlap
            >
              <v-progress-circular
                :rotate="0"
                :size="70"
                :width="10"
                :value="0"
                color="grey"
              >
                {{ 0 }}
              </v-progress-circular>
            </v-badge>
            <v-badge
              v-if="selectedProfessionalJourney && journeysKpis"
              color="secondary"
              inline
              content="Novos pacientes (no mês)"
              overlap
            >
              <v-progress-circular
                :rotate="0"
                :size="70"
                :width="10"
                :value="getNewPercentage()"
                color="secondary"
              >
                {{
                  journeysKpis[$moment().hour()][
                    selectedProfessionalJourney.queryId
                  ].newActive
                }}
              </v-progress-circular>
            </v-badge>
            <v-badge
              v-else
              color="grey"
              inline
              content="Novos pacientes (no mês)"
              overlap
            >
              <v-progress-circular
                :rotate="0"
                :size="70"
                :width="10"
                :value="0"
                color="grey"
              >
                {{ 0 }}
              </v-progress-circular>
            </v-badge>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card
      width="100%"
      outlined
      color="grey lighten-2"
      elevation="24"
      class="mt-2"
    >
      <v-tabs
        left
        v-model="tab"
        grow
        icons-and-text
        color="secondary"
        height="35"
      >
        <v-tabs-slider color="warning"></v-tabs-slider>
        <v-tab>
          <span class="caption">Populacional</span>
        </v-tab>
        <v-tab>
          <span class="caption">Momentos da Jornada</span>
        </v-tab>
        <v-tab>
          <span class="caption">Indicadores</span>
        </v-tab>
      </v-tabs>
    </v-card>
    <v-tabs-items class="transparent" v-model="tab">
      <v-tab-item
        class="transparent"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
        <Populational
          v-if="selectedProfessionalJourney && journeysKpis"
          :kpis="
            journeysKpis[$moment().hour()][selectedProfessionalJourney.queryId]
          "
        ></Populational>
      </v-tab-item>
      <v-tab-item
        class="transparent"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
        <Moments
          v-if="selectedProfessionalJourney && journeysKpis"
          :kpis="
            journeysKpis[$moment().hour()][selectedProfessionalJourney.queryId]
          "
        ></Moments>
      </v-tab-item>
      <v-tab-item
        class="transparent"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
        <Kpis
          v-if="selectedProfessionalJourney && journeysKpis"
          :kpis="
            journeysKpis[$moment().hour()][selectedProfessionalJourney.queryId]
          "
        ></Kpis>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import Populational from "./tabs/Populational.vue";
import Kpis from "./tabs/Kpis.vue";
import Moments from "./tabs/Moments.vue";
import RoomsDashboardButton from "../../rooms/dialog/Dialog.vue";

export default {
  name: "app-monitoring",
  components: {
    //Tabs
    Populational,
    Kpis,
    Moments,
    RoomsDashboardButton,
  },
  data() {
    return {
      tab: null,
      today: null,
      professionalJourneys: [],
      selectedProfessionalJourney: null,
      overlay: false,
      journeyRooms: null,
    };
  },
  computed: {
    ...mapGetters({
      professional: "professional",
      journey: "journey",
      journeysKpis: "journeysKpis",
      rooms: "rooms",
    }),
  },
  methods: {
    getNewPercentage() {
      let percentage =
        this.journeysKpis[this.$moment().hour()][
          this.selectedProfessionalJourney.queryId
        ].newActive > 0
          ? 100
          : 0;
      return percentage;
    },
    getActivePercentage() {
      let percentage =
        this.journeysKpis[this.$moment().hour()][
          this.selectedProfessionalJourney.queryId
        ].active /
        (this.journeysKpis[this.$moment().hour()][
          this.selectedProfessionalJourney.queryId
        ].active +
          this.journeysKpis[this.$moment().hour()][
            this.selectedProfessionalJourney.queryId
          ].closed);
      percentage = 100 * percentage;
      return percentage;
    },
    getClosedPercentage() {
      let percentage =
        this.journeysKpis[this.$moment().hour()][
          this.selectedProfessionalJourney.queryId
        ].closed /
        (this.journeysKpis[this.$moment().hour()][
          this.selectedProfessionalJourney.queryId
        ].active +
          this.journeysKpis[this.$moment().hour()][
            this.selectedProfessionalJourney.queryId
          ].closed);
      percentage = 100 * percentage;
      return percentage;
    },
    async selectionChanged(selection) {
      this.overlay = true;

      if (selection.rooms && selection.rooms.length > 0) {
        this.journeyRooms = await this.rooms.filter(function (m) {
          return this.findIndex((pm) => pm == m.queryId) > -1;
        }, selection.rooms);
      } else {
        this.journeyRooms = null;
      }

      if (selection.length == 0) {
        await this.$store.dispatch("unbindJourneysKpis");
        this.overlay = false;
      }

      await this.$store.dispatch("unbindJourneysKpis");

      await this.$store.dispatch("bindJourneysKpis", {
        journey: selection.queryId,
        key: this.today,
      });
      this.overlay = false;
    },
  },
  async created() {
    this.today = this.$moment(new Date()).format("YYYY-M-D");
    this.professionalJourneys = await this.journey.filter(function (m) {
      return this.findIndex((pm) => pm.queryId == m.queryId) > -1;
    }, this.professional.journeys);
  },
};
</script>
<style lang="scss">
@import "../../../../scss/_variables.scss";

.data-studio {
  @media #{$xxlmax-device} {
    width: 100%;
    height: 600px;
  }

  @media #{$xlmax-device} {
    width: 100%;
    height: 700px;
  }

  @media #{$desktop-device} {
    width: 100%;
    height: 600px;
  }

  @media #{$tablet-device} {
    width: 100%;
    height: 800px;
  }

  @media #{$large-mobile} {
    height: 700px;
  }

  @media #{$extra-small-mobile} {
    height: 600px;
  }
}

.select {
  max-width: 100%;
  min-width: 620px;
}
</style>