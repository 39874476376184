import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

export default {
  state: {
    whatsappTemplates: [],
  },
  actions: {
    bindWhatsappTemplates: firestoreAction((context) => {
      let ref = db.collection("whatsapp-templates");
      context.bindFirestoreRef("whatsappTemplates", ref, {
        wait: true,
        reset: true,
      });
    }),
  },
  getters: {
    whatsappTemplates(state) {
      return state.whatsappTemplates;
    },
  },
};
