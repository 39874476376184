<template>
  <v-card flat class="pa-2">
    <form ref="customerInfo">
      <v-card class="mx-auto" flat outlined>
        <v-toolbar dense flat>
          <v-toolbar-title class="text-subtitle-1">
            <v-btn
              x-small
              elevation="0"
              fab
              text
              class="white--text"
              :color="getColor(customer.gender)"
            >
              <font-awesome-icon
                :icon="['fal', 'info']"
                fixed-width
                style="font-size: 20px"
              />
            </v-btn>
            <span class="font-weight-medium ml-1 mt-4">
              {{ customer.fullName }}</span
            ></v-toolbar-title
          >

          <v-spacer></v-spacer>
        </v-toolbar>

        <v-divider></v-divider>
        <v-tabs v-model="tab" icons-and-text class="rounded" height="60">
          <v-tabs-slider color="warning"></v-tabs-slider>
          <v-tab>Geral</v-tab>
          <v-tab>Documentos</v-tab>
          <v-tab :disabled="hasOccupationalData">Ocupacional</v-tab>
          <v-tab :disabled="hasHealthcarePayer">Plano de Saúde</v-tab>
        </v-tabs>
        <v-tabs-items class="transparent" v-model="tab">
          <v-tab-item class="rounded pa-2 mt-2">
            <v-row
              ><v-col cols="6">
                <v-text-field
                  label="Celular"
                  placeholder="11999999999"
                  outlined
                  v-model="customer.phone"
                ></v-text-field>
                <v-text-field
                  label="Sexo"
                  placeholder=""
                  outlined
                  v-model="customer.gender"
                ></v-text-field>
                <v-textarea
                  label="Endereço"
                  placeholder=""
                  outlined
                  v-model="address"
                ></v-textarea>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Data nascimento"
                  placeholder="Data nascimento"
                  outlined
                  v-model="customer.birthdate"
                ></v-text-field>
                <v-text-field
                  label="Identificação"
                  placeholder="Identificação"
                  outlined
                  v-model="customer.userId"
                ></v-text-field> </v-col
            ></v-row>
          </v-tab-item>
          <v-tab-item class="rounded pa-2 mt-2">
            <v-text-field
              label="CPF"
              placeholder="CPF"
              outlined
              v-model="customer.cpf"
            ></v-text-field>
            <v-text-field
              label="RG"
              placeholder="RG"
              outlined
              v-model="customer.rg"
            ></v-text-field>
          </v-tab-item>
          <v-tab-item class="rounded pa-2 mt-2">
            <v-row
              ><v-col cols="6">
                <v-text-field
                  label="Empresa"
                  placeholder=""
                  outlined
                  v-model="companyName"
                ></v-text-field>
                <v-text-field
                  label="CNPJ"
                  placeholder=""
                  outlined
                  v-model="cnpj"
                ></v-text-field>
                <v-text-field
                  label="Cargo"
                  placeholder=""
                  outlined
                  v-model="jobTitle"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Departamento"
                  placeholder=""
                  outlined
                  v-model="department"
                ></v-text-field> </v-col
            ></v-row>
          </v-tab-item>
          <v-tab-item class="rounded pa-2 mt-2">
            <v-row
              ><v-col cols="6">
                <v-text-field
                  label="Plano de Saúde"
                  placeholder=""
                  outlined
                  v-model="healthcarePayerName"
                ></v-text-field>
                <v-text-field
                  label="Número do Beneficiário"
                  placeholder=""
                  outlined
                  v-model="healthcarePayerCode"
                ></v-text-field>
                <v-text-field
                  label="Segmentação"
                  placeholder=""
                  outlined
                  v-model="healthcarePayerSegmentation"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Acomodação"
                  placeholder=""
                  outlined
                  v-model="healthcarePayerAccommodation"
                ></v-text-field>
                <v-text-field
                  label="Rede de Atendimento"
                  placeholder=""
                  outlined
                  v-model="healthcarePayerNetwork"
                ></v-text-field>
                <v-text-field
                  label="Inclusão"
                  placeholder=""
                  outlined
                  v-model="healthcarePayerDate"
                ></v-text-field> </v-col
            ></v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </form>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      tab: null,
    };
  },
  methods: {
    getColor(gender) {
      if (gender === "Feminino") return "pink";
      else return "primary";
    },
  },
  computed: {
    ...mapGetters({
      customer: "customer",
    }),
    address() {
      if (this.customer.address) {
        return (
          this.customer.address +
          ", " +
          this.customer.addressNumber +
          ", " +
          this.customer.addressComplement +
          "\n" +
          this.customer.neighborhood +
          " - " +
          this.customer.city +
          " - " +
          this.customer.state
        );
      } else {
        return "";
      }
    },
    hasOccupationalData() {
      if (
        this.customer.occupationalData == null ||
        this.customer.occupationalData.company == null ||
        this.customer.occupationalData.company === ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    department() {
      if (
        this.customer.occupationalData == null ||
        this.customer.occupationalData.department == null ||
        this.customer.occupationalData.department === ""
      ) {
        return "";
      } else {
        return this.customer.occupationalData.department;
      }
    },
    jobTitle() {
      if (
        this.customer.occupationalData == null ||
        this.customer.occupationalData.jobTitle == null ||
        this.customer.occupationalData.jobTitle === ""
      ) {
        return "";
      } else {
        return this.customer.occupationalData.jobTitle;
      }
    },
    cnpj() {
      if (
        this.customer.occupationalData == null ||
        this.customer.occupationalData.cnpj == null ||
        this.customer.occupationalData.cnpj === ""
      ) {
        return "";
      } else {
        return this.customer.occupationalData.cnpj;
      }
    },
    companyName() {
      if (
        this.customer.occupationalData == null ||
        this.customer.occupationalData.companyName == null ||
        this.customer.occupationalData.companyName === ""
      ) {
        return "";
      } else {
        return this.customer.occupationalData.companyName;
      }
    },
    hasHealthcarePayer() {
      if (
        this.customer.healthcarePayer == null ||
        this.customer.healthcarePayer.name == null ||
        this.customer.healthcarePayer.name === ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    healthcarePayerName() {
      if (
        this.customer.healthcarePayer == null ||
        this.customer.healthcarePayer.name == null ||
        this.customer.healthcarePayer.name === ""
      ) {
        return "";
      } else {
        return this.customer.healthcarePayer.name;
      }
    },
    healthcarePayerCode() {
      if (
        this.customer.healthcarePayer == null ||
        this.customer.healthcarePayer.code == null ||
        this.customer.healthcarePayer.code === ""
      ) {
        return "";
      } else {
        return this.customer.healthcarePayer.code;
      }
    },
    healthcarePayerSegmentation() {
      if (
        this.customer.healthcarePayer == null ||
        this.customer.healthcarePayer.segmentation == null ||
        this.customer.healthcarePayer.segmentation === ""
      ) {
        return "";
      } else {
        return this.customer.healthcarePayer.segmentation;
      }
    },
    healthcarePayerAccommodation() {
      if (
        this.customer.healthcarePayer == null ||
        this.customer.healthcarePayer.accommodation == null ||
        this.customer.healthcarePayer.accommodation === ""
      ) {
        return "";
      } else {
        return this.customer.healthcarePayer.accommodation;
      }
    },
    healthcarePayerNetwork() {
      if (
        this.customer.healthcarePayer == null ||
        this.customer.healthcarePayer.network == null ||
        this.customer.healthcarePayer.network === ""
      ) {
        return "";
      } else {
        return this.customer.healthcarePayer.network;
      }
    },
    healthcarePayerDate() {
      if (
        this.customer.healthcarePayer == null ||
        this.customer.healthcarePayer.date == null ||
        this.customer.healthcarePayer.date === ""
      ) {
        return "";
      } else {
        return this.customer.healthcarePayer.date;
      }
    },
  },
  mounted() {},
  destroy() {
    this.$refs.customerInfo.reset();
  },
};
</script>

<style>
</style>