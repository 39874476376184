import { firestoreAction } from "vuexfire";
import { db, functions } from "../../firebase";

export default {
  state: {
    immunizations: [],
  },
  mutations: {
    SET_IMMUNIZATIONS(state, data) {
      state.immunizations = data;
    },
  },
  actions: {
    fetchImmunizations(context, info) {
      let customer = info.customer;
      let getEhrImmunization = functions.httpsCallable(
        "sis-ehr-get-history-api"
      );
      getEhrImmunization({ customer: customer, history: "immunization" })
        .then(async (result) => {
          context.commit("SET_IMMUNIZATIONS", result.data.items);
        })
        .catch((error) => {
          console.error(error.message);
        });
    },
  },
  getters: {
    immunizations(state) {
      return state.immunizations;
    },
  },
};
