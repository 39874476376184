import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

export default {
  state: {
    journey: [],
  },
  actions: {
    unbindJourney: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef("journey", true);
    }),
    bindJourney: firestoreAction((context, data) => {
      let ref = db.collection("journey");
      context.bindFirestoreRef("journey", ref, { reset: true });
    }),
  },
  getters: {
    journey(state) {
      return state.journey;
    },
  },
};
