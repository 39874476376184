<template>
  <iframe id="doxyTelemedicine" frameborder="0" height="680" width="100%" scrolling="no"
    src="https://icareyou.doxy.me/sign-in" allowusermedia allow="camera *;microphone *"></iframe>
</template>

<script>
export default {
  components: {},
  name: "Doxy",
  computed: {},
  methods: {},
  created() {
    this.$refs['doxyTelemedicine'].contentWindow.location.reload();
  },
};
</script>

<style>

</style>