<template>
  <v-dialog v-model="dialog" max-width="1200" eager>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
            elevation="0"
            small
            text
            color="primary"
            min-width="35"
            max-width="35"
            min-height="35"
            max-height="35"
          >
            <font-awesome-icon
              :icon="['fal', 'camera-web']"
              fixed-width
              style="font-size: 25px"
            />
          </v-btn>
        </template>
        <span>Realizar consulta</span>
      </v-tooltip>
    </template>
    <v-card></v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    patient: {
      type: String,
      required: false,
      default: null,
    },
  },
  data: () => ({
    dialog: false,
  }),
  components: {},
  computed: {
    ...mapGetters({}),
  },
  watch: {
    async dialog(visible) {
      if (visible) {
        if (this.patient) {
          await this.$store.dispatch("fetchCustomer", {
            customer: this.patient,
          });
        }
      } else {
        if (this.patient) {
          this.$store.dispatch("resetCustomerState");
        }
      }
    },
  },
  methods: {},
  destroyed() {},
};
</script>

<style>
</style>