<template>
  <v-card color="transparent" tile flat class="d-flex justify-center">
    <v-row>
      <v-col class="d-flex justify-center align-self-baseline flex-wrap">
        <div v-for="card in suggestions" :key="card.id">
          <HealthSuggestionCard
            :id="card.id"
            :card="card"
          ></HealthSuggestionCard>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import HealthSuggestionCard from "./HealthSuggestionCard";
import compare from "compare-arrays";

export default {
  data() {
    return {
      suggestions: [],
      answers: [],
      ai: [
        {
          answers: [
            "jaudice",
            "rash",
            "bleeding-skin",
            "vomit",
            "diarrhea",
            "fever",
          ],
          diagnostics: ["A01.0", "A27.9", "B15.9", "B65.9", "C22.9", "C24.9"],
        },
        {
          answers: ["hematemesis", "hypotonia", "dyspnea", "cough"],
          diagnostics: ["A95.9", "C16.9", "K27.9", "K56.6", "T18.9", "Y19.9"],
        },
        {
          answers: [
            "abnormal-menstrual-cycle",
            "Menorrhagia",
            "hypotension",
          ],
          diagnostics: ["C53.9", "D69.3", "N21.9", "N73.9", "O00.9", "O45.9"],
        },
      ],
    };
  },
  mounted() {
    for (let index = 0; index < this.ai.length; index++) {
      if (
        compare(
          this.answer.sort((a, b) => (a < b ? 1 : -1)),
          this.ai[index].answers.sort((a, b) => (a < b ? 1 : -1))
        )
      ) {
        for (let idx = 0; idx < this.ai[index].diagnostics.length; idx++) {
          this.suggestions.push(
            this.knowledgebase.filter(
              (kb) => kb.id == this.ai[index].diagnostics[idx]
            )[0]
          );
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      knowledgebase: "knowledgebase",
      datasource: "datasource",
    }),
  },
  watch: {},
  methods: {},
  components: {
    HealthSuggestionCard,
  },
  created() {
    this.answer = this.datasource["findings"].filter(
      (finding) => finding.data.value == "yes"
    );
    this.answer = this.answer.map(function (a) {
      return a.resource.key;
    });
  },
};
</script>

<style></style>
