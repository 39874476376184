var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"flat":"","color":"transparent","outlined":""}},[_c('v-card',{staticClass:"mt-2 d-flex justify-center align-content-center flex-wrap transparent",attrs:{"width":"100%","flat":""}},[_c('BarChart',{attrs:{"info":_vm.kpis.population.gender,"chartTitle":'Pacientes por Sexo'}}),_vm._v(" "),_c('LineChart',{attrs:{"seriesInfo":[
        {
          name: 'Pacientes',
          series: _vm.kpis.population.age,
          color: '#6fa8dc',
        },
      ],"chartTitle":'Pacientes por Idade',"xaxisTitle":"Idade"}}),_vm._v(" "),_c('LineChart',{attrs:{"chartTitle":'Pacientes do Sexo Feminino por Idade',"seriesInfo":[
        {
          name: 'Feminino',
          series: _vm.kpis.population.genderByAge.Feminino,
          color: '#a64d79',
        },
      ],"xaxisTitle":"Idade"}}),_vm._v(" "),_c('LineChart',{attrs:{"chartTitle":'Pacientes do Sexo Masculino por Idade',"seriesInfo":[
        {
          name: 'Masculino',
          series: _vm.kpis.population.genderByAge.Masculino,
          color: '#16537e'
        },
      ],"xaxisTitle":"Idade"}}),_vm._v(" "),_c('BarChart',{attrs:{"info":_vm.kpis.population.city,"chartTitle":'Pacientes por Cidade'}}),_vm._v(" "),_c('BarChart',{attrs:{"info":_vm.kpis.population.state,"chartTitle":'Pacientes por Estado'}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }