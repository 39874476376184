<template>
  <v-card
    class="mr-4 mt-4 d-flex flex-column"
    width="320"
    min-height="165"
    elevation="3"
  >
    <div class="d-flex flex-no-wrap justify-space-between">
      <div>
        <v-card-title class="text-subtitle-2 secondary--text text--darken-1">
          <span class="ml-1 mb-1">{{ kpi.description }}</span>
        </v-card-title>
      </div>
      <v-avatar size="90" rounded class="ma-3" color="grey lighten-5">
        <span
          class="secondary--text text--darken-1 text-h4 mx-0 font-weight-black"
          >{{ kpi.data.true ? kpi.data.true : 0 }}</span
        >
      </v-avatar>
    </div>
    <v-divider></v-divider>
    <v-card-actions>
      <v-chip
        label
        small
        outlined
        color="purple"
        class="mb-n8 text-left text-caption font-weight-black purple--text"
        ><font-awesome-icon
          :icon="['fal', 'gauge-max']"
          fixed-width
          style="font-size: 16px"
        /><span class="ml-1 caption"
          >População:
          {{
            kpi.data.true
              ? ((kpi.data.true / (kpis.active + kpis.closed)) * 100).toFixed(2)
              : 0
          }}%</span
        ></v-chip
      >
      <v-spacer></v-spacer>

      <v-chip
        v-if="kpi.patients && kpi.patients.length > 0"
        label
        small
        color="warning"
        @click="getPatientList()"
        class="ml-4 mb-n8 text-left text-caption font-weight-black"
      >
        <span class="ml-1 caption">Pacientes</span>
      </v-chip>
    </v-card-actions>
    <v-dialog v-model="dialog" max-width="1200" eager>
      <v-card flat class="pa-2">
        <v-card class="mx-auto" flat outlined>
          <v-toolbar dense flat>
            <v-toolbar-title class="text-subtitle-1">
              <font-awesome-icon
                :icon="['fal', 'gauge-max']"
                fixed-width
                style="font-size: 16px"
              />
              <span class="font-weight-medium ml-1 mt-4">
                {{ kpi.description }}</span
              ></v-toolbar-title
            >

            <v-spacer></v-spacer>
          </v-toolbar>
          <v-divider></v-divider>
          <v-text-field
            color="purple"
            dense
            outlined
            v-model="search"
            append-icon="mdi-magnify"
            label="Nome, contato ou identificador do paciente"
            single-line
            hide-details
            clearable
            class="ma-2 caption"
          >
          </v-text-field>
          <v-data-table
            class="elevation-0"
            :headers="headers"
            :items="patients"
            :search="search"
            :items-per-page="5"
            :sort-by="['fullName']"
            :sort-desc="[false]"
            :calculate-widths="true"
          >
            <template v-slot:[`item.userId`]="{ item }">
              <span class="caption">{{ item.userId }}</span>
            </template>

            <template v-slot:[`item.age`]="{ item }">
              <span class="caption">{{
                formatBirthdate(item.birthdate) | moment("from", "now", true)
              }}</span>
            </template>
            <template v-slot:[`item.gender`]="{ item }">
              <span class="caption">{{ item.gender }}</span>
            </template>
            <template v-slot:[`item.birthdate`]="{ item }">
              <span class="caption">{{ item.birthdate }}</span>
            </template>
            <template v-slot:[`item.phone`]="{ item }">
              <span class="caption">{{ formatPhone(item.phone) }}</span>
            </template>
            <template v-slot:[`item.features`]="{ item }">
              <ActionsMenu
                :patient="item.id"
                :name="item.fullName"
              ></ActionsMenu>
            </template>
            <template v-slot:[`item.fullName`]="{ item }">
              <span class="caption">{{ item.fullName }}</span>
            </template>
          </v-data-table>
        </v-card>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { functions } from "../../../../../firebase";
import { formatToCPF, formatToPhone } from "brazilian-values";
import ActionsMenu from "../../../ehr/components/actions/Menu.vue";

export default {
  components: {
    ActionsMenu,
  },
  props: {
    kpi: {
      type: Object,
      required: true,
      default: null,
    },
    kpis: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      patients: [],
      dialog: false,
      search: "",
      headers: [
        { text: "Nome", value: "fullName" },
        { text: "Sexo", value: "gender" },
        { text: "Idade", value: "age" },
        { text: "Nascimento", value: "birthdate" },
        { text: "Contato", value: "phone" },
        { text: "Identificador", value: "userId" },
        { text: "Ações", value: "features", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    async getPatientList() {
      this.dialog = true;
      let sisKpiCustomerList = functions.httpsCallable(
        "sis-kpi-customer-list-api"
      );
      await sisKpiCustomerList({
        patients: this.kpi.patients,
      })
        .then(async (result) => {
          console.info(result.data.patients);
          this.patients = result.data.patients;
        })
        .catch((error) => {
          console.error(error.message);
        });
    },
    formatBirthdate(birthdate) {
      return this.$moment(birthdate, "DD/MM/YYYY");
    },
    getColor(gender) {
      if (gender === "Feminino") return "pink";
      else return "primary";
    },
    formatCPF(item) {
      return formatToCPF(item);
    },
    formatPhone(item) {
      return formatToPhone(item);
    },
  },
  mounted() {},
};
</script>

<style>
</style>