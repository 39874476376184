<template>
  <v-card
    v-if="waitingRoomKpis"
    flat
    color="transparent"
    class="pa-1 d-flex justify-center align-content-center flex-wrap transparent"
    outlined
  >
    <v-card
      class="d-flex justify-center align-content-center flex-wrap transparent"
      width="100%"
      flat
    >
      <v-select
        color="purple"
        dense
        outlined
        clearable="true"
        :items="waitingRoomKpis.professionals"
        v-model="practitioner"
        label="Profissional"
        :return-object="true"
        @change="(selection) => selectionChanged(selection)"
        class="mt-6 caption"
      >
        <template v-slot:selection="{ item, index }">
          <v-chip small outlined v-if="index === 0">
            <span class="caption">{{ item.text }}</span>
          </v-chip>
        </template>
      </v-select>
    </v-card>
    <v-card
      v-if="practitioner && info"
      class="mt-n5"
      width="100%"
      outlined
      min-height="5vh"
    >
      <v-card-title class="caption mt-n3 ml-n2 font-weight-bold"
        >Visão Geral dos Atendimentos de: {{ practitioner.text }}</v-card-title
      >
      <v-card-text>
        <v-row>
          <v-col>
            <v-badge
              color="primary"
              inline
              overlap
              content="Total de Atendimentos"
            >
              <v-progress-circular
                :rotate="0"
                :size="70"
                :width="10"
                :value="100"
                color="primary"
              >
                {{ info.closed }}
              </v-progress-circular>
            </v-badge>
            <v-badge
              color="primary"
              inline
              overlap
              content="de Atendimentos com Atrasos Acima de 10 Minutos"
            >
              <v-progress-circular
                :rotate="0"
                :size="70"
                :width="10"
                :value="100"
                color="primary"
              >
                {{ getWaitPercentage() }}%
              </v-progress-circular>
            </v-badge>
            <v-badge
              color="secondary"
              inline
              overlap
              content="Tempo Médio (minutos)"
            >
              <v-progress-circular
                :rotate="0"
                :size="70"
                :width="10"
                :value="100"
                color="secondary"
              >
                {{
                  [$ss.mean(info.encounter.times), "seconds"]
                    | duration("minutes")
                }}
              </v-progress-circular>
            </v-badge>
            <v-badge
              color="warning"
              inline
              overlap
              content="Tempo Médio de Espera (minutos)"
            >
              <v-progress-circular
                :rotate="0"
                :size="70"
                :width="10"
                :value="100"
                color="warning"
              >
                {{
                  [$ss.mean(info.encounter.waittimes), "seconds"]
                    | duration("minutes")
                }}
              </v-progress-circular>
            </v-badge>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card
      v-if="practitioner"
      class="mt-2 d-flex justify-center align-content-center flex-wrap transparent"
      width="100%"
      flat
    >
      <RoomOverviewBarChart :info="info" :month="month"></RoomOverviewBarChart>
      <MeanTimeBarChart :info="info" :month="month"></MeanTimeBarChart>
      <WaitTimeBarChart :info="info" :month="month"></WaitTimeBarChart>
      <EnterTheRoomTimeBarChart
        :info="info"
        :month="month"
      ></EnterTheRoomTimeBarChart>
      <DelayTimeBarChart :info="info" :month="month"></DelayTimeBarChart>
    </v-card>
  </v-card>
</template>      

<script>
import { mapGetters } from "vuex";
import ActionsMenu from "../../ehr/components/actions/Menu.vue";
import RoomOverviewBarChart from "../chart/RoomOverviewBarChart.vue";
import MeanTimeBarChart from "../chart/MeanTimeBarChart.vue";
import WaitTimeBarChart from "../chart/WaitTimeBarChart.vue";
import EnterTheRoomTimeBarChart from "../chart/EnterTheRoomTimeBarChart.vue";
import DelayTimeBarChart from "../chart/DelayTimeBarChart.vue";
import LineChart from "../chart/LineChart.vue";

export default {
  name: "kpis",
  components: {
    ActionsMenu,
    RoomOverviewBarChart,
    MeanTimeBarChart,
    WaitTimeBarChart,
    EnterTheRoomTimeBarChart,
    DelayTimeBarChart,
    LineChart,
  },
  props: {
    month: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      practitioner: null,
      info: null,
    };
  },
  computed: {
    ...mapGetters({
      professional: "professional",
      waitingRoomKpis: "waitingRoomKpis",
    }),
  },
  watch: {
    waitingRoomKpis() {
      this.practitioner = null;
      this.info = null;
    },
  },
  methods: {
    async selectionChanged(selection) {
      this.info = this.waitingRoomKpis.practitioners[selection.value];
    },
    getWaitPercentage() {
      let delays = this.info.encounter.waittimes.filter((val) => val / 60 > 10);
      let percentage = (delays.length / this.info.closed) * 100;
      return percentage.toFixed(0);
    },
    getMeanTime() {
      let duration = this.$moment.duration(
        this.$ss.mean(this.info.encounter.times),
        "seconds"
      );
      let formatted = duration.format("hh:mm:ss");
      return 1;
    },
  },
  mounted() {
    this.info = null;
    this.practitioner = null;
  },
};
</script>
<style lang="scss">
@import "../../../../scss/variables";

.data-studio {
  @media #{$xxlmax-device} {
    width: 100%;
    height: 600px;
  }

  @media #{$xlmax-device} {
    width: 100%;
    height: 700px;
  }

  @media #{$desktop-device} {
    width: 100%;
    height: 600px;
  }

  @media #{$tablet-device} {
    width: 100%;
    height: 800px;
  }

  @media #{$large-mobile} {
    height: 700px;
  }

  @media #{$extra-small-mobile} {
    height: 600px;
  }
}
</style>