<template>
  <v-card flat ref="form">
    <TiptapVuetify
      min-height="500"
      id="note"
      v-model="healthNote"
      :extensions="extensions"
      :card-props="{ flat: true, color: 'grey lighten-5' }"
    />
    <small>*indica campos obrigatórios</small>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { functions } from "../../../../../firebase";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";

export default {
  components: {
    TiptapVuetify,
  },
  computed: {
    ...mapGetters({}),
    healthNote: {
      get() {
        return this.$store.getters.healthNote;
      },
      set(value) {
        this.$store.dispatch("setHealthNote", value);
      },
    },
  },
  data: () => ({
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3, 4, 5],
          },
        },
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
    ],
    note: null,
    dialog: false,
    overlay: false,
  }),
  methods: {},
  created() {},
  beforeDestroy() {},
};
</script>