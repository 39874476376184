<template>
  <v-card color="transparent" class="pa-2" flat>
    <v-toolbar color="white" class="rounded" height="60" width="100%" elevation="0">
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Recurso" single-line hide-details
        clearable></v-text-field>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-data-table class="elevation-0" :headers="headers" :items="filteredArray" :search="search" :items-per-page="8"
      :sort-by="['created']" :sort-desc="[false]" :calculate-widths="false">
      <template v-slot:[`item.choose`]="{ item }">
        <AddButton :resourceValue="item"></AddButton>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import AddButton from "./Add.vue";

export default {
  components: {
    AddButton,
  },
  props: {
    filter: String,
    default: null,
  },
  data() {
    return {
      filteredArray: [],
      overlay: false,
      search: "",
      load: 0,
      headers: [
        { text: "Escolher", value: "choose", sortable: false },
        { text: "Descrição", value: "text" },
        //{ text: "Categoria", value: "category" },
        //{ text: "Subcategoria", value: "subCategory" },
        //{ text: "Item", value: "item" },
        //{ text: "Chave", value: "key" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      resources: "resources",
    }),
  },
  watch: {
    search(newValue, oldValue) {
      if (newValue.text) {
        this.search = newValue.text;
      } else {
        this.search = newValue;
      }
    },
  },
  methods: {},
  mounted() {
    if (this.filter) {
      this.filteredArray = this.resources.filter(
        (rs) => rs.subCategory == this.filter
      );
    } else {
      this.filteredArray = this.resources;
    }
  },
};
</script>
