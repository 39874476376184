import {firestoreAction} from "vuexfire";
import {db, functions, FieldValue} from "../../firebase";

export default {
    state: {
        interview: null,
        datasource: {}
    },
    mutations: {
        SET_INTERVIEW(state, value) {
            state.interview = value;
        },

        SET_DATASOURCE(state, value) {
            state.datasource = value;
        }
    },
    actions: {
        async saveInterviewDraft(context, info) {
            let customer = info.customer;
            let interview = info.interview;

            let ehrRef = db.collection("electronic-health-record").doc(customer);
            let interviewRef = ehrRef.collection("interviews").doc(interview);
            let diagnosticReportRef = interviewRef.collection("diagnostics").doc("diagnostic-report").collection("reports").doc("draft");

            let diagnosticReport = {};

            /*let items = context.getters.datasource["findings"].filter((ds) => {
                return ds.data.value === "yes";
            });*/
            let items = context.getters.datasource["findings"];
            diagnosticReport.previousQuestionnaireResponses = [];
            diagnosticReport.previousQuestionnaireResponses = items.map((ds) => {
                return(ds.view.text + ": " + (
                ds.data.text ? ds.data.text : ds.data.value
            ));
            });

            diagnosticReport.pastHistoryOfCurrentDisease = context.getters.pastHistoryOfCurrentDisease ? context.getters.pastHistoryOfCurrentDisease : "";
            diagnosticReport.diagnosticHypothesis = context.getters.diagnosticHypothesis ? context.getters.diagnosticHypothesis : "";
            diagnosticReport.diagnosticHypothesisCid = context.getters.diagnosticHypothesisCid ? context.getters.diagnosticHypothesisCid : [];
            diagnosticReport.medicalHandling = context.getters.medicalHandling ? context.getters.medicalHandling : [];

            diagnosticReportRef.set(diagnosticReport, {merge: true});
        },
        async fetchInterview(context, info) {
            let queue = info.queue;
            let view = info.view;

            let ehrRef = db.collection("electronic-health-record").doc(queue.uid);
            let queueRef = db.collection("interviews").doc(queue.id);

            if (! queue.interview) {
                let newInterview = {};
                newInterview.status = "new";
                newInterview.anamnesis = "";
                newInterview.lastStep = "";
                newInterview.practitioner = [];
                newInterview.created = FieldValue.serverTimestamp();
                newInterview.updated = FieldValue.serverTimestamp();

                await ehrRef.collection("interviews").add(newInterview).then(async (interview) => {
                    queue.interview = interview.id
                    await ehrRef.update({lastInterview: interview.id});
                    await queueRef.update({interview: interview.id});
                });
            }

            let interviewRef = ehrRef.collection("interviews").doc(queue.interview);

            let interviewData = await interviewRef.get();
            let itw = interviewData.data();
            itw.id = interviewData.id;

            context.commit("SET_INTERVIEW", itw);

            // Get Diagnostic Report Draft
            let diagnosticReportDraftRef = interviewRef.collection("diagnostics").doc("diagnostic-report").collection("reports").doc("draft");
            let diagnosticReportDraftData = await diagnosticReportDraftRef.get();
            await context.dispatch("setPastHistoryOfCurrentDisease", diagnosticReportDraftData.data() ? diagnosticReportDraftData.data().pastHistoryOfCurrentDisease : null);
            await context.dispatch("setDiagnosticHypothesis", diagnosticReportDraftData.data() ? diagnosticReportDraftData.data().diagnosticHypothesis : null);
            await context.dispatch("setDiagnosticHypothesisCid", diagnosticReportDraftData.data() ? diagnosticReportDraftData.data().diagnosticHypothesisCid : null);
            await context.dispatch("setMedicalHandling", diagnosticReportDraftData.data() ? diagnosticReportDraftData.data().medicalHandling : null);

            // Get Datasource
            let interviewDatasourceGet = functions.httpsCallable("sis-interview-datasource-get-api");

            await interviewDatasourceGet({customer: queue.uid, interview: queue.interview, view: view}).then(async (result) => {
                context.commit("SET_DATASOURCE", result.data.datasource);
            }).catch((error) => {
                console.error(error.message);
            });
        }
    },
    getters: {
        interview(state) {
            return state.interview;
        },
        datasource(state) {
            return state.datasource;
        }
    }
};
