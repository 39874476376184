<template>
  <v-dialog v-model="dialog" max-width="980">
    <template v-slot:activator="{ on, attrs }">
      <v-btn :disabled="!professional.memedActive" small class="mr-n3" height="25" dark color="primary" elevation="0"
        center v-bind="attrs" v-on="on">
        <font-awesome-icon class="mr-2" color="white" :icon="['fal', 'pills']" fixed-width style="font-size: 20px" />
        Prescrição
      </v-btn>
    </template>
    <v-card>
      <Prescription></Prescription>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import Prescription from "./Prescription.vue";

export default {
  data: () => ({
    dialog: false,
  }),
  components: { Prescription },
  computed: {
    ...mapGetters({
      professional: "professional"
    }),
  },
  watch: {},
  methods: {},
  destroyed() { },
};
</script>

<style>

</style>
