import v1 from "../../../../components/functional/ehr/views/endocrinology/v1/View.vue";
import v2 from "../../../../components/functional/ehr/views/endocrinology/v2/View.vue";

export default[
    {
        path: "view/endocrinology-v1",
        name: "endocrinology-v1",
        component: v1,
        meta: {
            requiresAuth: true
        },
        children: []
    }, {
        path: "view/endocrinology-v2",
        name: "endocrinology-v2",
        component: v2,
        meta: {
            requiresAuth: true
        },
        children: []
    },
];
