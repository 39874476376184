import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

export default {
  state: {
    evaluations: [],
    customerEvaluations: [],
  },
  actions: {
    unbindEvaluations: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef("evaluations", true);
    }),
    bindEvaluations: firestoreAction((context, data) => {
      let evaluators = data.evaluators;
      let alert = data.alert;
      let ref = db.collection("evaluations");
      ref = ref.where("active", "==", true);
      ref = ref.where("evaluator", "in", evaluators);
      if (alert) {
        ref = ref.where("alerts.0", "!=", null);
      }

      context.bindFirestoreRef("evaluations", ref, {
        reset: true,
      });
    }),
    unbindCustomerEvaluations: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef("customerEvaluations", true);
    }),
    bindCustomerEvaluations: firestoreAction((context, data) => {
      let customerId = data.customer;
      let ref = db.collection("evaluations");
      context.bindFirestoreRef(
        "customerEvaluations",
        ref.where("customer", "==", customerId),
        {
          reset: true,
        }
      );
    }),
  },
  getters: {
    evaluations(state) {
      return state.evaluations;
    },
    customerEvaluations(state) {
      return state.customerEvaluations;
    },
  },
};
