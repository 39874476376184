<template>
  <v-card color="transparent" flat>
    <patientInfo></patientInfo>
    <v-card-title>
      <AddButton></AddButton>
      <v-spacer></v-spacer>
      <v-text-field class="mr-n4 caption" dense outlined color="purple" v-model="search" append-icon="mdi-magnify"
        label="Menssagens" single-line hide-details clearable></v-text-field>
    </v-card-title>

    <v-data-table class="elevation-0" :headers="headers" :items="notifications" :search="search" :items-per-page="10"
      :sort-by="['created']" :sort-desc="[true]" :calculate-widths="true">
      <template v-slot:[`item.created`]="{ item }">
        <v-chip label color="secondary" x-small>{{
        item.created.toDate() | moment("from", "now")
        }}</v-chip>
      </template>
      <template v-slot:[`item.messageStatus`]="{ item }">
        <font-awesome-icon v-if="item.messageStatus === 'READ'" :icon="['fal', 'check-double']" fixed-width
          class="secondary--text" style="font-size: 18px" />
        <font-awesome-icon v-if="item.messageStatus === 'SENT'" :icon="['fal', 'check']" fixed-width color="grey--text"
          style="font-size: 18px" />
        <font-awesome-icon v-if="item.messageStatus === 'DELIVERED'" :icon="['fal', 'check-double']" fixed-width
          color="grey--text" style="font-size: 18px" />
      </template>
      <template v-slot:[`item.details`]="{ item }">
        <span class="caption">{{
        item.text
        }}</span>
      </template>
      <template v-slot:[`item.queryId`]="{ item }">
        <span class="caption">{{
        item.name
        }}</span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import AddButton from "./Add.vue";
import patientInfo from "../identification/Identification.vue";

export default {
  components: {
    AddButton,
    patientInfo,
  },
  data() {
    return {
      overlay: false,
      search: "",
      load: 0,
      headers: [
        {
          text: "text",
          value: "text",
          filterable: true,
          sortable: false,
          align: " d-none",
        },
        {
          text: "name",
          value: "name",
          filterable: true,
          sortable: false,
          align: " d-none",
        },
        {
          text: "Enviado",
          value: "created",
          filterable: false,
          sortable: false,
        },
        { text: "Nome", value: "queryId", filterable: false, sortable: false },
        { text: "Texto", value: "details", filterable: false, sortable: false, width:"80%" },
        {
          text: "Status",
          value: "messageStatus",
          filterable: false,
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      notifications: "notifications",
      customer: "customer",
    }),
  },
  watch: {
    search(newValue, oldValue) {
      if (newValue.text) {
        this.search = newValue.text;
      } else {
        this.search = newValue;
      }
    },
  },
  methods: {},

  async destroyed() {
    await this.$store.dispatch("unbindNotifications");
  },
};
</script>
