var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"color":"transparent ml-5 mr-5","flat":"","tile":""}},[_c('v-card-title',[_vm._v("\n    Conversas\n    "),_c('v-spacer')],1),_vm._v(" "),_c('vue-advanced-chat',{attrs:{"height":"calc(80vh)","current-user-id":_vm.professional.id,"message-actions":_vm.messageActions,"templates-text":_vm.templatesText,"text-messages":_vm.textMessages,"showSearch":true,"show-files":true,"show-audio":true,"show-emojis":true,"show-reaction-emojis":true,"show-new-messages-divider":true,"show-footer":true,"text-formatting":true,"textarea-action-enabled":true,"single-room":true,"rooms":_vm.rooms,"rooms-loaded":true,"accepted-files":"image/png, image/jpeg, application/pdf","messages":_vm.chat.messages,"messages-loaded":_vm.messagesLoaded,"styles":{
      general: {
        color: '#0a0a0a',
        colorSpinner: '#333',
        borderStyle: '1px solid #e1e4e8',
      },
    
      container: {
        boxShadow:
          '0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)',
      },
    
      content: {
        background: '#f8f8f8',
      },
    
      footer: {
        background: '#ffffff',
        backgroundReply: 'rgba(0, 0, 0, 0.08)',
      },
    
      icons: {
        search: '#9ca6af',
      },
    }},on:{"send-message":_vm.sendMessage,"fetch-messages":_vm.fetchMessages}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }