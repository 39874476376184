<template>
  <div
    v-if="
      monitor.action == 'anamnesis' &&
      monitor.cancelled == true &&
      anamnesisNotAnsweredAlerts > 0
    "
  >
    <v-dialog v-model="dialog" width="700" eager>
      <template v-slot:activator="{ on: dialog, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              @click="resend()"
              class="mr-1"
              v-bind="attrs"
              v-on="{ ...tooltip, ...dialog }"
              elevation="0"
              x-small
              text
              color="primary"
              min-width="35"
              max-width="35"
              min-height="35"
              max-height="35"
            >
              <font-awesome-icon
                :icon="['fal', 'file-import']"
                fixed-width
                style="font-size: 25px"
              />
            </v-btn>
          </template>
          <span>REENVIAR QUESTIONÁRIO OBRIGATÓRIO</span>
        </v-tooltip>
      </template>
    </v-dialog>
  </div>
  <div v-else></div>
</template>

<script>
import { mapGetters } from "vuex";
import { db, functions } from "../../../../../firebase";

export default {
  components: {},
  props: {
    monitor: {
      type: Object,
      required: false,
      default: null,
    },
    alert: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      anamnesisNotAnsweredAlerts: 0,
    };
  },
  watch: {
    async monitor(newValue) {
      this.anamnesisNotAnsweredAlerts = 0;
      await this.init();
    },
  },
  computed: {
    ...mapGetters({
      customer: "customer",
      professional: "professional",
    }),
  },
  methods: {
    async init() {
      let monitorRef = db.collection("monitoring-actions").doc(this.monitor.id);

      await monitorRef
        .collection("alerts")
        .where("type", "==", "anamnesisNotAnswered")
        .where("treated", "==", false)
        .get()
        .then((snap) => {
          if (!snap.empty) {
            this.anamnesisNotAnsweredAlertsCollection = new Map(
              snap.docs.map((alert) => {
                return [alert.id, alert.data()];
              })
            );
            this.anamnesisNotAnsweredAlerts =
              this.anamnesisNotAnsweredAlertsCollection.size;
          }
        });
    },
    resend() {
      this.$swal
        .fire({
          title: "Reenviar Monitoria",
          text:
            "Você realmente deseja enviar o monitoramento do dia " +
            this.monitor.day +
            "/" +
            (this.monitor.month < 10
              ? "0" + this.monitor.month
              : this.monitor.month) +
            "/" +
            this.monitor.year +
            "?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            this.$swal.showLoading();
            this.$swal.getCancelButton().setAttribute("disabled", "");
            let sisMonitoringResend = functions.httpsCallable(
              "sis-monitoring-resend-api"
            );
            await sisMonitoringResend({
              professional: this.professional,
              monitor: this.monitor.id,
              alert: this.alert.id,
            })
              .then(async (result) => {
                return;
              })
              .catch((error) => {
                console.error(error.message);
                this.$swal.getCancelButton().setAttribute("enabled", "");
                this.$swal.showValidationMessage(
                  `Erro ao reenviar a monitoria: ${error}`
                );
              });
          },
        })
        .then((result) => {
          this.dialog = false;
          if (result.isConfirmed) {
            this.$swal.fire({
              icon: "success",
              title: "Monitoria reenviada com sucesso! ",
              toast: false,
              showConfirmButton: true,
              timer: 1000,
              timerProgressBar: true,
            });
          }
        });
    },
  },
  async created() {
    await this.init();
  },
};
</script>