<template>

  <v-card class="mr-2 d-flex flex-column" width="370" elevation="0">
    <v-card-title class="text-subtitle-2">
      <font-awesome-icon class="error--text mb-1" :icon="['fal', 'note-medical']" fixed-width style="font-size: 25px" />
      <span class="error--text ml-2">
        Questionário obrigatório não respondido</span>
      <v-spacer></v-spacer>
      <Menu :name="customer.fullName"></Menu>
    </v-card-title>
    <v-card-text class="caption">
      <v-card class="mx-0 pa-1" outlined>
        <span class="error--text text-justify">
          <font-awesome-icon :icon="['fal', 'message']" fixed-width class="grey--text text--darken-2"
            style="font-size: 12px" /> Data Planejada:
          <span class="font-weight-bold">{{ (alert.day > 10?alert.day:'0'+alert.day) + "/" + (alert.month >
          10?alert.month:"0"+alert.month) + "/" +
          alert.year }} </span><span class="font-weight-bold"></span>
        </span>
      </v-card>
    </v-card-text>
    <v-card-text class="caption mt-n7">
      <v-card class="mx-0 pa-1" outlined>
        <span class="grey--text text--darken-1 text-justify">Questionário que gerou o alerta:
          <span class="font-weight-bold">{{monitor.anamnesisId}}</span></span>
      </v-card>
    </v-card-text>
    <v-card-text v-if="this.alert.treated && alert.practitioner != null">
      <v-card class="mt-n6 grey lighten-4" elevation="0">
        <v-card-text class="caption"><span class="caption purple--text font-weight-medium">Ação:</span>
          <div v-html="alert.practitioner.action"></div>
        </v-card-text>
        <v-card-text class="caption mt-n7"><span class="caption purple--text font-weight-medium">Tratativa:</span>
          <div class="mb-n4" v-html="treatement"></div>
        </v-card-text>
      </v-card>
    </v-card-text>
    <v-spacer></v-spacer>
    <v-divider></v-divider>
    <v-card-actions>
      <v-chip v-if="alert.treated" label small outlined color="purple" text-color="purple"
        class="mx-0 text-left font-weight-black">Encerrado</v-chip>
      <v-chip v-else label small outlined color="error" text-color="error">Não Encerrado</v-chip>
      <v-spacer></v-spacer>
      <Reply v-if="!alert.treated" :monitor="monitor" :alert="alert"></Reply>
      <Anamnesis v-if="!alert.treated" @close-interview="closeAlert" :monitor="monitor" :alert="alert"></Anamnesis>
      <EditAlert v-if="!alert.treated" @close-alert="closeAlert" :alert="alert" :monitor="monitor"></EditAlert>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import EditAlert from "./EditAlert.vue";
import Menu from "../../../ehr/components/actions/Menu.vue";
import Anamnesis from "../../../ehr/components/anamnesis/Dialog.vue";
import Reply from "../reply/Reply.vue";

export default {
  components: { EditAlert, Menu, Reply, Anamnesis },
  data() {
    return {
    };
  },
  props: {
    monitor: {
      type: Object,
      required: true,
      default: null,
    },
    alert: {
      type: Object,
      required: true,
      default: null,
    },
  },
  methods: {
    closeAlert() {
      this.$emit("refresh");
    },
  },
  computed: {
    ...mapGetters({
      customer: "customer",
    }),
    treatement() {
      if (this.alert.treated) {
        return this.alert.note;
      } else {
        return "";
      }
    },
    elevation() {
      if (this.alert.treated) {
        return "0";
      } else {
        return "15";
      }
    },
  },
  async mounted() { },
};
</script>

<style>

</style>
