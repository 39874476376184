<template>
  <v-dialog v-model="dialog" width="900" eager>
    <template v-slot:activator="{ on, attrs }">
      <v-btn text class="ml-n4" v-bind="attrs" v-on="on" dark color="secondary" elevation="0">
        <v-icon dark>mdi-plus</v-icon>Nova Mensagem
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-subtitle-2"> </v-card-title>
      <v-card-text>
        <v-row class="mb-n10">
          <v-col>
            <v-select :items="whatsappTemplates" label="Templates" item-text="name" clearable outlined
              v-model="template" :return-object="true"></v-select>
          </v-col>
        </v-row>
        <v-row class="mb-n10">
          <v-col>
            <v-select :items="answers" label="Resposta do paciente" item-text="text" item-value="payload" clearable
              outlined v-model="answer" :return-object="true"></v-select>
          </v-col>

          <v-col>
            <v-select :items="types" label="Tipo da ação" clearable outlined v-model="type"
              :return-object="true"></v-select>
          </v-col>
          <v-col>
            <v-select :items="actions" item-text="text" item-value="id" label="Valor da ação" clearable outlined
              v-model="action" :return-object="true"></v-select>
          </v-col>
        </v-row>
        <v-row class="mt-n10" v-if="type != null && type.value === 'direct-message'">
          <v-col><v-switch v-model="transfer" :label="`Transferir ao atendente: ${transfer == true ? 'Sim' : 'Não'
          }`"></v-switch></v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="this.template">
        <v-row dense>
          <v-col cols="6">
            <v-card class="grey lighten-4" flat><v-card-text>{{ this.template.text }}</v-card-text></v-card></v-col>
          <v-col cols="6">
            <v-text-field v-model="fieldsValues[field]" :id="field" class="mb-2" v-for="field in this.template.fields"
              :key="field.name" :label="field" single-line hide-details clearable :placeholder="field" outlined>
              {{ field }}
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn dark color="secondary" elevation="0" text @click="sendMessage()">
          <v-icon dark>mdi-send-outline</v-icon> Enviar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { functions } from "../../../../firebase";
const format = require("string-template");
export default {
  data() {
    return {
      dialog: false,
      template: null,
      actions: [],
      action: null,
      answers: [],
      answer: null,
      fieldsValues: [],
      transfer: false,
      types: [
        { text: "Opção do Menu", value: "menu" },
        { text: "Enviar Mensagem", value: "message" },
        { text: "Responder Anamnese", value: "direct-message" },
      ],
      type: null,
    };
  },
  computed: {
    ...mapGetters({
      whatsappTemplates: "whatsappTemplates",
      customer: "customer",
      professional: "professional",
      flows: "flows",
      anamnesis: "anamnesis",
    }),
  },
  methods: {
    async sendMessage() {
      let fields = {};
      for (let field in this.fieldsValues) {
        fields[field] = this.fieldsValues[field];
      }
      this.$swal
        .fire({
          title: "Enviar Mensagem",
          text:
            "Você realmente deseja enviar uma mensagem para o(a) paciente " +
            this.customer.fullName +
            "?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            this.$swal.showLoading();
            this.$swal.getCancelButton().setAttribute("disabled", "");
            let actionNew = functions.httpsCallable("sis-action-new-api");
            if (this.type.value != 'message') {
              await actionNew({
                text: format(
                  format(this.template.components.body.text, fields),
                  fields
                ),
                location: "cis",
                customer: this.customer,
                answer: this.answer,
                cancel: "cancel",
                type: this.type,
                menu: this.type.value == "menu" ? this.action.id : null,
                anamnesis:
                  this.type.value == "direct-message"
                    ? this.action.queryId
                    : null,
                transfer: this.transfer,
              })
                .then(async (result) => {
                  let notificationSendWhatsapp = functions.httpsCallable(
                    "sis-notification-send-whatsapp-api"
                  );
                  await notificationSendWhatsapp({
                    template: this.template,
                    fields: fields,
                    customer: this.customer.id,
                    professional: this.professional.id,
                  })
                    .then((result) => {
                      this.dialog = false;
                      return;
                    })
                    .catch((error) => {
                      this.$swal.getCancelButton().setAttribute("enabled", "");
                      this.$swal.showValidationMessage(`Erro: ${error}`);
                      console.error(error.message);
                    });
                })
                .catch((error) => {
                  this.$swal.getCancelButton().setAttribute("enabled", "");
                  this.$swal.showValidationMessage(`Erro: ${error}`);
                  console.error(error.message);
                });
            } else {
              let notificationSendWhatsapp = functions.httpsCallable(
                "sis-notification-send-whatsapp-api"
              );
              await notificationSendWhatsapp({
                template: this.template,
                fields: fields,
                customer: this.customer.id,
                professional: this.professional.id,
              })
                .then((result) => {
                  this.dialog = false;
                  return;
                })
                .catch((error) => {
                  this.$swal.getCancelButton().setAttribute("enabled", "");
                  this.$swal.showValidationMessage(`Erro: ${error}`);
                  console.error(error.message);
                });
            }
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$swal
              .fire({
                icon: "success",
                title: "Mensagem enviada com sucesso! ",
                toast: false,
                showConfirmButton: true,
                timer: 1000,
                timerProgressBar: true,
              })
              .then((result) => {
                this.dialog = false;
              });
          }
        });
    },
  },
  props: {},
  watch: {
    template: function (newTemplate, oldTemplate) {
      this.fieldsValues = [];
      this.answers = newTemplate.components.buttons.items;
      this.answer = null;
    },
    dialog: function (newDialog, oldDialog) {
      if (newDialog == false) {
        this.fieldsValues = [];
        this.template = null;
        this.actions = [];
        this.action = null;
        this.answers = [];
        this.answer = null;
        this.type = null;
      }
    },
    type: function (newType, oldType) {
      this.actions = [];
      this.action = null;

      if (newType.value === "menu") {
        this.actions = this.flows;
      } else if (newType.value === "direct-message") {
        this.actions = this.anamnesis;
      }
    },
  },
  created() {
    this.$store.dispatch("bindFlows");
    this.$store.dispatch("bindAnamnesis");
  },
};
</script>

<style>

</style>
