<template>
  <container class="mr-1" name="ro-nurse-health-screening-menu">
    <v-row>
      <v-col>
        <PatientDetailsButton></PatientDetailsButton>
      </v-col>
      <v-col class="ml-n4">
        <ImmunizationHistoryButton></ImmunizationHistoryButton>
      </v-col>
      <v-col class="ml-n4">
        <PatientHistoryButton></PatientHistoryButton>
      </v-col>
    </v-row>
  </container>
</template>

<script>
import { mapGetters } from "vuex";
import PatientDetailsButton from "../../../../components/profile/Dialog.vue";
import ImmunizationHistoryButton from "../../../../components/immunization/Dialog.vue";
import PatientHistoryButton from "../../../../components/historic/Dialog.vue";

export default {
  components: {
    PatientDetailsButton,
    ImmunizationHistoryButton,
    PatientHistoryButton,
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {},
  destroyed() { },
};
</script>
<style>

</style>