<template>
  <v-dialog v-model="dialog" width="900" eager>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="ml-n4"
        v-bind="attrs"
        v-on="on"
        dark
        color="secondary"
        elevation="0"
      >
        <v-icon dark>mdi-plus</v-icon>Nova Avaliação
      </v-btn>
    </template>
    <form ref="evaluatorInfo">
      <v-card>
        <v-card-title class="text-subtitle-2"> </v-card-title>
        <v-card-text>
          <v-row class="mb-n10">
            <v-col>
              <v-autocomplete
                label="Avaliadores"
                v-model="evaluator"
                auto-select-first
                chips
                clearable
                deletable-chips
                small-chips
                :items="evaluators"
                :return-object="true"
                item-text="fullName"
                solo
                flat
                outlined
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="mb-n10">
            <v-col>
              <v-autocomplete
                label="Questionários"
                v-model="anamnesis_"
                auto-select-first
                chips
                clearable
                deletable-chips
                small-chips
                :items="anamnesis"
                :return-object="true"
                item-text="queryId"
                solo
                flat
                outlined
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-if="this.evaluator && this.anamnesis_">
          <v-row dense>
            <v-col cols="6">
              <v-card flat>
                <v-card-title>
                  <span>Descrição</span>
                </v-card-title>
                <v-card-text class="pa-4 grey lighten-4">{{
                  this.anamnesis_.description
                }}</v-card-text></v-card
              ></v-col
            >
            <v-col cols="6">
              <v-card class="rounded" flat min-height="450" width="100%">
                <v-card-title>
                  <span>Data da Avaliação</span>
                </v-card-title>
                <v-date-picker
                  v-model="day"
                  color="primary"
                  :picker-date.sync="pickerDate"
                  event-color="error"
                  full-width
                  scrollable
                />
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-bind="attrs"
            v-on="on"
            dark
            color="secondary"
            elevation="0"
            text
            @click="addEvaluation()"
          >
            <v-icon dark>mdi-plus-circle-outline</v-icon> Adicionar
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { functions } from "../../../../firebase";
const format = require("string-template");
export default {
  data() {
    return {
      evaluator: null,
      anamnesis_: null,
      dialog: null,
      day: null,
      pickerDate: null,
      today: null,
    };
  },
  computed: {
    ...mapGetters({
      evaluators: "evaluators",
      customer: "customer",
      anamnesis: "anamnesis",
    }),
  },
  methods: {
    async addEvaluation() {
      this.$swal
        .fire({
          title: "Adicionar Avaliação",
          text:
            "Você realmente deseja adicionar a avaliação para o paciente " +
            this.customer.fullName +
            " com o profissional " +
            this.evaluator.fullName +
            "?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            this.$swal.showLoading();
            this.$swal.getCancelButton().setAttribute("disabled", "");

            let monitoringAdd = functions.httpsCallable(
              "sis-evaluation-add-evaluator-api"
            );
            await monitoringAdd({
              customer: this.customer,
              evaluator: this.evaluator,
              evaluatorId: this.evaluator.id,
              anamnesis: this.anamnesis_,
              day: this.calendarDay,
              month: this.calendarMonth,
              year: this.calendarYear,
            })
              .then((result) => {
                this.dialog = false;
                return;
              })
              .catch((error) => {
                this.$swal.getCancelButton().setAttribute("enabled", "");
                this.$swal.showValidationMessage(`Erro: ${error}`);
                console.error(error.message);
              });

            this.dialog = false;
            return;
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$swal
              .fire({
                icon: "success",
                title: "Avaliação adicionada com sucesso! ",
                toast: false,
                showConfirmButton: true,
                timer: 1000,
                timerProgressBar: true,
              })
              .then((result) => {
                this.dialog = false;
              });
          }
        });
    },
  },
  props: {},
  watch: {
    dialog: function (newDialog, oldDialog) {
      if (newDialog == false) {
        this.monitors = [];
        this.monitor = null;
      }
    },
    pickerDate: function (newPicker, oldPicker) {
      this.calendarYear = this.$moment(newPicker).year();
      this.calendarMonth =
        this.$moment(newPicker).month() + 1 < 10
          ? "0" + (this.$moment(newPicker).month() + 1)
          : this.$moment(newPicker).month() + 1;
      this.calendarDay = this.$moment(this.day).date();
    },
    day: function (newDay, oldDay) {
      this.calendarDay = this.$moment(this.day).date();
    },
  },
  created() {
    this.today = this.$moment(new Date()).format("YYYY-MM-DD");
    this.calendarYear = this.$moment(new Date()).year();
    this.calendarMonth =
      this.$moment(new Date()).month() + 1 < 10
        ? "0" + (this.$moment(new Date()).month() + 1)
        : this.$moment(new Date()).month() + 1;
  },
  destroy() {
    this.$refs.evaluatorInfo.reset();
  },
};
</script>

<style></style>
