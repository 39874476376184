<template>
  <v-dialog v-model="dialog" width="900" eager>
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="ml-n4" v-bind="attrs" v-on="on" dark text color="secondary" elevation="0">
        <v-icon dark>mdi-plus</v-icon>Novo Monitoramento
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-subtitle-2"> </v-card-title>
      <v-card-text>
        <v-row class="mb-n10">
          <v-col>
            <v-select :items="professionalMonitors" label="Monitorias" item-text="text" clearable outlined
              v-model="monitor" :return-object="true"></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="this.monitor">
        <v-row dense>
          <v-col cols="6">
            <v-card flat>
              <v-card-title>
                <span>Descrição</span>
              </v-card-title>
              <v-card-text class="grey lighten-4">{{
    this.monitor.description
}}</v-card-text></v-card></v-col>
          <v-col cols="6">
            <v-card class="rounded" flat min-height="450" width="100%">
              <v-card-title>
                <span>Data de Início</span>
              </v-card-title>
              <v-date-picker v-model="day" color="primary" :picker-date.sync="pickerDate" event-color="error" full-width
                scrollable />
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn dark color="secondary" elevation="0" text @click="addMonitor()">
          <v-icon dark>mdi-plus-circle-outline</v-icon> Adicionar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { functions } from "../../../../firebase";
const format = require("string-template");
export default {
  data() {
    return {
      dialog: null,
      monitor: null,
      day: null,
      pickerDate: null,
      today: null,
      professionalMonitors: [],
    };
  },
  computed: {
    ...mapGetters({
      monitors: "monitors",
      customer: "customer",
      professional: "professional",
    }),
  },
  methods: {
    async addMonitor() {
      this.$swal
        .fire({
          title: "Adicionar Monitoria",
          text:
            "Você realmente deseja adicionar a monitoria " +
            this.monitor.text +
            " para o paciente " +
            this.customer.fullName +
            "?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            this.$swal.showLoading();
            this.$swal.getCancelButton().setAttribute("disabled", "");

            let monitoringAdd = functions.httpsCallable(
              "sis-monitoring-add-api"
            );
            await monitoringAdd({
              customer: this.customer,
              monitor: this.monitor,
              day: this.calendarDay,
              month: this.calendarMonth,
              year: this.calendarYear,
            })
              .then((result) => {
                this.dialog = false;
                return;
              })
              .catch((error) => {
                this.$swal.getCancelButton().setAttribute("enabled", "");
                this.$swal.showValidationMessage(`Erro: ${error}`);
                console.error(error.message);
              });

            this.dialog = false;
            return;
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$swal
              .fire({
                icon: "success",
                title: "Monitoria adicionada com sucesso! ",
                toast: false,
                showConfirmButton: true,
                timer: 1000,
                timerProgressBar: true,
              })
              .then((result) => {
                this.dialog = false;
              });
          }
        });
    },
  },
  props: {},
  watch: {
    dialog: function (newDialog, oldDialog) {
      if (newDialog == false) {
        this.monitors = [];
        this.monitor = null;
      }
    },
    pickerDate: function (newPicker, oldPicker) {
      this.calendarYear = this.$moment(newPicker).year();
      this.calendarMonth =
        this.$moment(newPicker).month() + 1 < 10
          ? "0" + (this.$moment(newPicker).month() + 1)
          : this.$moment(newPicker).month() + 1;
      this.calendarDay = this.$moment(this.day).date();
    },
    day: function (newDay, oldDay) {
      this.calendarDay = this.$moment(this.day).date();
    },
  },
  async mounted() {
    console.info(this.monitors);
    this.professionalMonitors = this.monitors.filter(function (m) {
      return this.findIndex((pm) => pm.queryId == m.queryId) > -1;
    }, this.professional.monitors);
  },
  async created() {
    this.today = this.$moment(new Date()).format("YYYY-MM-DD");
    this.calendarYear = this.$moment(new Date()).year();
    this.calendarMonth =
      this.$moment(new Date()).month() + 1 < 10
        ? "0" + (this.$moment(new Date()).month() + 1)
        : this.$moment(new Date()).month() + 1;
  },
};
</script>

<style>

</style>
