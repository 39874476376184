import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

export default {
  state: {
    resources: [],
  },
  actions: {
    bindResources: firestoreAction((context) => {
      let ref = db.collection("resources");
      context.bindFirestoreRef("resources", ref.where("category", "!=", ""), {
        wait: true,
        reset: true,
      });
    }),
  },
  getters: {
    resources(state) {
      return state.resources;
    },
  },
};
