<template>
  <v-card
    class="pa-2 ml-2 mr-2 mt-2 mb-2 d-flex flex-column justify-center align-content-center"
    elevation="3"
    min-height="300"
    min-width="600"
  >
    <apexchart
      width="100%"
      heigth="100%"
      type="bar"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </v-card>
</template>

<script>
export default {
  name: "RadialBarExample",
  props: {
    info: {
      type: Object,
      required: false,
      default: null,
    },
    chartTitle: {
      type: String,
      required: false,
      default: null,
    },
    groupBar: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {},
  data: function () {
    return {
      chart: {
        type: "bar",
        events: {
          click: function (chart, w, e) {
            //console.info(chart, w, e)
          },
        },
      },
      chartOptions: {
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
          },
        },
        legend: {
          show: true,
        },
        dataLabels: {
          enabled: true,
        },
        xaxis: {
          categories:
            Object.keys(this.info)[0] != "undefined"
              ? Object.keys(this.info)
              : ["Não Definido"],
          labels: {
            style: {
              fontSize: "12px",
              color: "#9d9d9d",
            },
          },
        },
        yaxis: {
          title: {
            text: "Quantidade de Pacientes",
            style: {
              fontSize: "12px",
              color: "#9d9d9d",
            },
          },
        },
        title: {
          text: this.chartTitle,
          floating: true,
          offsetY: 0,
          align: "center",
          style: {
            fontSize: "12px",
            color: "#6d6d6d",
          },
        },
      },
      series: [
        {
          name: "Pacientes:",
          data: Object.values(this.info),
        },
      ],
    };
  },
  created() {
    //console.info(Object.keys(this.info)[0] == 'undefined');
  },
};
</script>