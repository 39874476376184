<template>
  <v-card flat class="transparent">
    <v-row class="d-flex justify-center" no-gutters>
      <v-col>
        <Identification></Identification>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center mt-2 mr-n2" no-gutters>
      <v-col cols="3" class="d-flex-col justify-center">
        <Telemedicine></Telemedicine>
      </v-col>
      <v-col cols="9" class="pl-2 d-flex-col justify-center">
        <v-row class="d-flex justify-start" no-gutters>
          <v-col cols="12" class=" pr-2 d-flex-col justify-center">
            <v-row class="d-flex justify-start" no-gutters>
              <v-col cols="12" class="mb-2 d-flex-col justify-center">
                <v-card flat min-height="83vh">
                  <v-tabs v-model="tab" background-color="secondary" centered dark icons-and-text height="35"
                    class="mb-2 rounded">
                    <v-tabs-slider color="warning"></v-tabs-slider>
                    <v-tab class="subtitle-2">
                      1. Informações Gerais do Paciente
                    </v-tab>
                    <v-tab class="subtitle-2">
                      2. Respostas Prévias do Paciente
                    </v-tab>
                    <v-tab class="subtitle-2">
                      3. História Pregressa da Moléstia Atual
                    </v-tab>
                    <v-tab class="subtitle-2">
                      4. Hipótese Diagnóstica
                    </v-tab>
                    <v-tab class="subtitle-2">
                      5. Dieta
                    </v-tab>
                    <v-tab class="subtitle-2">
                      6. CID
                    </v-tab>
                    <v-tab class="subtitle-2">
                      7. Conduta
                    </v-tab>
                    <v-tab class="subtitle-2">
                      8. Linha de Cuidado
                    </v-tab>
                    <v-tab class="subtitle-2">
                      9. Prescrição
                    </v-tab>
                    <v-tabs-items class="transparent" v-model="tab">
                      <v-tab-item transition="false" class="rounded">
                        <EndocrinologyInfo :sequence="1" class="mb-2"></EndocrinologyInfo>
                      </v-tab-item>
                      <v-tab-item transition="false" class="rounded">
                        <PreviousQuestionnaireResponses :sequence="2"></PreviousQuestionnaireResponses>
                      </v-tab-item>
                      <v-tab-item transition="false" class="rounded">
                        <PastHistoryOfCurrentDisease :sequence="3"></PastHistoryOfCurrentDisease>
                      </v-tab-item>
                      <v-tab-item transition="false" class="rounded">
                        <DiagnosticHypothesis :sequence="4"></DiagnosticHypothesis>
                      </v-tab-item>
                      <v-tab-item transition="false" class="rounded">
                        <PastHistoryOfCurrentDisease :sequence="3"></PastHistoryOfCurrentDisease>
                      </v-tab-item>
                      <v-tab-item transition="false" class="rounded">
                        <DiagnosticHypothesisCID :sequence="5"></DiagnosticHypothesisCID>
                      </v-tab-item>
                      <v-tab-item transition="false" class="rounded">
                        <MedicalHandling :sequence="6"></MedicalHandling>
                      </v-tab-item>
                      <v-tab-item transition="false" class="rounded">
                        <EndocrinologyPrimaryCare :sequence="7"></EndocrinologyPrimaryCare>
                      </v-tab-item>
                      <v-tab-item transition="false" class="rounded">
                        <Prescription :sequence="8"></Prescription>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-tabs>

                </v-card>
              </v-col>
            </v-row>
            <v-row class="mt-n1 d-flex justify-start" no-gutters>
              <v-col cols="4" class="d-flex-col justify-center">

              </v-col>
              <v-col cols="4" class="d-flex-col justify-center">
                <ReleasePatient :sequence="9"></ReleasePatient>
              </v-col>
              <v-col cols="4" class="pl-2 d-flex-col justify-center">
                <CloseSession :sequence="10"></CloseSession>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import Identification from "./components/patient/Identification.vue";
import PreviousQuestionnaireResponses from "./components/PreviousQuestionnaireResponses.vue";
import Telemedicine from "../../../components/telemedicine/Telemedicine.vue";
import PastHistoryOfCurrentDisease from "./components/PastHistoryOfCurrentDisease.vue";
import DiagnosticHypothesis from "./components/DiagnosticHypothesis.vue";
import DiagnosticHypothesisCID from "./components/DiagnosticHypothesisCID.vue";
import MedicalHandling from "./components/MedicalHandling.vue";
import Prescription from "./components/Prescription.vue";
import CloseSession from "./components/CloseSession.vue";
import ReleasePatient from "./components/ReleasePatient.vue";
import EndocrinologyInfo from "./components/EndocrinologyInfo.vue";
import EndocrinologyPrimaryCare from "./components/EndocrinologyPrimaryCare.vue";


export default {
  data: () => ({
    tab: null,
  }),
  components: {
    Identification,
    Telemedicine,
    PreviousQuestionnaireResponses,
    PastHistoryOfCurrentDisease,
    DiagnosticHypothesis,
    DiagnosticHypothesisCID,
    MedicalHandling,
    Prescription,
    CloseSession,
    EndocrinologyInfo,
    EndocrinologyPrimaryCare,
    ReleasePatient
  },
  computed: {
    ...mapGetters({
      customer: "customer",
      interview: "interview",
    }),
  },
  methods: {},
  created() {
    if (!this.customer.fullName) {
      this.$router.replace({ name: "WaitingRoom" });
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      this.$store.dispatch("saveInterviewDraft", {
        customer: this.customer.id,
        interview: this.interview.id,
      });
    }, 5000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  destroyed() { },
};
</script>