import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

export default {
    state: {
        cid10: null
    },
    mutations: {
        SET_CID_10(state, data) {
            state.cid10 = data;
        }
    },
    actions: {
        fetchCID10(context) {
            db.collection("health-info").doc("CID10").get().then((cidDoc) => {
                context.commit("SET_CID_10", cidDoc.data().codes);
            });
        }
    },
    getters: {
        cid10(state) {
            return state.cid10;
        }
    }
};
