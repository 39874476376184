<template>
  <v-dialog v-model="dialog" max-width="1300" eager>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            :disabled="rooms == null"
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
            elevation="0"
            color="secondary"
            min-width="0"
            min-height="0"
            max-height="41"
            class="mt-n1"
          >
            <font-awesome-icon
              :icon="['fal', 'magnifying-glass-chart']"
              fixed-width
              style="font-size: 20px"
            />
            ATENDIMENTOS
          </v-btn>
        </template>
        <span>Abrir Salas da Linha de Cuidado</span>
      </v-tooltip>
    </template>
    <v-card class="pa-1" min-height="90vh">
      <Historical
        v-if="dialog"
        :journeyRooms="rooms"
        @closeRoomHistoricalSchedule="closeRoomHistorical"
      ></Historical>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import Historical from "../Historical.vue";

export default {
  data: () => ({
    dialog: false,
  }),
  props: {
    rooms: {
      type: Object,
      required: false,
      default: null,
    },
  },
  components: { Historical },
  computed: {
    ...mapGetters({}),
  },
  watch: {},
  methods: {
    closeRoomHistorical() {
      this.$emit("closeRoomHistorical");
      this.dialog = false;
    },
  },
  mounted() {},
};
</script>

<style>
</style>