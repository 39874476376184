import Vue from "vue";
import Vuex from "vuex";
import {vuexfireMutations, firestoreOptions} from "vuexfire";

import professional from "./modules/professional";
import customer from "./modules/customer";
import customers from "./modules/customers";
import ehr from "./modules/ehr";
import recommendations from "./modules/recommendations";
import rooms from "./modules/rooms";
import vitalsigns from "./modules/vitalsigns";
import primaryCare from "./modules/primaryCare";
import memed from "./modules/memed";
import resources from "./modules/resources";
import companies from "./modules/companies";
import notifications from "./modules/notifications";
import messages from "./modules/messages";
import templates from "./modules/templates";
import views from "./modules/views";
import flows from "./modules/flows";
import anamnesis from "./modules/anamnesis";
import conversations from "./modules/conversations";

// New
import diagnostics from "./modules/diagnostics";
import waitingRoom from "./modules/waitingRoom";
import waitingRoomKpis from "./modules/waitingRoomKpis";
import interview from "./modules/interview";
import notes from "./modules/notes";
import knowledgebase from "./modules/knowledgebase";
import immunizations from "./modules/immunizations";
import monitors from "./modules/monitors";
import monitoring from "./modules/monitoring";
import surveys from "./modules/surveys";
import tcles from "./modules/tcles";
import journeys from "./modules/journeys";
import journeysKpis from "./modules/journeysKpis";
import journey from "./modules/journey";
import evaluation from "./modules/evaluation";
import telemedicine from "./modules/telemedicine";
import config from "./modules/config";
import healthInfo from "./modules/healthInfo";
import instance from "./modules/instance";

firestoreOptions.wait = true;

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        professional,
        customer,
        customers,
        ehr,
        recommendations,
        rooms,
        vitalsigns,
        primaryCare,
        memed,
        resources,
        companies,
        messages,
        notifications,
        templates,
        flows,
        anamnesis,
        conversations,
        views,
        diagnostics,
        interview,
        waitingRoom,
        notes,
        knowledgebase,
        immunizations,
        monitors,
        monitoring,
        surveys,
        evaluation,
        telemedicine,
        config,
        journeys,
        journeysKpis,
        journey,
        tcles,
        healthInfo,
        instance,
        waitingRoomKpis
    },

    mutations: {
        ...vuexfireMutations
    }
});

export default store;
