<template>
  <v-card flat class="transparent">
    <v-tabs v-model="tab" background-color="primary" centered dark icons-and-text height="35" class="mb-2 rounded">
      <v-tabs-slider color="warning"></v-tabs-slider>
      <v-tab>
        CHAT
      </v-tab>
      <v-tab>
        TELEATENDIMENTO
      </v-tab>
    </v-tabs>
    <v-card flat>
      <v-tabs-items class="transparent" v-model="tab">
        <v-tab-item class="rounded">
          <Chat></Chat>
        </v-tab-item>
        <v-tab-item class="rounded" v-if="(selectedRoom != null)">
          <v-toolbar flat height="22" elevation="0" class="secondary">
            <span class="caption white--text">Usuário:</span>
            <v-chip v-clipboard="selectedRoom.username" class="ml-1" color="grey lighten-3" label x-small>{{
    selectedRoom.username
}}
              <font-awesome-icon class="ml-2 grey--text text--darken-1" :icon="['fal', 'copy']" fixed-width
                style="font-size: 12px" /></v-chip>
            <span class="caption white--text ml-2">Senha:</span>
            <v-chip v-clipboard="selectedRoom.password" class="ml-1" color="grey lighten-3" label x-small>{{
    selectedRoom.password
}}
              <font-awesome-icon class="ml-2 grey--text text--darken-1" :icon="['fal', 'copy']" fixed-width
                style="font-size: 12px" /></v-chip>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-toolbar flat height="22" elevation="0" class="secondary">
            <span class="caption white--text">Link de acesso:</span>
            <v-btn x-small class="ml-2" height="16" dark color="primary" elevation="0" center @click="sendLink()">
              enviar ao paciente
            </v-btn>
          </v-toolbar>
          <Doxy v-if="telemedicine == 'doxy'"></Doxy>
          <ICYVideo v-else></ICYVideo>
        </v-tab-item>
        <v-tab-item class="rounded" v-else>
          <v-card outlined flat max-height="593" min-height="593" class="overflow-y-auto">
            <v-data-table class="elevation-0" :headers="headers" :items="telemedicineRooms" :items-per-page="30"
              :sort-by="['name']" :sort-desc="[false]" :calculate-widths="true">

              <template v-slot:[`item.available`]="{ item }">
                <v-btn x-small class="mr-n3" height="25" dark color="success" elevation="0" center
                  @click="select(item)">
                  <font-awesome-icon class="mr-2" color="white" :icon="['fal', 'display-medical']" fixed-width
                    style="font-size: 15px" />utilizar
                </v-btn>
              </template>

            </v-data-table>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate :rotate="360" :size="100" :width="8" color="white">
        </v-progress-circular>
      </v-overlay>
    </v-card>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { functions, db } from "../../../../../firebase";
import Doxy from "./doxy/Doxy.vue";
import ICYVideo from "./video/Video.vue";
import Chat from "./chat/Chat.vue";

export default {
  data: () => ({
    overlay: false,
    tab: null,
    link: null,
    headers: [
      { text: "ID", value: "id", align: ' d-none' },
      { text: "Sala", value: "name" },
      { text: "Disponíveis", value: "available" },
    ],
  }),
  components: {
    Doxy,
    ICYVideo,
    Chat,
  },
  computed: {
    ...mapGetters({
      customer: "customer",
      queueInfo: "queueInfo",
      telemedicine: "telemedicine",
      telemedicineRooms: "telemedicineRooms",
      selectedRoom: "selectedRoom",
      chat: "chat",
      professional: "professional",
      interview: "interview",
    }),
  },
  methods: {
    async select(room) {
      this.overlay = true;
      let sisTelemedicineReserve = functions.httpsCallable(
        "sis-telemedicine-reserve-api"
      );
      sisTelemedicineReserve({
        room: room.id,
        queue: this.queueInfo
      })
        .then(async (result) => {
          await this.$store.dispatch("reserveRoom", { room: room });
          await this.$store.dispatch("fetchQueueInfo", this.queueInfo);
          this.link = this.selectedRoom.link + '/' + this.selectedRoom.doxy + '?username=' + this.customer.name + this.selectedRoom.options
          let message = {};
          message.content = this.link;
          await this.sendMessage(message);
          this.overlay = false;
        })
        .catch((error) => {
          this.overlay = false;
          console.error(error.message);
        });
    },
    async sendLink() {
      if (this.selectedRoom) {
        this.link = this.selectedRoom.link + '/' + this.selectedRoom.doxy + '?username=' + this.customer.name + this.selectedRoom.options
        let message = {};
        message.content = this.link;
        await this.sendMessage(message);
      }
    },
    async sendMessage(message) {
      let sendMessage = functions.httpsCallable(
        "sis-conversation-send-message-api"
      );
      await sendMessage({
        id: this.chat.id,
        senderId: this.professional.id,
        message: message,
        username: this.professional.name,
        customer: this.customer,
        professional: this.professional,
      })
        .then((result) => {
          return;
        })
        .catch((error) => {
          console.error(error.message);
        });
    },

  },
  async mounted() {
    if (this.queueInfo.telemedicine) {
      let telemedicineDoc = await db.collection("telemedicine").doc(this.queueInfo.telemedicine).get();
      await this.$store.dispatch("reserveRoom", { room: telemedicineDoc.data() });
    }
  },
  async beforeDestroy() {
    await this.$store.dispatch("releaseRoom");
  },

};
</script>

<style>

</style>
