<template>
  <v-dialog v-model="dialog" max-width="1100" eager>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn small color="primary" text v-bind="attrs" v-on="{ ...tooltip, ...dialog }" elevation="0" min-width="35"
            max-width="35" min-height="35" max-height="35">
            <font-awesome-icon :icon="['fal', 'history']" fixed-width style="font-size: 25px" />
          </v-btn>
        </template>
        <span>Histórico do Paciente</span>
      </v-tooltip>
    </template>
    <v-card min-height="100vh">
      <Notes></Notes>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import Notes from "./Notes.vue";

export default {
  components: {
    Notes,
  },
  props: {
    patient: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  watch: {
    async dialog(visible) {
      if (visible) {
        if (this.patient) {
          await this.$store.dispatch("fetchCustomer", {
            customer: this.patient,
          });
          await this.$store.dispatch("bindNotes", { customer: this.patient });
        } else {
          await this.$store.dispatch("bindNotes", {
            customer: this.customer.id,
          });
        }
      } else {
        if (this.patient) {
          await this.$store.dispatch("resetCustomerState");
          await this.$store.dispatch("unbindNotes");
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      customer: "customer",
    }),
  },
  methods: {},
  mounted() { },
};
</script>
<style scoped>
</style>
