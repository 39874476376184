import {firestoreAction} from "vuexfire";
import {db} from "../../firebase";

export default {
    state: {
        flows: [],
        flowInstanceTrail: [],
        flowInstance: null
    },
    actions: {
        bindFlows: firestoreAction(
            (context, data) => {
                let ref = db.collection("business-flows");
                context.bindFirestoreRef("flows", ref, {
                    wait: true,
                    reset: true
                });
            }
        ),
        unbindFlowInstanceTrail: firestoreAction(
            ({unbindFirestoreRef}) => {
                unbindFirestoreRef("flowInstanceTrail", true);
            }
        ),
        bindFlowInstanceTrail: firestoreAction(
            (context, data) => {
                let instance = data.instance;
                let instanceRef = db.collection("flows-instances").doc(instance);
                let instanceTrailRef = instanceRef.collection("trail");
                context.bindFirestoreRef("flowInstanceTrail", instanceTrailRef, {reset: true});
            }
        ),
        unbindFlowInstance: firestoreAction(
            ({unbindFirestoreRef}) => {
                unbindFirestoreRef("flowInstance", true);
            }
        ),
        bindFlowInstance: firestoreAction(
            (context, data) => {
                let instance = data.instance;
                let instanceRef = db.collection("flows-instances").doc(instance);
                context.bindFirestoreRef("flowInstance", instanceRef, {reset: true});
            }
        )
    },
    getters: {
        flows(state) {
            return state.flows;
        },
        flowInstanceTrail(state) {
            return state.flowInstanceTrail;
        },
        flowInstance(state) {
            return state.flowInstance;
        }
    }
};
