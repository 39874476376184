<template>
  <v-dialog :id="resourceValue.id" v-model="dialog" width="700" eager>
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="ml-1" v-bind="attrs" v-on="on" elevation="0" min-width="0" color="primary" max-width="30"
        min-height="0" max-height="30">
        <font-awesome-icon :icon="['fal', 'plus-square']" fixed-width class="white--text" style="font-size: 17px" />
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-subtitle-2">
        <font-awesome-icon :icon="['fal', 'pen']" fixed-width style="font-size: 15px" class="mt-4" />
        <span class="ml-1 mt-4">Editar: {{ resourceValue.text }}</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text v-if="resourceValue.type == 'multi'">
        <v-select :items="resourceValue.options" label="Opções" dense clearable flat solo-inverted hide-details
          v-model="resource" :return-object="true"></v-select>
      </v-card-text>
      <v-card-text v-else>
        <v-text-field v-model="resource" label="Recurso" single-line hide-details clearable>
        </v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn dark color="secondary" elevation="0" text @click="saveResource()">
          <v-icon dark>mdi-plus</v-icon> Adicionar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { db, firebase } from "../../../../../firebase";
export default {
  data() {
    return {
      dialog: false,
      resource: null,
    };
  },
  computed: {
    ...mapGetters({
      customer: "customer",
      professional: "professional",
      interview: "interview",
    }),
  },
  methods: {
    async saveResource() {
      if (this.resource != null) {
        let ehrRef = await db
          .collection("electronic-health-record")
          .doc(this.customer.id);

        let interviewRef = ehrRef
          .collection("interviews")
          .doc(this.interview.id);

        let categoryRef = interviewRef.collection(this.resourceValue.category);
        let subCategoryRef = categoryRef.doc(this.resourceValue.subCategory);
        let itemRef = subCategoryRef.collection(this.resourceValue.item);
        let keyRef = itemRef.doc(this.resourceValue.key);

        let practitionerRef = {};
        practitionerRef.id = this.professional.id;
        practitionerRef.email = this.professional.email;
        practitionerRef.fullName = this.professional.fullName;
        practitionerRef.professionalId = this.professional.professionalId;
        practitionerRef.cpf = this.professional.cpf;
        practitionerRef.specialty = this.professional.specialty;
        practitionerRef.phone = this.professional.phone;

        await keyRef
          .get()
          .then(async (keyDoc) => {
            let document = {};
            let history = {};

            document.question = this.resourceValue.text;
            document.lastUpdate = firebase.firestore.Timestamp.now();

            if (this.resourceValue.type === "multi") {
              document.value = this.resource.value;
              document.text = this.resource.text;
            } else {
              document.value = this.resource;
            }

            if (keyDoc.exists) {
              history = keyDoc.data();
              history.created = firebase.firestore.Timestamp.now();
              await keyRef.collection("history").add(history);
              document.changed = firebase.firestore.FieldValue.delete();
              document.changeBy = firebase.firestore.FieldValue.delete();
              await keyRef.update(document);
            } else {
              await itemRef.doc(this.resourceValue.key).set(document);
            }

            this.dialog = false;
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
  },
  props: {
    resourceValue: Object,
  },
};
</script>

<style>

</style>
