<template>
  <!--div v-if="!isMobile()"-->
  <router-view></router-view>
  <!--/div-->
  <!--div v-else>
    <v-container class="grey lighten-5">
      <v-row no-gutters style="height: 150px">
        <v-col align-self="center">
          <v-card class="pa-2" align-self="center">
            <v-alert shaped prominent type="error">
              <span class="ml-8 caption">
                O sis não é suportado em celulares e tablets.
              </span>
            </v-alert>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div-->
</template>

<script>
export default {
  name: "sis",
  components: {},

  data() {
    return {
      script: null,
    };
  },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  async mounted() {
    await this.$store.dispatch("fetchSession");
  },
};
</script>

<style>
@import "../node_modules/typeface-roboto/index.css";

#app {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
