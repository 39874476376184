var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"color":"transparent","flat":"","tile":""}},[_c('patientInfo'),_vm._v(" "),_c('v-card-title',[_c('v-spacer')],1),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.customerMonitoringActions,"items-per-page":10,"sort-by":['created'],"sort-desc":[false],"calculate-widths":true},scopedSlots:_vm._u([{key:`item.alerts`,fn:function({ item }){return [_c('CloseAlert',{attrs:{"monitor":item}})]}},{key:`item.plannedDate`,fn:function({ item }){return [(item.executed)?_c('span',{staticClass:"caption"},[_vm._v(_vm._s((parseInt(item.day) >= 10 ? item.day : "0" + item.day) +
        "/" +
        (parseInt(item.month) >= 10 ? item.month : "0" + item.month) +
        "/" +
        item.year))]):_c('span',{staticClass:"caption grey--text"},[_vm._v(_vm._s((parseInt(item.day) >= 10 ? item.day : "0" + item.day) +
        "/" +
        (parseInt(item.month) >= 10 ? item.month : "0" + item.month) +
        "/" +
        item.year))])]}},{key:`item.weekDay`,fn:function({ item }){return [(item.executed)?_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.getWeekDay(item)))]):_c('span',{staticClass:"caption grey--text"},[_vm._v(_vm._s(_vm.getWeekDay(item)))])]}},{key:`item.period`,fn:function({ item }){return [(item.executed)?_c('span',{staticClass:"caption"},[_vm._v(_vm._s(item.period === "MNG"
          ? "Manhã"
          : item.period === "EVN"
          ? "Tarde"
          : "Noite"))]):_c('span',{staticClass:"caption grey--text"},[_vm._v(_vm._s(item.period === "MNG"
          ? "Manhã"
          : item.period === "EVN"
          ? "Tarde"
          : "Noite"))])]}},{key:`item.details`,fn:function({ item }){return [(item.action == 'anamnesis')?_c('div',[_c('v-chip',{attrs:{"x-small":"","label":"","color":_vm.actionColor(item)}},[_vm._v("\n          "+_vm._s(item.anamnesisId.toUpperCase())+"\n        ")])],1):_c('div',[(item.action == 'survey')?_c('div',[_c('v-chip',{attrs:{"x-small":"","label":"","color":_vm.actionColor(item)}},[_vm._v("\n            "+_vm._s(item.surveyId.toUpperCase())+"\n          ")])],1):_c('div',[_c('span',{staticClass:"text-center caption success--text"},[_vm._v(_vm._s("-"))])])])]}},{key:`item.type`,fn:function({ item }){return [(item.action == 'message')?_c('div',[_c('v-chip',{attrs:{"x-small":"","label":"","color":_vm.actionColor(item)}},[_vm._v(_vm._s(_vm.actionText(item)))])],1):_c('div',[(item.action == 'anamnesis')?_c('div',[_c('v-chip',{attrs:{"x-small":"","label":"","color":_vm.actionColor(item)}},[_vm._v("QUESTIONÁRIO")])],1):_c('div',[_c('v-chip',{attrs:{"x-small":"","label":"","color":_vm.actionColor(item)}},[_vm._v("PESQUISA DE SATISFAÇÃO")])],1)])]}},{key:`item.status`,fn:function({ item }){return [_c('Status',{attrs:{"monitor":item}})]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }