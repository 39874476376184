<template>
  <v-card class="mx-auto" flat>
    <v-card class="mb-2 rounded" flat color="grey lighten-3">
      <v-toolbar flat height="35" elevation="0" class="secondary">
        <v-toolbar-title class="subtitle-2 white--text">{{ sequence }}. História Pregressa da Moléstia Atual
          (HPMA)</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card>
    <TiptapVuetify min-height="35vh" max-height="35vh" scrolled :toolbar-attributes="{}" rounded
      v-model="pastHistoryOfCurrentDisease" :extensions="extensions"
      :card-props="{ flat: true, color: '', outlined: true, class: 'pa-0' }" :editor-props="{
        attributes: {
          class: 'prose mx-auto focus:outline-none',
        }
      }" />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import {
  History,
  TiptapVuetify,
  Bold,
  Italic,
  Strike,
  Underline,
  BulletList,
  OrderedList,
  ListItem,
  Blockquote,

} from "tiptap-vuetify";

export default {
  props: {
    sequence: {
      type: Number,
      required: true,
      default: null,
    },
  },
  data: () => ({
    timer: null,
    extensions: [
      History,
      Blockquote,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      Bold,
    ],
  }),
  components: {
    TiptapVuetify,
  },
  computed: {
    pastHistoryOfCurrentDisease: {
      get() {
        return this.$store.getters.pastHistoryOfCurrentDisease;
      },
      set(value) {
        this.$store.dispatch("setPastHistoryOfCurrentDisease", value);
      },
    },
    ...mapGetters({}),
  },
  methods: {},
};
</script>

<style>

</style>