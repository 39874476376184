<template>
  <v-card class="pa-0 mt-2 mx-auto" flat>
    <v-card class="mb-2 rounded" flat color="grey lighten-3">
      <v-toolbar dense rounded outlined elevation="0" height="35">
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card>
    <v-card outlined flat min-height="65vh" max-height="65vh" class="pa-2 d-flex-column justify-start">
      <v-row>
        <v-col><span class="grey--text text--darken-2">{{ weight.view.text }}: <span
              class="grey--text text--darken-2 font-weight-black">{{
                weight.data.value
              }} Kg</span></span>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col><span class="grey--text text--darken-2">{{ height.view.text }}: <span
              class="grey--text text--darken-2 font-weight-black">{{
                height.data.value
              }} cm</span></span>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col><span class="grey--text text--darken-2">IMC: <span class="grey--text text--darken-2 font-weight-black">{{
          imc
        }} kg/m2</span></span>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col><span class="grey--text text--darken-2">Comorbidades: <span
              class="grey--text text--darken-2 font-weight-black">{{ cmbd }}</span></span>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col><span class="grey--text text--darken-2">Diabetes: <span
              class="grey--text text--darken-2 font-weight-black">{{ dmt2 }}</span></span>
        </v-col>
      </v-row>
    </v-card>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { functions } from "../../../../../../../firebase";

export default {
  props: {
    sequence: {
      type: Number,
      required: true,
      default: null,
    },
  },
  data: () => ({
    selectedItem: 1,
    weight: null,
    height: null,
    imc: 0,
    cmbd: null,
    dmt2: null,
    diabetes: null,
    diabetesType: null,
  }),
  components: {},
  computed: {
    ...mapGetters({
      datasource: "datasource",
      customer: "customer"
    }),
  },
  methods: {},
  async created() {
    this.weight = this.datasource["findings"].find((view) => view.resource.queryId === 'vital-signs-weight');
    this.height = this.datasource["findings"].find((view) => view.resource.queryId === 'vital-signs-height');
    this.diabetes = this.datasource["findings"].find((view) => view.resource.queryId === 'conditions-diabetes');
    this.diabetesType = this.datasource["findings"].find((view) => view.resource.queryId === 'conditions-diabetes-type');


    let sisCalculatedFieldsCalculate = functions.httpsCallable(
      "sis-calculated-fields-calculate-api"
    );
    await sisCalculatedFieldsCalculate({
      customer: this.customer.id,
      field: "cf-imc"
    })
      .then((result) => {
        this.imc = result.data.value.toFixed(2);
      })
      .catch((error) => {
        console.error(error.message);
      });

    await sisCalculatedFieldsCalculate({
      customer: this.customer.id,
      field: "cf-cmbd"
    })
      .then((result) => {
        this.cmbd = (result.data.value ? "Sim" : "Não");
      })
      .catch((error) => {
        console.error(error.message);
      });

    await sisCalculatedFieldsCalculate({
      customer: this.customer.id,
      field: "cf-dmt2"
    })
      .then((result) => {
        this.dmt2 = (result.data.value ? "Tipo 2" : this.diabetes.data.value == 'Sim' ? this.diabetesType.data.value : this.diabetes.data.value);
      })
      .catch((error) => {
        console.error(error.message);
      });



  },
};
</script>

<style>

</style>
