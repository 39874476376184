<template>
  <v-card color="transparent" flat tile>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate :rotate="360" :size="100" :width="8" color="white">
      </v-progress-circular>
    </v-overlay>

    <v-toolbar dense rounded outlined elevation="0">
      <v-card-title class="ml-n6 grey--text text--darken-2">
        <font-awesome-icon :icon="['fal', 'fa-ballot-check']" fixed-width style="font-size: 25px" />Avaliações
      </v-card-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-card outlined width="100%" class="pa-2 mt-2">
      <v-badge color="secondary" inline content="Avaliações ativas" overlap>
        <v-progress-circular :rotate="0" :size="50" :width="5" :value="0" color="secondary">
          {{ evaluations.length }}
        </v-progress-circular>
      </v-badge>
    </v-card>
    <v-card-title class="mt-4">
      <v-row>
        <v-spacer></v-spacer>
        <v-select dense outlined :items="evaluators" label="Avaliador" item-text="fullName" clearable multiple
          @change="(selection) => selectionChanged(selection)" :return-object="true" class="select">
          <template v-slot:selection="{ item, index }">
            <v-chip small outlined v-if="index === 0">
              <span class="caption">{{ item.fullName }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text text-caption">
              (+ outros)
            </span>
          </template>
        </v-select>
        <v-text-field dense outlined class="ml-5 search" color="purple" v-model="search" append-icon="mdi-magnify"
          label="Nome do paciente ou do avaliador" single-line hide-details clearable></v-text-field>
      </v-row>
    </v-card-title>

    <v-dialog v-model="dialog" width="450">
      <v-card>
        <v-alert text prominent icon="mdi-information" type="info" color="secondary" elevation="5" class="caption">
          {{ this.description }}
        </v-alert>
      </v-card>
    </v-dialog>

    <v-data-table class="elevation-0" :headers="headers" :items="evaluations" :search="search" :items-per-page="10"
      :calculate-widths="true" :custom-sort="customSort">
      <template v-slot:[`item.alert`]="{ item }">
        <container v-if="item.alerts.length > 0">
          <v-badge color="warning" overlap bordered dot :value="item.newAlert">
            <v-chip small pill color="error">{{
            item.alerts.length
            }}</v-chip>
          </v-badge>
        </container>
        <container v-else>
          <v-chip small pill color="success">{{ 0 }}</v-chip>
        </container>
      </template>

      <template v-slot:[`item.stamps`]="{ item }">
        <container v-if="item.stamps.length > 0">
          <v-chip small pill color="primary">{{ item.stamps.length }}</v-chip>
        </container>
        <container v-else>
          <v-chip small pill color="success">{{ 0 }}</v-chip>
        </container>
      </template>

      <template v-slot:[`item.customerName`]="{ item }">
        <span :class="getAlertTextColor(item.alerts.length, item.gender)">
          {{ item.customerName }}</span>
      </template>

      <template v-slot:[`item.anamnesis`]="{ item }">
        <v-chip @click="evaluationDescription(item.description)" outlined small pill
          :color="getAlertColor(item.alerts.length, item.gender)">{{ item.anamnesis }}</v-chip>
      </template>

      <template v-slot:[`item.evaluationDate`]="{ item }">
        <span class="subtitle-2">{{
        (parseInt(item.day) >= 10 ? item.day : "0" + item.day) +
        "/" +
        (parseInt(item.month) >= 10 ? item.month : "0" + item.month) +
        "/" +
        item.year
        }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <Notes :patient="item.customer"></Notes>
        <Profile :patient="item.customer"></Profile>
        <Messages :patient="item.customer"></Messages>
        <Interview :patient="item.customer"></Interview>
        <v-tooltip top color="error">
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="remove(item)" class="mr-1" small text v-bind="attrs" v-on="on" elevation="0" color="error"
              min-width="0" max-width="30" min-height="0" max-height="30">
              <font-awesome-icon :icon="['fal', 'trash']" fixed-width style="font-size: 25px" />
            </v-btn>
          </template>
          <span>Cancelar Avaliação</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { functions, db } from "../../../../firebase";
import Notes from "../../ehr/components/historic/Dialog.vue";
import Messages from "../../ehr/components/message/Dialog.vue";
import Profile from "../../ehr/components/profile/Dialog.vue";
import Interview from "../../ehr/components/interview/Dialog.vue";

let unsubscribe;

export default {
  name: "app-evaluations",
  components: {
    Notes,
    Profile,
    Interview,
    Messages,
  },
  data() {
    return {
      selectedItems: [],
      previousSelection: [],
      overlay: false,
      search: "",
      dialog: false,
      dialogBi: false,
      dialogAlert: false,
      description: null,
      headers: [
        {
          text: "ID",
          value: "id",
          filterable: false,
          sortable: false,
          align: " d-none",
        },
        {
          text: "Avaliação",
          value: "evaluationDate",
          filterable: false,
          sortable: false,
        },
        { text: "Alertas", value: "alert", filterable: false, sortable: false },
        {
          text: "Etiquetas",
          value: "stamps",
          filterable: false,
          sortable: false,
        },
        {
          text: "Pontuação",
          value: "score",
          filterable: false,
          sortable: false,
        },
        { text: "Paciente", value: "customerName", sortable: false },
        { text: "Avaliador", value: "evaluatorName", sortable: false },
        {
          text: "Questionário",
          value: "anamnesis",
          filterable: false,
          sortable: false,
        },
        { text: "Ações", value: "actions", filterable: false, sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters({
      evaluations: "evaluations",
      evaluators: "evaluators",
    }),
    newAlerts() {
      return this.evaluations.filter(function (m) {
        return m.newAlert === true;
      }).length;
    },
    totalAlerts() {
      return this.evaluations.filter(function (m) {
        return m.alerts.length > 0;
      }).length;
    },
  },
  methods: {
    evaluationsListener(list) {
      unsubscribe = db
        .collection("evaluations")
        .where("active", "==", true)
        .where("evaluator", "in", list)
        .onSnapshot({ includeMetadataChanges: false }, (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            /*if (change.type === "added") {
            console.log("New: ", change.doc.data().newAlert);
          }*/
            if (change.type === "modified") {
              if (change.doc.data().newAlert) {
                var audioFile = new Audio(
                  "https://firebasestorage.googleapis.com/v0/b/icareyou-dev.appspot.com/o/sound%2Fmessage_tones.mp3?alt=media&token=d613cb23-725b-4752-a8c4-58348c9ab905"
                );
                audioFile.play();
              }
            }
            /*if (change.type === "removed") {
            console.log("Removed: ", change.doc.data().newAlert);
          }*/
          });
        });
    },
    async selectionChanged(selection) {
      this.overlay = true;
      if (selection.length == 0) {
        await this.$store.dispatch("unbindEvaluations");
        this.overlay = false;
      }
      if (unsubscribe) {
        unsubscribe();
      }
      await this.$store.dispatch("unbindEvaluations");
      let selectedList = await Array.from(selection, (s) => s.id);
      await this.$store.dispatch("bindEvaluations", {
        evaluators: selectedList,
        alert: false,
      });
      this.evaluationsListener(selectedList);
      this.overlay = false;
    },
    customSort: function (items, index, isDesc) {
      if (this.newAlerts > 0) {
        items.sort((a, b) => {
          if (a.newAlert == true && b.newAlert == false) {
            return -1;
          } else if (a.newAlert == false && b.newAlert == true) {
            return 1;
          } else {
            return 0;
          }
        });
      } else {
        items.sort((a, b) => {
          if (a.created > b.created) {
            return -1;
          } else if (a.created < b.created) {
            return 1;
          } else {
            return 0;
          }
        });
      }
      return items;
    },
    remove(evalution) {
      this.$swal
        .fire({
          title: "Cancelar a Avaliação?",
          text: "Você realmente deseja cancelar essa avaliação ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            this.$swal.showLoading();
            this.$swal.getCancelButton().setAttribute("disabled", "");
            /*let sisMonitoringCancel = functions.httpsCallable(
              "sis-monitoring-cancel-api"
            );
            await sisMonitoringCancel({
              monitor: monitor.id,
            })
              .then(async (result) => {
                return;
              })
              .catch((error) => {
                this.$swal.getCancelButton().setAttribute("enabled", "");
                this.$swal.showValidationMessage(
                  `Erro ao cancelar a avaliação: ${error}`
                );
                console.error(error.message);
              });*/
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$swal
              .fire({
                icon: "success",
                title: "Avaliação cancelada com sucesso! ",
                toast: false,
                showConfirmButton: true,
                timer: 1000,
                timerProgressBar: true,
              })
              .then((result) => {
                this.$router.replace({ name: "Evaluations" });
              });
          }
        });
    },

    monitorDescription(description) {
      this.description = description;
      this.dialog = true;
    },
    getColor(gender) {
      if (gender == "Feminino") return "pink";
      else return "primary";
    },
    getAlertColor(length, gender) {
      if (length > 0) return "error--text";
      else return "grey--text text--darken-2";
    },
    getAlertTextColor(length, gender) {
      if (length > 0) return "error--text";
      else return "grey--text text--darken-2";
    },
  },
  beforeCreate() {
    this.$store.dispatch("resetCustomerState");
    this.$store.dispatch("unbindMessage");
    this.$store.dispatch("unbindNotes");
    this.$store.dispatch("unbindEvaluations");
  },
  beforeDestroy() {
    if (unsubscribe) {
      unsubscribe();
    }
  },
  created() {
    this.$store.dispatch("bindEvaluators");
    this.$store.dispatch("bindEvaluations");
  },
};
</script>
<style lang="scss">
@import "../../../../scss/_variables.scss";

.data-studio {
  @media #{$xxlmax-device} {
    width: 100%;
    height: 600px;
  }

  @media #{$xlmax-device} {
    width: 100%;
    height: 700px;
  }

  @media #{$desktop-device} {
    width: 100%;
    height: 600px;
  }

  @media #{$tablet-device} {
    width: 100%;
    height: 800px;
  }

  @media #{$large-mobile} {
    height: 700px;
  }

  @media #{$extra-small-mobile} {
    height: 600px;
  }
}

.select {
  max-width: 500px;
  min-width: 500px;
}

.search {
  max-width: 400px;
  min-width: 400px;
}

.v-dialog {
  border-radius: 6px;
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  z-index: inherit;
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%);
}
</style>