<template>
  <v-card flat width="100%" color="transparent" class="mt-4">
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate :rotate="360" :size="100" :width="8" color="white">
      </v-progress-circular>
    </v-overlay>

    <v-data-table class="elevation-0 transparent" :headers="headers" :items="historic" :search="name"
      :items-per-page="10" :calculate-widths="true" sort-by="customerName">
      <template v-slot:[`item.age`]="{ item }">
        <AgeChip :customer="item.customer"></AgeChip>
      </template>

      <template v-slot:[`item.customerName`]="{ item }">
        <span class="caption"> {{ item.customerName }}</span>
      </template>

      <template v-slot:[`item.start`]="{ item }">
        <span class="caption">{{
                  (item.startDay < 10 ?'0'+ item.startDay:item.startDay) + "/" + (item.startMonth < 10 ?'0'+
        item.startMonth:item.startMonth) + "/" + item.startYear }}</span>
      </template>
      <template v-slot:[`item.end`]="{ item }">
        <span class="caption">{{
                  (item.closeDay < 10 ?'0'+ item.closeDay:item.closeDay) + "/" + (item.closeMonth < 10 ?'0'+
        item.closeMonth:item.closeMonth) + "/" + item.closeYear }}</span>
      </template>

      <template v-slot:[`item.monitoring`]="{ item }">
        <span class="caption">{{ item.monitor.text }}</span>
      </template>

      <template v-slot:[`item.tags`]="{ item }">
        <Tags :monitoring="item"></Tags>
      </template>

      <template v-slot:[`item.execution`]="{ item }">
        <v-progress-circular :rotate="360" :size="40" :width="2" :value="Math.trunc((item.executed / item.total) * 100)"
          color="secondary">
          {{ Math.trunc((item.executed / item.total) * 100) }}%
        </v-progress-circular>
      </template>

      <template v-slot:[`item.cancelled`]="{ item }">
        <v-progress-circular :rotate="360" :size="40" :width="2"
          :value="Math.trunc((item.cancelled / item.total) * 100)" color="error">
          {{ Math.trunc((item.cancelled / item.total) * 100) }}%
        </v-progress-circular>
      </template>

      <template v-slot:[`item.details`]="{ item }">
        <v-btn @click="openMonitor(item)" class="mr-1" small text elevation="0" color="primary" min-width="40"
          max-width="40" min-height="40" max-height="40">
          <font-awesome-icon :icon="['fal', 'timeline-arrow']" fixed-width style="font-size: 25px" />
        </v-btn>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <ActionsMenu :patient="item.customer" :name="item.customerName"></ActionsMenu>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import AgeChip from "../age/AgeChip.vue";
import Tags from "../tags/Tags.vue"
import ActionsMenu from "../../../ehr/components/actions/Menu.vue";

export default {
  name: "app-monitoring",
  components: {
    AgeChip,
    Tags,
    ActionsMenu,
  },
  props: {
    name: {
      type: String,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      tab: null,
      overlay: false,
      headers: [
        {
          text: "ID",
          value: "id",
          filterable: false,
          sortable: false,
          align: " d-none",
        },
        {
          text: "Detalhes",
          value: "details",
          filterable: false,
          sortable: false,
        },
        {
          text: "Classificação",
          value: "tags",
          filterable: false,
          sortable: false,
        },
        { text: "Início", value: "start", filterable: false, sortable: false },
        { text: "Fim", value: "end", filterable: false, sortable: false },
        { text: "Idade", value: "age", sortable: false },
        { text: "Nome", value: "customerName", sortable: false },
        {
          text: "Evolução",
          value: "execution",
          filterable: false,
          sortable: false,
        },
        {
          text: "Não Respondido",
          value: "cancelled",
          filterable: false,
          sortable: false,
        },
        {
          text: "Monitoria",
          value: "monitoring",
          filterable: false,
          sortable: false,
        },
        { text: "Ações", value: "actions", filterable: false, sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters({
      historic: "historic"
    }),
  },
  methods: {
    getColor(gender) {
      if (gender == "Feminino") return "pink";
      else return "primary";
    },
    async openMonitor(monitor) {
      this.overlay = true;
      await this.$store.dispatch("fetchCustomer", {
        customer: monitor.customer,
      });

      await this.$store.dispatch("bindCustomerMonitoringActions", {
        customer: monitor.customer,
        monitoring: monitor.id,
      });
      this.overlay = false;
      this.$router.replace({ name: "Monitor" });
    },
  },
  async created() { },
};
</script>
<style lang="scss">
@import "../../../../../scss/_variables.scss";

.data-studio {
  @media #{$xxlmax-device} {
    width: 100%;
    height: 600px;
  }

  @media #{$xlmax-device} {
    width: 100%;
    height: 700px;
  }

  @media #{$desktop-device} {
    width: 100%;
    height: 600px;
  }

  @media #{$tablet-device} {
    width: 100%;
    height: 800px;
  }

  @media #{$large-mobile} {
    height: 700px;
  }

  @media #{$extra-small-mobile} {
    height: 600px;
  }
}

.select {
  max-width: 500px;
  min-width: 500px;
}

.search {
  max-width: 400px;
  min-width: 400px;
}

.v-dialog {
  border-radius: 6px;
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  z-index: inherit;
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%);
}
</style>