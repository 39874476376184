import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

export default {
  state: {
    views: [],
  },
  actions: {
    bindViews: firestoreAction((context) => {
      let ref = db.collection("views");
      context.bindFirestoreRef("views", ref, {
        wait: true,
        reset: true,
      });
    }),
  },
  getters: {
    views(state) {
      return state.views;
    },
  },
};
