<template>
  <v-card flat class="pa-2">
    <v-card class="mx-auto" flat outlined>
      <v-toolbar dense flat>
        <v-toolbar-title class="text-subtitle-1">
          <v-btn x-small elevation="0" fab text class="white--text" :color="getColor(customer.gender)">
            <font-awesome-icon :icon="['fal', 'calendar-plus']" fixed-width style="font-size: 20px" />
          </v-btn>
          <span class="ml-1 mt-4"> Agendar consulta</span></v-toolbar-title>

        <v-spacer></v-spacer>
      </v-toolbar>

      <v-divider></v-divider>
      <v-card color="transparent" flat class="mt-2 pa-2">
        <v-row><v-col>
            <v-select :items="rooms" label="Salas" dense clearable flat outlined item-text="name" item-value="name"
              hide-details v-model="room" :return-object="true"></v-select>
          </v-col>
          <v-col>
            <v-spacer></v-spacer>
            <v-btn dark color="secondary" elevation="0" @click="saveSchedule()">
              <v-icon dark>mdi-plus</v-icon>Agendar
            </v-btn></v-col>
        </v-row>
        <v-row><v-col>
            <v-card class="rounded" flat min-height="450" width="100%">
              <v-card-title>
                <span>Data</span>
              </v-card-title>
              <v-date-picker v-model="day" full-width color="primary" :min="today" :events="events"
                :picker-date.sync="pickerDate" :allowed-dates="allowedDates"
                event-color="error"></v-date-picker></v-card></v-col>
          <v-col>
            <v-card class="rounded" flat min-height="470" width="100%">
              <v-card-title>
                <span>Horário</span>
              </v-card-title>
              <v-select :items="periods" :disabled="disabledPeriods" label="Períodos" outlined v-model="period"
                :return-object="true"></v-select>
              <v-chip-group column v-model="time">
                <v-row>
                  <v-col>
                    <v-chip :outlined="true" color="secondary" v-for="time in times" :key="time.index"
                      :disabled="time.disabled" :value="time.text">
                      {{ time.text }}
                    </v-chip>
                  </v-col>
                </v-row>
              </v-chip-group>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-card>
  </v-card>
</template>

<script>
import { db } from "../../../../../firebase";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      disabledPeriods: true,
      alert: false,
      timeout: 4000,
      alertMessage: null,
      times: [],
      period: null,
      periods: [],
      day: null,
      today: null,
      landscape: true,
      room: null,
      time: null,
      events: [],
      blocked: [],
      pickerDate: null,
      calendarYear: null,
      calendarMonth: null,
      calendarDay: null,
      calendarWeekday: null,
    };
  },
  created() {
    this.room = this.rooms[0];
    this.today = this.$moment(new Date()).format("YYYY-MM-DD");
    this.calendarYear = this.$moment(new Date()).year();
    this.calendarMonth = this.$moment(new Date()).month() + 1;
  },
  mounted() {
    this.room = this.rooms[0];
  },
  computed: {
    ...mapGetters({
      rooms: "rooms",
      customer: "customer",
    }),
  },
  watch: {
    pickerDate: function (newPicker, oldPicker) {
      this.calendarYear = this.$moment(newPicker).year();
      this.calendarMonth = this.$moment(newPicker).month() + 1;
      this.calendarDay = this.$moment(this.day).date();
      this.getEvents();
    },
    day: function (newDay, oldDay) {
      this.calendarDay = this.$moment(this.day).date();
      this.calendarWeekday = this.$moment(this.day).weekday();
      this.getPeriods();
    },
    period: function (newValue, oldValue) {
      this.times = [];
      this.time = null;
      if (newValue) {
        this.updateTime();
      }
    },
    room: function (newRoom, oldRoom) {
      this.clear();
    },
  },
  methods: {
    getColor(gender) {
      if (gender === "Feminino") return "pink";
      else return "primary";
    },
    clear() {
      this.day = null;
      this.times = [];
      this.time = null;
      this.period = null;
      this.periods = [];
      this.disabledPeriods = true;
    },
    getPeriods() {
      this.times = [];
      this.time = null;
      this.period = null;
      this.periods = null;
      this.disabledPeriods = false;
      this.periods = this.room.periods[this.calendarWeekday];
    },
    allowedDates(val) {
      if (this.room.periods[this.$moment(val).weekday()]) {
        return true;
      }
      return false;
    },
    getEvents() {
      db.collection("calendar")
        .where("customer.uid", "==", this.customer.id)
        .where("year", "==", this.calendarYear)
        .where("month", "==", this.calendarMonth)
        .where("status", "not-in", ["closed", "cancelled"])
        .get()
        .then((calendars) => {
          calendars.forEach((calendar) => {
            this.blocked.push({
              key:
                calendar.data().year.toString() +
                calendar.data().month.toString() +
                calendar.data().day.toString() +
                calendar.data().hour.toString() +
                calendar.data().minute.toString(),
            });
            this.events.push(calendar.data().date);
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    updateTime() {
      let now = new Date().getHours();
      let nowMinutes = new Date().getMinutes();
      let slice = this.period.slice;
      let start = parseInt(this.period.start.substring(0, 2));
      let end = parseInt(this.period.end.substring(0, 2));
      for (var h = start; h <= end; h++) {
        for (var s = 0; s < 60 / slice; s++) {
          let time = {};
          time.hour = h;
          time.minute = s * slice;
          time.text =
            (time.hour < 10 ? "0" + time.hour : time.hour) +
            ":" +
            (time.minute < 10 ? "0" + time.minute : time.minute);

          if (this.day == this.today) {
            if (now > end) {
              time.disabled = true;
            } else {
              if (time.hour >= now) {
                if (time.minute <= nowMinutes && time.hour <= now) {
                  time.disabled = true;
                } else {
                  //colocar a verificação de disponibilidade
                  let key =
                    this.calendarYear.toString() +
                    this.calendarMonth.toString() +
                    this.calendarDay.toString() +
                    time.hour.toString() +
                    time.minute.toString();
                  if (this.blocked.find((b) => b.key === key)) {
                    time.disabled = true;
                  } else {
                    time.disabled = false;
                  }
                }
              } else {
                time.disabled = true;
              }
            }
          } else {
            let key =
              this.calendarYear.toString() +
              this.calendarMonth.toString() +
              this.calendarDay.toString() +
              time.hour.toString() +
              time.minute.toString();
            if (this.blocked.find((b) => b.key === key)) {
              time.disabled = true;
            } else {
              time.disabled = false;
            }
          }
          this.times.push(time);
        }
      }
    },
    async saveSchedule() {
      let start = new Date(`${this.day}T${this.time}:00`);
      let end = new Date(`${this.day}T${this.time}:00`);
      end.setMinutes(end.getMinutes() + parseInt(this.period.slice));
      let event = {};
      event.customer = {};
      event.status = "new";
      event.customer.uid = this.customer.id;
      event.customer.fullName = this.customer.name;
      event.customer.email = this.customer.email;
      event.customer.phone = this.customer.phone;
      event.customer.phoneCode = this.customer.phoneCode;
      event.customer.gender = this.customer.gender;
      event.date = this.day;
      event.day = start.getDate();
      event.dayEnd = end.getDate();
      event.hour = start.getHours();
      event.minute = start.getMinutes();
      event.hourEnd = end.getHours();
      event.minuteEnd = end.getMinutes();
      event.month = parseInt(start.getMonth()) + 1;
      event.monthEnd = parseInt(end.getMonth()) + 1;
      event.year = start.getFullYear();
      event.yearEnd = end.getFullYear();
      event.start =
        event.year +
        "-" +
        event.month +
        "-" +
        event.day +
        "T" +
        event.hour +
        ":" +
        event.minute;
      event.end =
        event.year +
        "-" +
        event.monthEnd +
        "-" +
        event.dayEnd +
        "T" +
        event.hourEnd +
        ":" +
        event.minuteEnd;
      event.name = this.customer.name;
      event.eventSummary =
        "Para você ter acesso a sua teleconsulta, por favor acessar a plataforma digital por meio de nossos canais digitais.";
      event.room = this.room.name;
      event.roomId = this.room.queryId;
      event.second = "00";
      event.secondEnd = "59";
      event.timer = true;
      event.status = "scheduled";
      await db
        .collection("calendar")
        .add(event)
        .then(() => {
          this.$swal.fire({
            icon: "success",
            title: "Agendamento realizado com sucesso! ",
            toast: true,
            position: "bottom",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
  },
  components: {},
};
</script>

<style>

</style>
