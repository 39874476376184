<template>
  <v-toolbar dense rounded outlined elevation="0">
    <v-row class="mt-0">
      <v-col cols="5" class="d-flex justify-start">
        <v-card-text class="mt-n2 ml-n4">
          <span class="caption font-weight-bold grey--text text--darken-1">Nome: </span><span
            class="caption primary--text font-weight-bold">{{ customer.fullName }}
          </span>
          <span class="caption font-weight-bold grey--text text--darken-1">Idade: </span><span
            class="caption primary--text font-weight-bold">{{
            formatBirthdate(customer.birthdate) | moment("from", "now", true)
            }}
          </span>
          <span class="caption font-weight-bold grey--text text--darken-1">Sexo: </span><span
            class="caption primary--text font-weight-bold">{{
            customer.gender
            }}</span>
        </v-card-text>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="mt-1 mr-2 d-flex justify-end">
        <router-link v-if="$routerHistory.hasPrevious()" :to="{ path: $routerHistory.previous().path }">
          <v-btn class="secondary--text" small text elevation="0" color="primary" min-width="40" max-width="30"
            min-height="30" max-height="30">
            <font-awesome-icon :icon="['fal', 'reply']" fixed-width style="font-size: 25px" />
          </v-btn>
        </router-link>
        <ActionsMenu v-if="customer" :name="customer.fullName"></ActionsMenu>
      </v-col>
    </v-row>
  </v-toolbar>
</template>

<script>
import { mapGetters } from "vuex";
import ActionsMenu from "../../ehr/components/actions/Menu.vue";

export default {
  props: {

  },
  components: {
    ActionsMenu,
  },
  computed: {
    ...mapGetters({
      customer: "customer",
    }),
  },
  methods: {
    formatBirthdate(birthdate) {
      return this.$moment(birthdate, "DD/MM/YYYY");
    },
  },
  mounted() {
    
  },
};
</script>

<style>

</style>