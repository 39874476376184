<template>
  <div v-if="menu.action === menuAction">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mx-3"
          v-bind="attrs"
          v-on="on"
          elevation="0"
          small
          text
          color="primary"
          min-width="0"
          max-width="35"
          min-height="0"
          max-height="35"
          @click="action(menu.route)"
        >
          <font-awesome-icon
            :icon="['fal', menu.icon]"
            fixed-width
            style="font-size: 24px"
          />
        </v-btn>
      </template>
      <span>{{ menu.tooltip }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    menu: Object,
    menuAction: String,
  },
  data: () => ({}),
  methods: {
    action(dest) {
      this.$router.push(dest);
    },
  },
  created() {},
};
</script>

<style></style>
