import {firestoreAction} from "vuexfire";
import {db} from "../../firebase";
import moment from "moment";

const getDefaultState = () => {
    return {
        queueInfo: {},
        queueWaiting: [],
        queueInAttendance: [],
        queueClosed: []};
};

const state = getDefaultState();

export default {
    state,
    mutations: {
        SET_QUEUE_INFO(state, value) {
            state.queueInfo = value;
        },
        RESET_WAITING_ROOM_STATE(state) {
            Object.assign(state, getDefaultState());
        }
    },
    actions: {
        fetchQueueInfoReset(context) {
            context.commit("RESET_WAITING_ROOM_STATE");
        },
        async fetchQueueInfo(context, value) {
            let queueId = null;
            if (value.id) {
                queueId = value.id;
            } else {
                queueId = value.queueId;
            }
            let queueRef = db.collection("interviews").doc(queueId);
            let queueDoc = await queueRef.get();
            let queue = {};
            queue = queueDoc.data();
            queue.queueId = queueDoc.id;
            context.commit("SET_QUEUE_INFO", queue);
        },
        unbindQueueWaiting: firestoreAction(
            ({unbindFirestoreRef}) => {
                unbindFirestoreRef("queueWaiting", true);
            }
        ),
        unbindQueueInAttendance: firestoreAction(
            ({unbindFirestoreRef}) => {
                unbindFirestoreRef("queueInAttendance", true);
            }
        ),
        unbindQueueClosed: firestoreAction(
            ({unbindFirestoreRef}) => {
                unbindFirestoreRef("queueClosed", true);
            }
        ),
        bindQueueWaiting: firestoreAction(async (context, data) => {
            let location = data.location;
            let ref = db.collection("interviews");
            ref = ref.where("state", "==", "new");
            ref = ref.where("location.identifier", "in", location);
            await context.bindFirestoreRef("queueWaiting", ref, {reset: true});
        }),
        bindQueueInAttendance: firestoreAction(async (context, data) => {
            let location = data.location;
            let ref = db.collection("interviews");
            ref = ref.where("state", "==", "runing");
            ref = ref.where("location.identifier", "in", location);
            await context.bindFirestoreRef("queueInAttendance", ref, {reset: true});
        }),
        bindQueueClosed: firestoreAction(async (context, data) => {
            let location = data.location;
            let ref = db.collection("interviews");
            ref = ref.where("endDay", "==", moment(new Date()).date());
            ref = ref.where("endMonth", "==", moment(new Date()).month() + 1);
            ref = ref.where("endYear", "==", moment(new Date()).year());
            ref = ref.where("state", "==", "closed");
            ref = ref.where("location.identifier", "in", location);
            await context.bindFirestoreRef("queueClosed", ref, {reset: true});
        })
    },
    getters: {
        queueInfo(state) {
            return state.queueInfo;
        },
        queueWaiting(state) {
            return state.queueWaiting;
        },
        queueInAttendance(state) {
            return state.queueInAttendance;
        },
        queueClosed(state) {
            return state.queueClosed;
        }
    }
};
