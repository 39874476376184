<template>
  <container name="ro-nurse-health-screening">
    <v-row class="d-flex justify-center" no-gutters>
      <v-col>
        <Identification></Identification>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center mt-2 mr-n2" no-gutters>
      <v-col cols="3" class="d-flex-col justify-center">
        <Telemedicine></Telemedicine>
      </v-col>
      <v-col cols="9" class="pl-2 d-flex-col justify-center">
        <v-row class="d-flex justify-start" no-gutters>
          <v-col cols="3" class="d-flex-col justify-center">
            <PreviousQuestionnaireResponses></PreviousQuestionnaireResponses>
          </v-col>

          <v-col cols="9" class="pl-2 pr-2 d-flex-col justify-center">
            <v-row class="d-flex justify-start" no-gutters>
              <v-col cols="12" class="mb-2 d-flex-col justify-center"
                ><Notes></Notes
              ></v-col>
            </v-row>
            <v-row class="d-flex justify-start" no-gutters>
              <v-col cols="6" class="d-flex-col justify-center"></v-col>
              <v-col cols="6" class="pl-2 d-flex-col justify-center">
                <CloseSession></CloseSession></v-col
            ></v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </container>
</template>

<script>
import { mapGetters } from "vuex";
import Identification from "./components/patient/Identification.vue";
import PreviousQuestionnaireResponses from "./components/PreviousQuestionnaireResponses.vue";
import Telemedicine from "../../components/telemedicine/Telemedicine.vue";
import CloseSession from "./components/CloseSession.vue";
import Notes from "./components/HealthNote.vue";

export default {
  components: {
    Identification,
    Telemedicine,
    PreviousQuestionnaireResponses,
    CloseSession,
    Notes,
  },
  computed: {
    ...mapGetters({
      customer: "customer",
      interview: "interview",
    }),
  },
  methods: {},
  created() {
    if (!this.customer.fullName) {
      this.$router.replace({ name: "WaitingRoom" });
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      this.$store.dispatch("saveInterviewDraft", {
        customer: this.customer.id,
        interview: this.interview.id,
      });
    }, 5000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  destroyed() {},
};
</script>

<style></style>
