<template>
  <v-container name="ro-internal-medicine">
    <v-row class="d-flex justify-center" no-gutters>
      <v-col>
        <Identification></Identification>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center mt-2 mr-n2" no-gutters>
      <v-col cols="3" class="d-flex-col justify-center">
        <Telemedicine></Telemedicine>
      </v-col>
      <v-col cols="9" class="pl-2 d-flex-col justify-center">
        <v-row class="d-flex justify-start" no-gutters>
          <v-col cols="3" class="d-flex-col justify-center">
            <PreviousQuestionnaireResponses :sequence="1"></PreviousQuestionnaireResponses>
          </v-col>

          <v-col cols="9" class="pl-2 pr-2 d-flex-col justify-center">
            <v-row class="d-flex justify-start" no-gutters>
              <v-col cols="6" class="mb-2 d-flex-col justify-center">
                <PastHistoryOfCurrentDisease :sequence="2"></PastHistoryOfCurrentDisease>
              </v-col>
              <v-col cols="6" class="pl-2 d-flex-col justify-center">
                <DiagnosticHypothesis :sequence="3"></DiagnosticHypothesis>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-start" no-gutters>
              <v-col cols="6" color="red" class="d-flex-col justify-center">
                <DiagnosticHypothesisCID :sequence="4"></DiagnosticHypothesisCID>
              </v-col>
              <v-col cols="6" class="pl-2 d-flex-col justify-center">
                <MedicalHandling :sequence="5"></MedicalHandling>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-start" no-gutters>
              <v-col cols="6" class="d-flex-col justify-center">
                <Prescription :sequence="6"></Prescription>
              </v-col>
              <v-col cols="6" class="pl-2 d-flex-col justify-center">
                <ReleasePatient :sequence="7"></ReleasePatient>
              </v-col>
            </v-row>
            <v-row class="mt-5 d-flex justify-start" no-gutters>
              <v-col cols="6" class="d-flex-col justify-center">
                <CloseSession :sequence="8"></CloseSession>
              </v-col>
              <v-col cols="6" class="pl-2 d-flex-col justify-center">

              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Identification from "./components/patient/Identification.vue";
import PreviousQuestionnaireResponses from "./components/PreviousQuestionnaireResponses.vue";
import Telemedicine from "../../components/telemedicine/Telemedicine.vue";
import PastHistoryOfCurrentDisease from "./components/PastHistoryOfCurrentDisease.vue";
import DiagnosticHypothesis from "./components/DiagnosticHypothesis.vue";
import DiagnosticHypothesisCID from "./components/DiagnosticHypothesisCID.vue";
import MedicalHandling from "./components/MedicalHandling.vue";
import Prescription from "./components/Prescription.vue";
import CloseSession from "./components/CloseSession.vue";
import ReleasePatient from "./components/ReleasePatient.vue";

export default {
  components: {
    Identification,
    Telemedicine,
    PreviousQuestionnaireResponses,
    PastHistoryOfCurrentDisease,
    DiagnosticHypothesis,
    DiagnosticHypothesisCID,
    MedicalHandling,
    Prescription,
    CloseSession,
    ReleasePatient
  },
  computed: {
    ...mapGetters({
      customer: "customer",
      interview: "interview",
    }),
  },
  methods: {},
  created() {
    if (!this.customer.fullName) {
      this.$router.replace({ name: "WaitingRoom" });
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      this.$store.dispatch("saveInterviewDraft", {
        customer: this.customer.id,
        interview: this.interview.id,
      });
    }, 5000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  destroyed() { },
};
</script>

<style>

</style>
