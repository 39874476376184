import View from "../../../../components/functional/ehr/views/nurseHealthScreening/View.vue";

export default [
  {
    path: "view/nurseHealthScreening",
    name: "nurseHealthScreening",
    component: View,
    meta: {
      requiresAuth: true,
    },
    children: [],
  },
];
