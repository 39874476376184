<template>
  <v-card flat class="transparent">
    <v-card outlined max max-height="40" min-height="40" class="grey lighten-4">
      <v-row>
        <v-col cols="6" class="d-flex justify-start">
          <v-card-text class="mt-n2 ml-n2">
            <span class="font-weight-bold grey--text text--darken-1">Nome: </span><span
              class="primary--text font-weight-bold">{{ customer.fullName }} </span>
            <span class="font-weight-bold grey--text text--darken-1">Idade: </span><span
              class="primary--text font-weight-bold">{{
    formatBirthdate(customer.birthdate)
}} anos
            </span>
            <span class="font-weight-bold grey--text text--darken-1">Sexo: </span><span
              class="primary--text font-weight-bold">{{ customer.gender }}</span>
          </v-card-text>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="6" class="d-flex justify-end mt-1">
          <span class="purple--text mr-2 mt-1 caption ">Sala: </span>
          <span class="purple--text caption mt-1">{{
    queueInfo.location.alias
}} </span>

          <span class="caption mt-1 ml-4 mr-2">Tempo de Consulta:</span>
          <v-card class="mr-10 pa-1" max-height="30" min-height="30" outlined flat>
            <countdown-plus :time="30 * 60 * 1000" format="HH:mm:ss" />
          </v-card>
          <Menu></Menu>
        </v-col>
      </v-row>
    </v-card>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import Menu from "../menu/Menu.vue";

export default {
  components: {
    Menu,
  },
  data() {
    return {
      countDown: 30
    }
  },
  computed: {
    ...mapGetters({
      customer: "customer",
      queueInfo: "queueInfo"
    }),
  },
  methods: {
    formatBirthdate(birthdate) {
      let years = this.$moment().diff(this.$moment(birthdate, "DD/MM/YYYY"), 'years');
      return years;
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      }
    }
  },
  destroyed() { },

  created() {
    this.countDownTimer()
  }
};
</script>

<style>

</style>