import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

const getDefaultState = () => {
  return { waitingRoomKpis: null };
};

const state = getDefaultState();

export default {
  state,
  mutations: {
    RESET_WAITING_ROOM_KPIS(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    resetWaitingRoomKpis({ commit }) {
      commit("RESET_WAITING_ROOM_KPIS");
    },
    unbindWaitingRoomKpis: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef("waitingRoomKpis", true);
    }),
    bindWaitingRoomKpis: firestoreAction((context, data) => {
      let room = data.room;
      let key = data.key;
      let ref = db.collection("kpis-encounter").doc(room);
      let kpisRef = ref.collection("kpis").doc(key);
      context.bindFirestoreRef("waitingRoomKpis", kpisRef, {
        wait: true,
        reset: true,
      });
    }),
  },
  getters: {
    waitingRoomKpis(state) {
      return state.waitingRoomKpis;
    },
  },
};
