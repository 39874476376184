<template>
  <v-card flat width="100%" color="transparent" class="mt-4">
    <v-overlay :value="overlay">
      <v-progress-circular
        :rotate="360"
        :size="100"
        :width="15"
        :value="load"
        color="white"
      >
        {{ Math.trunc(load) }}
      </v-progress-circular>
    </v-overlay>
    <v-data-table
      class="elevation-0 transparent"
      :headers="headers"
      :items="queueWaiting"
      :search="search"
      :items-per-page="10"
      :sort-by="['created']"
      :sort-desc="[false]"
      :calculate-widths="true"
      :loading="loading"
    >
      <template v-slot:[`item.age`]="{ item }">
        <AgeChip :customer="item.uid"></AgeChip>
      </template>

      <template v-slot:[`item.createdDate`]="{ item }">
        <v-chip outlined x-small label pill :color="getColor(item.gender)">{{
          item.createdDate
        }}</v-chip>
      </template>

      <template v-slot:[`item.delay`]="{ item }">
        <v-rating v-model="item.delayAlerts">
          <template v-slot:item="props">
            <v-icon
              class="mr-n2 ml-n2"
              :color="props.isFilled ? 'warning' : 'grey lighten-1'"
              small
            >
              {{ props.isFilled ? "mdi-circle" : "mdi-circle-outline" }}
            </v-icon>
          </template>
        </v-rating>
      </template>

      <template v-slot:[`item.waitTime`]="{ item }">
        <v-chip @click="getQueueInfo(item)" label color="secondary" x-small>{{
          item.created.toDate() | moment("from", "now")
        }}</v-chip>
      </template>

      <template v-slot:[`item.alias`]="{ item }">
        <v-chip label outlined color="primary" x-small>{{
          item.location.alias
        }}</v-chip>
      </template>

      <template v-slot:[`item.fullName`]="{ item }">
        <span class="caption">{{ item.fullName }}</span>
      </template>

      <template v-slot:[`item.phone`]="{ item }">
        <span class="caption">{{ item.phone }}</span>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-card flat class="transparent pa-0 ma-0 d-flex d-flex-row">
          <v-tooltip color="secondary" left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="openInterview(item)"
                small
                text
                elevation="0"
                :color="getColor(item.gender)"
                min-width="30"
                max-width="30"
                min-height="30"
                max-height="30"
              >
                <font-awesome-icon
                  :icon="['fal', 'user-doctor']"
                  fixed-width
                  style="font-size: 25px"
                />
              </v-btn>
            </template>
            <span class="white--text">Iniciar Atendimento</span>
          </v-tooltip>
          <Message :customer="item.uid" :conversation="item.dialog"></Message>
          <ActionsMenu :patient="item.uid" :name="item.fullName"></ActionsMenu>
        </v-card>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { stg, functions } from "../../../../firebase";
import ActionsMenu from "../../ehr/components/actions/Menu.vue";
import AgeChip from "../age/AgeChip.vue";
import Message from "../message/Message.vue";
import copy from "copy-to-clipboard";

export default {
  props: {
    search: {
      type: String,
      required: true,
      default: null,
    },
    loading: {
      type: Boolean,
      required: true,
      default: null,
    },
  },
  components: {
    ActionsMenu,
    AgeChip,
    Message,
  },
  data() {
    return {
      overlay: false,
      load: 0,
      headers: [
        { text: "Espera", value: "waitTime", sortable: false },
        { text: "Alertas de Atraso", value: "delay", sortable: false },
        { text: "Idade", value: "age" },
        { text: "Nome", value: "fullName" },
        { text: "Contato", value: "phone" },
        { text: "Sala", value: "alias" },
        { text: "Iniciar Atendimento?", value: "action", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters({
      interview: "interview",
      professional: "professional",
      queueWaiting: "queueWaiting",
      views: "views",
      rooms: "rooms",
    }),
  },
  methods: {
    getQueueInfo(queue) {
      copy(queue.id, { debug: false });
    },
    getBirthDate(birthdate) {
      return this.$moment(birthdate, "DD/MM/YYYY");
    },
    async getProfilePicture(file) {
      stg
        .ref()
        .child(file)
        .getDownloadURL()
        .then((url) => {
          return url.toString();
        });
    },
    getColor(gender) {
      if (gender === "Feminino") return "pink";
      else return "primary";
    },
    async openInterview(queueInfo) {
      this.overlay = true;
      let _room = this.rooms.find(
        (room) => room.queryId === queueInfo.location.identifier
      );

      let _roomView = _room.views.find(
        (view) => view.profile === this.professional.profile
      );

      let _encounterView = _roomView.view;

      if (_roomView.list && _roomView.list.length > 0) {
        if (this.professional.dev) {
          let inputOptions = {};
          for (let index = 0; index < _roomView.list.length; index++) {
            inputOptions[_roomView.list[index]] = _roomView.list[index];
          }
          await this.$swal
            .fire({
              title: "Versão da Sala",
              text: "Você deseja realizar o atendimento com qual versão da sala de atendimento?",
              icon: "info",
              input: "select",
              inputOptions: inputOptions,
              inputPlaceholder: "Selecione a versão...",
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Selecionar",
              showLoaderOnConfirm: true,
              preConfirm: async (value) => {
                this.$swal.showLoading();
                this.$swal.getCancelButton().setAttribute("disabled", "");
                _encounterView = value;
              },
            })
            .then((result) => {
              if (result.isConfirmed) {
                this.$swal.fire({
                  icon: "success",
                  title: "Versão da sala selecionada! ",
                  position: "bottom",
                  toast: true,
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              }
            });
        }
      }

      let _view = this.views.find((view) => view.queryId === _encounterView);

      //Fetch Customer
      this.load += 100 / 8;
      await this.$store.dispatch("fetchCustomer", { customer: queueInfo.uid });

      //Fetch History
      this.load += 100 / 8;
      await this.$store.dispatch("fetchDatasourceEHR", {
        customer: queueInfo.uid,
      });

      //Fetch Resources
      this.load += 100 / 8;
      await this.$store.dispatch("fetchInterview", {
        queue: queueInfo,
        view: _view,
      });

      //Fetch Queue
      this.load += 100 / 8;
      await this.$store.dispatch("fetchQueueInfo", queueInfo);

      //Fetch Resources
      this.load += 100 / 8;
      await this.$store.dispatch("bindChat", { id: queueInfo.dialog });

      //Fetch PrimaryCare
      this.load += 100 / 8;
      await this.$store.dispatch("bindPrimaryCares");

      //Add Practitioner
      let sisInterviewAddPractitioner = functions.httpsCallable(
        "sis-interview-add-practitioner-api"
      );
      await sisInterviewAddPractitioner({
        customer: queueInfo.uid,
        interview: this.interview.id,
        queue: queueInfo.id,
        professional: this.professional,
        action: "start",
      })
        .then((result) => {
          this.load = 100;
          this.overlay = false;
          this.$router.replace({ name: _view.name });
        })
        .catch((error) => {
          console.error(error.message);
        });
    },
  },
};
</script>
<style>
.select {
  max-width: 500px;
  min-width: 500px;
}

.search {
  max-width: 400px;
  min-width: 400px;
}
</style>