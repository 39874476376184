<template>
  <v-card class="card" flat rounded>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        :rotate="360"
        :size="100"
        :width="8"
        color="white"
      >
      </v-progress-circular>
    </v-overlay>
    <v-sheet>
      <v-toolbar flat rounded elevation="0">
        <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
          HOJE
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="prev">
          <v-icon small> mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="next">
          <v-icon small> mdi-chevron-right </v-icon>
        </v-btn>
        <v-toolbar-title v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-select
          color="purple"
          dense
          outlined
          :items="professionalRooms"
          v-model="room"
          label="Sala"
          item-text="name"
          @change="(selection) => selectionChanged(selection)"
          :return-object="true"
          class="mt-6 mr-2 caption"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip small outlined v-if="index === 0">
              <span class="caption">{{ item.name }}</span>
            </v-chip>
          </template>
        </v-select>
        <v-select
          :disabled="room != null ? false : true"
          color="purple"
          dense
          outlined
          :items="types"
          v-model="type"
          label="Visualizar por"
          class="mt-6 mr-2 caption"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip small outlined v-if="index === 0">
              <span class="caption">{{ item.text }}</span>
            </v-chip>
          </template>
        </v-select>
        <v-select
          :disabled="room != null ? false : true"
          color="purple"
          dense
          outlined
          :items="nextAvailableDays"
          v-model="days"
          label="Próximos dias com disponibilidade de horário"
          class="mt-6 caption"
          @change="(selection) => daysSelectionChanged(selection)"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip small outlined v-if="index === 0">
              <span class="caption">{{ item.text }}</span>
            </v-chip>
          </template>
        </v-select>
      </v-toolbar>
    </v-sheet>
    <v-sheet class="ml-4 mr-4 calendar">
      <v-calendar
        ref="calendar"
        flat
        v-model="focus"
        color="primary"
        :events="events"
        :event-more="true"
        event-overlap-mode="column"
        :event-color="getEventColor"
        :type="type"
        @click:event="showEvent"
        @click:more="viewDay"
        @click:date="viewDay"
        @change="updateRange"
      >
        <template v-slot:event="{ event }">
          <span v-if="event.minute < 10" class="ml-2"
            ><span
              v-if="event.status == 'cancelled'"
              class="text-decoration-line-through"
              >{{ event.hour }}:0{{ event.minute }} -
              {{ event.customer.fullName }}
            </span>
            <span v-else
              >{{ event.hour }}:0{{ event.minute }} -
              {{ event.customer.fullName }}</span
            >
          </span>
          <span v-else class="ml-2">
            <span
              v-if="event.status == 'cancelled'"
              class="text-decoration-line-through"
              >{{ event.hour }}:{{ event.minute }} -
              {{ event.customer.fullName }}
            </span>
            <span v-else
              >{{ event.hour }}:{{ event.minute }} -
              {{ event.customer.fullName }}</span
            >
          </span>
        </template>
      </v-calendar>
      <!-- Event details -->
      <v-menu
        v-if="selectedEvent.status != 'available'"
        min-width="600px"
        max-width="600px"
        v-model="selectedOpen"
        :close-on-content-click="false"
        :activator="selectedElement"
        offset-y
      >
        <v-card class="pa-1" min-width="600px" max-width="600px" flat>
          <v-toolbar dense rounded outlined elevation="0">
            <v-card-title class="ml-n6 grey--text text--darken-2">
              <font-awesome-icon
                @click="getEventId(selectedEvent.id)"
                :icon="['fal', 'calendar-plus']"
                fixed-width
                style="font-size: 20px"
              />
              <span class="mt-1">{{ selectedEvent.customer.fullName }}</span>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom color="warning">
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  :disabled="
                    selectedEvent.status == 'blocked' ||
                    selectedEvent.status == 'cancelled' ||
                    selectedEvent.status == 'closed'
                  "
                  icon
                  min-width="30"
                  v-on="{ ...tooltip }"
                  max-width="30"
                  min-height="30"
                  max-height="30"
                  color="warning"
                  @click="noShow()"
                >
                  <font-awesome-icon
                    :icon="['fal', 'user-slash']"
                    fixed-width
                    style="font-size: 20px"
                  />
                </v-btn>
              </template>
              <span>Não Compareceu</span>
            </v-tooltip>
            <v-tooltip bottom color="error">
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  :disabled="
                    selectedEvent.status == 'cancelled' ||
                    selectedEvent.status == 'closed'
                  "
                  icon
                  min-width="30"
                  v-on="{ ...tooltip }"
                  max-width="30"
                  min-height="30"
                  max-height="30"
                  color="red darken-4"
                  @click="close()"
                >
                  <font-awesome-icon
                    :icon="['fal', 'trash']"
                    fixed-width
                    style="font-size: 20px"
                  />
                </v-btn>
              </template>
              <span>Cancelar Agendamento</span>
            </v-tooltip>
            <EditCalendar
              v-if="
                selectedEvent.status != 'blocked' &&
                selectedEvent.status != 'closed' &&
                selectedEvent.status != 'cancelled'
              "
              :roomId="selectedEvent.roomQueryId"
              :eventId="selectedEvent.id"
              :patient="selectedEvent.customer.uid"
              @closeSchedule="closeSchedule"
            ></EditCalendar>
            <ActionsMenu
              v-if="selectedEvent.status != 'blocked'"
              :patient="selectedEvent.customer.uid"
              :name="selectedEvent.customer.fullName"
            ></ActionsMenu>
          </v-toolbar>
          <v-card-text class="pa-2">
            <v-row>
              <v-col class="mt-1">
                <JourneyName
                  v-if="selectedEvent.flowInstance"
                  :instance="selectedEvent.flowInstance"
                ></JourneyName>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mt-n6">
                <v-text-field
                  prepend-icon="mdi-account"
                  dense
                  flat
                  readonly
                  :value="selectedEvent.customer.fullName"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mt-n6">
                <v-text-field
                  prepend-icon="mdi-clock"
                  dense
                  flat
                  readonly
                  :value="
                    (selectedEvent.day < 10
                      ? '0' + selectedEvent.day
                      : selectedEvent.day) +
                    '/' +
                    (selectedEvent.month < 10
                      ? '0' + selectedEvent.month
                      : selectedEvent.month) +
                    '/' +
                    selectedEvent.year +
                    ' às ' +
                    (selectedEvent.hour < 10
                      ? '0' + selectedEvent.hour
                      : selectedEvent.hour) +
                    ':' +
                    (selectedEvent.minute < 10
                      ? '0' + selectedEvent.minute
                      : selectedEvent.minute)
                  "
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="selectedEvent.status != 'blocked'">
              <v-col class="mt-n6">
                <v-text-field
                  prepend-icon="mdi-phone"
                  dense
                  flat
                  readonly
                  :value="selectedEvent.customer.phone"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="mt-n6 mr-0">
            <v-spacer></v-spacer>
            <v-btn
              text
              dark
              color="secondary"
              elevation="0"
              @click="selectedOpen = false"
            >
              <v-icon dark>mdi-close</v-icon>FECHAR
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-sheet>
    <v-system-bar class="ml-2" color="transparent" height="30">
      <span class="caption">LEGENDA: </span>
      <v-chip
        x-small
        label
        class="ml-2 error darken-4 text-decoration-line-through"
        dark
      >
        <span class="caption">AGENDAMENTO CANCELADO</span>
      </v-chip>
      <v-chip x-small label class="ml-2 purple" dark>
        <span class="caption">PACIENTE AGENDADO</span>
      </v-chip>
      <v-chip x-small label class="ml-2 warning" dark>
        <span class="caption">PACIENTE NÃO COMPARECEU</span>
      </v-chip>
      <v-chip x-small label class="ml-2 grey" dark>
        <span class="caption">CONSULTA REALIZADA</span>
      </v-chip>
      <v-chip x-small label class="ml-2 error" dark>
        <span class="caption">PACIENTE INATIVO AGENDADO</span>
      </v-chip>
      <v-chip x-small label class="ml-2 black" dark>
        <span class="caption">BLOQUEADO</span>
      </v-chip>
    </v-system-bar>
  </v-card>
</template>

<script>
import { db, functions } from "../../../../../firebase";
import { mapGetters } from "vuex";
import ActionsMenu from "../actions/Menu.vue";
import EditCalendar from "./RescheduleDialog.vue";
import JourneyName from "./JourneyName.vue";
import copy from "copy-to-clipboard";

export default {
  components: { ActionsMenu, EditCalendar, JourneyName },
  data: () => ({
    professionalRooms: [],
    days: 1,
    overlay: false,
    focus: "",
    type: "month",
    room: null,
    start: null,
    end: null,
    types: [
      { text: "Dia", value: "day" },
      { text: "Semana", value: "week" },
      { text: "Mês", value: "month" },
    ],
    nextAvailableDays: [
      { text: "1 dia", value: 1 },
      { text: "2 dias", value: 2 },
      { text: "3 dias", value: 3 },
      { text: "4 dias", value: 4 },
      { text: "5 dias", value: 5 },
      { text: "6 dias", value: 6 },
      { text: "7 dias", value: 7 },
      { text: "8 dias", value: 8 },
      { text: "9 dias", value: 9 },
      { text: "10 dias", value: 10 },
      { text: "11 dias", value: 11 },
      { text: "12 dias", value: 12 },
      { text: "13 dias", value: 13 },
      { text: "14 dias", value: 14 },
      { text: "15 dias", value: 15 },
    ],
    selectedEvent: {
      customer: {
        email: "",
        fullName: "",
        phone: "",
      },
    },
    selectedElement: null,
    selectedOpen: false,
    events: [],
    timer: null,
  }),
  computed: {
    ...mapGetters({
      rooms: "rooms",
      professional: "professional",
    }),
  },
  async created() {
    this.professionalRooms = await this.rooms.filter(function (m) {
      return this.findIndex((pm) => pm == m.queryId) > -1;
    }, this.professional.rooms);
  },
  mounted() {
    this.$refs.calendar.checkChange();
  },
  methods: {
    getEventId(id) {
      copy(id, { debug: false });
    },
    selectionChanged(selection) {
      this.room = selection;
      if (this.room) {
        this.updateCalendar();
      }
    },
    daysSelectionChanged(selection) {
      if (this.room) {
        this.updateCalendar();
      }
    },
    closeSchedule() {
      this.selectedOpen = false;
      this.updateCalendar();
    },
    async updateCalendar() {
      this.overlay = true;

      let sisCalendarGetEvents = functions.httpsCallable(
        "sis-calendar-get-events-api"
      );
      await sisCalendarGetEvents({
        days: this.days,
        year: this.start.year,
        month: [this.start.month, this.end.month],
        room: this.room.queryId,
      })
        .then(async (result) => {
          this.overlay = false;
          this.events = result.data.events;
          return;
        })
        .catch((error) => {
          console.error(error.message);
        });
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    close() {
      this.$swal
        .fire({
          title: "Cancelar?",
          text: "Você deseja cancelar esse agendamento?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        })
        .then((result) => {
          if (result.value) {
            this.selectedOpen = false;
            db.collection("calendar")
              .doc(this.selectedEvent.id)
              .update({
                status: "cancelled",
              })
              .then(() => {
                this.updateCalendar();
              });
          }
        });
    },
    noShow() {
      this.$swal
        .fire({
          title: "Faltou?",
          text: "Você deseja informar que esse paciente não compareceu na teleconsulta?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        })
        .then((result) => {
          if (result.value) {
            this.selectedOpen = false;
            db.collection("calendar")
              .doc(this.selectedEvent.id)
              .update({
                status: "no-show",
                color: "warning",
              })
              .then(() => {
                this.updateCalendar();
              });
          }
        });
    },
    block(event) {
      this.overlay = true;
      this.$swal
        .fire({
          title: "Bloquear?",
          text:
            "Você deseja bloquear o horário de " +
            event.hour +
            ":" +
            (event.minute < 10 ? "0" + event.minute : event.minute) +
            " para novo agendamento?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        })
        .then(async (result) => {
          if (result.value) {
            let sisCalendarBlock = functions.httpsCallable(
              "sis-calendar-block-api"
            );
            await sisCalendarBlock({
              event: event,
            })
              .then(async (result) => {
                this.overlay = false;
                this.updateCalendar();
              })
              .catch((error) => {
                console.error(error.message);
              });
          }
          this.overlay = false;
        });
    },
    showEvent({ nativeEvent, event }) {
      if (event.status != "available") {
        const open = () => {
          this.selectedEvent = event;
          this.selectedElement = nativeEvent.target;
          setTimeout(() => {
            this.selectedOpen = true;
          }, 5);
        };

        if (this.selectedOpen) {
          this.selectedOpen = false;
          setTimeout(open, 5);
        } else {
          open();
        }

        nativeEvent.stopPropagation();
      } else {
        this.block(event);
      }
    },
    updateRange({ start, end }) {
      this.start = start;
      this.end = end;
      if (this.room) {
        this.updateCalendar();
      }
    },
  },
};
</script>
<style lang="scss" scope>
@import "../../../../../scss/_variables.scss";

.card {
  @media #{$xxlmax-device} {
    height: 800px;
  }

  @media #{$xlmax-device} {
    height: 600px;
  }

  @media #{$desktop-device} {
    height: 600px;
  }

  @media #{$tablet-device} {
    height: 850px;
  }

  @media #{$large-mobile} {
    height: 700px;
  }

  @media #{$extra-small-mobile} {
    height: 600px;
  }
}

.calendar {
  @media #{$xxlmax-device} {
    height: 750px;
  }

  @media #{$xlmax-device} {
    height: 550px;
  }

  @media #{$desktop-device} {
    height: 600px;
  }

  @media #{$tablet-device} {
    height: 850px;
  }

  @media #{$large-mobile} {
    height: 700px;
  }

  @media #{$extra-small-mobile} {
    height: 600px;
  }
}

.select {
  max-width: 650px;
  min-width: 650px;
}
</style>