<template>
  <v-card class="mx-auto" flat>
    <v-card class="mb-2 rounded" flat color="grey lighten-3">
      <v-toolbar flat height="35" elevation="0" class="secondary">
        <v-toolbar-title class="subtitle-2 white--text">{{ sequence }}. Linha de Cuidado</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card>
    <v-card class="mt-2 pa-0" flat>
      <PrimaryCare></PrimaryCare>
    </v-card>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import PrimaryCare from "../../../../components/primaryCare/PrimaryCare.vue";

export default {
  props: {
    sequence: {
      type: Number,
      required: true,
      default: null,
    },
  },
  data: () => ({

  }),
  components: { PrimaryCare },
  computed: {
    ...mapGetters({
    }),
  },

  methods: {
  },
  created() {
  },
};
</script>

<style>

</style>
