import {firestoreAction} from "vuexfire";
import {db} from "../../firebase";

export default {
    state: {
        rooms: []
    },
    actions: {
        bindRooms: firestoreAction(
            (context) => {
                let ref = db.collection("rooms");
                context.bindFirestoreRef("rooms", ref, {
                    wait: true,
                    reset: true
                });
            }
        )
    },
    getters: {
        rooms(state) {
            return state.rooms;
        }
    }
};
