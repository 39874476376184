<template>
  <div v-if="!alert.treated">
    <v-dialog id="editAlert" v-model="dialog" width="850" eager>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate :rotate="360" :size="100" :width="8" color="white">
        </v-progress-circular>
      </v-overlay>
      <template v-slot:activator="{ on, attrs }">
        <v-hover v-slot="{ hover }" open-delay="10">
          <v-btn class="mx-1" x-small color="error" v-bind="attrs" v-on="on" :elevation="hover ? 3 : 8">
            <span class="white--text">Encerrar</span>
          </v-btn>
        </v-hover>
      </template>

      <v-card>
        <v-card-title class="text-subtitle-2">
          <font-awesome-icon :icon="['fal', 'exclamation-triangle']" fixed-width color="orange" style="font-size: 15px"
            class="mt-4" />
          <span class="ml-1 mt-4 grey--text text--darken-2">Resolução do Alerta</span>
          <v-spacer></v-spacer>
          <span class="caption grey--text ml-1 mt-4">
            Por favor, informe com detalhes a resolução do alerta.
          </span>
        </v-card-title>
        <v-card-text>
          <v-select class="mb-n5" :items="actions" label="Ação" item-text="text" clearable outlined v-model="action"
            :return-object="true"></v-select>
        </v-card-text>
        <v-card-text class="mt-n5">
          <TiptapVuetify min-height="300" autoFocus="true" v-model="note" :extensions="extensions"
            :card-props="{ flat: true, color: 'grey lighten-5' }" />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn dark color="error" elevation="0" text @click="closeAlert()">
            <v-icon dark>mdi-close</v-icon> Encerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { functions, db } from "../../../../../firebase";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";

export default {
  components: { TiptapVuetify },
  data() {
    return {
      actions: [{}],
      action: null,
      dialog: false,
      note: null,
      overlay: false,
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3, 4, 5],
            },
          },
        ],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak,
      ],
    };
  },
  computed: {
    ...mapGetters({
      customer: "customer",
      professional: "professional",
      monitors: "monitors"
    }),
  },
  methods: {
    closeAlert() {
      if (this.note && this.action) {
        this.overlay = true;

        let sisMonitoringCloseAlert = functions.httpsCallable(
          "sis-monitoring-alert-close-api"
        );

        sisMonitoringCloseAlert({
          monitor: this.monitor.id,
          text: this.note,
          professional: this.professional,
          alert: this.alert.id,
          action: this.action,
        })
          .then(async (result) => {
            if (this.alert.type == "healthAlert") {
              let sisEhrNoteSave = functions.httpsCallable(
                "sis-ehr-clinical-save-note-api"
              );
              await sisEhrNoteSave({
                customer: this.customer.id,
                text: this.note,
                professional: this.professional,
                config: {
                  type: "monitor",
                  icon: "face-thermometer",
                  color: "red",
                  colorCard: "red-color",
                },
              }).then((result) => {
                this.$swal.fire({
                  icon: "success",
                  title: "Alerta encerrado com sucesso! ",
                  toast: true,
                  position: "bottom",
                  showConfirmButton: false,
                  timer: 2000,
                  timerProgressBar: true,
                });
                this.$emit("closeAlert");
                this.overlay = false;
                this.note = null;
                this.dialog = false;
              });
            }
          })
          .catch((error) => {
            console.error(error.message);
          });
      }
    },
  },
  props: {
    alert: {
      type: Object,
      required: true,
      default: null,
    },
    monitor: {
      type: Object,
      required: true,
      default: null,
    },
  },
  async created() {
    await db.collection("monitoring").doc(this.monitor.monitoring).get().then(monitoring => {
      this.actions = this.monitors.find(monitor => monitor.queryId == monitoring.data().monitor.queryId).actions;
    })

  },
};
</script>

<style>

</style>
