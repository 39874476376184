<template>
  <span v-if="status" @click="getInstanceInfo(journey)" class="caption">
    {{ journey.customerName }}</span
  >
  <container v-else>
    <v-chip
      label
      x-small
      outlined
      color="error"
      @click="getInstanceInfo(journey)"
      class="caption error--text mt-n1"
    >
      INATIVO
    </v-chip>
    <span @click="getInstanceInfo(journey)" class="caption error--text">
      {{ journey.customerName }}</span
    >
  </container>
</template>

<script>
import { db } from "../../../../../firebase";
import copy from "copy-to-clipboard";

export default {
  components: {},
  props: {
    customer: {
      type: String,
      required: false,
      default: null,
    },
    journey: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      status: 0,
    };
  },
  watch: {},
  computed: {},
  methods: {
    getInstanceInfo(queue) {
      copy(queue.instance, { debug: false });
    },
  },
  async created() {
    let customerDoc = await db.collection("customer").doc(this.customer).get();
    this.status = customerDoc.data().active;
  },
};
</script>
