import { firestoreAction } from "vuexfire";
import { db, functions } from "../../firebase";

export default {
  state: {
    notes: [],
  },
  actions: {
    unbindNotes: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef("notes", true);
    }),
    bindNotes: firestoreAction(async (context, data) => {
      let ref = db
        .collection("electronic-health-record")
        .doc(data.customer)
        .collection("clinical")
        .doc("health-note")
        .collection("notes")
        .orderBy("created", "desc");
      await context.bindFirestoreRef("notes", ref, {
        reset: true,
      });
    }),
  },
  getters: {
    notes(state) {
      return state.notes;
    },
  },
};
