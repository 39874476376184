<template>
  <v-navigation-drawer app :isOpen="mini" :expand-on-hover="true" :permanent="true" color="blue darken-2"
    :hide-overlay="true" dark class="elevation-18">
    <v-system-bar class="mb-n4 transparent" height="20">
      <v-spacer></v-spacer>
      <span class="caption font-weight-thin">
        {{
          $sisVersion.version.major +
          "." +
          $sisVersion.version.minor +
          "." +
          $sisVersion.version.patch
        }}
      </span>
    </v-system-bar>
    <v-list dense nav class="py-0 blue darken-2">
      <v-list-item two-line>
        <v-list-item-avatar class="profile rounded-lg" size="30">
          <img v-if="professional.profilePic" :src="professional.profilePic" />
          <v-icon v-else @click="getSessionId()">mdi-account-circle</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{ professional.fullName }}</v-list-item-title>
          <v-list-item-subtitle>{{
            professional.professionalDesc
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mb-4"></v-divider>
      <template>
        <DrawerItem v-for="item in items" :key="item.router" :item="item"></DrawerItem>
      </template>
    </v-list>
    <template v-slot:append>
      <v-list-item v-if="professional.professionalType == 'technology'" link @click="openZendesk">
        <v-list-item-action>
          <font-awesome-icon :icon="['fal', 'ticket']" style="font-size: 20px" fixed-width />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            <span class="caption font-weight-light">Suporte</span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link @click="signOut">
        <v-list-item-action>
          <font-awesome-icon :icon="['fal', 'sign-out']" style="font-size: 20px" fixed-width />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Sair</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-card id="conversation-badge"></v-card>
</v-navigation-drawer>
</template>

<script>


import { mapGetters } from "vuex";
import { auth } from "../../firebase";
import DrawerItem from "./DrawerItem";
import copy from "copy-to-clipboard";

export default {
  name: "app-drawer",
  data: () => ({
    available: true,
    mini: false,
    body: document.querySelector("body"),
    open: false,
  }),
  components: {
    DrawerItem,
  },
  computed: {
    ...mapGetters({
      professional: "professional",
      items: "items",
      session: "session"
    }),
  },

  methods: {
    getSessionId() {
      copy(this.session.id, { debug: false });
    },
    openZendesk() {
      if (this.open) {
        window.zE("messenger", "close");
      } else {
        window.zE("messenger", "open");
      }
      this.open = !this.open;
    },
    signOut() {
      this.$swal
        .fire({
          title: "Sair do Sistema",
          text: "Você realmente deseja sair do iCareYou?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        })
        .then((result) => {
          if (result.value) {
            auth.signOut().then(() => {
              window.zE("messenger", "close");
              this.$router.replace({
                name: "Root",
              });
            });
          }
        });
    },
  },
  mounted() {
    let script = document.createElement("script");
    script.setAttribute("id", "ze-snippet");
    script.setAttribute("type", "text/javascript");
    script.src =
      "https://static.zdassets.com/ekr/snippet.js?key=42bfdae1-5971-4a69-abc4-171fd8034e92";
    script.onload = function () { };
    document.head.appendChild(script);
  },
};
</script>

<style></style>
