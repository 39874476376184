<template>
  <v-card v-if="surveys.length > 0" flat width="100%" color="transparent" class="mt-4">
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate :rotate="360" :size="100" :width="8" color="white">
      </v-progress-circular>
    </v-overlay>

    <v-data-table class="elevation-0 transparent" :headers="headers" :items="surveys" :search="search"
      :items-per-page="13" :calculate-widths="true" sort-by="created">


      <template v-slot:[`item.age`]="{ item }">
        <AgeChip :customer="item.customer"></AgeChip>
      </template>

      <template v-slot:[`item.created`]="{ item }">
        <span class="caption">{{
    item.created.toDate() | moment("DD/MM/YYYY hh:mm:ss")
}}</span>
      </template>

      <template v-slot:[`item.customerName`]="{ item }">
        <span class="caption">{{
    item.customerName
}}</span>
      </template>

      <template v-slot:[`item.sourceText`]="{ item }">
        <span class="caption">{{
    item.sourceText
}}</span>
      </template>

      <template v-slot:[`item.surveyId`]="{ item }">
        <span class="caption">{{
    item.surveyId
}}</span>
      </template>

      <template v-slot:[`item.ended`]="{ item }">
        <span class="caption">{{
    item.ended.toDate() | moment("DD/MM/YYYY hh:mm:ss")
}}</span>
      </template>

      <template v-slot:[`item.startMonth`]="{ item }">
        <MonitoringStartMonth :monitoring="item.monitoring"></MonitoringStartMonth>
      </template>

      <template v-slot:[`item.survey`]="{ item }">
        <Answers :answers="item.answers"></Answers>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <ActionsMenu :patient="item.customer" :name="item.customerName"></ActionsMenu>
      </template>
    </v-data-table>
  </v-card>
  <v-card v-else color="transparent" flat width="100%" class="mt-4">
    <v-card-text class="subtitle-2 grey--text text--darken-1">Não há pesquisas de satisfação respondidas!</v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import AgeChip from "../patient/AgeChip.vue";
import ActionsMenu from "../../../../ehr/components/actions/Menu.vue";
import MonitoringStartMonth from "../monitoring/StartMonth.vue";
import Answers from "../answer/Answers.vue"

export default {
  name: "app-monitoring",
  components: {
    AgeChip,
    ActionsMenu,
    MonitoringStartMonth,
    Answers
  },
  props: {
    search: {
      type: String,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      tab: null,
      overlay: false,
      dialog: false,
      headersDetails: [
        { text: "Questão", value: "question", filterable: false, sortable: false },
        {
          text: "Resposta",
          value: "text",
          filterable: false,
          sortable: false,
        }
      ],
      headers: [
        {
          text: "ID",
          value: "id",
          filterable: false,
          sortable: false,
          align: " d-none",
        },
        { text: "Respostas", value: "survey", filterable: false, sortable: false },
        {
          text: "Enviado",
          value: "created",
          filterable: false,
          sortable: false,
        },
        {
          text: "Respondido",
          value: "ended",
          filterable: false,
          sortable: false,
        },
        { text: "Paciente", value: "customerName", sortable: false },
        { text: "Idade", value: "age", filterable: false, sortable: false },
        { text: "Origem", value: "sourceText", filterable: false, sortable: false },
        {
          text: "Pesquisa",
          value: "surveyId",
          filterable: false,
          sortable: false,
        },
        { text: "Ações", value: "actions", filterable: false, sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters({
      surveys: "surveys",
    }),
  },
  methods: {

    getColor(gender) {
      if (gender == "Feminino") return "pink";
      else return "primary";
    },
  }
};
</script>