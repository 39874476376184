<template>
  <v-dialog v-model="dialog" max-width="1200" eager>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" text small elevation="0" class="grey--text text--darken-2 align-center"
        min-width="40" max-width="40" min-height="40" max-height="40">
        <font-awesome-icon :icon="['fal', 'thumbs-up']" fixed-width style="font-size: 25px" />
      </v-btn>
    </template>
    <v-card flat class="pa-1">
      <v-card class="mx-auto" flat outlined>
        <v-toolbar dense flat>
          <v-toolbar-title class="text-subtitle-1">
            <v-btn text small elevation="0" class="mr-1 grey--text text--darken-1" min-width="40" max-width="40"
              min-height="40" max-height="40">
              <font-awesome-icon :icon="['fal', 'thumbs-up']" fixed-width style="font-size: 25px" />
            </v-btn>
            <span class="ml-1 mt-4">Respostas da Pesquisa de Satisfação</span>
          </v-toolbar-title>
        </v-toolbar>

        <v-divider></v-divider>
        <v-card flat color="transparent" class="d-flex justify-center">
          <v-card-text>
            <v-row>
              <v-col class="d-flex justify-center align-self-baseline flex-wrap">
                <v-card width="100%" flat>

                  <v-simple-table fixed-header height="400px" v-if="answers != null && answers.length > 0">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th width="50%" class="text-justify">
                            Pergunta
                          </th>
                          <th class="text-center">
                            Resposta
                          </th>
                          <th class="text-center">
                            Valor
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(answer, i) in answers" :key="i">
                          <td class="text-justify">{{ (i+1)+ " - "+ answer.question }}</td>
                          <td class="text-center font-weight-medium">{{ answer.text }}</td>
                          <td class="text-center font-weight-medium">{{ answer.value }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-list-item-group v-else color="primary">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="text-caption">
                          Não há respostas para a pesquisa de satisfação.
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  props: {
    answers: {
      type: Array,
      required: false,
      default: null,
    }
  },
  data() {
    return {
      dialog: false,
      anamnesisNotAnsweredAlerts: 0,
    };
  },
  watch: {
  },
  computed: {
    ...mapGetters({
    }),
  },
  methods: {
  },
  async created() {
  },
};
</script>