<template>
  <span class="caption text-capitalize">{{ "0" + startMonth | moment("MMMM")}}{{"/" + startYear}}
  </span>
</template>

<script>
import { db } from "../../../../../../firebase";

export default {
  props: {
    monitoring: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      startMonth: null,
      startYear: null
    };
  },
  watch: {},
  computed: {
  },
  methods: {
    async init() {
      let monitorDoc = await db.collection("monitoring").doc(this.monitoring).get();
      let monitorData = monitorDoc.data();
      this.startMonth = monitorData.startMonth;
      this.startYear = monitorData.startYear;
    },

    getMonth() {
      console.info("teste");
    }
  },
  async created() {
    await this.init();
  },
};
</script>
