<template>
  <v-card flat color="transparent" class="" outlined>
    <v-card
      class="
        mt-2
        d-flex
        justify-center
        align-content-center
        flex-wrap
        transparent
      "
      width="100%"
      flat
    >
      <BarChart
        :info="kpis.population.gender"
        :chartTitle="'Pacientes por Sexo'"
      ></BarChart>
      <LineChart
        :seriesInfo="[
          {
            name: 'Pacientes',
            series: kpis.population.age,
            color: '#6fa8dc',
          },
        ]"
        :chartTitle="'Pacientes por Idade'"
        xaxisTitle="Idade"
      ></LineChart>
      <LineChart
        :chartTitle="'Pacientes do Sexo Feminino por Idade'"
        :seriesInfo="[
          {
            name: 'Feminino',
            series: kpis.population.genderByAge.Feminino,
            color: '#a64d79',
          },
        ]"
        xaxisTitle="Idade"
      ></LineChart>
      <LineChart
        :chartTitle="'Pacientes do Sexo Masculino por Idade'"
        :seriesInfo="[
          {
            name: 'Masculino',
            series: kpis.population.genderByAge.Masculino,
            color: '#16537e'
          },
        ]"
        xaxisTitle="Idade"
      ></LineChart>
      <BarChart
        :info="kpis.population.city"
        :chartTitle="'Pacientes por Cidade'"
      ></BarChart>
      <BarChart
        :info="kpis.population.state"
        :chartTitle="'Pacientes por Estado'"
      ></BarChart>
    </v-card>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ActionsMenu from "../../../ehr/components/actions/Menu.vue";
import BarChart from "../chart/BarChart.vue";
import LineChart from "../chart/LineChart.vue";

export default {
  name: "bar-chart",
  components: {
    BarChart,
    LineChart,
    ActionsMenu,
  },
  props: {
    kpis: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      tab: null,
    };
  },
  computed: {
    ...mapGetters({
      historic: "historic",
    }),
  },
  methods: {
    getColor(gender) {
      if (gender == "Feminino") return "pink";
      else return "primary";
    },
  },
  async created() {},
};
</script>
<style lang="scss">
@import "../../../../../scss/_variables.scss";

.data-studio {
  @media #{$xxlmax-device} {
    width: 100%;
    height: 600px;
  }

  @media #{$xlmax-device} {
    width: 100%;
    height: 700px;
  }

  @media #{$desktop-device} {
    width: 100%;
    height: 600px;
  }

  @media #{$tablet-device} {
    width: 100%;
    height: 800px;
  }

  @media #{$large-mobile} {
    height: 700px;
  }

  @media #{$extra-small-mobile} {
    height: 600px;
  }
}
</style>