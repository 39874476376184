import {firestoreAction} from "vuexfire";
import {db} from "../../firebase";

const getDefaultState = () => {
    return {
        session: {
            id: null,
            browserName: null,
            browserVersion: null,
            browserUa: null
        }
    };
};

const state = getDefaultState();

export default {
    state,
    mutations: {
        SET_SESSION(state, data) {
            state.session = data;
        },
        RESET_SESSION_STATE(state) {
            Object.assign(state, getDefaultState());
        }
    },
    actions: {
        fetchSession(context) {
            context.commit("RESET_SESSION_STATE");
            let session = {};
            session.browserName = this._vm.$browserDetect.meta.name;
            session.browserVersion = this._vm.$browserDetect.meta.version;
            session.browserUa = this._vm.$browserDetect.meta.ua;
            session.id = this._vm.$uuid.v4()
            context.commit("SET_SESSION", session);
        }
    },
    getters: {
        session(state) {
            return state.session;
        }
    }
};
