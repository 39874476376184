import WaitingRoom from "../../components/functional/rooms/WaitingRoom.vue";
import WaitingRoomHistorical from "../../components/functional/rooms/Historical.vue";
import CalendarNew from "../../components/functional/ehr/components/calendar/CalendarNew.vue";
import DataStudio from "../../components/functional/bi/DataStudio.vue";
import GlobalChat from "../../components/functional/chat/Chat.vue";
import Patients from "../../components/functional/patient/Patients.vue";
import PatientNew from "../../components/functional/patient/New.vue";
import Message from "../../components/functional/patient/message/Messages.vue";
import Monitoring from "../../components/functional/patient/monitoring/Monitors.vue";
import Evaluation from "../../components/functional/patient/evaluation/Evaluation.vue";
import Evaluations from "../../components/functional/patient/evaluation/Evaluations.vue";
import Monitors from "../../components/functional/patient/monitor/Monitors.vue";
import Monitor from "../../components/functional/patient/monitor/Monitor.vue";
import Journeys from "../../components/functional/patient/journey/Journeys.vue";
import JourneyFlow from "../../components/functional/patient/journey/JourneyFlow.vue";
import JourneysHistorical from "../../components/functional/patient/journey/Historical.vue";
import MonitorHistorical from "../../components/functional/patient/monitor/Historical.vue";
import MonitorSurveys from "../../components/functional/patient/monitor/surveys/Surveys.vue";
import JourneySurveys from "../../components/functional/patient/journey/surveys/Surveys.vue";
import EncounterSurveys from "../../components/functional/rooms/surveys/Surveys.vue";
import Tcles from "../../components/functional/patient/tcles/Tcles.vue";

export default [
  {
    path: "waitingRoom",
    name: "WaitingRoom",
    component: WaitingRoom,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "waitingRoomHistorical",
    name: "WaitingRoomHistorical",
    component: WaitingRoomHistorical,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "patients",
    name: "Patients",
    component: Patients,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "bi",
    name: "BI",
    component: DataStudio,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "calendarNew",
    name: "CalendarNew",
    component: CalendarNew,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "monitorSurveys",
    name: "MonitorSurveys",
    component: MonitorSurveys,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "journeySurveys",
    name: "JourneySurveys",
    component: JourneySurveys,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "encounterSurveys",
    name: "EncounterSurveys",
    component: EncounterSurveys,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "tcles",
    name: "Tcles",
    component: Tcles,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "journeys",
    name: "Journeys",
    component: Journeys,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "journeysHistorical",
    name: "JourneysHistorical",
    component: JourneysHistorical,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "journeyFlow",
    name: "JourneyFlow",
    component: JourneyFlow,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "monitors",
    name: "Monitors",
    component: Monitors,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "monitor",
    name: "Monitor",
    component: Monitor,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "monitorHistorical",
    name: "MonitorHistorical",
    component: MonitorHistorical,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "globalChat",
    name: "GlobalChat",
    component: GlobalChat,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "patientNew",
    name: "PatientNew",
    component: PatientNew,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "message",
    name: "Message",
    component: Message,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "monitoring",
    name: "Monitoring",
    component: Monitoring,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "evaluations",
    name: "Evaluations",
    component: Evaluations,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "evaluation",
    name: "Evaluation",
    component: Evaluation,
    meta: {
      requiresAuth: true,
    },
  },
];
