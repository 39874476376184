<template>
  <v-card v-if="items" flat color="transparent" class="" outlined>
    <v-data-iterator
      :items="items"
      :search="search"
      disable-pagination
      sort-by="description"
      :sort-desc="false"
      hide-default-footer
    >
      <template v-slot:header>
        <v-toolbar
          class="rounded mt-4 transparent"
          height="40"
          width="100%"
          elevation="0"
        >
          <v-text-field
            outlined
            width="100%"
            color="purple"
            v-model="search"
            dense
            clearable
            flat
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="Pesquisar"
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:default="props">
        <v-card
          class="
            d-flex
            justify-center
            align-content-center
            flex-wrap
            transparent
          "
          width="100%"
          flat
        >
          <div v-for="kpi in props.items" :key="kpi.key">
            <KpiOneValueCard
              v-if="kpi.view == 'text'"
              :kpi="kpi"
              :kpis="kpis"
            ></KpiOneValueCard>
            <KpiLineValueCard
              v-if="kpi.view == 'line'"
              :kpi="kpi"
              :kpis="kpis"
            ></KpiLineValueCard>
          </div>
        </v-card>
      </template>
    </v-data-iterator>
  </v-card>
</template>      

<script>
import KpiOneValueCard from "../card/KpiOneValue.vue";
import KpiLineValueCard from "../card/KpiLineValue.vue";

export default {
  name: "kpis",
  components: {
    KpiOneValueCard,
    KpiLineValueCard,
  },
  props: {
    kpis: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      tab: null,
      items: null,
      search: "",
    };
  },
  computed: {},
  methods: {
    getColor(gender) {
      if (gender == "Feminino") return "pink";
      else return "primary";
    },
  },
  mounted() {
    this.items = [];
    if (this.kpis.functions) {
      let functionsKeys = Object.keys(this.kpis.functions);
      for (let index = 0; index < functionsKeys.length; index++) {
        this.items.push(this.kpis.functions[functionsKeys[index]]);
      }
    }
    if (this.kpis["calculated-fields"]) {
      let cfKeys = Object.keys(this.kpis["calculated-fields"]);
      for (let index = 0; index < cfKeys.length; index++) {
        this.items.push(this.kpis["calculated-fields"][cfKeys[index]]);
      }
    }
  },
};
</script>
<style lang="scss">
@import "../../../../../scss/_variables.scss";

.data-studio {
  @media #{$xxlmax-device} {
    width: 100%;
    height: 600px;
  }

  @media #{$xlmax-device} {
    width: 100%;
    height: 700px;
  }

  @media #{$desktop-device} {
    width: 100%;
    height: 600px;
  }

  @media #{$tablet-device} {
    width: 100%;
    height: 800px;
  }

  @media #{$large-mobile} {
    height: 700px;
  }

  @media #{$extra-small-mobile} {
    height: 600px;
  }
}
</style>