<template>
  <v-dialog v-model="dialog" max-width="1200" eager>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
            elevation="0"
            icon
            color="primary"
            min-width="0"
            max-width="30"
            min-height="0"
            max-height="30"
          >
            <font-awesome-icon
              :icon="['fal', 'calendar-plus']"
              fixed-width
              style="font-size: 25px"
            />
          </v-btn>
        </template>
        <span>Agendamento</span>
      </v-tooltip>
    </template>
    <v-card><Schedule></Schedule></v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import Schedule from "./Schedule.vue";

export default {
  data: () => ({
    dialog: false,
  }),
  components: { Schedule },
  computed: {
    ...mapGetters({}),
  },
  watch: {},
  methods: {},
  destroyed() {},
};
</script>

<style>
</style>