import { firestoreAction } from "vuexfire";
import { db, functions } from "../../firebase";

export default {
  state: {
    message: null,
    messages: [],
  },
  actions: {
    unbindMessage: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef("message", true);
    }),
    bindMessages: firestoreAction(async (context, data) => {
      let ref = db.collection("message");
      ref = ref.where("new", "==", true);
      await context.bindFirestoreRef("messages", ref, {
        reset: true,
      });
    }),
    bindMessage: firestoreAction(async (context, data) => {
      let ref = db.collection("message").doc(data.customer);
      await context.bindFirestoreRef("message", ref, {
        reset: true,
      });
    }),
  },
  getters: {
    message(state) {
      return state.message;
    },
    messages(state) {
      return state.messages;
    },
  },
};
