<template>
  <vue-advanced-chat v-if="chat.messages != null && rooms.length > 0" height="calc(80vh)"
    :current-user-id="professional.id" :message-actions="JSON.stringify(messageActions)"
    :templates-text="JSON.stringify(templatesText)" :text-messages="JSON.stringify(textMessages)" :showSearch="false"
    :show-files="true" :show-audio="true" :show-emojis="true" :show-reaction-emojis="false"
    :show-new-messages-divider="true" :show-footer="true" :text-formatting="true" :textarea-action-enabled="false"
    :single-room="true" :rooms="JSON.stringify(rooms)" :rooms-loaded="true"
    accepted-files="image/png, image/jpeg, application/pdf" :messages="JSON.stringify(chat.messages)"
    :messages-loaded="JSON.stringify(messagesLoaded)" @send-message="sendMessage" @fetch-messages="fetchMessages"
    :styles="JSON.stringify({
      general: {
        color: '#0a0a0a',
        colorSpinner: '#333',
        borderStyle: '1px solid #e1e4e8',
      },
    
      container: {
        boxShadow:
          '0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)',
      },
    
      content: {
        background: '#f8f8f8',
      },
    
      footer: {
        background: '#ffffff',
        backgroundReply: 'rgba(0, 0, 0, 0.08)',
      },
    
      icons: {
        search: '#9ca6af',
      },
    })">
  </vue-advanced-chat>
</template>

<script>
import { mapGetters } from "vuex";
import { functions } from "../../../../../../firebase";
import { register } from 'vue-advanced-chat'
register()

export default {
  components: {
  },
  data() {
    return {
      telemedicine: "",
      textMessages: {
        ROOMS_EMPTY: "Sem conversa",
        ROOM_EMPTY: "Nenhuma conversa selecionada",
        NEW_MESSAGES: "Novas mensagens",
        MESSAGE_DELETED: "Esta mensagem foi removida",
        MESSAGES_EMPTY: "Nenhuma mensagem",
        CONVERSATION_STARTED: "A conversa começou em:",
        TYPE_MESSAGE: "Digite sua mensagem",
        SEARCH: "Pesquisar",
        IS_ONLINE: "Está online",
        LAST_SEEN: "Última conexão ",
        IS_TYPING: "está digitando...",
        CANCEL_SELECT_MESSAGE: 'Cancelar mensagem'
      },
      messageActions: [],
      templatesText: [],
      rooms: [],
      messagesLoaded: false,
      messages: [],
    };
  },
  computed: {
    ...mapGetters({
      chat: "chat",
      customer: "customer",
      professional: "professional"
    }),
  },
  methods: {
    async fetchMessages() {
      this.messagesLoaded = true
    },
    async sendMessage(message) {
      let sendMessage = functions.httpsCallable(
        "sis-conversation-send-message-api"
      );
      sendMessage({
        id: this.chat.id,
        senderId: this.professional.id,
        message: message.detail[0],
        username: this.professional.name,
        customer: this.customer,
        professional: this.professional,
      })
        .then((result) => {
          return;
        })
        .catch((error) => {
          console.error(error.message);
        });
    },
  },
  mounted() {
    let room = {};
    room.avatar =
      "https://firebasestorage.googleapis.com/v0/b/icareyou-dev.appspot.com/o/img%2Fbubble.png?alt=media&token=089ff61d-c6f8-4286-9539-65ccceeb2991";

    room.roomName = this.customer.fullName;
    room.roomId = this.chat.id;
    room.users = [];
    room.users.push({
      _id: this.customer.id,
      username: this.customer.name,
      status: {
        state: "online",
      },
    });
    room.users.push({
      _id: this.professional.id,
      username: this.professional.name,
      status: {
        state: "online",
      },
    });
    this.rooms.push(room);
  },
};
</script>