import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

export default {
  state: {
    notifications: [],
  },
  actions: {
    unbindNotifications: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef("notifications", true);
    }),
    bindNotifications: firestoreAction((context, data) => {
      try {
        let phone =
          data.phoneCode +
          data.phone
            .replace(/-/g, "")
            .replace(/\(/g, "")
            .replace(/\)/g, "")
            .replace(/ /g, "");
        let ref = db.collection("notifications");
        context.bindFirestoreRef(
          "notifications",
          ref.where("type", "==", "whatsapp-template").where("to", "==", phone),
          { reset: true }
        );
      } catch (e) {
        console.error(e);
      }
    }),
  },
  getters: {
    notifications(state) {
      return state.notifications;
    },
  },
};
