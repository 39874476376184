<template>
  <v-card v-if="tclesNotAccepteds.length > 0" flat width="100%" color="transparent" class="mt-4">
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate :rotate="360" :size="100" :width="8" color="white">
      </v-progress-circular>
    </v-overlay>

    <v-data-table class="elevation-0 transparent" :headers="headers" :items="tclesNotAccepteds" :search="search"
      :items-per-page="13" :calculate-widths="true" sort-by="created">


      <template v-slot:[`item.age`]="{ item }">
        <AgeChip :customer="item.customer"></AgeChip>
      </template>

      <template v-slot:[`item.customer`]="{ item }">
        <Name :customer="item.customer"></Name>
      </template>

      <template v-slot:[`item.created`]="{ item }">
        <span v-if="item.created" class="caption">{{
            item.created.toDate() | moment("DD/MM/YYYY hh:mm:ss")
        }}</span>
      </template>

      <template v-slot:[`item.date`]="{ item }">
        <span class="caption">{{
            (item.day < 10 ? '0' + item.day : item.day) + "/" + (item.month < 10 ? '0' + item.month : item.month) + "/" + item.year
        }}</span>
      </template>

      <template v-slot:[`item.time`]="{ item }">
        <span class="caption">{{
            (item.hour < 10 ? '0' + item.hour : item.hour) + ":" + (item.minute < 10 ? '0' + item.minute : item.minute) + ":" +
            (item.second < 10 ? '0' + item.second : item.second)
        }}</span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <ActionsMenu :patient="item.customer" :name="item.customerName"></ActionsMenu>
      </template>
    </v-data-table>
  </v-card>
  <v-card v-else color="transparent" flat width="100%" class="mt-4">
    <v-card-text class="subtitle-2 grey--text text--darken-1">Não há termos de consentimento!</v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import AgeChip from "../patient/AgeChip.vue";
import Name from "../patient/Name.vue";
import ActionsMenu from "../../../ehr/components/actions/Menu.vue";

export default {
  name: "app-monitoring",
  components: {
    AgeChip,
    ActionsMenu,
    Name
  },
  props: {
    search: {
      type: String,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      tab: null,
      overlay: false,
      dialog: false,
      headers: [
        {
          text: "ID",
          value: "id",
          filterable: false,
          sortable: false,
          align: " d-none",
        },
        {
          text: "Respondido há",
          value: "created",
          filterable: false,
          sortable: false,
        },
        { text: "Paciente", value: "customer", sortable: false },
        { text: "Idade", value: "age", filterable: false, sortable: false },
        { text: "Data", value: "date", sortable: false },
        { text: "Hora", value: "time", sortable: false },
        { text: "Ações", value: "actions", filterable: false, sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters({
      tclesNotAccepteds: "tclesNotAccepteds",
    }),
  },
  methods: {

    getColor(gender) {
      if (gender == "Feminino") return "pink";
      else return "primary";
    },
  }
};
</script>