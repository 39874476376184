<template>
  <div>
    <v-hover v-slot="{ hover }">
      <v-card
        :elevation="hover ? 16 : 2"
        :class="{ 'on-hover': hover }"
        class="mr-4 mt-4 d-flex flex-column"
        width="300"
        min-height="280"
        color="grey lighten-5"
      >
        <div class="d-flex flex-no-wrap justify-space-between">
          <v-card-title class="text-caption inline-block text-truncate">
            <font-awesome-icon
              class="amber--text text--darken-3 mb-1"
              :icon="['fal', card.icon]"
              fixed-width
              style="font-size: 15px"
            />
            <span class="mb-1 ml-1 font-weight-black">{{ card.text }}</span>
          </v-card-title>

          <!--v-avatar
          v-if="card.info.class == 'Urgente'"
          size="70"
          rounded
          class="ma-3"
          color="error"
        >
          <span class="white--text text-h5 mx-0 font-weight-black"
            >~{{ card.info.value }}%</span
          >
        </v-avatar>

        <v-avatar v-else size="70" rounded class="ma-3" color="primary">
          <span class="white--text text-h5 mx-0 font-weight-black"
            >~{{ card.info.value }}%</span
          >
        </v-avatar-->
        </div>
        <v-card-text class="mt-n5 text-justify text-caption text--purple">
          <span class="text-caption">
            <span class="font-weight-black">Sintomas Associados:</span>
            {{ card.info.associatedSymptoms }}</span
          >
        </v-card-text>

        <v-spacer></v-spacer>
        <v-divider></v-divider>
        <v-card-actions>
          <v-chip
            v-if="card.info.class == 'Urgente'"
            label
            small
            color="error"
            text-color="white"
            class="mx-0 text-left text-caption font-weight-black"
          >
            {{ card.info.class }}
          </v-chip>
          <v-chip
            v-else
            label
            small
            color="primary"
            text-color="white"
            class="mx-0 text-left text-caption font-weight-black"
          >
            {{ card.info.class }}
          </v-chip>
          <v-spacer></v-spacer>
          <SuggestionDetails :id="card.id" :card="card"></SuggestionDetails>
        </v-card-actions>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SuggestionDetails from "./SuggestionDetails";

export default {
  components: {
    SuggestionDetails,
  },
  data() {
    return {
      info: null,
      history: null,
    };
  },
  props: {
    card: Object,
  },
  computed: {
    ...mapGetters({
      customer: "customer",
    }),
  },

  mounted() {},
};
</script>

<style></style>
