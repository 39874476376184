import { app } from "@/main.js";

export default class SecurityService {
  static verifyButton(action) {
    const buttons = app.$store.getters.buttons;

    if (buttons.find((b) => b.action === action)) {
      return true;
    } else {
      return false;
    }
  }

  static verifyGroupOfButtons(actions) {
    const buttons = app.$store.getters.buttons;
    let show = false;
    for (let index = 0; index < actions.length; index++) {
      const action = actions[index];
      if (buttons.find((b) => b.action === action)) {
        show = true;
      }
    }
    return show;
  }
}
