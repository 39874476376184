import { db, functions, firebase } from "../../firebase";
import { firestoreAction } from "vuexfire";
import LoggerService from "../../Services/LoggerService";
const path = require("path");

export default {
  state: {
    professional: {},
    menu: [],
    items: [],
    buttons: [],
    evaluators: [],
  },
  mutations: {
    SET_PROFESSIONAL(state, value) {
      state.professional = value;
    },
    SET_MENU(state, value) {
      state.menu = value;
    },
    SET_ITEM(state, value) {
      state.items = value;
    },
    SET_BUTTONS(state, value) {
      state.buttons = value;
    },
  },
  actions: {
    bindEvaluators: firestoreAction((context) => {
      let ref = db.collection("professionals");
      context.bindFirestoreRef(
        "evaluators",
        ref.where("evaluator", "==", true),
        {
          wait: true,
          reset: true,
        }
      );
    }),
    async fetchProfessional(context, professional) {
      LoggerService.info(`Starting fetch Professional for: ${professional.id}`);
      await context.dispatch("bindViews");
      LoggerService.debug(`Views fetched...`);
      await context.dispatch("bindRooms");
      LoggerService.debug(`Rooms fetched...`);
      await context.dispatch("bindTcles");
      LoggerService.debug(`TCLEs fetched...`);
      await context.dispatch("bindMonitors");
      LoggerService.debug(`Monitors fetched...`);
      await context.dispatch("bindJourney");
      LoggerService.debug(`Journey fetched...`);

      context.dispatch("bindTelemedicineRooms");
      context.dispatch("bindResources");
      context.dispatch("bindWhatsappTemplates");
      context.dispatch("bindKnowledgebase");
      context.dispatch("fetchTelemedicine");
      context.dispatch("bindMessages");
      LoggerService.debug(
        "Telemedicine Rooms, Resources, WhatsAppTemplates, KnowledgeBase, Telemedicine and Messages fetched..."
      );

      context.dispatch("fetchCID10");
      LoggerService.debug("CID 10 fetched...");

      let professionalRef = db.collection("professionals").doc(professional.id);

      LoggerService.debug(`Professional profile: ${professional.profile}`);
      let profileRef = db
        .collection("profiles")
        .where("queryId", "==", professional.profile);

      let profileData = await profileRef.get();
      let profileDoc = profileData.docs[0].data();

      let _menus = [];
      for (let index = 0; index < profileDoc.menus.length; index++) {
        if (profileDoc.menus[index].active) {
          _menus.push(profileDoc.menus[index]);
        }
      }
      context.commit("SET_MENU", _menus);
      LoggerService.debug("Professional menus: ", _menus);

      let _items = [];
      for (let index = 0; index < profileDoc.items.length; index++) {
        if (profileDoc.items[index].active) {
          _items.push(profileDoc.items[index]);
        }
      }

      context.commit("SET_ITEM", _items);
      LoggerService.debug("Professional items: ", _items);

      let _buttons = [];
      for (let index = 0; index < profileDoc.buttons.length; index++) {
        if (profileDoc.buttons[index].active) {
          _buttons.push(profileDoc.buttons[index]);
        }
      }

      context.commit("SET_BUTTONS", _buttons);
      LoggerService.debug("Professional buttons: ", _buttons);

      LoggerService.debug(
        `Professional type: ${professional.professionalType}`
      );
      if (
        (professional.professionalType == "physician" ||
          professional.professionalType == "technology") &&
        professional.memedActive
      ) {
        await context.dispatch("fetchMemed", professional);
        LoggerService.debug("Memed fetched");

        let getToken = functions.httpsCallable("memed-get-token-api");

        await getToken({ professional: professional.id, type: "cpf" })
          .then(async (result) => {
            LoggerService.debug("Memed token refreshed");
            let token = result.data.data.attributes.token;

            await professionalRef
              .update(
                {
                  memed: {
                    city: professional.memed.city,
                    specialty: professional.memed.specialty,
                    state: professional.memed.state,
                    token: token,
                  },
                },
                { merge: true }
              )
              .then(() => {
                LoggerService.info("Memed token updated into professional");
                professional.memed.token = token;
                context.commit("SET_PROFESSIONAL", professional);
                LoggerService.info("Professional loaded with success");
              });
          })
          .catch((error) => {
            LoggerService.error(error.message, error);
            context.commit("SET_PROFESSIONAL", professional);
            LoggerService.warn(
              "Professional loaded with errors during memed token refresh process"
            );
          });
      } else {
        context.commit("SET_PROFESSIONAL", professional);
        LoggerService.info("Professional loaded with success");
      }
    },
  },
  getters: {
    professional(state) {
      return state.professional;
    },
    menu(state) {
      return state.menu;
    },
    buttons(state) {
      return state.buttons;
    },
    items(state) {
      return state.items;
    },
    evaluators(state) {
      return state.evaluators;
    },
  },
};
